import React from "react";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

import Spinner from "../ExcalidrawAPI/components/Spinner";

import "../scss/FloatingSaveDiscard.scss";

//TODO - You might need to enhance the placement and orientation.
const FloatingSaveDiscard = ({
  classes,
  orientation,
  isShown,
  isLoading,
  buttonToShow = "both",
  onSave,
  onDiscard,
}) => {
  //Atom

  //Functions
  const getOrientationClassName = () => {
    const propClasses = classes?.join(" ");
    if (orientation === "vertical") {
      return `floating-save-discard-buttons-container floating-save-discard-buttons-container_vertical ${propClasses}`;
    }
    return `floating-save-discard-buttons-container ${propClasses}`;
  };

  const saveHandler = () => {
    onSave && onSave();
  };

  const discardHandler = () => {
    onDiscard && onDiscard();
  };

  //Render
  return isShown ? (
    <section className={getOrientationClassName()}>
      <div className="floating-save-discard_actions">
        {["both", "discard"].includes(buttonToShow) && (
          <button
            title="Discard"
            className="floating-save-discard_action floating-save-discard_discard"
            disabled={isLoading}
            onClick={discardHandler}
          >
            <ClearRoundedIcon />
          </button>
        )}

        {buttonToShow === "both" && (
          <div className="floating-save-discard-divider"></div>
        )}

        {["both", "save"].includes(buttonToShow) && (
          <button
            title={buttonToShow === "both" ? "Save" : undefined}
            className={`floating-save-discard_action floating-save-discard_save${
              buttonToShow === "save" ? "_only" : ""
            }`}
            disabled={isLoading}
            onClick={saveHandler}
          >
            {isLoading ? <Spinner size="1.1rem" /> : <CheckRoundedIcon />}
          </button>
        )}
      </div>
    </section>
  ) : null;
};

export default FloatingSaveDiscard;
