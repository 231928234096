import { useAtom } from "jotai";

import _ from "lodash";

import {
  currentDrawingAtom,
  currentDrawingsAtom,
  currentProjectAtom,
  excalidrawApiAtom,
  keyContactsAtom,
  usersAtom,
} from "../store/variables";
import { newElementWith } from "../ExcalidrawAPI/element/mutateElement";
import { randomId, randomInteger } from "../ExcalidrawAPI/random";
import { getUpdatedTimestamp } from "../ExcalidrawAPI/utils";
import { redrawTextBoundingBox } from "../ExcalidrawAPI/element";
import {
  BAR_CODE_WIDTH,
  BAR_CODE_HEIGHT,
  COVER_SHEET_TEXT_FONT_SIZE,
  FRAME_HEIGHT,
  FRAME_WIDTH,
  KEY_CONTACTS_ROLES,
  MARGIN_UNIT,
  NUMBER_OF_PLAYERS,
  PIXELS_PER_FEET,
  NORTH_ICON,
} from "../helpers/constants";
import {
  convertDesignersObjToText,
  getFirstLettersFromCurrentUser,
  getMappedChange,
  getUnwrappedText,
  getUpdateTimeElement,
  getUsedHeader,
  preventTextWrapping,
} from "../helpers/commonFrameFunctions";
import {
  checkIfObjectRepresentsDate,
  formatDrawingOrder,
  getDataFromTimestamp,
} from "../helpers/common";
import { legalText } from "../helpers/staticPlayersData";
import { drawGridRuler } from "../helpers/drawGridRuler";
import { useRulerDrawer } from "./useRulerDrawer";
import { isOpeningSceneAtom, isSyncingAtom } from "../store/UI";
import { useCommonFunctionsHook } from "./useCommonFunctionsHook";
import { useImageMethods } from "./useImageMethods";

//Constants
const propsInCommon = {
  angle: 0,
  backgroundColor: "transparent",
  boundElements: [],
  customData: { isImmutable: true },
  fillStyle: "solid",
  isDeleted: false,
  link: null,
  locked: true,
  opacity: 100,
  roughness: 0,
  roundness: null,
  strokeColor: "#000000",
  strokeStyle: "solid",
  strokeWidth: 1,
  seed: randomInteger(),
  version: randomInteger(),
  versionNonce: randomInteger(),
  updated: getUpdatedTimestamp(),
};

const groupId = randomId();

const rectangleElement = {
  ...propsInCommon,
  id: randomId(),
  locked: true,
  type: "rectangle",
  groupId,
  strokeColor: "transparent",
};

const lineElement = {
  ...propsInCommon,
  id: randomId(),
  lastCommittedPoint: null,
  roundness: { type: 2 },
  type: "line",
  groupId,
};

const textElement = {
  ...propsInCommon,
  baseline: 0,
  fontFamily: "Arial",
  id: randomId(),
  type: "text",
  groupId,
};

export const useFrameMethods = () => {
  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [currentProject] = useAtom(currentProjectAtom);
  const [currentDrawing] = useAtom(currentDrawingAtom);
  const [currentDrawings] = useAtom(currentDrawingsAtom);
  const [users] = useAtom(usersAtom);
  const [keyContacts] = useAtom(keyContactsAtom);
  const [, setIsSyncing] = useAtom(isSyncingAtom);
  const [, setIsOpeningScene] = useAtom(isOpeningSceneAtom);

  //Effects

  //Custom Hooks
  const { drawScaleRuler, redrawScaleRuler } = useRulerDrawer();
  const { doAfterLoadingScene, fixS3Url } = useCommonFunctionsHook();
  const { renderImageElement } = useImageMethods();

  //Functions
  const updateScene = (destination, update) => {
    const sceneData = {
      [destination]: update,
    };
    excalidrawAPI?.updateScene(sceneData);
  };

  const getLabelHeight = () => {
    const HEIGHT_IN_FEET = 2;
    return HEIGHT_IN_FEET * PIXELS_PER_FEET;
  };

  const getDataHeight = () => {
    const HEIGHT_IN_FEET = 22;
    return HEIGHT_IN_FEET * PIXELS_PER_FEET;
  };

  const getOffsetX = (cells) => {
    return cells.reduce((acc, suc) => acc + suc.count * suc.width, 0);
  };

  const getFontSize = (cellWidth, cellHeight) => {
    let fontSize = 0;
    if (cellHeight / cellWidth >= 1.75) {
      fontSize = 0.055 * cellWidth;
    } else if (cellWidth / cellHeight >= 1.75) {
      fontSize = 0.055 * cellHeight;
    } else {
      fontSize = 0.045 * Math.min(cellHeight, cellWidth);
    }
    return fontSize;
  };

  const drawNorthDirection = async ({ projectToUse }) => {
    const validProject = projectToUse || currentProject;
    const IMAGE_MARGIN = 6 * PIXELS_PER_FEET;
    const imageDimension = 7 * PIXELS_PER_FEET;
    const customData = {
      valueFor: "North Icon",
      isImmutable: true,
    };
    const imageProps = {
      url: fixS3Url(NORTH_ICON),
      width: imageDimension,
      height: imageDimension,
      offset: { x: IMAGE_MARGIN, y: IMAGE_MARGIN },
      imageType: "north-icon",
      angle: validProject.northHeading ?? 0,
      isToReposition: false,
      customData,
    };

    await renderImageElement(imageProps);
  };

  const fillDrawingDetails = ({
    rectProps,
    offset,
    content,
    solid,
    reverse,
    currentProject,
  }) => {
    const basicTextProps = {
      groupIds: [groupId],
      boundElements: [],
    };

    const LABEL_HEIGHT = 0.25 * rectProps.unitHeight;
    const CONTENT_HEIGHT = rectProps.height - LABEL_HEIGHT;

    const elementsToReturn = [];
    const [textId, cellId] = [randomId(), randomId()];
    const [key, value] = Object.entries(content);
    let labelToRender = `${key[1]}:`;
    if (!key[1]) {
      labelToRender = "";
    }

    const labelText = newElementWith(textElement, {
      ...basicTextProps,
      id: textId,
      containerId: cellId,
      textAlign: reverse ? "right" : "left",
      verticalAlign: "middle",
      fontSize: 0.18 * rectProps.unitHeight,
      text: labelToRender,
      originalText: labelToRender,
      height: LABEL_HEIGHT,
      baseline: 0.65 * LABEL_HEIGHT,
    });

    const labelRect = newElementWith(rectangleElement, {
      id: cellId,
      groupIds: [groupId],
      boundElements: [{ id: textId, type: "text" }],
      height: LABEL_HEIGHT,
      width: rectProps.width,
      x: offset.x,
      y: offset.y + 2,
      backgroundColor: "transparent",
    });

    redrawTextBoundingBox(labelText, labelRect);

    if (key[1] === "PROJECT NO") {
      const imageProps = {
        url: currentProject.barcode,
        width: BAR_CODE_WIDTH,
        height: BAR_CODE_HEIGHT,
        offset: {
          x: offset.x + (rectProps.width - BAR_CODE_WIDTH) / 2 - 3,
          y: offset.y + (rectProps.height - BAR_CODE_HEIGHT - 5),
        },
        imageType: "barcode",
        isToReposition: false,
        customData: { isImmutable: true },
      };

      renderImageElement(imageProps);
    }
    let contentToRender = value[1];
    if (typeof contentToRender === "object") {
      if (checkIfObjectRepresentsDate(contentToRender)) {
        contentToRender = getDataFromTimestamp(contentToRender);
      }
    }

    const [contentRectId, contentTextId] = [randomId(), randomId()];
    let contentText = newElementWith(textElement, {
      ...basicTextProps,
      id: contentTextId,
      containerId: contentRectId,
      textAlign: key[1] === "PROJECT NO" ? "center" : "left",
      verticalAlign: "top",
      fontSize: 0.21 * rectProps.unitHeight,
      text: contentToRender,
      originalText: contentToRender,
      height: CONTENT_HEIGHT,
      width: rectProps.width,
      baseline: 0.65 * CONTENT_HEIGHT,
      customData: {
        order: key[1] === "PAGE NO" ?? false,
        valueFor: labelToRender,
        isEditableText: true,
        ...propsInCommon.customData,
      },
    });

    const contentRect = newElementWith(rectangleElement, {
      id: contentRectId,
      groupIds: [groupId],
      boundElements: [{ id: contentTextId, type: "text" }],
      height: CONTENT_HEIGHT,
      width: rectProps.width,
      x: offset.x,
      y: offset.y + LABEL_HEIGHT,
      backgroundColor: "transparent",
    });

    if (solid) {
      contentText = getUnwrappedText(
        contentText,
        contentRect,
        0.21 * rectProps.unitHeight,
      );
    }

    redrawTextBoundingBox(contentText, contentRect);
    elementsToReturn.push(contentText, contentRect);

    elementsToReturn.push(labelText, labelRect);
    return elementsToReturn;
  };

  const drawVerticalDividers = ({
    prevDividers,
    cellProps,
    start,
    iterator,
  }) => {
    return {
      ...lineElement,
      id: randomId(),
      groupIds: [groupId],
      x:
        iterator === 0
          ? start.x + cellProps.width
          : prevDividers[prevDividers.length - 1].x + cellProps.width,
      y: start.y,
      points: [
        [0, 0],
        [0, cellProps.height],
      ],
      width: 0,
      height: cellProps.height,
    };
  };

  const createCellsWithContent = (
    type,
    { rectProps, offset, solid, content },
  ) => {
    const [textId, cellId] = [randomId(), randomId()];

    const basicTextProps = {
      groupIds: [groupId],
      boundElements: [],
    };

    if (type === "label") {
      let labelText = newElementWith(textElement, {
        ...basicTextProps,
        id: textId,
        containerId: cellId,
        textAlign: "left",
        verticalAlign: "middle",
        fontSize: 0.4 * rectProps.height,
        text: content,
        originalText: content,
        height: 0.5 * rectProps.height,
        baseline: 0.65 * 0.5 * rectProps.height,
      });

      const labelRect = newElementWith(rectangleElement, {
        id: cellId,
        groupIds: [groupId],
        boundElements: [{ id: textId, type: "text" }],
        height: rectProps.height,
        width: rectProps.width,
        x: offset.x,
        y: offset.y,
        backgroundColor: "transparent",
      });

      if (solid) {
        if (solid) {
          labelText = getUnwrappedText(
            labelText,
            labelRect,
            0.4 * rectProps.height,
          );
        }
      }

      redrawTextBoundingBox(labelText, labelRect);
      return [labelText, labelRect];
    } else if (type === "data") {
      const [part1RectId, part1TextId, part2RectId, part2TextId] = [
        randomId(),
        randomId(),
        randomId(),
        randomId(),
      ];

      const part1Text = newElementWith(textElement, {
        ...basicTextProps,
        id: part1TextId,
        containerId: part1RectId,
        textAlign: "center",
        verticalAlign: "middle",
        fontSize: COVER_SHEET_TEXT_FONT_SIZE - 4,
        text: !content.logo
          ? `${content.customerName?.trim().toUpperCase() || ""}`
          : "",
        originalText: !content.logo
          ? `${content.customerName?.toUpperCase() || ""}`
          : "",
      });

      const part1Rect = newElementWith(rectangleElement, {
        id: part1RectId,
        groupIds: [groupId],
        boundElements: [{ id: part1TextId, type: "text" }],
        height: rectProps.height / 2,
        width: rectProps.width,
        x: offset.x,
        y: offset.y,
        backgroundColor: "transparent",
      });

      if (content && content.logo) {
        const IMAGE_MARGIN = 0.2 * part1Rect.height;
        const imageDimension =
          Math.min(rectProps.width, part1Rect.height) - IMAGE_MARGIN;
        const imageProps = {
          url: content.logo,
          width: imageDimension,
          height: imageDimension,
          offset: {
            x: offset.x + (rectProps.width - imageDimension) / 2,
            y: offset.y + IMAGE_MARGIN / 2,
          },
          imageType: "company-logo",
          isToReposition: false,
          customData: { isImmutable: true },
        };

        renderImageElement(imageProps);
      }

      const getCustomerInfoAsString = (customerInfo) => {
        if (customerInfo.empty || !customerInfo) {
          return "";
        }
        const { addresses, phones, website, logo, customerName } = customerInfo;
        const primaryAddress = addresses.find((el) => el.primary);
        const primaryPhone = phones.find((el) => el.primary);
        const { house, streetName, city, state, zipCode } = primaryAddress;
        return `${
          logo ? customerName.trim() : ""
        }\n\n ${house} ${streetName}\n ${city}, ${state} ${zipCode}\n ${
          primaryPhone.number ?? ""
        }\n ${website ?? ""}`;
      };

      const customerInfoAsString = getCustomerInfoAsString(content);

      const part2Text = newElementWith(textElement, {
        ...basicTextProps,
        id: part2TextId,
        containerId: part2RectId,
        textAlign: "center",
        verticalAlign: "middle",
        fontSize: COVER_SHEET_TEXT_FONT_SIZE - 4,
        text: customerInfoAsString,
        originalText: customerInfoAsString,
      });

      const part2Rect = newElementWith(rectangleElement, {
        id: part2RectId,
        groupIds: [groupId],
        boundElements: [{ id: part2TextId, type: "text" }],
        height: rectProps.height / 2,
        width: rectProps.width,
        x: offset.x,
        y: offset.y + rectProps.height / 2,
        backgroundColor: "transparent",
      });

      redrawTextBoundingBox(part1Text, part1Rect);
      redrawTextBoundingBox(part2Text, part2Rect);

      const result = [part1Text, part2Text, part1Rect, part2Rect];

      return result;
    }
  };

  const createCellsOfPlayersSection = ({
    start,
    cellWidth,
    numberOfCells,
    options,
    currentProject,
    excalidrawAPI,
  }) => {
    const verticalDividers = [];
    const horizontalDividers = [];
    const rectangleCells = [];
    const drawingDetailsCells = [];
    const SECTION_HEIGHT = 24 * PIXELS_PER_FEET;
    const DATA_HEIGHT = getDataHeight(PIXELS_PER_FEET);
    const LABEL_HEIGHT = getLabelHeight(PIXELS_PER_FEET);
    const startInY = FRAME_HEIGHT - SECTION_HEIGHT;
    for (let i = 0; i < numberOfCells; i++) {
      const verticalDivider = drawVerticalDividers({
        groupId,
        prevDividers: verticalDividers,
        cellProps: { width: cellWidth, height: SECTION_HEIGHT },
        start: { x: start, y: startInY },
        iterator: i,
      });
      verticalDividers.push(verticalDivider);
      const MARGIN_LEFT = 6;
      const offsetX =
        i === 0
          ? start + MARGIN_LEFT
          : verticalDividers[verticalDividers.length - 1].x -
            cellWidth +
            MARGIN_LEFT;
      if (options.hasContent) {
        const cellsWithLabels = createCellsWithContent("label", {
          groupId,
          rectProps: { width: cellWidth - MARGIN_LEFT, height: LABEL_HEIGHT },
          offset: { x: offsetX, y: startInY },
          content: options.data[i]?.role || "",
          solid: options.solid,
          excalidrawAPI,
        });

        const cellsWithData = createCellsWithContent("data", {
          groupId,
          rectProps: { width: cellWidth, height: DATA_HEIGHT },
          offset: { x: offsetX - MARGIN_LEFT, y: startInY + LABEL_HEIGHT },
          content: options.data[i] || "",
          solid: options.solid,
          excalidrawAPI,
        });
        rectangleCells.push(...cellsWithLabels, ...cellsWithData);
      } else if (options.isDivided) {
        let unitHeight = 0;
        if (options.divisions) {
          unitHeight =
            SECTION_HEIGHT / options.divisions.reduce((acc, suc) => acc + suc);

          for (let i = 0; i < options.divisionsCount; i++) {
            const offsetY =
              i === 0
                ? startInY + options.divisions[i] * unitHeight
                : horizontalDividers[i - 1].y +
                  options.divisions[i] * unitHeight;

            if (i < options.divisionsCount - 1) {
              horizontalDividers.push(
                newElementWith(lineElement, {
                  id: randomId(),
                  groupIds: [groupId],
                  x: start,
                  y: offsetY,
                  points: [
                    [0, 0],
                    [cellWidth, 0],
                  ],
                  width: cellWidth,
                  height: 0,
                }),
              );
            }

            drawingDetailsCells.push(
              ...fillDrawingDetails({
                groupId,
                rectProps: {
                  width: cellWidth - MARGIN_LEFT,
                  height: options.divisions[i] * unitHeight,
                  unitHeight,
                },
                offset: {
                  x: start + MARGIN_LEFT,
                  y: offsetY - options.divisions[i] * unitHeight,
                },
                content: options.data[i] || "",
                currentProject,
                excalidrawAPI,
              }),
            );
          }
        } else {
          unitHeight = SECTION_HEIGHT / options.divisionsCount;

          for (let i = 0; i < options.divisionsCount; i++) {
            const offsetY =
              i === 0
                ? startInY + unitHeight
                : horizontalDividers[i - 1].y + unitHeight;

            if (i < options.divisionsCount - 1) {
              horizontalDividers.push(
                newElementWith(lineElement, {
                  id: randomId(),
                  groupIds: [groupId],
                  x: start,
                  y: offsetY,
                  points: [
                    [0, 0],
                    [cellWidth, 0],
                  ],
                  width: cellWidth,
                  height: 0,
                }),
              );
            }

            drawingDetailsCells.push(
              ...fillDrawingDetails({
                groupId,
                rectProps: {
                  width: cellWidth - MARGIN_LEFT,
                  height: unitHeight,
                  unitHeight,
                },
                offset: {
                  x: start + MARGIN_LEFT,
                  y: offsetY - unitHeight,
                },
                content: options.data[i] || "",
                solid: options.solid,
                reverse: options.reverse,
                currentProject,
                excalidrawAPI,
              }),
            );
          }
        }
      } else if (options.isLegalCell) {
        const [textId, cellId] = [randomId(), randomId()];

        const fontSize = getFontSize(cellWidth, SECTION_HEIGHT);

        const container = newElementWith(rectangleElement, {
          id: cellId,
          groupIds: [groupId],
          boundElements: [{ id: textId, type: "text" }],
          height: SECTION_HEIGHT,
          width: cellWidth,
          x:
            i === 0
              ? start
              : verticalDividers[verticalDividers.length - 1].x - cellWidth,
          y: startInY,
        });
        const textItself = newElementWith(textElement, {
          id: textId,
          groupIds: [groupId],
          containerId: cellId,
          boundElements: [],
          text: options.legalText,
          originalText: options.legalText,
          textAlign: "center",
          verticalAlign: "middle",
          fontSize,
          x:
            i === 0
              ? start + 3
              : verticalDividers[verticalDividers.length - 1].x - cellWidth + 3,
          y: startInY,
          height: 0.8 * SECTION_HEIGHT,
          width: 0.75 * cellWidth,
        });

        rectangleCells.push(container, textItself);
        redrawTextBoundingBox(textItself, container);
      }
    }

    return [
      ...rectangleCells,
      ...verticalDividers,
      ...horizontalDividers,
      ...drawingDetailsCells,
    ];
  };

  const drawOuterFrame = async () => {
    const headerText = await getUsedHeader();
    //NOTE - The frame consists of three perpendicular lines Π
    const [textId, containerId] = [randomId(), randomId()];

    const headerTextElement = {
      ...textElement,
      id: textId,
      groupIds: [groupId],
      containerId,
      boundElements: [],
      fontSize: COVER_SHEET_TEXT_FONT_SIZE,
      width: FRAME_WIDTH,
      textAlign: "left",
      verticalAlign: "middle",
      text: `${headerText}`,
      originalText: `${headerText}`,
    };

    const headerRectElement = {
      ...rectangleElement,
      id: containerId,
      groupIds: [groupId],
      height: 2 * MARGIN_UNIT,
      width: FRAME_WIDTH,
      x: MARGIN_UNIT,
      y: 0,
    };
    redrawTextBoundingBox(headerTextElement, headerRectElement);

    const frameElements = [
      //Line #1 left vertical line
      {
        ...lineElement,
        id: randomId(),
        groupIds: [groupId],
        x: 0,
        y: 0,
        points: [
          [0, 0],
          [0, FRAME_HEIGHT],
        ],
        width: 0,
        height: FRAME_HEIGHT,
      },
      //Line #2 top horizontal line
      {
        ...lineElement,
        id: randomId(),
        groupIds: [groupId],
        x: 0,
        y: 0,
        points: [
          [0, 0],
          [FRAME_WIDTH, 0],
        ],
        width: FRAME_WIDTH,
        height: 0,
      },
      //Line #3 right vertical line
      {
        ...lineElement,
        id: randomId(),
        groupIds: [groupId],
        x: FRAME_WIDTH,
        y: 0,
        points: [
          [0, 0],
          [0, FRAME_HEIGHT],
        ],
        width: 0,
        height: FRAME_HEIGHT,
      },
    ];

    return [...frameElements, headerTextElement, headerRectElement];
  };

  const drawPlayersSection = ({ drawingToUse, projectToUse }) => {
    const SECTION_HEIGHT = 24 * PIXELS_PER_FEET;
    const LABEL_HEIGHT = getLabelHeight(PIXELS_PER_FEET);

    //The total width contains NUMBER_OF_PLAYERS + 8.5 cells (1 * 1.25 + 1 x 1 + 1 x 2.5 + 1 x 1.25 + 1 x 2.5 )
    //Cell Area = cell width x 24' (SECTION_HEIGHT)
    // const NUMBER_OF_PLAYERS = 7;
    const validDrawing = drawingToUse || currentDrawing || {};
    const validProject = projectToUse || currentProject;
    const spaceSections = NUMBER_OF_PLAYERS * 1.25 + 8.5;
    const cellWidth = FRAME_WIDTH / spaceSections;
    const lastOrderOfDrawings =
      (currentDrawings?.filter((el) => el.type !== "coverSheet").length || 0) +
      2; //We added 2 as the current drawings don't include the one to be drawn or the cover sheet.
    const creator = getFirstLettersFromCurrentUser(
      validDrawing?.creator || "",
      users,
    );
    const designers = convertDesignersObjToText(
      validDrawing?.designers || [],
      users,
    );
    const frameOuterLines = [
      {
        ...lineElement,
        id: randomId(),
        groupIds: [groupId],
        x: 0,
        y: FRAME_HEIGHT - SECTION_HEIGHT,
        points: [
          [0, 0],
          [FRAME_WIDTH, 0],
        ],
        width: FRAME_WIDTH,
        height: 0,
      },
      {
        ...lineElement,
        id: randomId(),
        groupIds: [groupId],
        x: 0,
        y: FRAME_HEIGHT - (SECTION_HEIGHT - LABEL_HEIGHT),
        points: [
          [0, 0],
          [NUMBER_OF_PLAYERS * (1.25 * cellWidth), 0],
        ],
        width: FRAME_WIDTH,
        height: 0,
      },
      {
        ...lineElement,
        id: randomId(),
        groupIds: [groupId],
        x: 0,
        y: FRAME_HEIGHT,
        points: [
          [0, 0],
          [FRAME_WIDTH, 0],
        ],
        width: FRAME_WIDTH,
        height: 0,
      },
    ];

    const cellsArray = [
      {
        offsetX: getOffsetX([]),
        cellWidth: 1.25 * cellWidth,
        cellsCount: NUMBER_OF_PLAYERS,
        options: {
          hasContent: true,
          labels: KEY_CONTACTS_ROLES,
          data: keyContacts,
          solid: true,
        },
      },
      {
        offsetX: getOffsetX([
          { count: NUMBER_OF_PLAYERS, width: 1.25 * cellWidth },
        ]),
        cellWidth: 1.25 * cellWidth,
        cellsCount: 1,
        options: {
          isLegalCell: true,
          legalText,
        },
      },
      {
        offsetX: getOffsetX([
          { count: NUMBER_OF_PLAYERS + 1, width: 1.25 * cellWidth },
        ]),
        cellWidth: 1 * cellWidth,
        cellsCount: 1,
        options: {
          isDivided: true,
          divisionsCount: 6,
          solid: true,
          data: [
            { label: "MAP NO", content: "12c" },
            {
              label: "BLOCK NO",
              content: validProject.blocK || "DEFAULT BLOCK",
            },
            { label: "LOT NO", content: validProject.lot || "DEFAULT LOT" },
            { label: "DESIGNED BY", content: "REA" },
            { label: "DRAWN BY", content: designers || creator },
            { label: "REVIEWED BY", content: creator },
          ],
        },
      },
      {
        offsetX: getOffsetX([
          { count: NUMBER_OF_PLAYERS + 1, width: 1.25 * cellWidth },
          { count: 1, width: cellWidth },
        ]),
        cellWidth: 2.5 * cellWidth,
        cellsCount: 1,
        options: {
          isDivided: true,
          divisionsCount: 4,
          divisions: [2, 1, 2, 1],
          data: [
            {
              label: "DRAWING TITLE",
              content:
                validDrawing?.name.trim().toUpperCase() || "DEFAULT TITLE",
            },
            {
              label: "PROJECT NAME",
              content: validProject.projectName || "DEFAULT NAME",
            },
            {
              label: "PROJECT ADDRESS",
              content: validProject.address || "DEFAULT ADDRESS",
            },
            {
              label: "PROJECT NO",
              content: validProject.id || "DEFAULT NUMBER",
            },
          ],
        },
      },
      {
        offsetX: getOffsetX([
          { count: NUMBER_OF_PLAYERS + 1, width: 1.25 * cellWidth },
          { count: 1, width: cellWidth },
          { count: 1, width: 2.5 * cellWidth },
        ]),
        cellWidth: 1.25 * cellWidth,
        cellsCount: 1,
        options: {
          isDivided: true,
          divisionsCount: 6,
          reverse: true,
          solid: true,
          data: [
            { label: "RECORD ID", content: validProject.recordId || "" },
            {
              label: "DRAWING NO",
              content: `FA-00${validDrawing.order}.00`,
            },
            {
              label: "STAGE",
              content: validDrawing.drawingStage || "",
            },
            {
              label: "PAGE SIZE",
              content: validDrawing.pageSize || "",
            },
            {
              label: "DATE UPDATED",
              content: validDrawing.modifiedAt || validDrawing.createdAt || "",
            },
            {
              label: "PAGE NO",
              content:
                formatDrawingOrder(validDrawing.order, lastOrderOfDrawings) ||
                "",
            },
          ],
        },
      },
    ];

    const verticalDividers = [];

    cellsArray.forEach(({ offsetX, cellWidth, cellsCount, options }) => {
      verticalDividers.push(
        ...createCellsOfPlayersSection({
          start: offsetX,
          cellWidth,
          groupId,
          numberOfCells: cellsCount,
          options,
          currentProject: validProject,
          excalidrawAPI,
        }),
      );
    });

    return [...frameOuterLines, ...verticalDividers];
  };

  const checkIfNorthChangeIncluded = (changes) => {
    return changes.some((el) => el.valueFor === "North Icon");
  };

  const editFrame = ({ drawing, changes, update = true, users = null }) => {
    if (changes.length > 0) {
      const drawingToUse = drawing || currentDrawing;
      const timeOfUpdate = getUpdateTimeElement();
      for (const change of changes) {
        if (change.valueFor === "pixelsPerUnit") {
          setIsSyncing(true);
          redrawScaleRuler(drawingToUse, groupId);
          return;
        }
      }
      let adjustedChanges = changes.map((change) => {
        return getMappedChange(change, users);
      });
      adjustedChanges = _.flatten(adjustedChanges);
      update && adjustedChanges.push(timeOfUpdate);
      doAfterLoadingScene(() => {
        let adjustedElements = _.cloneDeep(excalidrawAPI?.getSceneElements());
        adjustedElements = adjustedElements.map((el) => {
          if (
            el.customData?.valueFor === "North Icon" &&
            checkIfNorthChangeIncluded(changes)
          ) {
            return newElementWith(el, {
              angle: changes.find((c) => c.valueFor === "North Icon").value,
            });
          }
          //Check if the current element needs to be updated according to the changes
          const fieldToChange = adjustedChanges.find(
            (change) => change.valueFor === el.customData?.valueFor,
          );
          if (fieldToChange) {
            const textContainer = newElementWith(
              adjustedElements.find((p) => p.id === el.containerId),
            );
            const textElement = newElementWith(el, {
              text: fieldToChange.value?.toUpperCase() ?? "",
              originalText: fieldToChange.value?.toUpperCase() ?? "",
              verticalAlign: "top",
            });
            const { textElement: newOccText, rectElement: newOccRect } =
              preventTextWrapping(textElement, textContainer);

            redrawTextBoundingBox(newOccText, newOccRect, true);

            return textElement;
          }
          return el;
        });
        updateScene("elements", [...adjustedElements]);
      });
    }
  };

  const drawCoverSheetFrame = async ({ drawingToUse, projectToUse }) => {
    const groupId = randomId();

    const outerFrameElements = await drawOuterFrame();
    const gridRulerElements = drawGridRuler({ groupId });
    const playerSectionElements = drawPlayersSection({
      drawingToUse,
      projectToUse,
    });

    return [
      ...outerFrameElements,
      ...gridRulerElements,
      ...playerSectionElements,
    ];
  };

  const drawFullFrame = async ({
    drawingToUse = null,
    projectToUse = null,
  }) => {
    setIsOpeningScene(true);
    const groupId = randomId();

    const outerFrameElements = await drawOuterFrame();
    const gridRulerElements = drawGridRuler({ groupId });
    const scaleRulerElements = drawScaleRuler({
      currentDrawing: drawingToUse,
      groupId,
    });
    const playerSectionElements = drawPlayersSection({
      drawingToUse,
      projectToUse,
    });
    await drawNorthDirection({ projectToUse });

    const currentSceneElements = excalidrawAPI?.getSceneElements();
    updateScene("elements", [
      ...currentSceneElements,
      ...outerFrameElements,
      ...gridRulerElements,
      ...scaleRulerElements,
      ...playerSectionElements,
    ]);

    doAfterLoadingScene(() => {
      excalidrawAPI.zoomToFitAllDrawing();
    });

    setIsOpeningScene(false);
  };

  return { drawCoverSheetFrame, editFrame, drawFullFrame };
};
