import React, { useState, useEffect, useRef } from "react";

import {
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  useMediaQuery,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

import { getFromLocalStorage, setToLocalStorage } from "../helpers/common";
import { selectComponentToResponse } from "../helpers/responsiveness";

import "../scss/OccupancyField.scss";
import { useCommonFunctionsHook } from "../hooks/useCommonFunctionsHook";
import { createIconAndCover } from "../helpers/iconsAndCovers";

const OccupancyField = ({
  id,
  index,
  field,
  isLoadingCurrentProject,
  occupancyTypes,
  allIconsAndCovers,
  setIsDirtyOccupancyInput,
  isEditMode,
  addFieldToChange,
  isRemoveDisabled,
  isRemoveShown = true,
  removeOccupancyField,
  setOccupancies,
  localStorageKey,
  occupancyLabel,
  missedData,
  removeFromMissedData,
  isAlreadyExisted,
}) => {
  //Custom Hooks
  const { triggerToast, convertToOccupancyUseFormat } =
    useCommonFunctionsHook();

  //State
  const [occupancyType, setOccupancyType] = useState(field.type || "");
  const [occupancyGroup, setOccupancyGroup] = useState(field.group || "");
  const [occupancyUse, setOccupancyUse] = useState(field.use || "");
  const [occupancyGroups, setOccupancyGroups] = useState([]);
  const [occupancyUseDialog, setOccupancyUseDialog] = useState({
    label: "",
  });
  const [occupancyDialogOpen, toggleOccupancyDialogOpen] = useState(false);
  const [filteredIconsAndCovers, setFilteredIconsAndCovers] = useState([]);
  const [isAddingNewUse, setIsAddingNewUse] = useState(false);

  //Constants
  const filter = createFilterOptions();
  const isMediumScreen = useMediaQuery("(max-width:1024px)");
  const isSmallScreen = useMediaQuery("(max-width:599px)");

  const inputLabelToResponse = {
    fontSize: isSmallScreen ? 12 : isMediumScreen ? 14 : 18,
    lineHeight: isSmallScreen ? 2 : isMediumScreen ? 1.8 : 1.3,
  };

  const removeBtnClasses = `occupancy-field_icon-button ${
    isRemoveDisabled ? "occupancy-field_disabled-button" : ""
  } ${!isRemoveShown ? "occupancy-field_hidden-button" : ""}`;

  const selectStyle = {
    ...selectComponentToResponse,
  };

  //Refs
  const addUseField = useRef(null);

  //Effects
  useEffect(() => {
    if (occupancyType && occupancyTypes.length > 0) {
      setOccupancyGroups(
        occupancyTypes.find((el) => el.id === occupancyType).groups,
      );
    }
  }, [occupancyType, occupancyTypes]);

  useEffect(() => {
    if (occupancyGroup && allIconsAndCovers) {
      setFilteredIconsAndCovers(
        allIconsAndCovers?.filter(
          (el) =>
            el.occupancyTypeId === occupancyType &&
            el.occupancyGroup === occupancyGroup,
        ) || [],
      );
    }
  }, [occupancyGroup, allIconsAndCovers]);

  //Functions
  const occupancyFieldsHandler = (prevOccupancies, newValue) => {
    if (newValue.use && isAlreadyExisted(newValue)) {
      return prevOccupancies;
    }
    const modifiedOccupancies = prevOccupancies.map((el) => {
      if (el.id === id) {
        return Object.assign(el, newValue);
      }
      return el;
    });

    let newOccupancies = modifiedOccupancies;

    if (!newOccupancies.find((o) => o.use?.id === newValue.use?.id)) {
      const newOccupancy = {
        id,
        type: occupancyType,
        group: occupancyGroup,
        ...newValue,
      };
      newOccupancies = [...modifiedOccupancies, newOccupancy];
    }

    !isEditMode &&
      setToLocalStorage(
        "appCustomData",
        { [localStorageKey]: newOccupancies },
        { merge: true },
      );

    if (newValue.use) {
      addFieldToChange({
        valueFor: localStorageKey,
        value: newOccupancies,
        originalName: localStorageKey,
      });
    }

    return newOccupancies;
  };

  const selectOccupancyTypeHandler = ({ target }) => {
    const typeObj = occupancyTypes.find((el) => el.id === target.value);
    removeFromMissedData(`type-${id}`);
    let [dominantGroup, dominantUse] = ["", ""];
    setOccupancyGroups(typeObj.groups);
    if (typeObj.groups.length === 1) {
      dominantGroup = typeObj.groups[0];
      const dominantUses = allIconsAndCovers?.filter(
        (el) => el.occupancyTypeId === typeObj.id,
      );
      dominantUse = dominantUses.length === 1 ? dominantUses[0] : "";
    }
    setIsDirtyOccupancyInput(true);
    const { value } = target;
    setOccupancyType(value);
    setOccupancyGroup(dominantGroup);
    setOccupancyUse(dominantUse);

    setOccupancies((state) => {
      return occupancyFieldsHandler(state, {
        type: value,
        group: dominantGroup,
        use: dominantUse,
      });
    });
  };

  const selectOccupancyGroupHandler = ({ target }) => {
    const { value } = target;
    setOccupancyGroup(value);
    removeFromMissedData(`group-${id}`);

    setOccupancyUse("");

    setOccupancies((state) => {
      return occupancyFieldsHandler(state, { group: value, use: "" });
    });
  };

  const selectedOccupancyUseHandler = (newValue) => {
    if (isAlreadyExisted(newValue)) {
      triggerToast("Already existed occupancy field!", "error");
      return;
    }
    if (typeof newValue === "string") {
      //CASE: not-existing option & empty use.
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        toggleOccupancyDialogOpen(true);
        setOccupancyUseDialog({
          label: newValue,
        });
        addUseField.current?.focus();
      });
    } else if (newValue && newValue.inputValue) {
      //CASE: not-existing option & selected use.
      toggleOccupancyDialogOpen(true);
      setOccupancyUseDialog({
        label: newValue.inputValue,
      });
      setTimeout(() => {
        addUseField.current?.focus();
      });
    } else {
      if (!newValue) {
        //CASE: no value
        return;
      }
      //CASE: selected value
      setOccupancyUse(newValue);
      removeFromMissedData(`use-${id}`);
      setOccupancies((state) => {
        return occupancyFieldsHandler(state, { use: newValue });
      });
    }
  };

  const closeNewOccupancyUse = () => {
    toggleOccupancyDialogOpen(false);
    setOccupancyUseDialog({ label: "" });
  };

  const onCreatingIconAndCoverSuccess = (newIconAndCover) => {
    setOccupancyUse(newIconAndCover);
    let newOccupancies = null;
    setOccupancies((state) => {
      newOccupancies = occupancyFieldsHandler(state, { use: newIconAndCover });
      return newOccupancies;
    });
    if (isEditMode) {
      const prevEditedDrawingProps =
        getFromLocalStorage("appCustomData")?.editedDrawingProps || {};
      setToLocalStorage(
        "appCustomData",
        {
          editedDrawingProps: {
            ...prevEditedDrawingProps,
            drawingOccupancies: newOccupancies,
          },
        },
        { merge: true },
      );
    }
    triggerToast(
      `Added '${newIconAndCover.label}' use successfully!`,
      "success",
    );
  };
  const submitNewOccupancyUseHandler = async (event) => {
    event.preventDefault();
    setIsAddingNewUse(true);
    const { label } = occupancyUseDialog;
    const newOccupancyUse = convertToOccupancyUseFormat(
      label,
      occupancyGroup,
      occupancyType,
      allIconsAndCovers,
    );
    toggleOccupancyDialogOpen(false);
    closeNewOccupancyUse();
    await createIconAndCover(
      onCreatingIconAndCoverSuccess,
      null,
      newOccupancyUse,
    );
    setIsAddingNewUse(false);
  };

  //To check the existence of the use you typed
  const filterDominantUseOptions = (options, params) => {
    const filtered = filter(options, params);

    //Check if it's not existed neither in DB iconsAndCovers nor the recently added items by freeSolo
    if (
      filteredIconsAndCovers.find((el) => el.occupancyUse === params.inputValue)
    ) {
      return filtered;
    } else if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        label: `Add: "${params.inputValue}"`,
        id: Date.now(),
      });
    }

    return filtered;
  };

  const getOptionLabel = (option) => {
    // e.g value selected with enter, right from the input
    if (typeof option?.label === "string") {
      return option.label;
    }
    if (option?.inputValue) {
      return option.inputValue;
    }
    return option?.label || "";
  };

  const getMissedClassName = (field, missedData) => {
    return missedData.includes(field) ? "missed" : "";
  };

  //Render
  return (
    <section className="occupancy-field_container">
      <div className="occupancy-field">
        <span className="occupancy-main-label">
          Dominant {occupancyLabel} Occupancy{" "}
          {!isRemoveDisabled ? `(${index + 1})` : ""}
        </span>
        <div className="occupancy-field-details">
          {isLoadingCurrentProject ? (
            <Skeleton
              variant="rounded"
              height={56}
              sx={{ marginBottom: "15px", width: "68%" }}
            />
          ) : (
            <FormControl
              id="occupancy-type-dropdown-container"
              className={getMissedClassName(`type-${id}`, missedData)}
              sx={{ width: "68%" }}
            >
              <InputLabel
                id="occupancy-type-dropdown-label"
                style={inputLabelToResponse}
              >
                Type
              </InputLabel>
              <Select
                labelId="occupancy-type-dropdown-label"
                id="occupancy-type-dropdown"
                value={occupancyType}
                label="Type"
                onChange={selectOccupancyTypeHandler}
                sx={selectStyle}
              >
                {occupancyTypes.length !== 0 ? (
                  occupancyTypes?.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.name ?? "Default type name"}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={occupancyType} value={occupancyType}>
                    {occupancyType}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}

          {isLoadingCurrentProject ? (
            <Skeleton
              variant="rounded"
              height={56}
              sx={{ marginBottom: "15px", width: "30%" }}
            />
          ) : (
            <FormControl
              id="occupancy-groups-dropdown-container"
              className={getMissedClassName(`group-${id}`, missedData)}
              sx={{ width: "30%" }}
            >
              <InputLabel
                id="occupancy-groups-dropdown-label"
                style={inputLabelToResponse}
              >
                Group
              </InputLabel>
              <Select
                labelId="occupancy-groups-dropdown-label"
                id="occupancy-groups-dropdown"
                value={occupancyGroup}
                label="Group"
                disabled={!occupancyType || occupancyGroup === "Default"}
                onChange={selectOccupancyGroupHandler}
                sx={{
                  "& .MuiSelect-icon": {
                    right: {
                      xs: "0px",
                    },
                  },
                  ...selectStyle,
                }}
              >
                {occupancyGroups.length > 0 ? (
                  occupancyGroups?.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={occupancyGroup} value={occupancyGroup}>
                    {occupancyGroup}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        </div>

        <React.Fragment>
          {isLoadingCurrentProject || isAddingNewUse ? (
            <Skeleton
              variant="rounded"
              height={56}
              sx={{ marginTop: "12px" }}
            />
          ) : (
            <Autocomplete
              className={getMissedClassName(`use-${id}`, missedData)}
              sx={{
                marginTop: "12px",
                textTransform: "capitalize",
              }}
              value={occupancyUse}
              disabled={!occupancyGroup || occupancyGroup === "Default"}
              onChange={(_, newValue) => {
                selectedOccupancyUseHandler(newValue);
              }}
              filterOptions={filterDominantUseOptions}
              id="drawing-use-select"
              options={
                filteredIconsAndCovers.length === 0
                  ? [
                      {
                        id: "No options",
                        label: "No options",
                      },
                    ]
                  : filteredIconsAndCovers.sort(
                      (a, b) => -b.label.localeCompare(a.label),
                    )
              }
              getOptionDisabled={(option) => {
                if (option.id === "No options") {
                  return true;
                }
                return false;
              }}
              getOptionLabel={getOptionLabel}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderOption={(props, option) => {
                return (
                  <li
                    {...props}
                    key={option.id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {option.label}
                  </li>
                );
              }}
              freeSolo
              renderInput={(params) => <TextField {...params} label="Use" />}
            />
          )}
          <Dialog open={occupancyDialogOpen} onClose={closeNewOccupancyUse}>
            <form onSubmit={submitNewOccupancyUseHandler}>
              <DialogTitle>Add a new occupancy use</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Didn't you find the required occupancy use? Please, add it!
                </DialogContentText>
                <TextField
                  id="name"
                  fullWidth
                  value={occupancyUseDialog.label}
                  onChange={(event) => {
                    setOccupancyUseDialog({
                      ...occupancyUseDialog,
                      label: event.target.value,
                    });
                  }}
                  label="Use"
                  type="text"
                  variant="outlined"
                  inputRef={addUseField}
                  inputProps={{
                    style: {
                      textTransform: "capitalize",
                    },
                  }}
                  sx={{ marginTop: "12px" }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeNewOccupancyUse}>Cancel</Button>
                <Button type="submit">Add</Button>
              </DialogActions>
            </form>
          </Dialog>
        </React.Fragment>
      </div>
      {isRemoveShown ? (
        <div
          className={removeBtnClasses}
          title="Remove Occupancy"
          onClick={() => {
            triggerToast("Occupancy Field Delete", "success");
            removeOccupancyField(id);
          }}
        >
          <RemoveIcon className="remove-occupancy_minus_icon" color="error" />
          <DeleteOutlineRoundedIcon
            className="remove-occupancy_trash_icon"
            color="error"
          />
        </div>
      ) : null}
    </section>
  );
};

export default OccupancyField;
