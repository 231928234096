import React, { useEffect, useState, useRef } from "react";
import { useAtom, useSetAtom } from "jotai";
import axios from "axios";

import firebase from "firebase/app";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Backdrop } from "@mui/material";

import ExcalidrawApp from "./ExcalidrawAPI/excalidraw-app";
import WelcomeScreen from "./ExcalidrawAPI/components/welcome-screen/WelcomeScreen";
import MainMenu from "./ExcalidrawAPI/components/main-menu/MainMenu";
import { MIME_TYPES } from "./ExcalidrawAPI/constants";
import ConfirmDialog from "./ExcalidrawAPI/components/ConfirmDialog";
import { t } from "./ExcalidrawAPI/i18n";

import DevicesSidebar from "./components/DevicesSidebar";
import UploadPopup from "./components/UploadPopup";
import SavedDrawingOpener from "./components/SavedDrawingOpener";
import InfoPopup from "./components/InfoPopup";
import SidebarToggler from "./components/SidebarToggler";
import CustomMainMenu from "./components/CustomMainMenu";
import CustomWelcomeScreen from "./components/CustomWelcomeScreen";
import Map from "./components/Map";
import PagesManager from "./components/PagesManager";

import {
  getAllProjects,
  getOccupancyTypes,
  updateProjectById,
} from "./helpers/projects.js";
import {
  createOrUpdateDrawing,
  getAllDrawings,
  getOneDrawingById,
  getOneDrawingByRoomKey,
} from "./helpers/drawings";
import { getAllDevicesSymbols, getDevicesIndustries } from "./helpers/devices";
import {
  clearDrawingOpenerFromLocalStorage,
  formatDrawingOrder,
  getFloorsOfWorkAsString,
  getFromLocalStorage,
  getLargestOrder,
  setToLocalStorage,
} from "./helpers/common.js";
import {
  getAllCodeCombinations,
  getAllCodeItems,
  getCodesGroupById,
  getRelatedCodes,
} from "./helpers/codeReferences";
import { getCoverSheetById } from "./helpers/coverSheets";
import { getAllIconsAndCovers } from "./helpers/iconsAndCovers";
import {
  COVER_SHEET_THUMBNAIL,
  DELETED_PROPS,
  EXIT_ENTRANCE_SYMBOL,
} from "./helpers/constants";
import { getAllUsers } from "./helpers/users";
import { setDrawingPropsInScene } from "./helpers/drawFrame";

import { useReactToPrint } from "react-to-print";

import initialDataStore from "./dummy/initialData";
import {
  allCodeItemsAtom,
  codeCombinationsAtom,
  currentDrawingAtom,
  currentDrawingsAtom,
  currentProjectAtom,
  excalidrawApiAtom,
  iconsAndCoversAtom,
  keyContactsAtom,
  northHeadingAtom,
  occupancyTypesAtom,
  projectToPreloadAtom,
  usersAtom,
} from "./store/variables";
import useReactiveUIVars from "./hooks/useReactiveUIVars";
import { useCoverSheetDrawer } from "./hooks/useCoverSheetDrawer";
import { collabAPIAtom } from "./ExcalidrawAPI/excalidraw-app/collab/Collab";
import {
  activeConfirmDialogAtom,
  activeLoaderAtom,
  isDetectingPixelsPerUnitAtom,
  isLeavingSceneAtom,
  isLoadingCurrentProjectAtom,
  isOpenFromSavedAtom,
  isOpeningSceneAtom,
  isSelectingBoundariesAtom,
  isUploadPopupOpenAtom,
} from "./store/UI";
import { currentUserAtom } from "./store/auth";
import { newElementWith } from "./ExcalidrawAPI/element/mutateElement";
import { useFrameMethods } from "./hooks/useFrameMethods";
import usePlaceOnMapHandler from "./hooks/usePlaceOnMapHandler";
import { STORAGE_KEYS } from "./ExcalidrawAPI/excalidraw-app/app_constants";
import CustomLoader from "./components/CustomLoader";
import { useCommonFunctionsHook } from "./hooks/useCommonFunctionsHook";
import { getAllAreas } from "./helpers/areas";
import { useAreaMethods } from "./hooks/useAreaMethods";
import AreasSlider from "./components/AreasSlider";
import SlidersPullers from "./components/SlidersPullers";
import SettingsSlider from "./components/SettingsSlider";

export default function App() {
  //Custom Hooks
  const {
    setIsEditMode,
    isPlacingOnMap,
    setIsPlacingOnMap,
    isReadyToDesign,
    setIsReadyToDesign,
  } = useReactiveUIVars();
  const {
    updateProjectSummary,
    updateCodeReference,
    renderCoverSheet,
    checkIfOccupyingEntireProperty,
  } = useCoverSheetDrawer();
  const { editFrame } = useFrameMethods();
  const { closePlacingOnMap } = usePlaceOnMapHandler();
  const { doAfterLoadingScene, triggerToast } = useCommonFunctionsHook();
  const {
    lockAllPartAreaElements,
    assignDroppedDeviceToArea,
    checkIfDroppedInAreaHandler,
    addAssignedDeviceToDB,
  } = useAreaMethods();

  //Excalidraw Hooks

  //Atom
  const [excalidrawAPI, setExcalidrawAPI] = useAtom(excalidrawApiAtom);
  const [collabAPI] = useAtom(collabAPIAtom);
  const [currentProject, setCurrentProject] = useAtom(currentProjectAtom);
  const [, setProjectToPreload] = useAtom(projectToPreloadAtom);
  const [currentDrawing, setCurrentDrawing] = useAtom(currentDrawingAtom);
  const [currentDrawings, setCurrentDrawings] = useAtom(currentDrawingsAtom);
  const [, setNorthHeading] = useAtom(northHeadingAtom);
  const [isDetectingPixelsPerUnit] = useAtom(isDetectingPixelsPerUnitAtom);
  const [isSelectingBoundaries] = useAtom(isSelectingBoundariesAtom);
  const [isLeavingScene, setIsLeavingScene] = useAtom(isLeavingSceneAtom);
  const [isOpeningScene] = useAtom(isOpeningSceneAtom);
  const [allUsers, setAllUsers] = useAtom(usersAtom);
  const [, setKeyContacts] = useAtom(keyContactsAtom);
  const [currentUser, setCurrentUser] = useAtom(currentUserAtom);
  const setCodeCombinations = useSetAtom(codeCombinationsAtom);
  const [allCodeItems, setAllCodeItems] = useAtom(allCodeItemsAtom);
  const [iconsAndCovers, setIconsAndCovers] = useAtom(iconsAndCoversAtom);
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useAtom(
    isUploadPopupOpenAtom,
  );
  const [isLoadingCurrentProject, setIsLoadingCurrentProject] = useAtom(
    isLoadingCurrentProjectAtom,
  );
  const [isOpenFromSaved, setIsOpenFromSaved] = useAtom(isOpenFromSavedAtom);
  const [, setActiveConfirmDialog] = useAtom(activeConfirmDialogAtom);
  const [activeLoader, setActiveLoader] = useAtom(activeLoaderAtom);

  //Constants
  const defaultValues = getFromLocalStorage("appCustomData");
  const DEFAULT_VALUES = {
    SELECTED_PROJECT: defaultValues?.drawingToUpload?.projectId || "",
    GRID_SIZE_VALUE: defaultValues?.gridSizeValue || 30,
    NORTH_HEADING: defaultValues?.northHeading || 0,
    DRAWING_TO_UPLOAD: defaultValues?.drawingToUpload || {},
    NEW_AREAS: defaultValues?.newAreas || [],
  };

  //State
  const [initialData] = useState(initialDataStore);
  const [drawingToBeUploaded, setDrawingToBeUploaded] = useState(
    DEFAULT_VALUES.DRAWING_TO_UPLOAD,
  );
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  const [isLoadingDrawings, setIsLoadingDrawings] = useState(true);
  const [gridModeValue, setGridModeValue] = useState(
    defaultValues?.gridMode || false,
  );
  const [gridSizeValue, setGridSizeValue] = useState(
    DEFAULT_VALUES.GRID_SIZE_VALUE,
  );
  const [allProjects, setAllProjects] = useState([]);
  const [allDrawings, setAllDrawings] = useState(null);
  const [allAreas, setAllAreas] = useState(null);
  const [allIndustries, setAllIndustries] = useState(null);
  const [occupancyTypes, setOccupancyTypes] = useAtom(occupancyTypesAtom);
  const [devicesSymbols, setDevicesSymbols] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [selectedProject, setSelectedProject] = useState(
    DEFAULT_VALUES.SELECTED_PROJECT,
  );
  const [orderOfDrawingToUpload, setOrderOfDrawingToUpload] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [mainMenuClickedButton, setMainMenuClickedButton] = useState(null);
  const [locationPolygonCoords, setLocationPolygonCoords] = useState([]);
  const [isProjectInDetails, setIsProjectInDetails] = useState(false);
  const [isProjectSummaryOpen, setIsProjectSummaryOpen] = useState(false);
  const [isPagesManagerOpen, setIsPagesManagerOpen] = useState(false);
  const [isAreaSliderOpen, setIsAreasSliderOpen] = useState(false);
  const [isSettingsSliderOpen, setIsSettingsSliderOpen] = useState(false);

  //Refs
  const componentToPrintRef = useRef();

  //Effects
  useEffect(() => {
    const setProjectOnRefresh = async () => {
      if (isPlacingOnMap) {
        setIsPlacingOnMap(false);
      }
      if (!currentDrawing?.active || allProjects.length === 0) {
        return;
      }

      const { projectId } =
        currentDrawing ?? defaultValues?.drawingToUpload ?? "";
      const currentProject = allProjects.find(
        (project) => project.id === projectId,
      );

      let projectInitialData = currentProject;
      if (currentProject) {
        if (!isProjectInDetails) {
          const { lot, block } = await getProjectInDetails(currentProject.id);
          projectInitialData = {
            ...currentProject,
            lot,
            block,
          };
        }
        setNorthHeading(
          DEFAULT_VALUES.NORTH_HEADING || projectInitialData.northHeading,
        );
        setCurrentProject(projectInitialData);
        if (projectInitialData.polygonCoordinates?.length > 0) {
          setLocationPolygonCoords(
            convertLatLngObjectsToArrays(projectInitialData.polygonCoordinates),
          );
        }
        setToLocalStorage(
          "appCustomData",
          { fieldsToChange: [], editedDrawingProps: {} },
          { merge: true },
        );
        setIsLoadingDrawings(false);
      } else {
        setCurrentDrawings([]);
      }
    };

    setProjectOnRefresh();
  }, [allDrawings, currentDrawing, allProjects]);

  useEffect(() => {
    if (!currentProject?.id) {
      return;
    }
    setCurrentDrawings(
      allDrawings?.filter((el) => el.projectId === currentProject.id) || [],
    );
  }, [allDrawings, currentProject, setCurrentDrawings]);

  useEffect(() => {
    if (location.hash === currentProject?.coverSheetRoom && currentDrawings) {
      updateMapImage();
      updateProjectSummaryHandler();
      editFrame({
        changes: [
          {
            valueFor: "PAGE NO",
            value: formatDrawingOrder(1, currentDrawings.length),
          },
        ],
        update: false,
      });
    }
  }, [currentProject, excalidrawAPI, currentDrawings]);

  useEffect(() => {
    if (location.hash) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
    if (isDetectingPixelsPerUnit || isPlacingOnMap || isSelectingBoundaries) {
      setIsUploadPopupOpen(!location.hash);
    }

    const fetchOccupancyTypes = async () => {
      const occupancyTypes = await getOccupancyTypes();
      setOccupancyTypes(occupancyTypes);
    };

    async function doGetAll() {
      const unsubscribeGetAllProjects = await getAllProjects(
        projectsOnSuccess,
        projectsOnError,
      );

      await fetchOccupancyTypes();

      const unsubscribeGetAllDrawings = await getAllDrawings(
        (drawings) => setAllDrawings(drawings),
        null,
      );

      const unsubscribeGetAllAreas = await getAllAreas(
        (areas) => setAllAreas(areas),
        null,
      );

      setAllIndustries(await getDevicesIndustries());

      const unsubscribeGetAllIconsAndCovers = await getAllIconsAndCovers(
        (result) => setIconsAndCovers(result),
        null,
      );

      const unsubscribeGetAllCombinations = await getAllCodeCombinations(
        (combinations) => setCodeCombinations(combinations),
        null,
      );

      const unsubscribeGetAllCodeItems = await getAllCodeItems(
        (codeItems) => setAllCodeItems(codeItems),
        null,
      );

      setDevicesSymbols(await getAllDevicesSymbols());

      const unsubscribeGetAllUsers = getAllUsers(usersOnSuccess, usersOnError);

      return {
        unsubscribeGetAllProjects,
        unsubscribeGetAllDrawings,
        unsubscribeGetAllAreas,
        unsubscribeGetAllIconsAndCovers,
        unsubscribeGetAllUsers,
        unsubscribeGetAllCombinations,
        unsubscribeGetAllCodeItems,
      };
    }

    const {
      unsubscribeGetAllProjects,
      unsubscribeGetAllDrawings,
      unsubscribeGetAllAreas,
      unsubscribeGetAllIconsAndCovers,
      unsubscribeGetAllUsers,
      unsubscribeGetAllCombinations,
      unsubscribeGetAllCodeItems,
    } = doGetAll();
    addExitSymbolToLibrary();

    return () => {
      unsubscribeGetAllProjects && unsubscribeGetAllProjects();
      unsubscribeGetAllDrawings && unsubscribeGetAllDrawings();
      unsubscribeGetAllAreas && unsubscribeGetAllAreas();
      unsubscribeGetAllIconsAndCovers && unsubscribeGetAllIconsAndCovers();
      unsubscribeGetAllUsers && unsubscribeGetAllUsers();
      unsubscribeGetAllCombinations && unsubscribeGetAllCombinations();
      unsubscribeGetAllCodeItems && unsubscribeGetAllCodeItems();
    };
  }, []);

  //Clear the areas that are drawn in the scene and not saved to the Local storage or the database
  useEffect(() => {
    if (!excalidrawAPI || !allAreas) {
      return;
    }
    doAfterLoadingScene(() => {
      excalidrawAPI.updateScene({
        elements: excalidrawAPI.getSceneElements().map((el) => {
          if (!el.customData?.isSelectedArea && !el.customData?.areaId) {
            return el;
          }
          const foundInLocalStorage = DEFAULT_VALUES.NEW_AREAS?.find(
            (a) => a.id === el.id || a.id === el.customData?.areaId,
          );
          const foundInDB = allAreas?.find(
            (a) => a.id === el.id || a.id === el.customData?.areaId,
          );
          if (
            (el.customData?.isSelectedArea || el.customData?.areaId) &&
            !foundInLocalStorage &&
            !foundInDB
          ) {
            return newElementWith(el, DELETED_PROPS);
          }
          return el;
        }),
      });
    });
  }, [allAreas, excalidrawAPI]);

  useEffect(() => {
    if (excalidrawAPI) {
      lockAllPartAreaElements(true);
    }
  }, [excalidrawAPI]);

  useEffect(() => {
    if (!currentDrawing && location.hash) {
      const setCurrentDrawingOnLoad = async () => {
        const currentDrawing = await getOneDrawingByRoomKey(location.hash);
        setCurrentDrawing(currentDrawing);
      };
      setCurrentDrawingOnLoad();
    }
  }, [currentDrawing]);

  useEffect(() => {
    if (!allDrawings?.length || !location.hash) {
      return;
    }
    const isNotFound = !allDrawings.find((el) => el.roomKey === location.hash);
    if (isNotFound) {
      redirectToHome();
    }
  }, [allDrawings]);

  useEffect(() => {
    const idOfCurrentUserFromAuth = firebase.auth().currentUser.uid;
    const currentUserToBe = allUsers.find(
      (el) => el.authId === idOfCurrentUserFromAuth,
    );
    setCurrentUser(currentUserToBe);
  }, [allUsers]);

  useEffect(() => {
    if (!excalidrawAPI || devicesSymbols.length === 0) {
      return;
    }
    const fetchData = async () => {
      devicesSymbols.map(async (d) => {
        const res = await fetch(d.downloadLink);
        const imageData = await res.blob();
        const reader = new FileReader();
        reader.readAsDataURL(imageData);
        reader.onload = function () {
          const imagesArray = [
            {
              id: d.id,
              dataURL: reader.result,
              mimeType: MIME_TYPES.jpg,
              created: Date.now(),
            },
          ];
          excalidrawAPI.addFiles(imagesArray);
        };
      });
    };

    fetchData();
  }, [excalidrawAPI, devicesSymbols]);

  //Functions
  const addExitSymbolToLibrary = () => {
    const existingLibraryItemsAsJSON = localStorage.getItem(
      STORAGE_KEYS.LOCAL_STORAGE_LIBRARY,
    );
    const existingLibraryItems = JSON.parse(existingLibraryItemsAsJSON) || [];

    if (
      !existingLibraryItems.some(
        (i) => i.elements[0]?.customData?.type === "custom-library-item",
      )
    ) {
      existingLibraryItems.push(EXIT_ENTRANCE_SYMBOL);
      localStorage.setItem(
        STORAGE_KEYS.LOCAL_STORAGE_LIBRARY,
        JSON.stringify(existingLibraryItems),
      );
    }
  };

  const redirectToHome = async () => {
    await collabAPI.stopCollaboration();
    setActiveConfirmDialog("deletedCurrentDrawing");
    goToWelcomeScreenHandler();
  };

  const convertToLatLng = (coordinates, returnType) => {
    if (!coordinates.length) {
      return;
    }
    //CHECK if the coordinates array wasn't an array of points arrays
    if (!Array.isArray(coordinates) || !coordinates[0][0]) {
      return [];
    }
    return coordinates.map(([lng, lat]) => {
      if (returnType === "object") {
        return { lat, lng };
      }
      return [lat, lng];
    });
  };

  const convertLatLngObjectsToArrays = (arrayOfObjects) => {
    return arrayOfObjects.map(({ lat, lng }) => {
      return [lat, lng];
    });
  };

  const getBuildingGeoData = async ({ lat, lon }) => {
    try {
      const response = await axios.get(
        "https://nominatim.openstreetmap.org/reverse",
        {
          params: {
            lat,
            lon,
            format: "json",
            polygon_geojson: 1, // Include polygon in GeoJSON format
          },
        },
      );

      const initialPolygonCoords =
        response.data.geojson?.coordinates[0] || null;

      if (!Array.isArray(initialPolygonCoords) || !initialPolygonCoords) {
        return {};
      }

      const coordinatesAsArrays = convertToLatLng(
        initialPolygonCoords,
        "array",
      );
      setLocationPolygonCoords(coordinatesAsArrays);

      return {
        placeId: response.data.place_id || "",
        osmType: response.data.osm_type || "",
        osmId: response.data.osm_id || "",
        polygonPoints: convertToLatLng(initialPolygonCoords, "object") || [],
      };
      // Process the response
    } catch (error) {
      console.error(error);
    }
  };

  const isMapChanged = () => {
    return (
      excalidrawAPI
        ?.getSceneElements()
        .find((el) => el.customData?.imageType === "mapImage")?.fileId ===
      currentProject?.mapImage
    );
  };

  const updateMapImage = () => {
    if (!excalidrawAPI || !currentProject || !isMapChanged) {
      return;
    }
    let sceneElements = excalidrawAPI?.getSceneElements();
    sceneElements = sceneElements.map((el) => {
      if (el.customData?.imageType === "mapImage") {
        return newElementWith(el, {
          fileId: currentProject.mapImage,
        });
      }
      return el;
    });
    updateScene("elements", sceneElements);
  };

  const signOutHandler = async () => {
    try {
      await firebase.auth().signOut();
      triggerToast("Signed out!", "success");
    } catch (error) {
      triggerToast("Failed to sign out!", "error");
    }
  };

  const resetScene = () => {
    updateScene("elements", []);
    excalidrawAPI.setActiveTool({ type: "selection" });
    updateScene("appState", { zoom: { value: 1 } });
    setToLocalStorage("excalidraw", []);
    setToLocalStorage("appCustomData", {});
    setToLocalStorage("currentDrawing", null);
    setCurrentDrawing(null);
    setCurrentProject(null);
    setIsReadyToDesign(false);
    setIsLeavingScene(false);
    setActiveLoader(null);
  };

  const goToWelcomeScreenHandler = () => {
    setIsFirstLoad(true);
    setCurrentDrawing(null);
    setProjectToPreload(null);
    setSelectedProject("");
    resetScene();
    triggerToast("Back home", "success");
  };

  const changeDrawingBtnHandler = ({ clicked, options = {} }) => {
    setMainMenuClickedButton(clicked);
    if (location.hash && clicked !== "open") {
      setIsConfirmDialogOpen(true);
    } else {
      pickDialogToOpen(clicked, options);
    }
  };

  const leaveSceneHandler = async (clickedButton) => {
    setIsEditMode(false);
    if (location.hash) {
      setIsLeavingScene(true);
      setActiveLoader("saving");
      setIsReadyToDesign(false);
      setMainMenuClickedButton(clickedButton);
      await collabAPI?.stopCollaboration();
      if (clickedButton === "welcome-screen") {
        goToWelcomeScreenHandler();
        setIsLeavingScene(false);
        setActiveLoader(null);
        return;
      }
      updateScene("elements", []);
      setSelectedProject("");
      setCurrentProject(null);
      setCurrentDrawing(null);
      setToLocalStorage("currentDrawing", null);
      clickedButton !== "open" && pickDialogToOpen(clickedButton);
      setIsLeavingScene(false);
      setActiveLoader(null);
    } else {
      pickDialogToOpen(clickedButton);
    }
  };

  const pickDialogToOpen = (clickedButton, options) => {
    if (clickedButton === "upload") {
      document.querySelector('[title="Insert image"]').click();
      setOrderOfDrawingToUpload(options?.pageOrder);
    } else if (clickedButton === "open") {
      setIsOpenFromSaved(true);
    }
  };

  const getProjectInDetails = async (projectNumber) => {
    setIsProjectInDetails(true);
    const url = `${process.env.REACT_APP_OC_API}/project/${projectNumber}`;
    const { data } = await axios.post(url, {
      key: process.env.REACT_APP_OC_KEYCONTACTS_KEY,
    });
    const currentPlayers = [];
    data.keyContacts.forEach(async (el) => {
      if (!!el.customer.length) {
        const customer = await getCustomerById(el.customer[0].id);
        currentPlayers.push(customer);
        setKeyContacts(currentPlayers);
      }
    });
    if (!isFirstLoad) {
      setTimeout(() => excalidrawAPI.zoomToFitAllDrawing(), 100);
    }
    return data;
  };

  const getCustomerById = async (customerId) => {
    const url = `${process.env.REACT_APP_OC_API}/v2/customers/get-by-id/`;
    const { data } = await axios.post(url, {
      key: process.env.REACT_APP_OC_KEYCONTACTS_KEY,
      customerId,
    });
    return data;
  };

  const selectedProjectHandler = async (projectNumber) => {
    setIsLoadingCurrentProject(!!projectNumber);
    if (!projectNumber) {
      setSelectedProject("");
      setCurrentDrawings([]);
      return;
    }
    setSelectedProject(projectNumber);
    setCurrentDrawings(
      allDrawings.filter((el) => el.projectId === projectNumber),
    );
    setIsLoadingDrawings(false);
    const { lot, block } = await getProjectInDetails(projectNumber);
    const projectInitialData = allProjects.find(
      (el) => el.id === projectNumber,
    );
    const geoData = await getBuildingGeoData({
      lat: projectInitialData.lat,
      lon: projectInitialData.lng,
    });
    const isToSetGeoData = Object.keys(geoData || {}).length;

    const { osmId, osmType, placeId, polygonPoints } = geoData ?? {};
    const currentProjectToBe = {
      ...projectInitialData,
      ...(isToSetGeoData ? { lot, block, osmId, osmType, placeId } : {}),
    };

    if (isToSetGeoData && projectInitialData.placeId !== placeId) {
      try {
        await updateProjectById(projectInitialData.id, {
          osmId,
          osmType,
          placeId,
          polygonCoordinates: polygonPoints,
        });
        setIsLoadingCurrentProject(false);
      } catch (error) {
        console.error("Couldn't save the geo data to the project: ", error);
        setIsLoadingCurrentProject(false);
      }
    }

    setCurrentDrawings(
      allDrawings.filter((el) => el.projectId === projectNumber),
    );
    setIsLoadingCurrentProject(false);
    setCurrentProject(currentProjectToBe);

    return currentProjectToBe;
  };

  const projectsOnSuccess = (projects) => {
    setIsLoadingProjects(false);
    setAllProjects(projects);
  };

  const projectsOnError = () => {
    setIsLoadingProjects(false);
  };

  const usersOnSuccess = (users) => {
    setAllUsers(users);
  };

  const usersOnError = (error) => {
    console.error(error);
  };

  const updateScene = (destination, update) => {
    const sceneData = {
      [destination]: update,
    };
    excalidrawAPI?.updateScene(sceneData);
  };

  const escapePressHandler = (options) => {
    if (options?.isPixelsPerUnitDetectingMode) {
      return;
    }
    //If there's no room yet.
    if (!location.hash) {
      setIsFirstLoad(true);
      updateScene("elements", []);
      updateScene("appState", { zoom: { value: 1 } });
      setCurrentDrawing(null);
      setToLocalStorage(
        "appCustomData",
        { drawingToUpload: {} },
        { merge: true },
      );
    }
    setIsOpenFromSaved(false);
    setIsUploadPopupOpen(false);
    setToLocalStorage("appCustomData", {});
    setIsLoadingCurrentProject(false);
  };

  const createCustomizedLibraryBtn = (excalidrawAPI) => {
    return <SidebarToggler excalidrawAPI={excalidrawAPI} />;
  };

  const renderSidebar = () => {
    const usedDrawing = (currentDrawing && currentDrawing[0]) || currentDrawing;
    return (
      <DevicesSidebar
        devicesIndustries={allIndustries}
        currentDrawing={usedDrawing}
        devicesSymbols={devicesSymbols}
      />
    );
  };

  //TODO - We should add the page size to the cover sheet document in the DB.
  //SUGGESTION - What about unifying the page size for all the project? makes sense?!
  const createCoverSheet = async (project = null) => {
    const projectToUse = project || currentProject;
    const drawingsToUse = allDrawings.filter(
      (el) => el.projectId === projectToUse.id,
    );
    await collabAPI.startCollaboration();
    await updateProjectById(projectToUse.id, {
      coverSheetRoom: location.hash,
    });
    const { stage, pageSize } = await getCoverSheetById(projectToUse.id);
    const initialCoverSheet = {
      order: 1,
      name: "Cover Sheet",
      projectId: projectToUse.id,
      thumbImageUrl: COVER_SHEET_THUMBNAIL,
      creator: currentUser?.authId || "",
      type: "coverSheet",
      drawingStage: stage,
      pageSize,
    };
    await createOrUpdateDrawing(initialCoverSheet);
    await renderCoverSheet(initialCoverSheet, projectToUse, drawingsToUse);
  };

  const updateFrameData = (openedDrawing, project = null) => {
    const projectToUse = project || currentProject;

    const currentDrawings = allDrawings.filter(
      (el) => el.projectId === projectToUse.id,
    );
    const lastOrder =
      currentDrawings?.length === 1 ? 1 : getLargestOrder(currentDrawings);

    editFrame({
      changes: [
        {
          valueFor: "PAGE NO",
          value: formatDrawingOrder(openedDrawing.order, lastOrder),
        },
        {
          valueFor: "DRAWING NO",
          value: `FA-00${openedDrawing.order}.00`,
        },
        {
          valueFor: "record Id",
          value: projectToUse.recordId,
          originalName: "recordId",
        },
        {
          valueFor: "North Icon",
          value: projectToUse.northHeading,
        },
        {
          valueFor: "drawing title",
          value: openedDrawing.name,
          originalName: "name",
        },
      ],
      update: false,
    });
  };

  const triggerOpeningToast = (openedDrawing) => {
    triggerToast(`You opened "${openedDrawing?.name}"`, "success");
  };

  const updateProjectSummaryHandler = async (project) => {
    if (
      !iconsAndCovers?.length ||
      !occupancyTypes.length ||
      !currentDrawings?.length
    ) {
      return;
    }
    const projectToUse = project || currentProject;
    const coverSheetData = await getCoverSheetById(projectToUse.id);
    const dominantPropertyUse = iconsAndCovers?.find(
      (el) => el.id === projectToUse.dominantPropertyOccupancy,
    );
    const propertyOccupancyType = occupancyTypes.find(
      (el) => el.id === dominantPropertyUse.occupancyTypeId,
    );
    const projectOccupancyType = occupancyTypes.find(
      (el) => el.id === projectToUse.iconAndCover.occupancyTypeId,
    );
    const floorsOfWork = getFloorsOfWorkAsString(currentDrawings);
    const totalWorkingArea = currentDrawings
      ?.filter((el) => el.type !== "coverSheet")
      .reduce((accArea, floor) => {
        return accArea + Number(floor.floorArea);
      }, 0);
    const dataForTable = {
      dominantPropertyOccupancyTypeGroup: `${dominantPropertyUse.occupancyGroup}/${propertyOccupancyType.name}`,
      dominantPropertyOccupancyUse: dominantPropertyUse.occupancyUse,
      dominantProjectOccupancyTypeGroup: `${projectToUse.iconAndCover.occupancyGroup}/${projectOccupancyType.name}`,
      dominantProjectOccupancyUse: projectToUse.iconAndCover.occupancyUse,
      typeOfConstruction: coverSheetData.typeOfConstruction,
      floorsOfWork,
      isOccupyingEntireProperty: checkIfOccupyingEntireProperty(
        currentDrawings,
        coverSheetData,
      ),
      propertyLevelsAboveGround: coverSheetData.propertyLevelsAboveGround,
      propertyLevelsBelowGround: coverSheetData.propertyLevelsBelowGround,
      totalPropertyLevels: String(
        Number(coverSheetData.propertyLevelsAboveGround) +
          Number(coverSheetData.propertyLevelsBelowGround),
      ),
      totalPropertyHeight: coverSheetData.totalPropertyHeight,
      propertyType:
        coverSheetData.totalPropertyHeight > 75 ? "HIGH-RISE" : "LOW-RISE",
      totalOccupancyLoad: coverSheetData.totalOccupancyLoad,
      totalBuildingArea: `${coverSheetData.totalBuildingArea} (${totalWorkingArea} IN PROJECT)`,
      isFloodHazardArea: coverSheetData.isFloodHazardArea ? "YES" : "NO",
    };

    updateProjectSummary(null, dataForTable);
  };

  const updateCodeReferenceHandler = async (project) => {
    const projectToUse = project || currentProject;
    const coverSheetData = await getCoverSheetById(projectToUse.id);
    const codesCombination = await getCodesGroupById(
      coverSheetData.codesCombinationId,
    );
    const dataObject = getRelatedCodes(allCodeItems, codesCombination);
    updateCodeReference(dataObject);
  };

  const openScene = async (selectedDrawing, isCoverSheet, project) => {
    let isCreatingCoverSheet = false;
    if (project) {
      selectedProjectHandler(project.id);
    }
    const projectToUse = project || currentProject;
    if (isCoverSheet && !projectToUse.coverSheetRoom) {
      if (!currentProject?.id) {
        setCurrentProject(projectToUse);
      }
      isCreatingCoverSheet = true;
      await createCoverSheet(project);
    }
    const openedDrawing =
      (await getOneDrawingById(selectedDrawing)) ||
      (await getOneDrawingByRoomKey(location.hash));
    if (!openedDrawing) {
      location.hash = "";
      setIsOpenFromSaved(false);
      clearDrawingOpenerFromLocalStorage();
      setIsReadyToDesign(false);
      setIsLeavingScene(false);
      setActiveLoader(null);
      return;
    }
    location.hash = openedDrawing.roomKey;
    setDrawingToBeUploaded(openedDrawing);
    excalidrawAPI.zoomToFitAllDrawing();

    setToLocalStorage("currentDrawing", openedDrawing);

    doAfterLoadingScene(() => {
      if (isCoverSheet && !isCreatingCoverSheet) {
        updateMapImage();
        updateProjectSummaryHandler(projectToUse);
        updateCodeReferenceHandler(projectToUse);
      }
      updateFrameData(openedDrawing, projectToUse);
    });

    setCurrentDrawing(openedDrawing);
    setIsOpenFromSaved(false);
    triggerOpeningToast(openedDrawing);
    clearDrawingOpenerFromLocalStorage();
    setIsReadyToDesign(true);
    setIsLeavingScene(false);
    setActiveLoader(null);
  };

  window.onbeforeunload = () => {
    isPlacingOnMap && closePlacingOnMap({ discard: true });
  };

  const renderMenu = () => {
    return (
      <MainMenu>
        <CustomMainMenu
          gridSizeValue={gridSizeValue}
          gridModeValue={gridModeValue}
          setGridSizeValue={setGridSizeValue}
          setGridModeValue={setGridModeValue}
          updateScene={updateScene}
          handlePrint={handlePrint}
          signOutHandler={signOutHandler}
          changeDrawingBtnHandler={changeDrawingBtnHandler}
        />
      </MainMenu>
    );
  };

  const renderWelcomeScreen = () => {
    if (isOpenFromSaved || isLeavingScene) {
      return <WelcomeScreen></WelcomeScreen>;
    }
    return (
      <WelcomeScreen>
        <WelcomeScreen.Center>
          <CustomWelcomeScreen isLoading={isLeavingScene} />
        </WelcomeScreen.Center>
        <WelcomeScreen.Hints.MenuHint>
          <h4>Start here, export, preferences, and more...</h4>
        </WelcomeScreen.Hints.MenuHint>
        <WelcomeScreen.Hints.ToolbarHint>
          <h4>Insert a drawing then pick a tool & start working!</h4>
        </WelcomeScreen.Hints.ToolbarHint>
        <WelcomeScreen.Hints.HelpHint>
          <h4>Shortcuts & help</h4>
        </WelcomeScreen.Hints.HelpHint>
      </WelcomeScreen>
    );
  };

  // const addDesignerId = async () => {
  //   let designers = currentDrawing.designers || [];
  //   if (!designers.includes(currentUser.authId)) {
  //     designers.push(currentUser.authId);
  //     designers = Array(...new Set(designers));
  //     try {
  //       await createOrUpdateDrawing({ ...currentDrawing, designers }, true);
  //       editFrame({
  //         changes: [{ valueFor: "DESIGNED BY:", value: designers }],
  //         update: true,
  //         users: allUsers,
  //       });
  //     } catch (error) {
  //       console.error("Couldn't get update the designers: ", error);
  //     }
  //   }
  // };

  const dropHandler = async ([element]) => {
    if (element.customData?.imageType !== "device") {
      return;
    }
    const allSceneElements = excalidrawAPI.getSceneElements();

    //Assign the dropped device to an area.
    const deviceSceneEl = allSceneElements.find(
      (el) => el.customData?.docId === element.customData?.docId,
    );
    //Check if the device was dropped in an area
    const updatedDeviceSceneEl = checkIfDroppedInAreaHandler(deviceSceneEl);

    //Update the dropped device to have the area ID.
    assignDroppedDeviceToArea(element, updatedDeviceSceneEl);

    //Upload device to the database
    updatedDeviceSceneEl && addAssignedDeviceToDB(updatedDeviceSceneEl);
  };

  const handleBeforeInsertImage = async (element) => {
    try {
      Object.assign(element, {
        customData: {
          imageType: "floor-drawing",
          isImmutable: true,
        },
        locked: true,
      });
      //The uploaded image changes after it's set in local storage (width: 0 => bigger number => actual number)
      //FIXME - remove the setTimeout and go to the definition of the onBeforeInsertImage
      setTimeout(() => {
        const { drawingWidth, drawingHeight, marginX, marginY } =
          setDrawingPropsInScene(element, updateScene, excalidrawAPI);
        const drawingToUse = {
          ...element.customData,
          active: true,
          width: drawingWidth,
          height: drawingHeight,
          x: marginX,
          y: marginY,
          fileId: element.fileId,
        };
        setToLocalStorage(
          "appCustomData",
          { drawingToUpload: drawingToUse },
          { merge: true },
        );
        setCurrentDrawing(drawingToUse);
        setDrawingToBeUploaded(drawingToUse);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  const onCreateOrUpdateDrawing = async () => {
    setIsUploadPopupOpen(false);
    excalidrawAPI.zoomToFitAllDrawing();
  };

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
    // pageStyle: `@media print and (width: 17in) and (height: 11in) { body { -webkit-print-color-adjust: exact; } @page { size: 17in 11in; margin: 1in !important }`,
  });

  //Common
  if (document.querySelector('[title="Insert image"]')) {
    Object.assign(document.querySelector('[title="Insert image"]').style, {
      position: "absolute",
      top: "-100px",
    });
  }

  //Render
  return (
    <>
      <ExcalidrawApp
        // ref={componentToPrintRef}
        excalidrawAPI={excalidrawAPI}
        excalidrawRefCallback={(api) => setExcalidrawAPI(api)}
        gridModeEnabled={gridModeValue}
        initialData={initialData}
        onAfterImageAction={() => {
          setIsUploadPopupOpen(true);
        }}
        onBeforeInsertImageElement={handleBeforeInsertImage}
        onDrop={dropHandler}
        onCollabButtonClick={() => window.alert("You clicked on collab button")}
        name="FiCAD"
        UIOptions={{ canvasActions: { loadScene: false } }}
        handleKeyboardGlobally
        renderSidebar={renderSidebar}
        renderTopRightUI={() =>
          isReadyToDesign && createCustomizedLibraryBtn(excalidrawAPI)
        }
        langCode="en"
      >
        {renderWelcomeScreen()}
        {renderMenu()}
        {isOpenFromSaved && (
          <SavedDrawingOpener
            isOpened={isOpenFromSaved}
            excalidrawAPI={excalidrawAPI}
            projects={allProjects}
            isLoadingProjects={isLoadingProjects}
            isLoadingCurrentProject={isLoadingCurrentProject}
            allDrawings={allDrawings}
            onCloseHandler={escapePressHandler}
            setIsOpened={setIsOpenFromSaved}
            leaveSceneHandler={leaveSceneHandler}
            openScene={openScene}
            uploadPage={changeDrawingBtnHandler}
          />
        )}

        {isPlacingOnMap ? (
          <Map
            location={{ lat: currentProject.lat, lng: currentProject.lng }}
            polygonCoordinates={locationPolygonCoords}
            title={currentProject.address}
          />
        ) : null}

        {isConfirmDialogOpen && (
          <ConfirmDialog
            title={t("leaveSceneDialog.title")}
            onConfirm={() => {
              leaveSceneHandler(mainMenuClickedButton);
              setIsConfirmDialogOpen(false);
            }}
            onCancel={() => {
              setIsConfirmDialogOpen(false);
            }}
          >
            <p className="clear-canvas__content"> {t("alerts.leaveScene")}</p>
          </ConfirmDialog>
        )}

        <InfoPopup
          isOpen={isProjectSummaryOpen}
          setIsOpen={setIsProjectSummaryOpen}
          resetScene={resetScene}
          openScene={openScene}
        />

        <AreasSlider
          isOpen={isAreaSliderOpen}
          setIsOpen={setIsAreasSliderOpen}
          areas={allAreas?.filter((el) => el.drawingId === currentDrawing?.id)}
        />

        <DndProvider backend={HTML5Backend}>
          <PagesManager
            isOpen={isPagesManagerOpen}
            setIsOpen={setIsPagesManagerOpen}
            selectedProject={currentDrawing?.projectId}
            resetScene={goToWelcomeScreenHandler}
            openScene={openScene}
            uploadPage={changeDrawingBtnHandler}
          />
        </DndProvider>

        <UploadPopup
          currentUser={currentUser}
          projects={allProjects}
          selectedProject={selectedProject}
          selectedProjectHandler={selectedProjectHandler}
          occupancyTypes={occupancyTypes}
          onCreateOrUpdateDrawing={onCreateOrUpdateDrawing}
          isLoadingProjects={isLoadingProjects}
          isLoadingDrawings={isLoadingDrawings}
          onCloseHandler={escapePressHandler}
          updateScene={updateScene}
          allDrawings={allDrawings}
          gridModeValue={gridModeValue}
          drawingOrder={orderOfDrawingToUpload}
          isOpened={isUploadPopupOpen}
          drawingToBeUploaded={drawingToBeUploaded}
          setDrawingToBeUploaded={setDrawingToBeUploaded}
          setIsOpened={setIsUploadPopupOpen}
          setGridModeValue={setGridModeValue}
          setIsEditMode={setIsEditMode}
          resetScene={resetScene}
        />

        <SettingsSlider
          isOpened={isSettingsSliderOpen}
          setIsOpened={setIsSettingsSliderOpen}
        />

        <SlidersPullers
          setIsProjectSummaryOpen={setIsProjectSummaryOpen}
          setIsPagesManagerOpen={setIsPagesManagerOpen}
          setIsAreasSliderOpen={setIsAreasSliderOpen}
          setIsSettingsSliderOpen={setIsSettingsSliderOpen}
        />
      </ExcalidrawApp>

      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.02)",
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
        }}
        open={isLeavingScene || isOpeningScene || !!activeLoader}
      >
        <CustomLoader status={activeLoader} />
      </Backdrop>
    </>
  );
}
