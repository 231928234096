import { atom } from "jotai";

import { getFromLocalStorage } from "../helpers/common";
import { ExcalidrawImperativeAPI } from "../ExcalidrawAPI/types";

export const excalidrawApiAtom = atom<ExcalidrawImperativeAPI | null>(null);
export const currentProjectAtom = atom({ id: "", northHeading: 0 });
export const projectToPreloadAtom = atom(null);
export const currentDrawingAtom = atom(
  getFromLocalStorage("appCustomData")?.currentDrawing ||
    getFromLocalStorage("appCustomData")?.drawingToUpload ||
    null,
);
export const currentDrawingsAtom = atom<Array<{}> | null>(null);
export const usersAtom = atom<Array<{ fn: ""; ln: ""; photo: "" }>>([]);
export const iconsAndCoversAtom = atom<Array<{}>>([]);
export const occupancyTypesAtom = atom<Array<{}>>([]);
export const keyContactsAtom = atom<Array<{}>>([]);
export const pageOrderAtom = atom(0);
export const northHeadingAtom = atom<number | string>(0);
export const codeCombinationsAtom = atom<Array<{}>>([]);
export const selectedCodesCombinationAtom = atom<Object | string>(
  getFromLocalStorage("appCustomData")?.coverSheetData?.codesCombinationId ||
    "",
);
export const relatedCodesAtom = atom<Object>({
  buildingCode: "",
  electricalCode: "",
  nfpaCode: "",
});
export const allCodeItemsAtom = atom<Array<{}> | null>(null);
export const isActivePolygonToolAtom = atom<boolean>(false);
export const isActiveEditTextModeAtom = atom<boolean>(false);
export const isSelectingPartAreaAtom = atom<boolean>(false);
export const globalSettingsAtom = atom<object>(
  getFromLocalStorage("appCustomData")?.globalSettings || {
    measureSystem: "US Customary",
    numberFormat: "Comma",
    decimalPoints: 2,
  },
);
