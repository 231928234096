import { useState, useEffect, useCallback, useMemo } from "react";

import SkeletonTextField from "./SkeletonTextField";

import {
  Skeleton,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  useMediaQuery,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

import { selectComponentToResponse } from "../../helpers/responsiveness";
import {
  DRAWING_STAGES,
  IS_FLOOD_HAZARD_AREA_OPTIONS,
  MENU_PROPS,
  TYPES_OF_CONSTRUCTION,
  ALLOWED_SQUARED_UNITS,
} from "../../helpers/constants";
import { pageSizesWithRatioAndText as PAGE_SIZES } from "../../helpers/pageSizes";

import { useAtom } from "jotai";
import { isEditModeAtom } from "../../store/UI";
import { getFromLocalStorage, setToLocalStorage } from "../../helpers/common";
import {
  allCodeItemsAtom,
  codeCombinationsAtom,
  currentProjectAtom,
  relatedCodesAtom,
  selectedCodesCombinationAtom,
} from "../../store/variables";
import { getCoverSheetById } from "../../helpers/coverSheets";
import {
  getCodesGroupById,
  getRelatedCodes,
} from "../../helpers/codeReferences";

import "../../scss/coverSheetSection.scss";
import Indicator from "./Indicator";

import "../../scss/CoverSheet.scss";
import ReadOnlyFieldWithTooltip from "../ReadOnlyFieldWithTooltip";
import { useCommonFunctionsHook } from "../../hooks/useCommonFunctionsHook";

const useStyles = makeStyles(() => ({
  disabledTextField: {
    backgroundColor: "rgba(0, 0, 0, 0.03)",
    "& .MuiInputLabel-root": {
      color: "rgba(0, 0, 0, 0.7)",
    },
    "& .MuiInputBase-input": {
      "-webkit-text-fill-color": "rgba(0, 0, 0, 0.7) !important",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  disabledTextFieldOfCodes: {
    backgroundColor: "rgba(0, 0, 0, 0.03)",
    "& .MuiInputLabel-root": {
      color: "rgba(0, 0, 0, 0.7)",
    },
    "& .MuiInputBase-input": {
      "-webkit-text-fill-color": "rgba(0, 0, 0, 0.7) !important",
      fontSize: "13px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

const CoverSheetSection = ({
  isLoading,
  isDisabled,
  isExpanded,
  missedData,
  coverSheetData,
  expandedPanelHandler,
  setCoverSheetData,
  removeFromMissedData,
  addFieldToChange,
}) => {
  //Atom
  const [isEditMode] = useAtom(isEditModeAtom);
  const [currentProject] = useAtom(currentProjectAtom);
  const [codeCombinations] = useAtom(codeCombinationsAtom);
  const [selectedCodesCombination, setSelectedCodesCombination] = useAtom(
    selectedCodesCombinationAtom,
  );
  const [relatedCodes, setRelatedCodes] = useAtom(relatedCodesAtom);
  const [allCodeItems] = useAtom(allCodeItemsAtom);

  //CustomHooks
  const { formatNumber, convertFromToSFT, formatNumbersInsideText } =
    useCommonFunctionsHook();

  //Persisted Data
  const DEFAULT_VALUES = useMemo(() => {
    const defaultValues = getFromLocalStorage("appCustomData") ?? {};
    return {
      STAGE: defaultValues.coverSheetData?.stage || "",
      PAGE_SIZE: defaultValues.coverSheetData?.pageSize || "",
      TYPES_OF_CONSTRUCTION:
        defaultValues.coverSheetData?.typeOfConstruction || "",
      LEVELS_ABOVE_GROUND:
        defaultValues.coverSheetData?.propertyLevelsAboveGround || "",
      LEVELS_BELOW_GROUND:
        defaultValues.coverSheetData?.propertyLevelsBelowGround || "",
      TOTAL_PROPERTY_HEIGHT:
        defaultValues.coverSheetData?.totalPropertyHeight || "",
      TOTAL_OCCUPANCY_LOAD:
        defaultValues.coverSheetData?.totalOccupancyLoad || "",
      TOTAL_BUILDING_AREA:
        defaultValues.coverSheetData?.totalBuildingArea || "",
      AREA_UNIT: defaultValues.coverSheetData?.areaUnit || "SFT",
      FLOOD_HAZARD_AREA:
        defaultValues.coverSheetData?.isFloodHazardArea !== undefined
          ? defaultValues.coverSheetData?.isFloodHazardArea
          : "",
      CODES_COMBINATION: defaultValues.coverSheetData?.codesCombinationId || "",
    };
  }, []);

  //State
  const [stage, setStage] = useState(DEFAULT_VALUES.STAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_VALUES.PAGE_SIZE);

  const [typeOfConstruction, setTypeOfConstruction] = useState(
    DEFAULT_VALUES.TYPES_OF_CONSTRUCTION,
  );
  const [propertyLevelsAboveGround, setPropertyLevelsAboveGround] = useState(
    DEFAULT_VALUES.LEVELS_ABOVE_GROUND,
  );
  const [propertyLevelsBelowGround, setPropertyLevelsBelowGround] = useState(
    DEFAULT_VALUES.LEVELS_BELOW_GROUND,
  );
  const [totalPropertyLevels, setTotalPropertyLevels] = useState("");
  const [totalPropertyHeight, setTotalPropertyHeight] = useState(
    DEFAULT_VALUES.TOTAL_PROPERTY_HEIGHT,
  );
  const [totalOccupancyLoad, setTotalOccupancyLoad] = useState(
    DEFAULT_VALUES.TOTAL_OCCUPANCY_LOAD,
  );
  const [totalBuildingArea, setTotalBuildingArea] = useState(
    DEFAULT_VALUES.TOTAL_BUILDING_AREA,
  );
  const [areaUnit, setAreaUnit] = useState(DEFAULT_VALUES.AREA_UNIT);
  const [isFloodHazardArea, setIsFloodHazardArea] = useState(
    DEFAULT_VALUES.FLOOD_HAZARD_AREA,
  );
  const [isLoadingRelatedCodes, setIsLoadingRelatedCodes] = useState(false);

  //Functions
  const stageHandler = (ev) => {
    if (ev.target.value) {
      removeFromMissedData("stage");
    }
    addFieldToChange({
      valueFor: "stage",
      value: ev.target.value,
      originalName: "drawingStage",
    });
    setStage(ev.target.value);
    addCoverSheetDataToLocalStorage({
      stage: ev.target.value,
    });
    setCoverSheetData((prevData) => ({
      ...prevData,
      stage: ev.target.value,
    }));
  };
  const pageSizeHandler = (ev) => {
    if (ev.target.value) {
      removeFromMissedData("pageSize");
    }
    addFieldToChange({
      valueFor: "page size",
      value: ev.target.value,
      originalName: "pageSize",
    });
    setPageSize(ev.target.value);
    addCoverSheetDataToLocalStorage({
      pageSize: ev.target.value,
    });
    setCoverSheetData((prevData) => ({
      ...prevData,
      pageSize: ev.target.value,
    }));
  };

  const typeOfConstructionHandler = (ev) => {
    if (ev.target.value) {
      removeFromMissedData("typeOfConstruction");
    }
    setTypeOfConstruction(ev.target.value);
    addCoverSheetDataToLocalStorage({
      typeOfConstruction: ev.target.value,
    });
    setCoverSheetData((prevData) => ({
      ...prevData,
      typeOfConstruction: ev.target.value,
    }));
  };

  const propertyLevelsAboveGroundHandler = (ev) => {
    const value =
      Number(ev.target.value) === 0 && ev.target.value !== ""
        ? 0
        : ev.target.value.replace(/\D/g, "");

    if (value !== "" && Number(value) >= 0) {
      removeFromMissedData("propertyLevelsAboveGround");
    }
    setPropertyLevelsAboveGround(value);
    addCoverSheetDataToLocalStorage({
      propertyLevelsAboveGround: value,
    });
    setCoverSheetData((prevData) => ({
      ...prevData,
      propertyLevelsAboveGround: value,
    }));
  };

  const propertyLevelsBelowGroundHandler = (ev) => {
    const value =
      Number(ev.target.value) === 0 && ev.target.value !== ""
        ? 0
        : ev.target.value.replace(/\D/g, "");

    if (value !== "" && Number(value) >= 0) {
      removeFromMissedData("propertyLevelsBelowGround");
    }
    setPropertyLevelsBelowGround(value);
    addCoverSheetDataToLocalStorage({
      propertyLevelsBelowGround: value,
    });
    setCoverSheetData((prevData) => ({
      ...prevData,
      propertyLevelsBelowGround: value,
    }));
  };

  const totalPropertyHeightHandler = (ev) => {
    if (ev.target.value) {
      removeFromMissedData("totalPropertyHeight");
    }
    setTotalPropertyHeight(ev.target.value.replace(/\D/g, ""));
    addCoverSheetDataToLocalStorage({
      totalPropertyHeight: ev.target.value.replace(/\D/g, ""),
    });
    setCoverSheetData((prevData) => ({
      ...prevData,
      totalPropertyHeight: ev.target.value.replace(/\D/g, ""),
    }));
  };

  const totalOccupancyLoadHandler = (ev) => {
    if (ev.target.value) {
      removeFromMissedData("totalOccupancyLoad");
    }
    setTotalOccupancyLoad(ev.target.value.toUpperCase());
    addCoverSheetDataToLocalStorage({
      totalOccupancyLoad: ev.target.value.toUpperCase(),
    });
    setCoverSheetData((prevData) => ({
      ...prevData,
      totalOccupancyLoad: ev.target.value.toUpperCase(),
    }));
  };

  const totalBuildingAreaHandler = (ev, value) => {
    const valueToUse = ev ? ev.target.value : value;
    if (valueToUse) {
      removeFromMissedData("totalBuildingArea");
    }
    setTotalBuildingArea(valueToUse.replace(/[^\d.]/g, ""));
    addCoverSheetDataToLocalStorage({
      totalBuildingArea: valueToUse.replace(/[^\d.]/g, ""),
    });
    setCoverSheetData((prevData) => ({
      ...prevData,
      totalBuildingArea: valueToUse.replace(/[^\d.]/g, ""),
    }));
  };

  const areaUnitHandler = (ev) => {
    setAreaUnit(ev.target.value);

    totalBuildingAreaHandler(
      null,
      String(convertFromToSFT(totalBuildingArea, ev.target.value)),
    );

    addCoverSheetDataToLocalStorage({
      areaUnit: ev.target.value,
    });
    setCoverSheetData((prevData) => ({
      ...prevData,
      areaUnit: ev.target.value,
    }));
  };

  const isFloodHazardAreaHandler = (ev) => {
    if ([true, false].includes(ev.target.value)) {
      removeFromMissedData("isFloodHazardArea");
    }
    setIsFloodHazardArea(ev.target.value);
    addCoverSheetDataToLocalStorage({
      isFloodHazardArea: ev.target.value,
    });
    setCoverSheetData((prevData) => ({
      ...prevData,
      isFloodHazardArea: ev.target.value,
    }));
  };

  const relatedCodesHandler = useCallback(
    async (codesCombinationId) => {
      setIsLoadingRelatedCodes(true);
      try {
        const codesCombination = await getCodesGroupById(codesCombinationId);
        const codes = getRelatedCodes(allCodeItems, codesCombination);
        setRelatedCodes(codes);
        setIsLoadingRelatedCodes(false);
      } catch (error) {
        setIsLoadingRelatedCodes(false);
        console.error(
          "Couldn't set the codes related to this combination ",
          error,
        );
      }
    },
    [allCodeItems, setRelatedCodes],
  );

  const selectedCodesCombinationHandler = async (ev) => {
    if (ev.target.value) {
      removeFromMissedData("codesCombinationId");
    }
    const codeCombination = ev.target.value;
    setSelectedCodesCombination(codeCombination);
    addCoverSheetDataToLocalStorage({
      codesCombinationId: codeCombination,
    });
    setCoverSheetData((prevData) => ({
      ...prevData,
      codesCombinationId: codeCombination,
    }));
    relatedCodesHandler(codeCombination);
  };

  const isDisabledResetBtn = () => {
    return (
      !stage &&
      !pageSize &&
      !typeOfConstruction &&
      !propertyLevelsAboveGround &&
      !propertyLevelsBelowGround &&
      !totalPropertyHeight &&
      !totalOccupancyLoad &&
      !totalBuildingArea &&
      !isFloodHazardArea
    );
  };

  const addCoverSheetDataToLocalStorage = (item) => {
    if (isEditMode) {
      return;
    }
    const prevCoverSheetData =
      getFromLocalStorage("appCustomData")?.coverSheetData || {};
    const updatedData = Object.assign(prevCoverSheetData, item);
    setToLocalStorage(
      "appCustomData",
      { coverSheetData: updatedData },
      { merge: true },
    );
  };

  const fillCoverSheetData = useCallback(
    (data) => {
      setCoverSheetData(data);
      setStage(data?.stage || "");
      setPageSize(data?.pageSize || "");
      setTypeOfConstruction(data?.typeOfConstruction || "");
      setPropertyLevelsAboveGround(
        Number(data?.propertyLevelsAboveGround) >= 0 &&
          data?.propertyLevelsAboveGround !== ""
          ? data?.propertyLevelsAboveGround
          : "",
      );
      setPropertyLevelsBelowGround(
        Number(data?.propertyLevelsBelowGround) >= 0 &&
          data?.propertyLevelsBelowGround !== ""
          ? data?.propertyLevelsBelowGround
          : "",
      );
      setTotalPropertyHeight(Number(data?.totalPropertyHeight) || "");
      setTotalOccupancyLoad(data?.totalOccupancyLoad || "");
      setTotalBuildingArea(data?.totalBuildingArea || "");
      setAreaUnit(data?.areaUnit || "SFT");
      setIsFloodHazardArea(
        data?.isFloodHazardArea !== undefined ? data?.isFloodHazardArea : "",
      );
      setSelectedCodesCombination(data?.codesCombinationId || "");
      !isEditMode &&
        setToLocalStorage(
          "appCustomData",
          { coverSheetData: data || {} },
          { merge: true },
        );
    },
    [
      isEditMode,
      setCoverSheetData,
      setStage,
      setPageSize,
      setPropertyLevelsAboveGround,
      setPropertyLevelsBelowGround,
      setTypeOfConstruction,
      setTotalBuildingArea,
      setAreaUnit,
      setTotalOccupancyLoad,
      setTotalPropertyHeight,
      setIsFloodHazardArea,
      setSelectedCodesCombination,
    ],
  );

  const fillCoverSheetDataInEditMode = useCallback(async () => {
    const dataToFill = (await getCoverSheetById(currentProject?.id)) || {};
    setCoverSheetData(dataToFill || "");
    fillCoverSheetData(dataToFill);
  }, [currentProject, setCoverSheetData, fillCoverSheetData]);

  const getMissedClassName = (field, missedData) => {
    return missedData.includes(field) ? "missed" : "";
  };

  //Effects
  useEffect(() => {
    fillCoverSheetData(coverSheetData);
  }, [coverSheetData, fillCoverSheetData]);

  useEffect(() => {
    setCoverSheetData({
      stage: DEFAULT_VALUES.STAGE,
      pageSize: DEFAULT_VALUES.PAGE_SIZE,
      typeOfConstruction: DEFAULT_VALUES.TYPES_OF_CONSTRUCTION,
      propertyLevelsAboveGround: DEFAULT_VALUES.LEVELS_ABOVE_GROUND,
      propertyLevelsBelowGround: DEFAULT_VALUES.LEVELS_BELOW_GROUND,
      totalPropertyHeight: DEFAULT_VALUES.TOTAL_PROPERTY_HEIGHT,
      totalOccupancyLoad: DEFAULT_VALUES.TOTAL_OCCUPANCY_LOAD,
      totalBuildingArea: DEFAULT_VALUES.TOTAL_BUILDING_AREA,
      areaUnit: DEFAULT_VALUES.AREA_UNIT,
      isFloodHazardArea: DEFAULT_VALUES.FLOOD_HAZARD_AREA,
      codesCombinationId: DEFAULT_VALUES.CODES_COMBINATION,
    });
  }, [setCoverSheetData, DEFAULT_VALUES]);

  useEffect(() => {
    if (isEditMode) {
      fillCoverSheetDataInEditMode();
    }
  }, [isEditMode, fillCoverSheetDataInEditMode]);

  useEffect(() => {
    const totalLevels =
      Number(propertyLevelsAboveGround || 0) +
      Number(propertyLevelsBelowGround || 0);
    setTotalPropertyLevels(totalLevels === 0 ? "" : totalLevels);
  }, [propertyLevelsAboveGround, propertyLevelsBelowGround]);

  useEffect(() => {
    if (!selectedCodesCombination) {
      setRelatedCodes({ buildingCode: "", electricalCode: "", nfpaCode: "" });
      return;
    }
    selectedCodesCombination &&
      allCodeItems?.length &&
      relatedCodesHandler(selectedCodesCombination);
  }, [
    selectedCodesCombination,
    setRelatedCodes,
    relatedCodesHandler,
    allCodeItems,
  ]);

  //Constants
  const textFieldStyle = {
    width: "100%",
    marginBottom: "16px",
    "& .MuiInputBase-input": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingRight: "50px",
    },
  };
  const textFieldWithHelperTextStyle = {
    ...textFieldStyle,
    "& .MuiFormHelperText-root": {
      fontSize: "13px",
      marginLeft: "0px",
      marginRight: "0px",
      textAlign: "center",
    },
  };

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const skeletonStyle = {
    marginBottom: {
      lg: "24px !important",
      xs: "6px !important",
    },
  };

  const accordionStyle = {
    marginBottom: {
      lg: "24px !important",
      xs: "6px !important",
    },
    boxShadow: isExpanded ? "auto" : "none",
    "&.Mui-expanded": {
      marginTop: "0px",
    },
    "& .MuiButtonBase-root-MuiAccordionSummary-root": {
      minHeight: "56px",
      padding: "4px 16px",
    },
  };

  const inputLabelToResponse = {
    fontSize: isSmallScreen ? 14 : 16,
    lineHeight: isSmallScreen ? 2 : 1.6,
  };

  const classes = useStyles();

  //Render
  return isLoading ? (
    <Skeleton variant="rounded" height={66} sx={skeletonStyle} />
  ) : (
    <Accordion
      sx={accordionStyle}
      expanded={isExpanded}
      onChange={() => {
        expandedPanelHandler("cover-sheet-panel");
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="cover-sheet-content"
        id="cover-sheet-header"
        className={`upload-accordion-summary ${
          !!missedData.length ? "blinking-accordion-summary" : ""
        }`}
        disabled={isDisabled}
        sx={{ width: "100%" }}
      >
        <Typography>Cover Sheet Details</Typography>
        <Indicator number={missedData.length} />
      </AccordionSummary>

      <AccordionDetails
        sx={{
          padding: isSmallScreen ? "8px 0 0" : " 8px 16px 16px",
        }}
      >
        <div className="page-props-fields">
          <FormControl
            id="coversheet-stage-dropdown"
            className={getMissedClassName("stage", missedData)}
            fullWidth={true}
            sx={{ margin: "0px 0 16px 0" }}
          >
            <InputLabel
              id="coversheet-stage-dropdown-label"
              style={inputLabelToResponse}
            >
              Stage
            </InputLabel>
            <Select
              labelId="coversheet-stage-dropdown-label"
              id="coversheet-stage-dropdown"
              value={stage}
              label="Stage"
              onChange={stageHandler}
              MenuProps={MENU_PROPS}
              sx={selectComponentToResponse}
            >
              {DRAWING_STAGES?.sort((a, b) => -b.localeCompare(a)).map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            id="cover-sheet-size-dropdown"
            className={getMissedClassName("pageSize", missedData)}
            fullWidth={true}
            sx={{ margin: "0px 0 16px 0" }}
          >
            <InputLabel
              id="cover-sheet-size-dropdown-label"
              style={inputLabelToResponse}
            >
              Page Size
            </InputLabel>
            <Select
              labelId="cover-sheet-size-dropdown-label"
              id="cover-sheet-size-dropdown"
              value={pageSize}
              label="Page Size"
              onChange={pageSizeHandler}
              MenuProps={MENU_PROPS}
              sx={selectComponentToResponse}
            >
              {PAGE_SIZES.sort(
                (a, b) => -b.sizeAsText.localeCompare(a.sizeAsText),
              ).map((el) => (
                <MenuItem key={el.id} value={el.sizeAsText}>
                  {el.sizeAsText}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="project-summary-fields">
          <FormControl
            id="type-of-construction-dropdown"
            className={getMissedClassName("typeOfConstruction", missedData)}
            fullWidth={true}
            sx={{ margin: "10px 0 16px 0" }}
          >
            <InputLabel
              id="type-of-construction-dropdown-label"
              style={inputLabelToResponse}
            >
              Type Of Construction
            </InputLabel>
            <Select
              labelId="type-of-construction-dropdown-label"
              id="type-of-construction-dropdown"
              value={typeOfConstruction}
              label="Type Of Construction"
              onChange={typeOfConstructionHandler}
              MenuProps={MENU_PROPS}
              sx={selectComponentToResponse}
            >
              {TYPES_OF_CONSTRUCTION?.map((t) => (
                <MenuItem key={t} value={t}>
                  {t}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="levels-above-ground-textfield"
            className={getMissedClassName(
              "propertyLevelsAboveGround",
              missedData,
            )}
            label="Building Levels Above Ground"
            value={propertyLevelsAboveGround}
            onChange={propertyLevelsAboveGroundHandler}
            autoComplete="off"
            sx={textFieldStyle}
          />
          <TextField
            id="levels-below-ground-textfield"
            className={getMissedClassName(
              "propertyLevelsBelowGround",
              missedData,
            )}
            label="Building Levels Below Ground"
            value={propertyLevelsBelowGround}
            onChange={propertyLevelsBelowGroundHandler}
            autoComplete="off"
            sx={textFieldStyle}
          />
          <ReadOnlyFieldWithTooltip
            id="total-property-levels-textfield"
            label="Total Building Levels"
            value={totalPropertyLevels}
            tooltip="The sum of levels above ground and levels below ground"
            style={textFieldStyle}
          />

          <div className="building-height-input">
            <TextField
              id="total-property-height-textfield"
              className={getMissedClassName("totalPropertyHeight", missedData)}
              label="Total Building Height (Feet)"
              variant="outlined"
              value={totalPropertyHeight}
              onChange={totalPropertyHeightHandler}
              autoComplete="off"
              sx={textFieldWithHelperTextStyle}
            />

            <Tooltip
              title="Total height from lowest level of fire department vehicle access"
              placement="right"
              arrow={true}
            >
              <HelpOutlineOutlinedIcon
                sx={{
                  fill: "rgba(0, 0, 0, 0.5)",
                  marginLeft: "5px",
                  marginBottom: "16px",
                  fontSize: "1.8rem",
                  position: "absolute",
                  right: "12px",
                }}
              />
            </Tooltip>
          </div>

          <ReadOnlyFieldWithTooltip
            id="property-type-textfield"
            label="Building Type"
            value={
              !totalPropertyHeight
                ? ""
                : Number(totalPropertyHeight) > 75
                ? "HIGH-RISE"
                : "LOW-RISE"
            }
            tooltip="If the height of the entire property exceeds 75 feet, it will be classified as a high-rise. Otherwise, it will be categorized as a low-rise."
            style={textFieldStyle}
          />

          <div className="occupancy-load-input">
            <TextField
              id="occupancy-load-textfield"
              className={getMissedClassName("totalOccupancyLoad", missedData)}
              label="Total Occupancy Load (Persons)"
              value={formatNumbersInsideText(totalOccupancyLoad)}
              onChange={totalOccupancyLoadHandler}
              autoComplete="off"
              sx={textFieldStyle}
            />

            <Tooltip
              title="Number of persons a building or a specific portion of a building is designed to accommodate for safety, building design, fire protection and evacuation, ventilation and plumbing, zoning and licensing"
              placement="right"
              arrow={true}
            >
              <HelpOutlineOutlinedIcon
                sx={{
                  fill: "rgba(0, 0, 0, 0.5)",
                  marginLeft: "5px",
                  marginBottom: "16px",
                  fontSize: "1.8rem",
                  position: "absolute",
                  right: "12px",
                }}
              />
            </Tooltip>
          </div>

          <div className="building-area-input">
            <TextField
              id="building-area-textfield"
              className={getMissedClassName("totalBuildingArea", missedData)}
              label={`Total Building Area (${areaUnit})`}
              value={formatNumber(totalBuildingArea)}
              onChange={totalBuildingAreaHandler}
              autoComplete="off"
              sx={{ ...textFieldStyle, marginBottom: "0" }}
            />

            <FormControl
              id="area-unit-dropdown-container"
              sx={{
                width: "20%",
                height: "40px",
                position: "absolute",
                right: "55px",
              }}
            >
              <InputLabel
                id="area-unit-dropdown-label"
                style={inputLabelToResponse}
              >
                Unit²
              </InputLabel>

              <Select
                labelId="area-unit-dropdown-label"
                id="project-dominant-type-dropdown"
                value={areaUnit}
                disabled={!totalBuildingArea}
                label="Unit²"
                onChange={areaUnitHandler}
                sx={{ ...selectComponentToResponse, height: "40px" }}
              >
                {ALLOWED_SQUARED_UNITS.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Tooltip
              title="Sum of the floor areas of a building across all its levels or stories"
              placement="right"
              arrow={true}
            >
              <HelpOutlineOutlinedIcon
                sx={{
                  fill: "rgba(0, 0, 0, 0.5)",
                  marginLeft: "5px",
                  fontSize: "1.8rem",
                  position: "absolute",
                  right: "12px",
                }}
              />
            </Tooltip>
          </div>

          <FormControl
            id="flood-hazard-area-dropdown"
            className={getMissedClassName("isFloodHazardArea", missedData)}
            fullWidth={true}
            sx={{ marginBottom: "16px" }}
          >
            <InputLabel
              id="flood-hazard-area-dropdown-label"
              style={inputLabelToResponse}
            >
              Special Flood Hazard Area?
            </InputLabel>
            <Select
              labelId="flood-hazard-area-dropdown-label"
              id="flood-hazard-area-dropdown"
              value={isFloodHazardArea}
              label="Special Flood Hazard Area?"
              onChange={isFloodHazardAreaHandler}
              MenuProps={MENU_PROPS}
              sx={selectComponentToResponse}
            >
              {IS_FLOOD_HAZARD_AREA_OPTIONS?.map((o) => (
                <MenuItem key={o.label} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="code-reference-fields">
          <FormControl
            id="code-reference-combination-dropdown"
            className={getMissedClassName("codesCombinationId", missedData)}
            fullWidth={true}
            sx={{ marginBottom: "16px" }}
          >
            <InputLabel
              id="code-reference-combination-dropdown-label"
              style={inputLabelToResponse}
            >
              Codes Group
            </InputLabel>
            <Select
              labelId="code-reference-combination-dropdown-label"
              id="code-reference-combination-dropdown"
              value={!codeCombinations?.length ? "" : selectedCodesCombination}
              label="Codes Group"
              onChange={selectedCodesCombinationHandler}
              MenuProps={MENU_PROPS}
              sx={selectComponentToResponse}
            >
              {codeCombinations?.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <SkeletonTextField
            label="Building Code"
            value={relatedCodes.buildingCode}
            isLoading={isLoadingRelatedCodes}
            classes={classes}
            textFieldStyle={textFieldStyle}
          />

          <SkeletonTextField
            label="Electrical Code"
            value={relatedCodes.electricalCode}
            isLoading={isLoadingRelatedCodes}
            classes={classes}
            textFieldStyle={textFieldStyle}
          />

          <SkeletonTextField
            label="NFPA Code"
            value={relatedCodes.nfpaCode}
            isLoading={isLoadingRelatedCodes}
            classes={classes}
            textFieldStyle={textFieldStyle}
          />
        </div>

        <Button
          id="cover-sheet-section_reset_btn"
          variant="outlined"
          disabled={isDisabledResetBtn()}
          startIcon={<RefreshOutlinedIcon />}
          onClick={() => fillCoverSheetData("")}
        >
          <span style={{ marginTop: "2px" }}>Reset</span>
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default CoverSheetSection;
