export const keyContacts = [
  {
    id: "nvodw_9erhvADcocSc",
    logo: "",
    companyName: "vf outdoor and action sports coalition",
    website: "",
    address: "1588 south coast, drive, costa mesa, CA 92626",
    phoneNumber: "(714) 755-4824",
    mail: "",
    role: "owner",
  },
  {
    id: "ljsadfVlNOFlldfk",
    logo: "",
    companyName: "robert g. lyon + associates, inc",
    website: "",
    address: "5100 river road, suite 125, schiller park, IL 60176",
    phoneNumber: "(847) 671-4200",
    mail: "info@www.rgla.com",
    role: "architect",
  },
  {
    id: "nvodw_9erhvADcocSc",
    logo: "https://www.thebluebook.com/inc/img/qp/2591197/ez-fire-logo.jpg",
    companyName: "",
    website: "https://ezfire.us",
    address: "",
    phoneNumber: "(646) 586-3058",
    mail: "service@ezfirecontrols.com",
    role: "system design",
  },
  {
    id: "nzvoSlviBldjvQ-k",
    logo: "https://www.thebluebook.com/inc/img/qp/2591197/ez-fire-logo.jpg",
    companyName: "",
    website: "https://ezfire.us",
    address: "",
    phoneNumber: "(646) 586-3058",
    mail: "service@ezfirecontrols.com",
    role: "filing representative",
  },
  {
    id: "nzvoSlviBldjvQ-k",
    logo: "",
    companyName: "schimenti construction company",
    website: "www.schimenti.com",
    address: "675 lexingrom avenue, 18th floor, new york, NY 10022",
    phoneNumber: "(212) 246-9100",
    mail: "",
    role: "general contractor",
  },
  {
    id: "vjadVAslkDEzvfek",
    logo: "https://www.thebluebook.com/inc/img/qp/2591197/ez-fire-logo.jpg",
    companyName: "",
    website: "https://ezfire.us",
    address: "",
    phoneNumber: "(646) 586-3058",
    mail: "service@ezfirecontrols.com",
    role: "system vendor",
  },
];

export const legalText =
  "THESE PLANS ARE PROVIDED BASED ON THE APPROVED WORK INDICATED ON THE ENGINEERED SET OF PLANS OR AS OTHERWISE AMENDED BY THE ENGINEER OF RECORD. THE SYSTEM SOLUTION ON THESE PLANS IS BASED ON APPROVED EQUIPMENT SOLD BY THE ORIGINAL EQUIPMENT MANUFACTURER. ALL OTHER MATTERS SHOWN ARE NOT TO BE RELIED UPON OR TO BE CONSIDERED AS EITHER BEING APPROVED OR IN ACCORDANCE WITH THE APPLICABLE CODES. THE PROJECT IS SUBJECT TO INSPECTION AND APPROVAL BY THE AUTHORITY HAVING JURISDICTION.";
