import React, { useState, useEffect } from "react";

import Switcher from "./Switcher";

import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ImageAspectRatioOutlinedIcon from "@mui/icons-material/ImageAspectRatioOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

import "../scss/FloatMapAction.scss";
import useCloseMapHandler from "../hooks/usePlaceOnMapHandler";
import { currentDrawingAtom } from "../store/variables";
import { useAtom } from "jotai";

const FloatMapOptions = ({
  disabled,
  setCurrentView,
  updateMapDataInDBHandler,
  style,
}) => {
  //Custom Hooks
  const { closePlacingOnMap } = useCloseMapHandler();

  //Atom
  const [currentDrawing] = useAtom(currentDrawingAtom);

  //State
  const [mapView, setMapView] = useState(false);

  //Effects
  useEffect(() => {
    if (currentDrawing?.type === "coverSheet") {
      setMapView(true);
    }
  }, [currentDrawing]);

  //Functions
  const currentViewHandler = (view) => {
    if (currentDrawing.type === "coverSheet") {
      return;
    }
    setCurrentView((prevView) => {
      if (view === prevView) {
        return prevView;
      }
      setMapView((preMapView) => !preMapView);
      return prevView === "map" ? "drawing" : "map";
    });
  };

  const floatingMapActionsManager = async (status) => {
    if (status === "save") {
      await updateMapDataInDBHandler();
      closePlacingOnMap({ discard: false });
    } else {
      closePlacingOnMap({ discard: true });
    }
  };

  //Render
  return (
    <section className="float-map-actions_container">
      <div className="float-map_view_actions" style={style}>
        <ImageAspectRatioOutlinedIcon
          className="drawing-view-icon"
          titleAccess="Control Drawing"
          style={{ color: mapView ? "#aaa" : "#444" }}
          onClick={() => currentViewHandler("drawing")}
        />
        <Switcher
          disabled={currentDrawing.type === "coverSheet"}
          onClick={currentViewHandler}
          value={mapView}
        />
        <MapOutlinedIcon
          className="map-view-icon"
          titleAccess="Control Map"
          style={{ color: mapView ? "#444" : "#aaa" }}
          onClick={() => currentViewHandler("map")}
        />
      </div>

      <div className="float-map-save_discard_actions">
        <button
          title="Discard"
          disabled={disabled}
          onClick={() => floatingMapActionsManager("discard")}
          className="float-map-discard_action"
        >
          <ClearRoundedIcon />
        </button>
        <button
          title="Save"
          disabled={disabled}
          onClick={() => floatingMapActionsManager("save")}
          className="float-map-save_action"
        >
          <CheckRoundedIcon />
        </button>
      </div>
    </section>
  );
};

export default FloatMapOptions;
