import React, { useState, useEffect } from "react";

import {
  getCategorizedDevices,
  getDevicesCategoriesByParentId,
  getDevicesSubCategoriesByParentId,
  getDevicesSubSubCategoriesByParentId,
} from "../helpers/devices";

import { Sidebar } from "../ExcalidrawAPI/components/Sidebar/Sidebar";
import Spinner from "../ExcalidrawAPI/components/Spinner";

import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

import CustomizedLibraryItem from "./CustomizedLibraryItem";
import DevicesSidebarDropdown from "./DevicesSidebarDropdown";

import "../scss/DevicesSidebar.scss";
import { getFromLocalStorage, setToLocalStorage } from "../helpers/common";

const DevicesSidebar = ({
  devicesIndustries,
  currentDrawing,
  devicesSymbols,
}) => {
  // Persisted Data
  const defaultValues = getFromLocalStorage("appCustomData") ?? {};
  const DEFAULT_VALUES = {
    SELECTED_INDUSTRY: defaultValues?.devicesSidebarData?.industry || null,
    SELECTED_CATEGORY: defaultValues?.devicesSidebarData?.category || null,
    SELECTED_SUB_CATEGORY:
      defaultValues?.devicesSidebarData?.subCategory || null,
    SELECTED_SUB_SUB_CATEGORY:
      defaultValues?.devicesSidebarData?.subSubCategory || null,
    CATEGORY_OPTIONS: defaultValues?.devicesSidebarData?.categoryOptions || [],
    SUB_CATEGORY_OPTIONS:
      defaultValues?.devicesSidebarData?.subCategoryOptions || [],
    SUB_SUB_CATEGORY_OPTIONS:
      defaultValues?.devicesSidebarData?.subSubCategoryOptions || [],
  };

  //Custom Hooks

  //Refs

  //Atom

  //State
  const [devicesCategories, setDevicesCategories] = useState(
    DEFAULT_VALUES.CATEGORY_OPTIONS,
  );
  const [devicesSubCategories, setDevicesSubCategories] = useState(
    DEFAULT_VALUES.SUB_CATEGORY_OPTIONS,
  );
  const [devicesSubSubCategories, setDevicesSubSubCategories] = useState(
    DEFAULT_VALUES.SUB_SUB_CATEGORY_OPTIONS,
  );
  const [selectedDeviceIndustry, setSelectedDeviceIndustry] = useState(
    DEFAULT_VALUES.SELECTED_INDUSTRY,
  );
  const [selectedDeviceCategory, setSelectedDeviceCategory] = useState(
    DEFAULT_VALUES.SELECTED_CATEGORY,
  );
  const [selectedDeviceSubCategory, setSelectedDeviceSubCategory] = useState(
    DEFAULT_VALUES.SELECTED_SUB_CATEGORY,
  );
  const [selectedDeviceSubSubCategory, setSelectedDeviceSubSubCategory] =
    useState(DEFAULT_VALUES.SELECTED_SUB_SUB_CATEGORY);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const [isLoading, setIsLoading] = useState({
    industries: false,
    categories: false,
    subCategories: false,
    subSubCategories: false,
  });

  // Effects
  useEffect(() => {
    const setDevices = async () => {
      setIsLoadingDevices(true);
      await devicesHandler(selectedDeviceSubSubCategory);
      setIsLoadingDevices(false);
    };
    setDevices();
  }, [selectedDeviceSubSubCategory]);

  //Functions
  const setDetailsInLocalStorage = (update) => {
    const appCustomData = getFromLocalStorage("appCustomData");
    if (!update) {
      setToLocalStorage(
        "appCustomData",
        { devicesSidebarData: {} },
        { merge: true },
      );
      return;
    }
    let devicesSidebarData = appCustomData?.devicesSidebarData || {};
    if ("industry" in update) {
      devicesSidebarData = update;
    } else if ("category" in update) {
      Object.assign(devicesSidebarData, update, {
        subCategory: null,
        subSubCategory: null,
        subSubCategoryOptions: [],
      });
    } else if ("subCategory" in update) {
      Object.assign(devicesSidebarData, update, {
        subSubCategory: null,
      });
    } else {
      Object.assign(devicesSidebarData, update);
    }
    setToLocalStorage("appCustomData", { devicesSidebarData }, { merge: true });
  };

  const selectDeviceIndustryHandler = async (_, value) => {
    if (!value) {
      return;
    }
    setSelectedDeviceIndustry(value);
    setSelectedDeviceCategory(null);
    setSelectedDeviceSubCategory(null);
    setSelectedDeviceSubSubCategory(null);
    setFilteredDevices([]);
    try {
      setIsLoading((prevStatus) => ({ ...prevStatus, categories: true }));
      const filteredCategories = await getDevicesCategoriesByParentId(value.id);
      setDevicesCategories(filteredCategories);
      setDetailsInLocalStorage({
        industry: value,
        categoryOptions: filteredCategories,
      });
      setIsLoading((prevStatus) => ({ ...prevStatus, categories: false }));
    } catch (error) {
      console.error("Couldn't get the devices categories: ", error);
      setIsLoading((prevStatus) => ({ ...prevStatus, categories: false }));
    }
  };

  const selectDeviceCategoryHandler = async (_, value) => {
    if (!value) {
      return;
    }
    try {
      setSelectedDeviceCategory(value);
      setSelectedDeviceSubCategory(null);
      setSelectedDeviceSubSubCategory(null);
      setFilteredDevices([]);
      setIsLoading((prevStatus) => ({ ...prevStatus, subCategories: true }));
      const filteredSubCategories = await getDevicesSubCategoriesByParentId(
        value.id,
      );
      setDevicesSubCategories(filteredSubCategories);
      setDetailsInLocalStorage({
        category: value,
        subCategoryOptions: filteredSubCategories,
      });
      setIsLoading((prevStatus) => ({ ...prevStatus, subCategories: false }));
    } catch (error) {
      console.error("Couldn't get the devices sub-categories: ", error);
    }
  };

  const selectDeviceSubCategoryHandler = async (_, value) => {
    if (!value) {
      return;
    }
    try {
      setSelectedDeviceSubCategory(value);
      setSelectedDeviceSubSubCategory(null);
      setFilteredDevices([]);
      setIsLoading((prevStatus) => ({
        ...prevStatus,
        subSubCategories: true,
      }));
      const filteredSubSubCategories =
        await getDevicesSubSubCategoriesByParentId(value.id);
      setDevicesSubSubCategories(filteredSubSubCategories);
      setDetailsInLocalStorage({
        subCategory: value,
        subSubCategoryOptions: filteredSubSubCategories,
      });
      setIsLoading((prevStatus) => ({
        ...prevStatus,
        subSubCategories: false,
      }));
    } catch (error) {
      console.error("Couldn't get the devices sub-sub-categories: ", error);
    }
  };

  const selectDeviceSubSubCategoryHandler = async (_, value) => {
    if (!value) {
      return;
    }
    try {
      setSelectedDeviceSubSubCategory(value);
      setFilteredDevices([]);
      setIsLoadingDevices(true);
      setDetailsInLocalStorage({ subSubCategory: value });
    } catch (error) {
      console.error("Couldn't get devices: ", error);
    }
    setIsLoadingDevices(false);
  };

  const devicesHandler = async (subSubCategory) => {
    if (!subSubCategory?.id) {
      setFilteredDevices([]);
      return;
    }
    const filteredDevices = await getCategorizedDevices({
      industryId: selectedDeviceIndustry.id,
      categoryId: selectedDeviceCategory.id,
      subCategoryId: selectedDeviceSubCategory.id,
      subSubCategoryId: subSubCategory.id,
    });
    const devicesWithSymbols = filteredDevices.map((el) => {
      const symbolToUse = devicesSymbols.find(
        (symbol) => symbol.id === el.symbolId,
      );
      return Object.assign(el, {
        icon: symbolToUse?.downloadLink,
        iconId: symbolToUse?.id,
      });
    });
    setFilteredDevices(devicesWithSymbols);
  };

  const resetInputs = () => {
    setSelectedDeviceIndustry(null);
    setSelectedDeviceCategory(null);
    setSelectedDeviceSubCategory(null);
    setSelectedDeviceSubSubCategory(null);
    setDetailsInLocalStorage(null);
  };

  //Constants
  const isDisabledResetBtn =
    !selectedDeviceIndustry &&
    !selectedDeviceCategory &&
    !selectedDeviceSubCategory &&
    !selectedDeviceSubSubCategory;

  //Common

  //Render

  return (
    <Sidebar className="customized_sidebar">
      <Sidebar.Header>
        <button
          title="Refresh"
          className="customized-library_reset_btn"
          onClick={resetInputs}
          disabled={isDisabledResetBtn}
        >
          <RefreshRoundedIcon fontSize="1rem" />
        </button>
      </Sidebar.Header>

      <p style={{ padding: "20px 0", textAlign: "center", fontWeight: 600 }}>
        Device Selection
      </p>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <DevicesSidebarDropdown
          label="Industry"
          isLoading={isLoading.industries}
          value={selectedDeviceIndustry}
          options={devicesIndustries}
          onChange={selectDeviceIndustryHandler}
        />

        <DevicesSidebarDropdown
          label="Category"
          isDisabled={!selectedDeviceIndustry}
          isLoading={isLoading.categories}
          value={selectedDeviceCategory}
          options={devicesCategories}
          onChange={selectDeviceCategoryHandler}
        />

        <DevicesSidebarDropdown
          label="Sub-Category"
          isDisabled={!selectedDeviceCategory}
          isLoading={isLoading.subCategories}
          value={selectedDeviceSubCategory}
          options={devicesSubCategories}
          onChange={selectDeviceSubCategoryHandler}
        />

        <DevicesSidebarDropdown
          label="Sub-Sub-Category"
          isDisabled={!selectedDeviceSubCategory}
          isLoading={isLoading.subSubCategories}
          value={selectedDeviceSubSubCategory}
          options={devicesSubSubCategories}
          onChange={selectDeviceSubSubCategoryHandler}
        />

        <>
          <div className="devices-separator"></div>

          <div className="devices-container">
            <p className="devices-container_header">Devices</p>
            <div className="devices-container_content">
              {filteredDevices.length > 0 ? (
                filteredDevices.map((device) => (
                  <CustomizedLibraryItem
                    key={device.id}
                    pixelsPerUnit={currentDrawing?.pixelsPerUnit}
                    correspondingUnit={currentDrawing?.correspondingUnit}
                    drawingId={currentDrawing?.id}
                    item={device}
                  />
                ))
              ) : (
                <>
                  {isLoadingDevices ? (
                    <div
                      style={{ width: "fit-content", height: "fit-content" }}
                    >
                      <Spinner size="2rem" />
                      <p
                        style={{
                          margin: "12px 0",
                          fontSize: "12px",
                          color: "#777",
                        }}
                      >
                        Loading Devices...
                      </p>
                    </div>
                  ) : (
                    <p
                      style={{
                        margin: "14px",
                        fontSize: "14px",
                        color: "#777",
                        height: "fit-content",
                      }}
                    >
                      {selectedDeviceSubSubCategory
                        ? "No Devices Available"
                        : "Selected Devices Will Appear Here"}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      </div>
    </Sidebar>
  );
};

export default DevicesSidebar;
