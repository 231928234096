import {
  isSyncableElement,
  SocketUpdateData,
  SocketUpdateDataSource,
} from "../data";

import { ExcalidrawElement } from "../../element/types";
import { WS_EVENTS, WS_SCENE_EVENT_TYPES } from "../app_constants";
import { trackEvent } from "../../analytics";
import { BroadcastedExcalidrawElement } from "./reconciliation";
import { encryptData } from "../../data/encryption";
import { PRECEDING_ELEMENT_KEY } from "../../constants";

class CustomPortal {
  socket: SocketIOClient.Socket | null = null;
  socketInitialized: boolean = false; // we don't want the socket to emit any updates until it is fully initialized
  roomId: string | null = null;
  roomKey: string | null = null;
  broadcastedElementVersions: Map<string, number> = new Map();

  open(socket: SocketIOClient.Socket, id: string, key: string) {
    return new Promise((resolve, reject) => {
      this.socket = socket;
      this.roomId = id;
      this.roomKey = key;

      // Initialize socket listeners
      this.socket.on("init-room", () => {
        this.socketInitialized = true;
        if (this.socket) {
          this.socket.emit("join-room", this.roomId);
          trackEvent("share", "room joined");
        }
        resolve(this.socket);
      });
    });
  }

  close() {
    if (!this.socket) {
      return;
    }
    this.socket.close();
    this.socket = null;
    this.roomId = null;
    this.roomKey = null;
    this.socketInitialized = false;
    this.broadcastedElementVersions = new Map();
  }

  isOpen() {
    return !!(
      this.socketInitialized &&
      this.socket &&
      this.roomId &&
      this.roomKey
    );
  }

  async _broadcastSocketData(
    data: SocketUpdateData,
    volatile: boolean = false,
  ) {
    if (this.isOpen()) {
      const json = JSON.stringify(data);
      const encoded = new TextEncoder().encode(json);
      const { encryptedBuffer, iv } = await encryptData(this.roomKey!, encoded);

      this.socket?.emit(
        volatile ? WS_EVENTS.SERVER_VOLATILE : WS_EVENTS.SERVER,
        this.roomId,
        encryptedBuffer,
        iv,
      );
    }
  }

  broadcastScene = async (
    updateType: WS_SCENE_EVENT_TYPES.INIT | WS_SCENE_EVENT_TYPES.UPDATE,
    allElements: readonly ExcalidrawElement[],
    syncAll: boolean,
  ) => {
    if (updateType === WS_SCENE_EVENT_TYPES.INIT && !syncAll) {
      throw new Error("syncAll must be true when sending SCENE.INIT");
    }

    // sync out only the elements we think we need to to save bandwidth.
    // periodically we'll resync the whole thing to make sure no one diverges
    // due to a dropped message (server goes down etc).
    const syncableElements = allElements.reduce(
      (acc, element: BroadcastedExcalidrawElement, idx, elements) => {
        if (
          (syncAll ||
            !this.broadcastedElementVersions.has(element.id) ||
            element.version >
              this.broadcastedElementVersions.get(element.id)!) &&
          isSyncableElement(element)
        ) {
          acc.push({
            ...element,
            // z-index info for the reconciler
            [PRECEDING_ELEMENT_KEY]: idx === 0 ? "^" : elements[idx - 1]?.id,
          });
        }
        return acc;
      },
      [] as BroadcastedExcalidrawElement[],
    );

    const data: SocketUpdateDataSource[typeof updateType] = {
      type: updateType,
      payload: {
        elements: syncableElements,
      },
    };

    for (const syncableElement of syncableElements) {
      this.broadcastedElementVersions.set(
        syncableElement.id,
        syncableElement.version,
      );
    }

    await this._broadcastSocketData(data as SocketUpdateData);
  };
}

export default CustomPortal;
