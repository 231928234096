/* Copyright (C) EZ Fire, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Hamza Younes <hamza@ezfirecontrols.com>, Feb 26th, 2023
 */

import firebase from "firebase/app";
import "firebase/firestore";
import secondaryFirebaseProject from "./secondaryProjectDB";

export const getAllIconsAndCovers = async (onSuccess, onError) => {
  await secondaryFirebaseProject.auth().signInAnonymously();
  return secondaryFirebaseProject
    .firestore()
    .collection("iconsAndCovers")
    .where("active", "==", true)
    .onSnapshot(
      (snap) => {
        if (snap) {
          const allIconsAndCovers = snap.docs.map((el) => {
            if (el.id !== el.data().id) {
              return { ...el.data(), id: el.id };
            }
            return el.data();
          });
          onSuccess && onSuccess(allIconsAndCovers);
        }
      },
      (error) => {
        onError && onError();
        console.error(error);
      },
    );
};

export const getIconAndCoverById = async (id) => {
  try {
    const res = await secondaryFirebaseProject
      .firestore()
      .collection("iconsAndCovers")
      .doc(id)
      .get();
    return res.data();
  } catch (error) {
    console.error(error);
  }
};

export const getHeaderOrFooter = () => {
  return secondaryFirebaseProject
    .firestore()
    .collection("settings")
    .doc("headerAndFooterSettings")
    .get()
    .then((doc) => {
      let result = {};
      if (doc.exists) {
        result = doc.data();
      }
      return result;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const createIconAndCover = async (
  onSuccess,
  onError,
  { id, icon, cover, occupancyTypeId, occupancyGroup, occupancyUse },
) => {
  const ref = secondaryFirebaseProject
    .firestore()
    .collection("iconsAndCovers")
    .doc(id);
  const data = {
    id,
    active: true,
    isDefault: false,
    isBackcover: false,
    occupancyTypeId,
    occupancyGroup,
    occupancyUse,
    icon, // From default
    cover, // From default
    label: occupancyUse,
    modifiedAt: firebase.firestore.Timestamp.now(),
    modifiedBy: firebase.auth().currentUser.uid,
  };
  try {
    await ref.set(data);
    onSuccess && onSuccess(data);
    return data;
  } catch (error) {
    console.error("Error Adding Document to Fire Store: ", error);
    onError && onError();
  }
};
