import { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { useAtom } from "jotai";
import { currentProjectAtom, excalidrawApiAtom } from "../store/variables";
import { newElementWith } from "../ExcalidrawAPI/element/mutateElement";
import {
  COVER_SHEET_CELL_HEIGHT,
  COVER_SHEET_TEXT_FONT_SIZE,
  DELETED_PROPS,
} from "../helpers/constants";
import { randomId, randomInteger } from "../ExcalidrawAPI/random";
import { getUpdatedTimestamp } from "../ExcalidrawAPI/utils";
import { redrawTextBoundingBox } from "../ExcalidrawAPI/element";
import { formatDrawingOrder } from "../helpers/common";
import { useSceneMethods } from "./useSceneMethods";

//Constants
const propsInCommon = {
  angle: 0,
  backgroundColor: "transparent",
  boundElements: [],
  customData: { isImmutable: true },
  fillStyle: "solid",
  isDeleted: false,
  link: null,
  locked: true,
  opacity: 100,
  roughness: 0,
  roundness: null,
  strokeColor: "#000000",
  strokeStyle: "solid",
  strokeWidth: 1,
  seed: randomInteger(),
  version: randomInteger(),
  versionNonce: randomInteger(),
  updated: getUpdatedTimestamp(),
};

const groupId = randomId();

const rectangleElement = {
  ...propsInCommon,
  id: randomId(),
  locked: true,
  type: "rectangle",
  groupId,
};

const lineElement = {
  ...propsInCommon,
  id: randomId(),
  lastCommittedPoint: null,
  roundness: { type: 2 },
  type: "line",
  groupId,
};

const textElement = {
  ...propsInCommon,
  baseline: 0,
  fontFamily: "Arial",
  id: randomId(),
  type: "text",
  groupId,
};

export const useTableMethods = () => {
  //Custom Hooks
  const { getCurrentElements } = useSceneMethods();

  //Atom
  const [currentProject] = useAtom(currentProjectAtom);
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);

  //State
  const [sceneElements, setSceneElements] = useState(null);

  //Effects
  useEffect(() => {
    const getSceneElements = async () => {
      if (currentProject?.coverSheetRoom) {
        setSceneElements(
          await getCurrentElements(currentProject.coverSheetRoom),
        );
      }
    };

    getSceneElements();
  }, [currentProject]);

  //Functions
  const isHorizontal = (el) => {
    return el.type === "line" && el.width > 0 && el.height === 0;
  };

  const createLine = ({
    dimensions,
    start,
    lineCustomData = {},
    style = {},
  }) => {
    const { width, height } = dimensions;
    return newElementWith(lineElement, {
      x: start.x,
      y: start.y,
      id: randomId(),
      customData: { ...propsInCommon.customData, ...lineCustomData },
      height,
      points: [
        [0, 0],
        [width, height],
      ],
      width,
      ...style,
    });
  };

  const createTableHorizontalLine = useCallback(
    ({ start, width, lineCustomData }) => {
      return createLine({
        dimensions: { height: 0, width },
        start,
        lineCustomData,
      });
    },
    [],
  );

  const createCell = ({
    start,
    offset,
    width,
    height,
    cellProps = {},
    style,
    textProps,
  }) => {
    const {
      textMarginX = 0,
      fontFamily = "Arial",
      fontSize = COVER_SHEET_TEXT_FONT_SIZE,
      textAlign = "left",
      textString,
    } = textProps;
    const [containerId, textId] = [randomId(), randomId()];
    const container = newElementWith(rectangleElement, {
      id: containerId,
      x: start.x + offset.x + textMarginX,
      y: start.y + offset.y,
      height,
      width,
      boundElements: [{ type: "text", id: textId }],
      customData: { ...propsInCommon.customData, ...cellProps },
      strokeColor: "transparent",
      backgroundColor: style?.backgroundColor || "transparent",
    });

    const text = newElementWith(textElement, {
      id: textId,
      containerId,
      customData: { ...propsInCommon.customData, ...cellProps },
      text: String(textString).toUpperCase(),
      originalText: String(textString).toUpperCase(),
      textAlign,
      verticalAlign: "middle",
      fontSize: style?.fontSize || fontSize,
      fontFamily,
      x: start.x + 2,
      strokeColor: style?.fontColor || "#000000",
    });

    return [text, container];
  };

  const getTableData = (tableElements, noOfRows) => {
    const data = [];
    for (let currentRowNo = 1; currentRowNo <= noOfRows; currentRowNo++) {
      const rowData = {};
      const textsOfRow = tableElements.filter(
        (el) => el.type === "text" && el.customData?.rowNo === currentRowNo,
      );
      for (const text of textsOfRow) {
        Object.assign(rowData, {
          order: currentRowNo,
          [text.customData?.columnKey]: text.text,
          rowId: text.customData?.rowId || "",
        });
      }
      data.push(rowData);
    }

    return data;
  };

  const getTableProps = useCallback(
    (tableKey, sceneElements) => {
      const tableElements = sceneElements.filter(
        (el) => el.customData?.tableKey === tableKey && !el.isDeleted,
      );

      if (tableElements.length === 0) {
        return {};
      }

      const hasLabels = tableElements.find((el) => el.customData?.rowNo === 0);

      const verticalLines = tableElements
        .filter((el) => el.type === "line" && el.width === 0 && el.height > 0)
        .sort((a, b) => a.x - b.x);

      const horizontalLines = tableElements.filter(
        (el) =>
          el.type === "line" &&
          el.height === 0 &&
          el.width > 0 &&
          el.customData?.rowNo > 0,
      );

      const noOfRows = horizontalLines.length - Number(!!hasLabels ? 0 : 1);

      const data = getTableData(tableElements, noOfRows);

      // Extract some props from left border element
      const { height, x, y } = tableElements.find(
        (el) => el.customData?.leftBorder,
      );

      // Extract some props from top border element
      const { width } =
        tableElements.find(
          (el) =>
            (el.customData?.rowNo === 0 || el.customData?.rowNo === 1) &&
            el.type === "line" &&
            el.width > 0,
        ) || {};

      const subtractions = verticalLines.reduce(
        (acc, currentObj, currentIndex) => {
          if (currentIndex === 0) {
            return acc;
          }
          const previousObj = verticalLines[currentIndex - 1];
          const subtraction = currentObj.x - previousObj.x;
          return [...acc, subtraction];
        },
        [],
      );

      return {
        height,
        width,
        x,
        y,
        data,
        verticalDivisions: subtractions,
        noOfRows,
        noOfColumns: verticalLines.length - 1,
        hasLabels: !!hasLabels,
      };
    },
    [excalidrawAPI, currentProject],
  );

  const getRowHeight = useCallback(
    (tableKey, rowNo, tableProps = {}, sceneElements) => {
      const notDeletedElements =
        sceneElements?.filter(
          (el) => !el.isDeleted || !el.customData?.isDeleted,
        ) || [];

      const { hasLabels, noOfRows } = tableProps;
      if (notDeletedElements.length === 0 || !noOfRows) {
        return 0;
      } else if (rowNo === 1 && !hasLabels) {
        const firstCellLines = notDeletedElements.filter(
          (el) =>
            el.customData?.tableKey === tableKey &&
            el.customData?.rowNo === 1 &&
            isHorizontal(el),
        );
        return Math.abs(firstCellLines[0].y - firstCellLines[1].y);
      } else if (rowNo === 0 && hasLabels) {
        const firstCellLines = notDeletedElements.filter(
          (el) =>
            el.customData?.tableKey === tableKey &&
            el.customData?.rowNo === 0 &&
            isHorizontal(el),
        );
        return Math.abs(firstCellLines[0].y - firstCellLines[1].y);
      }
      const filterCondition = (el) =>
        el.customData?.tableKey === tableKey &&
        el.customData?.rowNo === rowNo - 1 &&
        el.type === "line";

      let cellTopLine = {};

      if ((rowNo === 1 && hasLabels) || (rowNo === 2 && !hasLabels)) {
        cellTopLine = notDeletedElements
          .filter(filterCondition)
          .reduce((maxYLine, currentLine) => {
            if (currentLine.y > (maxYLine ? maxYLine.y : -Infinity)) {
              return currentLine;
            }
            return maxYLine;
          }, null);
      } else {
        cellTopLine = notDeletedElements.find(filterCondition);
      }

      const cellBottomLine = notDeletedElements.find(
        (el) =>
          el.customData?.tableKey === tableKey &&
          el.customData?.rowNo === rowNo &&
          el.type === "line",
      );

      return cellBottomLine && cellTopLine
        ? cellBottomLine.y - cellTopLine.y
        : 0;
    },
    [excalidrawAPI, getTableProps, sceneElements],
  );

  const updatedSheetNumberEl = ({ end, maxNumber, element, sceneElements }) => {
    const tableElements = sceneElements.filter(
      (el) => el.customData?.tableKey === "drawingsList",
    );
    const tableProps = getTableProps("drawingsList", sceneElements);
    const rowsToUpdate = tableProps.data
      .filter((el) => !end || el.order < end)
      .map((el) => el.rowId);
    const cellsToUpdate = tableElements
      .filter(
        (el) =>
          el.type === "text" &&
          rowsToUpdate.includes(el.customData?.rowId) &&
          el.customData?.columnKey === "sheetNumber",
      )
      .map((el) => el.id);
    for (let i = 0; i < sceneElements.length; i++) {
      if (cellsToUpdate.includes(element.id)) {
        const pageOrder = tableElements.find((item) => item.id === element.id)
          .customData?.rowNo;
        return newElementWith(element, {
          text: formatDrawingOrder(pageOrder, maxNumber),
          originalText: formatDrawingOrder(pageOrder, maxNumber),
        });
      }
    }
    return false;
  };

  const addRow = ({
    tableKey,
    rowNo,
    data = {},
    textProps = {},
    sceneElements,
  }) => {
    const notDeletedElements =
      sceneElements?.filter(
        (el) => !el.isDeleted || !el.customData?.isDeleted,
      ) || [];
    const tableProps = getTableProps(tableKey, sceneElements);
    if (notDeletedElements.length === 0 || !Object.keys(tableProps).length) {
      return;
    }

    let cellHeight = COVER_SHEET_CELL_HEIGHT;
    let adjustedSceneElements = _.cloneDeep(notDeletedElements);

    const elementsToAdd = [];
    const { height, width, x, y, verticalDivisions, noOfRows, hasLabels } =
      tableProps;

    const tableStartPoint = { x, y };
    let notToShiftEl = {};
    let startPoint = tableStartPoint;

    if (rowNo === 0 || rowNo === 1) {
      rowNo = 1;
      notToShiftEl = notDeletedElements.filter(
        (el) =>
          el.customData?.tableKey === tableKey &&
          el.customData?.rowNo === Number(hasLabels ? 0 : 1) &&
          isHorizontal(el),
      )[0];
      startPoint = {
        x: tableStartPoint.x,
        y:
          tableStartPoint.y +
          Number(
            hasLabels
              ? getRowHeight(tableKey, 0, tableProps, sceneElements)
              : 0,
          ),
      };
    } else if (!rowNo || rowNo >= noOfRows) {
      rowNo = noOfRows + 1;
      startPoint = { x: tableStartPoint.x, y: tableStartPoint.y + height };
    } else {
      const currentElWithSameRowNo = notDeletedElements.filter(
        (el) =>
          el.customData?.tableKey === tableKey &&
          el.customData?.rowNo === rowNo - 1 &&
          isHorizontal(el),
      )[0];
      startPoint = { x: tableStartPoint.x, y: currentElWithSameRowNo.y };
    }

    let i = 0;
    for (const rowCellKey in data) {
      if (rowCellKey === "rowId") {
        continue;
      }
      //TODO - Refactor this offsetX
      const offsetX =
        i === 0
          ? 0
          : i === 1
          ? verticalDivisions[i - 1]
          : verticalDivisions[i - 1] + verticalDivisions[i - 2];
      const [text, container] = createCell({
        start: startPoint,
        offset: { x: offsetX, y: 0 },
        width: verticalDivisions[i],
        height: cellHeight,
        cellProps: {
          tableKey,
          columnKey: rowCellKey,
          rowNo,
          rowId: data.rowId || "",
        },
        textProps: {
          textMarginX: textProps.textMarginX || 0,
          fontSize: COVER_SHEET_TEXT_FONT_SIZE,
          textAlign: textProps.textAlign || "center",
          textString: data[rowCellKey],
        },
      });

      redrawTextBoundingBox(text, container);
      cellHeight =
        cellHeight < container.height ? container.height : cellHeight;
      elementsToAdd.push(...[text, container]);
      i++;
    }

    const cellBottomLine = createTableHorizontalLine({
      start: {
        x: startPoint.x,
        y: startPoint.y + cellHeight,
      },
      width,
      lineCustomData: { tableKey, rowNo },
    });
    elementsToAdd.push(cellBottomLine);

    adjustedSceneElements = adjustedSceneElements.map((el) => {
      const updatedNumberEl = updatedSheetNumberEl({
        end: rowNo,
        maxNumber: noOfRows + 1,
        element: el,
        sceneElements,
      });
      const { type, height, width, customData } = el;
      const isVerticalLine = type === "line" && width === 0 && height > 0;

      if (updatedNumberEl) {
        return updatedNumberEl;
      }
      if (el.customData?.tableKey === tableKey) {
        const isToShift =
          customData?.rowNo >= rowNo &&
          rowNo !== noOfRows + 1 &&
          el.id !== notToShiftEl.id;
        if (isToShift) {
          return newElementWith(el, {
            y: el.y + cellHeight,
            customData: { ...customData, rowNo: customData?.rowNo + 1 },
          });
        }
        if (isVerticalLine) {
          const points = [
            [0, 0],
            [0, height + cellHeight],
          ];
          return newElementWith(el, {
            height: height + cellHeight,
            points,
          });
        }
      }
      return el;
    });

    adjustedSceneElements = [...adjustedSceneElements, ...elementsToAdd];
    return adjustedSceneElements;
  };

  const isRelatedToOrderCell = (el) => {
    return (
      el.type === "text" &&
      el.originalText !== "SHEET NUMBER" &&
      el.originalText !== "FILE CODE" &&
      (el.customData?.columnKey === "sheetNumber" ||
        el.customData?.columnKey === "fileCode")
    );
  };

  const isPageNumberCell = (el) => {
    return (
      el.type === "text" &&
      el.originalText !== "SHEET NUMBER" &&
      el.customData?.columnKey === "sheetNumber"
    );
  };

  const getShiftedOrder = (deletedOrders, order, tableProps, sceneElements) => {
    deletedOrders = deletedOrders.sort((a, b) => a - b);
    let orderOffset = 0;
    let offsetInY = 0;
    for (const deletedOrder of deletedOrders) {
      if (order > deletedOrder) {
        offsetInY += getRowHeight(
          "drawingsList",
          deletedOrder,
          tableProps,
          sceneElements,
        );
        orderOffset++;
      }
    }
    return { newOrder: order - orderOffset, offsetInY };
  };

  const deleteRow = ({
    tableKey,
    rowsToDelete,
    currentDrawingsLength,
    allElements,
    tableProps,
  }) => {
    if (!currentProject.id) {
      excalidrawAPI.setToast({
        message: "no project available",
        duration: 2000,
        closable: true,
        options: {
          position: "topRight",
          status: "error",
        },
      });
      return;
    }
    const notDeletedElements =
      allElements?.filter((el) => !el.isDeleted || !el.customData?.isDeleted) ||
      [];

    if (!tableKey || !rowsToDelete?.length || !notDeletedElements?.length) {
      return;
    }

    const { noOfRows } = tableProps;
    const totalHeightDecrement = rowsToDelete
      .map((r) => getRowHeight(tableKey, r, tableProps, allElements))
      .reduce((acc, cur) => acc + cur, 0);

    const adjustedSceneElements = notDeletedElements.map((el) => {
      if (el.customData?.tableKey !== tableKey) {
        return el;
      }
      const { customData, type, height, width } = el;
      const isVerticalLine = type === "line" && !width && height > 0;
      const isToDelete = rowsToDelete.includes(el.customData?.rowNo);
      const { newOrder, offsetInY } = getShiftedOrder(
        rowsToDelete,
        el.customData?.rowNo,
        tableProps,
        allElements,
      );
      const isToShift = !isToDelete && customData?.rowNo !== newOrder;

      if (isVerticalLine) {
        const points = [
          [0, 0],
          [0, height - totalHeightDecrement],
        ];
        return newElementWith(el, {
          height: height - totalHeightDecrement,
          points,
        });
      } else if (customData?.rowNo <= noOfRows) {
        if (isRelatedToOrderCell(el) && !isToDelete) {
          const formattedOrder = formatDrawingOrder(
            newOrder,
            currentDrawingsLength,
          );
          const newFileCode = `FA-${String(newOrder).padStart(3, "0")}`;

          return newElementWith(el, {
            originalText: isPageNumberCell(el) ? formattedOrder : newFileCode,
            text: isPageNumberCell(el) ? formattedOrder : newFileCode,
            ...(isToShift ? { y: el.y - offsetInY } : {}),
            customData: { ...customData, rowNo: newOrder },
          });
        }
        if (isToShift) {
          return newElementWith(el, {
            y: el.y - offsetInY,
            customData: { ...customData, rowNo: newOrder },
          });
        } else if (isToDelete) {
          return newElementWith(el, { ...DELETED_PROPS });
        }
      }
      return el;
    });

    return adjustedSceneElements;
  };

  return {
    getTableProps,
    createCell,
    addRow,
    deleteRow,
  };
};
