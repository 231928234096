import "./ToolIcon.scss";

import clsx from "clsx";
import { ToolButton } from "./ToolButton";
import { SelectionIcon } from "./icons";
import { setCursorForShape, updateActiveTool } from "../utils";
import { t } from "../i18n";
import { AppState } from "../types";
import { trackEvent } from "../analytics";
import { useAtom } from "jotai";
import { isActivePolygonToolAtom } from "../../store/variables";

type SelectIconProps = {
  canvas: HTMLCanvasElement | null;
  title?: string;
  name?: string;
  checked: boolean;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  onChange?(): void;
  isMobile?: boolean;
};

const shortcut = "V" ? `V ${t("helpDialog.or")} 1` : "1";

export const SelectButton = (props: SelectIconProps) => {
  const [isActivePolygonTool, setIsActivePolygonTool] = useAtom(
    isActivePolygonToolAtom,
  );
  return (
    <ToolButton
      className={clsx("Shape", { fillable: true })}
      key="selection"
      type="radio"
      icon={SelectionIcon}
      checked={props.checked}
      name="editor-current-shape"
      title="Selection — V"
      //   keyBindingLabel={"1" || "V"}
      aria-label="Selection"
      aria-keyshortcuts={shortcut}
      data-testid="toolbar-selection"
      onPointerDown={({ pointerType }) => {
        if (!props.appState.penDetected && pointerType === "pen") {
          props.setAppState({
            penDetected: true,
            penMode: true,
          });
        }
      }}
      onChange={() => {
        if (props.appState.activeTool.type !== "selection") {
          trackEvent("toolbar", "selection", "ui");
        }
        const nextActiveTool = updateActiveTool(props.appState, {
          type: "selection",
        });
        if (isActivePolygonTool) {
          setIsActivePolygonTool(false);
          document
            .querySelector(".ToolIcon__icon.regular-button")
            ?.classList.remove("regular-button");
        }

        props.setAppState({
          activeTool: nextActiveTool,
          multiElement: null,
          selectedElementIds: {},
        });
        setCursorForShape(props.canvas, {
          ...props.appState,
          activeTool: nextActiveTool,
        });
      }}
    />
  );
};
