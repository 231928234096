import React, { useState, useEffect, useRef, useCallback } from "react";
import { Resizable } from "react-resizable";

import { useAtom } from "jotai";
import _ from "lodash";

import { randomId } from "../ExcalidrawAPI/random";
import { collabAPIAtom } from "../ExcalidrawAPI/excalidraw-app/collab/Collab";
import ConfirmDialog from "../ExcalidrawAPI/components/ConfirmDialog";
import { t } from "../ExcalidrawAPI/i18n";
import { calculateScrollCenter } from "../ExcalidrawAPI/scene";
import { useExcalidrawSetAppState } from "../ExcalidrawAPI/components/App";
import { CheckboxItem } from "../ExcalidrawAPI/components/CheckboxItem";

import { createOrUpdateDrawing } from "../helpers/drawings.js";
import {
  setToLocalStorage,
  getFromLocalStorage,
  getLargestOrder,
  generateId,
  calculateAreaFromCoordinates,
  getFloorsOfWorkAsString,
  checkLineIntersections,
  openPanelOnError,
} from "../helpers/common";
import { createIconAndCover } from "../helpers/iconsAndCovers";
import { updateProjectById } from "../helpers/projects";
import { UNITS, ALL_UPLOAD_INPUTS, PULLER_MARGIN } from "../helpers/constants";
import {
  checkBoxLabelToResponse,
  selectComponentToResponse,
} from "../helpers/responsiveness";

import {
  currentDrawingAtom,
  currentDrawingsAtom,
  currentProjectAtom,
  globalSettingsAtom,
  excalidrawApiAtom,
  iconsAndCoversAtom,
  northHeadingAtom,
  projectToPreloadAtom,
  relatedCodesAtom,
} from "../store/variables";

import {
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormControl,
  Tooltip,
  SwipeableDrawer,
  useMediaQuery,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import LocationSearchingOutlinedIcon from "@mui/icons-material/LocationSearchingOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import FormatShapesRoundedIcon from "@mui/icons-material/FormatShapesRounded";
import LinearScaleRoundedIcon from "@mui/icons-material/LinearScaleRounded";
import BackupIcon from "@mui/icons-material/Backup";
import ArticleIcon from "@mui/icons-material/Article";

import LoadingButton from "./LoadingButton";
import CloseButton from "./CloseButton";
import OccupancyField from "./OccupancyField";
import ReadOnlyFieldWithTooltip from "./ReadOnlyFieldWithTooltip";
import CoverSheetSection from "./uploadPopup/CoverSheetSection";
import ValidationTooltip from "./uploadPopup/ValidationTooltip";
import Indicator from "./uploadPopup/Indicator";

import { uploadDrawingToFireStorage } from "../services/firebaseStorage";
import useReactiveUIVars from "../hooks/useReactiveUIVars";
import usePlaceOnMapHandler from "../hooks/usePlaceOnMapHandler";
import { useCoverSheetDrawer } from "../hooks/useCoverSheetDrawer";
import { useFrameMethods } from "../hooks/useFrameMethods";
import { useCommonFunctionsHook } from "../hooks/useCommonFunctionsHook";
import {
  createOrUpdateCoverSheet,
  getCoverSheetById,
} from "../helpers/coverSheets";
import {
  activeLoaderAtom,
  expandedUploadPopupPanelAtom,
  isDetectingPixelsPerUnitAtom,
  isLoadingCurrentProjectAtom,
  isSelectingBoundariesAtom,
} from "../store/UI";

import "../scss/UploadPopup.scss";

const UploadPopup = ({
  occupancyTypes,
  currentUser,
  isLoadingProjects,
  isLoadingDrawings,
  projects,
  onCreateOrUpdateDrawing,
  onCloseHandler,
  selectedProject,
  selectedProjectHandler,
  drawingToBeUploaded,
  setDrawingToBeUploaded,
  allDrawings,
  gridModeValue,
  setGridModeValue,
  resetScene,
}) => {
  //Atom
  const [collabAPI] = useAtom(collabAPIAtom);
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [currentDrawing, setCurrentDrawing] = useAtom(currentDrawingAtom);
  const [currentDrawings] = useAtom(currentDrawingsAtom);
  const [currentProject] = useAtom(currentProjectAtom);
  const [projectToPreload, setProjectToPreload] = useAtom(projectToPreloadAtom);
  const [iconsAndCovers] = useAtom(iconsAndCoversAtom);
  const [northHeading, setNorthHeading] = useAtom(northHeadingAtom);
  const [isDetectingPixelsPerUnit, setIsDetectingPixelsPerUnit] = useAtom(
    isDetectingPixelsPerUnitAtom,
  );
  const [isSelectingBoundaries, setIsSelectingBoundaries] = useAtom(
    isSelectingBoundariesAtom,
  );
  const [isLoadingCurrentProject] = useAtom(isLoadingCurrentProjectAtom);
  const [, setActiveLoader] = useAtom(activeLoaderAtom);
  const [relatedCodes] = useAtom(relatedCodesAtom);
  const [expandedPanel, setExpandedPanel] = useAtom(
    expandedUploadPopupPanelAtom,
  );
  const [globalSettings] = useAtom(globalSettingsAtom);

  //Custom Hooks
  const {
    isUploadPopupOpen: isOpened,
    setIsUploadPopupOpen: setIsOpened,
    isEditMode,
    isPlacingOnMap,
    setIsEditMode,
    setIsReadyToDesign,
    setActiveConfirmDialog,
  } = useReactiveUIVars();
  const {
    updateProjectSummary,
    updateCodeReference,
    addDrawingHandler,
    getCoverSheetElements,
    checkIfOccupyingEntireProperty,
  } = useCoverSheetDrawer();
  const { placeOnMapHandler, closePlacingOnMap } = usePlaceOnMapHandler();
  const { drawFullFrame, editFrame } = useFrameMethods();
  const {
    getBarcode,
    doAfterLoadingScene,
    triggerToast,
    formatNumber,
    convertFromToSFT,
    convertToOccupancyUseFormat,
    getNewOccupancyAsString,
  } = useCommonFunctionsHook();

  //Refs
  const submitBtn = useRef();
  const scaleLineCoords = useRef([]);
  const uploadPopupMainRef = useRef(null);
  const container =
    uploadPopupMainRef !== null ? () => uploadPopupMainRef.current : null;

  //Persisted Data
  const defaultValues = getFromLocalStorage("appCustomData") ?? {};
  const DEFAULT_VALUES = {
    SELECTED_DOMINANT_TYPE: defaultValues.selectedDominantType || "",
    SELECTED_DOMINANT_GROUP: defaultValues.selectedDominantGroup || "",
    SELECTED_DOMINANT_USE: defaultValues.selectedDominantUse || null,
    PROJECT_ADDRESS: defaultValues.projectAddress || "",
    RECORD_ID: defaultValues.recordId || "",
    SYSTEM_DESCRIPTION: defaultValues.systemDescription || "",
    MAP_IMAGE: defaultValues.mapImage || "",
    SELECTED_DOMINANT_PROPERTY_TYPE:
      defaultValues.selectedDominantPropertyType || "",
    SELECTED_DOMINANT_PROPERTY_GROUP:
      defaultValues.selectedDominantPropertyGroup || "",
    SELECTED_DOMINANT_PROPERTY_USE:
      defaultValues.selectedDominantPropertyUse || null,
    DOMINANT_USE: defaultValues.drawingToUpload?.dominantUse || "",
    IS_OCCUPYING_ENTIRE_PROPERTY:
      defaultValues.isOccupyingEntireProperty || false,
    OCCUPANCY_USE: defaultValues.drawingToUpload?.occupancyUse || [],
    DRAWING_OCCUPANCIES: defaultValues.drawingOccupancies || [],
    DRAWING_NAME: defaultValues.drawingToUpload?.name || "",
    NOMENCLATURE: defaultValues.drawingToUpload?.nomenclature || "",
    FLOOR_AREA:
      defaultValues.editedDrawingProps?.floorArea ||
      defaultValues.drawingToUpload?.floorArea ||
      "",
    IS_LOWEST_LEVEL: defaultValues.drawingToUpload?.isLowestLevel || false,
    PAGE_NO: defaultValues.pageNo || "001",
    IS_VALID_DRAWING_NAME:
      defaultValues.isValidDrawingName === undefined
        ? true
        : defaultValues.isValidDrawingName,
    IS_VALID_NOMENCLATURE:
      defaultValues.isValidNomenclature === undefined
        ? true
        : defaultValues.isValidNomenclature,
    IS_DIRTY_INPUT: defaultValues.isDirtyInput ?? {},

    IS_DIRTY_OCCUPANCY_INPUT: defaultValues.isDirtyOccupancyInput || false,
    PIXELS_OF_DETECTED_LINE: defaultValues.pixelsOfDetectedLine || 0,
    DETECTED_LINE_WIDTH: defaultValues.detectedLineWidth || "",
    SELECTED_UNIT: defaultValues.selectedUnit || "inch",
    SELECTED_AREA_UNIT: defaultValues.selectedAreaUnit || "SFT",
    EXPANDED_PANEL: defaultValues.expandedPanel,
    FIELDS_TO_CHANGE: defaultValues.fieldsToChange || [],
    ERRORS: defaultValues.errors || {},
    FLOOR_AREA_EL: defaultValues.floorAreaEl || null,
    EDITED_DRAWING_PROPS: defaultValues.editedDrawingProps || {},
  };

  //Constants
  const filter = createFilterOptions();

  const drawingNameRegex = new RegExp("^[a-zA-Z0-9 -]+$");

  const excalidrawCanvas =
    document.querySelector(".excalidraw__canvas") || null;

  //State
  const [open, toggleOpen] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(
    window.innerWidth <= 422 ? window.innerWidth - PULLER_MARGIN : 450,
  );
  const [currentViewWidth, setCurrentViewWidth] = useState(window.innerWidth);
  const [openNewPropertyOccDialog, setOpenNewPropertyOccDialog] =
    useState(false);

  const [projectAddress, setProjectAddress] = useState(
    DEFAULT_VALUES.PROJECT_ADDRESS,
  );
  const [recordId, setRecordId] = useState(DEFAULT_VALUES.RECORD_ID);
  const [systemDescription, setSystemDescription] = useState(
    DEFAULT_VALUES.SYSTEM_DESCRIPTION,
  );
  const [selectedDominantType, setSelectedDominantType] = useState(
    DEFAULT_VALUES.SELECTED_DOMINANT_TYPE,
  );
  const [selectedDominantGroup, setSelectedDominantGroup] = useState(
    DEFAULT_VALUES.SELECTED_DOMINANT_GROUP,
  );
  const [selectedDominantUse, setSelectedDominantUse] = useState(
    DEFAULT_VALUES.SELECTED_DOMINANT_USE,
  );
  const [isOccupyingEntireProperty, setIsOccupyingEntireProperty] = useState(
    DEFAULT_VALUES.IS_OCCUPYING_ENTIRE_PROPERTY,
  );
  const [mapImage, setMapImage] = useState(DEFAULT_VALUES.MAP_IMAGE);
  const [missedProjectData, setMissedProjectData] = useState([]);
  const [selectedDominantPropertyType, setSelectedDominantPropertyType] =
    useState(DEFAULT_VALUES.SELECTED_DOMINANT_PROPERTY_TYPE);
  const [selectedDominantPropertyGroup, setSelectedDominantPropertyGroup] =
    useState(DEFAULT_VALUES.SELECTED_DOMINANT_PROPERTY_GROUP);
  const [selectedDominantPropertyUse, setSelectedDominantPropertyUse] =
    useState(DEFAULT_VALUES.SELECTED_DOMINANT_PROPERTY_USE);
  const [missedPropertyData, setMissedPropertyData] = useState([]);
  const [coverSheetData, setCoverSheetData] = useState({});
  const [missedCoverSheetData, setMissedCoverSheetData] = useState([]);
  const [drawingOccupancies, setDrawingOccupancies] = useState(
    DEFAULT_VALUES.DRAWING_OCCUPANCIES,
  );
  const [drawingName, setDrawingName] = useState(DEFAULT_VALUES.DRAWING_NAME);
  const [isValidDrawingName, setIsValidDrawingName] = useState(
    DEFAULT_VALUES.IS_VALID_DRAWING_NAME,
  );
  const [nomenclature, setNomenclature] = useState(DEFAULT_VALUES.NOMENCLATURE);
  const [isValidNomenclature, setIsValidNomenclature] = useState(
    DEFAULT_VALUES.IS_VALID_NOMENCLATURE,
  );
  const [pixelsOfDetectedLine, setPixelsOfDetectedLine] = useState(
    DEFAULT_VALUES.PIXELS_OF_DETECTED_LINE,
  );
  const [detectedLineWidth, setDetectedLineWidth] = useState(
    DEFAULT_VALUES.DETECTED_LINE_WIDTH,
  );
  const [selectedUnit, setSelectedUnit] = useState(
    DEFAULT_VALUES.SELECTED_UNIT,
  );
  const [floorArea, setFloorArea] = useState(DEFAULT_VALUES.FLOOR_AREA);
  const [selectedAreaUnit, setSelectedAreaUnit] = useState(
    DEFAULT_VALUES.SELECTED_AREA_UNIT,
  );
  const [isLowestLevel, setIsLowestLevel] = useState(
    DEFAULT_VALUES.IS_LOWEST_LEVEL,
  );
  const [pageNo, setPageNo] = useState(DEFAULT_VALUES.PAGE_NO);
  const [missedDrawingData, setMissedDrawingData] = useState([]);
  const [dialogValue, setDialogValue] = useState({ label: "" });
  const [occupancyGroups, setOccupancyGroups] = useState([]);
  const [allIconsAndCovers, setAllIconsAndCovers] = useState(null);
  const [propertyIconsAndCovers, setPropertyIconsAndCovers] = useState([]);
  const [projectIconsAndCovers, setProjectIconsAndCovers] = useState([]);
  const [propertyOccupancyGroups, setPropertyOccupancyGroups] = useState([]);
  const [isDirtyInput, setIsDirtyInput] = useState(
    DEFAULT_VALUES.IS_DIRTY_INPUT,
  );
  const [isDirtyOccupancyInput, setIsDirtyOccupancyInput] = useState(
    DEFAULT_VALUES.IS_DIRTY_OCCUPANCY_INPUT,
  );
  const [fieldsToChange, setFieldsToChange] = useState(
    DEFAULT_VALUES.FIELDS_TO_CHANGE,
  );
  const [originalDrawing, setOriginalDrawing] = useState({});
  const [isDiscardConfirmDialogOpen, setIsDiscardConfirmDialogOpen] =
    useState(false);
  const [initialGridModeValue] = useState(_.cloneDeep(gridModeValue));
  const [errors, setErrors] = useState(DEFAULT_VALUES.ERRORS);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [floorAreaEl, setFloorAreaEl] = useState(DEFAULT_VALUES.FLOOR_AREA_EL);
  const [editedDrawingProps, setEditedDrawingProps] = useState(
    DEFAULT_VALUES.EDITED_DRAWING_PROPS,
  );
  const [isClosedBehavior, setIsClosedBehavior] = useState(true);

  //Callbacks
  const updateScene = useCallback(
    (destination, update) => {
      const sceneData = {
        [destination]: update,
      };
      excalidrawAPI?.updateScene(sceneData);
    },
    [excalidrawAPI],
  );

  //Effects
  useEffect(() => {
    if (isOpened === false) {
      setTimeout(() => {
        setIsClosedBehavior(true);
      }, 400);
    } else {
      setIsClosedBehavior(false);
    }
  }, [isOpened]);

  useEffect(() => {
    selectedAreaUnitHandler(
      globalSettings.measureSystem === "Metric" ? "M²" : "SFT",
    );
  }, [globalSettings]);

  useEffect(() => {
    const handleResize = () => {
      setCurrentViewWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const resetDrawingRotation = () => {
      updateScene(
        "elements",
        excalidrawAPI.getSceneElements().map((el) => {
          if (
            el.customData?.imageType === "floor-drawing" &&
            el.status !== "pending"
          ) {
            return { ...el, angle: 0 };
          }
          return el;
        }),
      );
    };

    doAfterLoadingScene(resetDrawingRotation);
  }, [excalidrawAPI, updateScene]);

  useEffect(() => {
    if (!iconsAndCovers?.length || !occupancyTypes.length) {
      return;
    }
    const iconsAndCoversWithLabel = iconsAndCovers.map((el) => {
      return {
        ...el,
        label: el.occupancyUse,
      };
    });
    setAllIconsAndCovers(iconsAndCoversWithLabel);
    setPropertyIconsAndCovers(
      iconsAndCoversWithLabel.filter(
        (el) =>
          el.occupancyTypeId === selectedDominantPropertyType &&
          el.occupancyGroup === selectedDominantPropertyGroup,
      ),
    );
    setProjectIconsAndCovers(
      iconsAndCoversWithLabel.filter(
        (el) =>
          el.occupancyTypeId === selectedDominantType &&
          el.occupancyGroup === selectedDominantGroup,
      ),
    );

    if (selectedDominantType) {
      const typeObj = occupancyTypes.find(
        (el) => el.id === selectedDominantType,
      );
      setOccupancyGroups(typeObj.groups);
    }

    if (!isEditMode) {
      setIsDirtyInput({
        ...isDirtyInput,
        selectedProject: !!selectedProject,
        drawingName: !!drawingName,
        nomenclature: !!nomenclature,
        detectedLineWidth: !!detectedLineWidth,
      });
    }

    return () => {
      document.removeEventListener("keyup", keysHandlerOnUploadDialog);
    };
  }, [occupancyTypes, iconsAndCovers]);

  useEffect(() => {
    if (excalidrawAPI) {
      isPlacingOnMap && closePlacingOnMap({ discard: true });
      isDetectingPixelsPerUnit && endDetectingPixelsPerFeet({ discard: true });
      isSelectingBoundaries && endSelectingFloorBoundaries({ discard: true });
    }
    //To make sure we're in editing mode.
    setIsEditMode(!!location.hash);
    if (isEditMode) {
      if (
        !excalidrawAPI ||
        projects.length === 0 ||
        !allIconsAndCovers?.length ||
        !currentDrawing
      ) {
        return;
      }

      !originalDrawing.id && setOriginalDrawing(_.cloneDeep(currentDrawing));

      const { projectId } = currentDrawing;
      //NOTE - For Project Details
      setProjectToBeEdited(projectId);
      //NOTE - For Drawing Details
      setDrawingToBeEdited(currentDrawing);
    }
  }, [excalidrawAPI, allIconsAndCovers, projects, currentDrawing]);

  useEffect(() => {
    setToLocalStorage("appCustomData", { isValidDrawingName }, { merge: true });
  }, [isValidDrawingName]);

  useEffect(() => {
    setToLocalStorage(
      "appCustomData",
      { isValidNomenclature },
      { merge: true },
    );
  }, [isValidNomenclature]);

  useEffect(() => {
    if (!isDirtyInput.pageNo && !isEditMode) {
      setDefaultPageNo();
    }
  }, [currentDrawings]);

  useEffect(() => {
    setToLocalStorage("appCustomData", { isDirtyInput }, { merge: true });
  }, [isDirtyInput]);

  useEffect(() => {
    if (currentDrawing?.type === "coverSheet") {
      addFieldToChange({
        value: coverSheetData,
        valueFor: "coverSheetData",
        originalName: "coverSheetData",
      });
    }
  }, [currentDrawing && coverSheetData]);

  useEffect(() => {
    setToLocalStorage("appCustomData", { expandedPanel }, { merge: true });
  }, [expandedPanel]);

  useEffect(() => {
    setToLocalStorage("appCustomData", { errors }, { merge: true });
  }, [errors]);

  useEffect(() => {
    //To Deselect all elements -> because it makes side effects.
    excalidrawAPI?.updateScene({
      appState: { selectedElementIds: {} },
    });

    if (projectToPreload && allIconsAndCovers) {
      selectProjectHandler(projectToPreload);
      setProjectToPreload(null);
    }
  }, [excalidrawAPI, allIconsAndCovers]);

  useEffect(() => {
    const typeObj = occupancyTypes.find(
      (el) => el.id === selectedDominantPropertyType,
    );
    setPropertyOccupancyGroups(typeObj?.groups);
    const iconsAndCoversWithLabel = iconsAndCovers?.map((el) => {
      return {
        ...el,
        label: el.occupancyUse,
      };
    });
    setPropertyIconsAndCovers(
      iconsAndCoversWithLabel.filter(
        (el) =>
          el.occupancyTypeId === selectedDominantPropertyType &&
          el.occupancyGroup === selectedDominantPropertyGroup,
      ),
    );
  }, [
    occupancyTypes,
    iconsAndCovers,
    selectedDominantPropertyType,
    selectedDominantPropertyGroup,
  ]);

  //Functions
  const scrollToBottomAfterSelection = (inputId) => {
    if (document.querySelector(".MuiDialogContent-root") && inputId) {
      const targetInput = document.querySelector(`#${inputId}`);
      targetInput && targetInput.focus();
      const container = document.querySelector(".MuiDialogContent-root");
      container.scrollTop = container.scrollHeight;
    }
  };

  const addOccupancyField = () => {
    const newOccupancyObj = {
      id: generateId(),
      type: "",
      group: "",
      use: "",
    };
    setDrawingOccupancies([...drawingOccupancies, newOccupancyObj]);

    if (!isEditMode) {
      setToLocalStorage(
        "appCustomData",
        {
          drawingOccupancies: [...drawingOccupancies, newOccupancyObj],
        },
        { merge: true },
      );
    }
  };

  const removeOccupancyField = (fieldId) => {
    const newOccupancies = drawingOccupancies.filter((el) => el.id !== fieldId);
    setDrawingOccupancies(newOccupancies);
    !isEditMode &&
      setToLocalStorage(
        "appCustomData",
        { drawingOccupancies: newOccupancies },
        { merge: true },
      );

    addFieldToChange({
      valueFor: "drawingOccupancies",
      value: newOccupancies,
      originalName: "drawingOccupancies",
    });
  };

  const onConfirmDialogHandler = () => {
    if (!isEditMode) {
      selectedProjectHandler(null);
      setDrawingToBeUploaded({});
    } else {
      northHeading !== currentProject.northHeading &&
        editFrame({
          drawing: currentDrawing,
          changes: [
            {
              valueFor: "North Icon",
              value: currentProject.northHeading,
            },
          ],
        });
    }
    discardChangesHandler();
    setIsDiscardConfirmDialogOpen(false);

    if (isEditMode) {
      setToLocalStorage(
        "appCustomData",
        { fieldsToChange: [], editedDrawingProps: {} },
        { merge: true },
      );
    } else {
      resetScene();
    }
  };

  const setAppState = useExcalidrawSetAppState();

  const checkIfExistedIconAndCover = (newIconAndCover) => {
    const currIconAndCover = getNewOccupancyAsString(newIconAndCover);
    for (let i = 0; i < allIconsAndCovers.length; i++) {
      const prevIconAndCover = `${allIconsAndCovers[i].occupancyTypeId}-${allIconsAndCovers[i].occupancyGroup}-${allIconsAndCovers[i].occupancyUse}`;
      if (
        currIconAndCover.toLocaleLowerCase() ===
        prevIconAndCover.toLocaleLowerCase()
      ) {
        triggerToast("Already existed!", "error");
        return true;
      }
    }
    return false;
  };

  const centerTheDrawing = () => {
    const elements = excalidrawAPI?.getSceneElements();
    const appState = excalidrawAPI?.getAppState();
    const canvas = document.querySelector(".excalidraw__canvas");

    setAppState({
      ...calculateScrollCenter(elements, appState, canvas),
    });
  };

  const closeNewOccupancyUseDialogHandler = () => {
    setDialogValue({ label: "" });
    toggleOpen(false);
    setOpenNewPropertyOccDialog(false);
  };

  const onAddingNewOccupancySuccess = (newUse, source) => {
    if (source === "project") {
      setSelectedDominantType(newUse.occupancyTypeId);
      setSelectedDominantGroup(newUse.occupancyGroup);
      setSelectedDominantUse(newUse);
    } else if (source === "property") {
      setSelectedDominantPropertyType(newUse.occupancyTypeId);
      setSelectedDominantPropertyGroup(newUse.occupancyGroup);
      setSelectedDominantPropertyUse(newUse);
    }
    triggerToast(`Added '${newUse.label}' use successfully!`, "success");
  };
  const submitNewOccupancyUseHandler = (event, source) => {
    event.preventDefault();

    let localStorageUpdate = {};
    const newDominantUse = convertToOccupancyUseFormat(
      dialogValue.label,
      source === "project"
        ? selectedDominantGroup
        : selectedDominantPropertyGroup,
      source === "project"
        ? selectedDominantType
        : selectedDominantPropertyType,
      allIconsAndCovers,
    );

    if (source === "project") {
      setSelectedDominantUse(newDominantUse);
      localStorageUpdate = { selectedDominantUse: newDominantUse };
      addFieldToChange({
        valueFor: "dominantOccupancy",
        value: newDominantUse,
        originalName: "dominantOccupancy",
      });
    } else if (source === "property") {
      setSelectedDominantPropertyUse(newDominantUse);
      localStorageUpdate = { selectedDominantPropertyUse: newDominantUse };
      removeFromMissedData(
        "selectedDominantPropertyUse",
        setMissedPropertyData,
      );
      addFieldToChange({
        valueFor: "dominantPropertyOccupancy",
        value: newDominantUse,
        originalName: "dominantPropertyOccupancy",
      });
    }

    !isEditMode &&
      setToLocalStorage("appCustomData", localStorageUpdate, { merge: true });

    if (!checkIfExistedIconAndCover(newDominantUse)) {
      createIconAndCover(
        (newUse) => onAddingNewOccupancySuccess(newUse, source),
        null,
        newDominantUse,
      );
    }
    closeNewOccupancyUseDialogHandler();
  };

  const setProjectToBeEdited = (projectId) => {
    fillProjectOccupancyData(projectId);
    fillPropertyOccupancyData(projectId);
  };

  const getDrawingOccupanciesOnEdit = (occupancyIds) => {
    if (!occupancyIds) {
      //In case of cover sheet
      return [];
    }
    const currentDrawingOccupancies = [];
    for (const id of occupancyIds) {
      const currentOccupancy = allIconsAndCovers?.find((el) => el.id === id);
      if (currentOccupancy) {
        const { occupancyGroup, occupancyTypeId } = currentOccupancy;
        currentDrawingOccupancies.push({
          id: generateId(),
          type: occupancyTypeId,
          group: occupancyGroup,
          use: currentOccupancy,
        });
      }
    }
    return currentDrawingOccupancies;
  };

  const setDrawingToBeEdited = (currentDrawing) => {
    if (!currentDrawing.id) {
      return;
    }
    const {
      occupancyIds,
      isLowestLevel,
      order,
      correspondingUnit,
      floorArea,
      name,
      nomenclature,
    } = currentDrawing;

    const { editedDrawingProps } = getFromLocalStorage("appCustomData");
    setToLocalStorage(
      "appCustomData",
      { drawingToUpload: currentDrawing },
      { merge: true },
    );

    setDrawingName(name);
    setNomenclature(nomenclature);
    setIsLowestLevel(isLowestLevel ?? false);
    setPageNo(order.toString().padStart(3, "0"));
    setSelectedUnit(correspondingUnit);
    setFloorArea(editedDrawingProps?.floorArea || floorArea);
    setDrawingOccupancies(
      editedDrawingProps?.drawingOccupancies ||
        getDrawingOccupanciesOnEdit(occupancyIds),
    );

    setDrawingToBeUploaded(_.cloneDeep(currentDrawing));
  };

  //To check if the use you typed was existed
  const filterDominantUseOptions = (options, params) => {
    const filtered = filter(options, params);

    //Check if it's not existed neither in DB iconsAndCovers nor the recently added items by freeSolo
    if (
      projectIconsAndCovers.find(
        (el) =>
          el.occupancyUse.trim().toLowerCase() ===
          params.inputValue.trim().toLowerCase(),
      )
    ) {
      return filtered;
    } else if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        label: `Add "${params.inputValue}"`,
        id: Date.now(),
      });
    }

    return filtered;
  };

  const getOptionLabel = (option) => {
    // e.g value selected with enter, right from the input
    if (typeof option?.label === "string") {
      return option.label;
    }
    if (option?.inputValue) {
      return option.inputValue;
    }
    return option?.label || "";
  };

  const handlePropertyOccupancyUseInLocalStorage = (occupancyUse) => {
    if (!isEditMode) {
      setToLocalStorage(
        "appCustomData",
        { selectedDominantPropertyUse: occupancyUse },
        { merge: true },
      );
    }

    if (!isDirtyOccupancyInput) {
      if (!isEditMode) {
        setToLocalStorage(
          "appCustomData",
          { selectedDominantPropertyUse: occupancyUse ? [occupancyUse] : [] },
          { merge: true },
        );
      }
    }
  };

  const selectDominantPropertyTypeHandler = ({ target }) => {
    const { value } = target;
    setSelectedDominantPropertyType(value);
    removeFromMissedData("selectedDominantPropertyType", setMissedPropertyData);

    let [dominantGroup, dominantUse] = ["", ""];
    const typeObj = occupancyTypes.find((el) => el.id === value);
    const dominantUses = allIconsAndCovers?.filter(
      (el) => el.occupancyTypeId === typeObj.id,
    );

    setPropertyOccupancyGroups(typeObj.groups);
    setPropertyIconsAndCovers(
      allIconsAndCovers?.filter(
        (el) =>
          el.occupancyTypeId === value &&
          el.occupancyGroup === typeObj.groups[0],
      ),
    );

    if (typeObj.groups.length === 1) {
      dominantGroup = typeObj.groups[0];
      dominantUse = dominantUses.length === 1 ? dominantUses[0] : "";
    }
    if (dominantUses.every((el) => el.occupancyUse === "Default")) {
      dominantUse = dominantUses[0];
    }
    setSelectedDominantPropertyGroup(dominantGroup);
    setSelectedDominantPropertyUse(dominantUse);
    handlePropertyOccupancyUseInLocalStorage(dominantUse);

    if (!isEditMode) {
      setToLocalStorage(
        "appCustomData",
        {
          selectedDominantPropertyType: value,
          selectedDominantPropertyGroup: dominantGroup,
        },
        { merge: true },
      );
    }
  };

  const selectDominantPropertyGroupHandler = ({ target }) => {
    const { value } = target;
    setSelectedDominantPropertyGroup(value);
    setSelectedDominantPropertyUse("");

    const filteredIconsAndCovers = allIconsAndCovers?.filter(
      (el) =>
        el.occupancyTypeId === selectedDominantPropertyType &&
        el.occupancyGroup === value,
    );

    setPropertyIconsAndCovers(filteredIconsAndCovers);

    setToLocalStorage(
      "appCustomData",
      {
        selectedDominantPropertyGroup: value,
        selectedDominantPropertyUse: null,
      },
      { merge: true },
    );
  };

  const setPropertyOccupancyInLocalStorage = (dominantOccupancy) => {
    const { occupancyTypeId, occupancyGroup } = dominantOccupancy;

    setToLocalStorage(
      "appCustomData",
      {
        selectedDominantPropertyType: occupancyTypeId,
        selectedDominantPropertyGroup: occupancyGroup,
        selectedDominantPropertyUse: dominantOccupancy,
      },
      { merge: true },
    );
  };

  const setCoverSheetDataInLocalStorage = (coverSheetData) => {
    setToLocalStorage("appCustomData", { coverSheetData }, { merge: true });
  };

  const setOccupancyInLocalStorageOnProjectFirstLoad = (dominantOccupancy) => {
    const { occupancyTypeId, occupancyGroup } = dominantOccupancy;

    setToLocalStorage(
      "appCustomData",
      {
        selectedDominantType: occupancyTypeId,
        selectedDominantGroup: occupancyGroup,
        selectedDominantUse: dominantOccupancy,
        drawingOccupancies: [
          {
            id: generateId(),
            type: occupancyTypeId,
            group: occupancyGroup,
            use: dominantOccupancy,
          },
        ],
      },
      { merge: true },
    );
  };

  const fillCoverSheetDataOnProjectFirstLoad = async (projectId) => {
    const projectToUse = projects.find((el) => el.id === projectId);
    if (!projectToUse) {
      return;
    }
    if (!projectToUse.coverSheetDataId) {
      setCoverSheetData({});
      setCoverSheetDataInLocalStorage({});
      return;
    }
    const { coverSheetDataId } = projectToUse;
    const dataToSet = await getCoverSheetById(coverSheetDataId);
    setCoverSheetData(dataToSet);
    if (!isEditMode) {
      setCoverSheetDataInLocalStorage(dataToSet);
    }
  };

  const fillPropertyOccupancyData = (projectId) => {
    const projectToUse = projects.find((el) => el.id === projectId);
    if (!projectToUse) {
      return;
    }
    const { dominantPropertyOccupancy } = projectToUse;
    if (!projectToUse.dominantPropertyOccupancy) {
      setPropertyOccupancyDetails("");
      setPropertyOccupancyInLocalStorage("");
      return;
    }
    let dominantOccupancy = allIconsAndCovers?.find(
      (el) => el.id === dominantPropertyOccupancy,
    );
    if (!dominantOccupancy) {
      dominantOccupancy = allIconsAndCovers?.find((el) => el.isDefault);
    }
    const { occupancyTypeId, occupancyGroup } = dominantOccupancy;
    const filteredIconsAndCovers = allIconsAndCovers?.filter(
      (el) =>
        el.occupancyTypeId === occupancyTypeId &&
        el.occupancyGroup === occupancyGroup,
    );
    const occuTypeObj = occupancyTypes.find((el) => el.id === occupancyTypeId);

    setPropertyOccupancyGroups(occuTypeObj?.groups);
    setPropertyIconsAndCovers(filteredIconsAndCovers);
    setPropertyOccupancyDetails(dominantOccupancy);

    if (!isEditMode) {
      setPropertyOccupancyInLocalStorage(dominantOccupancy);
    }
  };

  const fillProjectOccupancyData = (projectId) => {
    const projectToUse = projects.find((el) => el.id === projectId);
    if (!projectToUse) {
      return;
    }
    setProjectAddress(projectToUse.addr);
    setRecordId(projectToUse.recordId);
    setSystemDescription(projectToUse.systemDescription || "");
    setMapImage(projectToUse.mapImage || "");
    const { iconAndCover } = projectToUse;
    let dominantOccupancy = allIconsAndCovers?.find(
      (el) => el.id === iconAndCover?.id,
    );
    if (!dominantOccupancy) {
      dominantOccupancy = allIconsAndCovers?.find((el) => el.isDefault);
    }
    const { occupancyTypeId, occupancyGroup } = dominantOccupancy;
    const filteredIconsAndCovers = allIconsAndCovers?.filter(
      (el) =>
        el.occupancyTypeId === occupancyTypeId &&
        el.occupancyGroup === occupancyGroup,
    );
    const occuTypeObj = occupancyTypes.find((el) => el.id === occupancyTypeId);

    selectedProjectHandler(projectId);
    setOccupancyGroups(occuTypeObj?.groups);

    setProjectIconsAndCovers(filteredIconsAndCovers);

    setProjectOccupancyDetails(dominantOccupancy);

    if (!isEditMode) {
      if (!isDirtyOccupancyInput) {
        setFirstDrawingOccupancyDetails(dominantOccupancy);
      }
      setOccupancyInLocalStorageOnProjectFirstLoad(dominantOccupancy);
    }
  };

  const setFirstDrawingOccupancyDetails = (dominantOccupancy) => {
    const { occupancyTypeId, occupancyGroup } = dominantOccupancy;
    setDrawingOccupancies([
      {
        id: generateId(),
        type: occupancyTypeId,
        group: occupancyGroup,
        use: dominantOccupancy,
      },
    ]);
  };

  const setPropertyOccupancyDetails = (dominantOccupancy) => {
    if (!dominantOccupancy) {
      setSelectedDominantPropertyType("");
      setSelectedDominantPropertyGroup("");
      setSelectedDominantPropertyUse("");
      return;
    }
    const { occupancyTypeId, occupancyGroup } = dominantOccupancy;
    setSelectedDominantPropertyType(occupancyTypeId);
    const filteredIconsAndCovers = allIconsAndCovers?.filter(
      (el) =>
        el.occupancyTypeId === occupancyTypeId &&
        el.occupancyGroup === occupancyGroup,
    );
    setPropertyIconsAndCovers(filteredIconsAndCovers);
    setSelectedDominantPropertyGroup(occupancyGroup);
    setSelectedDominantPropertyUse(dominantOccupancy);
  };

  const setProjectOccupancyDetails = (dominantOccupancy) => {
    const { occupancyTypeId, occupancyGroup } = dominantOccupancy;
    setSelectedDominantType(occupancyTypeId);
    const filteredIconsAndCovers = allIconsAndCovers?.filter(
      (el) =>
        el.occupancyTypeId === occupancyTypeId &&
        el.occupancyGroup === occupancyGroup,
    );
    setProjectIconsAndCovers(filteredIconsAndCovers);
    setSelectedDominantGroup(occupancyGroup);
    setSelectedDominantUse(dominantOccupancy);
  };

  const handleOccupancyUseInLocalStorage = (occupancyUse) => {
    if (!isEditMode) {
      setToLocalStorage(
        "appCustomData",
        { selectedDominantUse: occupancyUse },
        { merge: true },
      );
    }

    if (!isDirtyOccupancyInput) {
      if (!isEditMode) {
        setToLocalStorage(
          "appCustomData",
          { drawingOccupancyUse: occupancyUse ? [occupancyUse] : [] },
          { merge: true },
        );
      }
    }
  };

  const selectProjectHandler = async (value) => {
    const currentProject = await selectedProjectHandler(value);
    resetMissedData();
    setIsDirtyInput({ ...isDirtyInput, selectedProject: true });
    addFieldToChange({
      valueFor: "project",
      value: currentProject,
      originalName: "projectId",
    });
    projectAddressHandler({ addr: currentProject.addr ?? "" });
    recordIdHandler({ id: currentProject.recordId ?? "" });
    systemDescriptionHandler(currentProject.systemDescription);
    mapImageHandler(currentProject.mapImage);
    !isEditMode && fillProjectOccupancyData(value);
    !isEditMode && fillPropertyOccupancyData(value);
    !isEditMode && fillCoverSheetDataOnProjectFirstLoad(value);

    isDirtyInput.drawingName && checkIfValidName(drawingName);

    setDrawingToBeUploaded((prevDrawing) => ({
      ...prevDrawing,
      projectId: value,
    }));

    setDefaultPageNo();

    const appCustomData = getFromLocalStorage("appCustomData");
    setToLocalStorage("appCustomData", {
      ...appCustomData,
      northHeading: currentProject.northHeading ?? 0,
      drawingToUpload: {
        ...appCustomData?.drawingToUpload,
        projectId: value,
      },
    });
    setNorthHeading(currentProject.northHeading ?? 0);
    if (!expandedPanel) {
      setExpandedPanel("drawing-panel");
    }
  };

  const projectAddressHandler = ({ ev, addr = null }) => {
    const value = addr || String(ev.target.value);
    setProjectAddress(value);
    !isEditMode &&
      setToLocalStorage(
        "appCustomData",
        { projectAddress: value },
        { merge: true },
      );
  };

  const recordIdHandler = ({ ev, id = null }) => {
    const regex = /^[a-zA-Z0-9-]*$/;
    const value = id ?? String(ev.target.value).toUpperCase();
    if (regex.test(value)) {
      setRecordId(value);
      !isEditMode &&
        setToLocalStorage(
          "appCustomData",
          { recordId: value },
          { merge: true },
        );
      addFieldToChange({
        valueFor: "record Id",
        value,
        originalName: "recordId",
      });
      setIsDirtyInput({ ...isDirtyInput, recordId: true });
    }
  };

  const systemDescriptionHandler = (value) => {
    value = value || "";
    setSystemDescription(value);
    !isEditMode &&
      setToLocalStorage(
        "appCustomData",
        { systemDescription: value },
        { merge: true },
      );
    addFieldToChange({
      valueFor: "System Description",
      value,
      originalName: "systemDescription",
    });
    setIsDirtyInput({ ...isDirtyInput, systemDescription: true });
  };

  const isOccupyingEntirePropertyCheckHandler = (status) => {
    setIsDirtyInput({ ...isDirtyInput, isOccupyingEntireProperty: true });

    addFieldToChange({
      valueFor: "isOccupyingEntireProperty",
      value: status,
      originalName: "isOccupyingEntireProperty",
    });
    setIsOccupyingEntireProperty(status);

    const appCustomData = getFromLocalStorage("appCustomData");

    setToLocalStorage("appCustomData", {
      ...appCustomData,
      isOccupyingEntireProperty: status,
    });
  };

  const mapImageHandler = (value) => {
    value = value || "";
    setMapImage(value);
    !isEditMode &&
      setToLocalStorage("appCustomData", { mapImage: value }, { merge: true });
    addFieldToChange({
      valueFor: "Map Image",
      value,
      originalName: "mapImage",
    });
    setIsDirtyInput({ ...isDirtyInput, mapImage: true });
  };

  const selectDominantTypeHandler = ({ target }) => {
    const { value } = target;
    setSelectedDominantType(value);
    removeFromMissedData("selectedDominantType", setMissedProjectData);

    let [dominantGroup, dominantUse] = ["", ""];
    const typeObj = occupancyTypes.find((el) => el.id === value);
    setOccupancyGroups(typeObj.groups);
    const dominantUses = allIconsAndCovers?.filter(
      (el) => el.occupancyTypeId === typeObj.id,
    );

    // if (typeObj.groups.length === 1) {
    //   dominantGroup = typeObj.groups[0];
    //   dominantUse = dominantUses.length === 1 ? dominantUses[0] : "";
    //   selectDominantGroupHandler({ target: { value: dominantGroup } });
    // }
    if (dominantUses.every((el) => el.occupancyUse === "Default")) {
      dominantUse = dominantUses[0];

      setProjectIconsAndCovers(
        allIconsAndCovers?.filter(
          (el) =>
            el.occupancyTypeId === value && el.occupancyGroup === dominantGroup,
        ),
      );

      addFieldToChange({
        valueFor: "occupancyIds",
        value: [dominantUse.occupancyTypeId],
        originalName: "occupancyIds",
      });
    }

    setSelectedDominantUse(dominantUse);
    handleOccupancyUseInLocalStorage(dominantUse);
    setSelectedDominantGroup(dominantGroup);

    if (!isEditMode) {
      setToLocalStorage(
        "appCustomData",
        {
          selectedDominantType: value,
          selectedDominantGroup: dominantGroup,
        },
        { merge: true },
      );
    }
  };

  const selectDominantGroupHandler = ({ target }) => {
    const { value } = target;
    removeFromMissedData("selectedDominantGroup", setMissedProjectData);
    setSelectedDominantGroup(value);
    setSelectedDominantUse("");

    const filteredIconsAndCovers = allIconsAndCovers?.filter(
      (el) =>
        el.occupancyTypeId === selectedDominantType &&
        el.occupancyGroup === value,
    );

    setProjectIconsAndCovers(filteredIconsAndCovers);

    setToLocalStorage(
      "appCustomData",
      {
        selectedDominantGroup: value,
        selectedDominantUse: null,
      },
      { merge: true },
    );
  };

  const selectedDominantUseHandler = (newValue) => {
    if (typeof newValue === "string") {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        toggleOpen(true);
        setDialogValue({
          label: newValue,
        });
      });
    } else if (newValue && newValue.inputValue) {
      toggleOpen(true);
      setDialogValue({
        label: newValue.inputValue,
      });
    } else {
      if (!newValue) {
        return;
      }
      setToLocalStorage(
        "appCustomData",
        { selectedDominantUse: newValue },
        { merge: true },
      );
      setSelectedDominantUse(newValue);
      removeFromMissedData("selectedDominantUse", setMissedProjectData);

      addFieldToChange({
        valueFor: "dominantOccupancy",
        value: newValue,
        originalName: "dominantOccupancy",
      });
      setIsDirtyInput({ ...isDirtyInput, selectedDominantUse: true });
    }
  };

  const selectedDominantPropertyUseHandler = (newValue) => {
    if (typeof newValue === "string") {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        setOpenNewPropertyOccDialog(true);
        setDialogValue({
          label: newValue,
        });
      });
    } else if (newValue && newValue.inputValue) {
      setOpenNewPropertyOccDialog(true);
      setDialogValue({
        label: newValue.inputValue,
      });
    } else {
      if (!newValue) {
        return;
      }
      setToLocalStorage(
        "appCustomData",
        { selectedDominantPropertyUse: newValue },
        { merge: true },
      );
      setSelectedDominantPropertyUse(newValue);

      addFieldToChange({
        valueFor: "dominantPropertyOccupancy",
        value: newValue,
        originalName: "dominantPropertyOccupancy",
      });
    }
  };

  const checkIfValidName = (name, nameType = "drawing") => {
    if (nameType === "nomenclature") {
      setIsDirtyInput({
        ...isDirtyInput,
        nomenclature: true,
      });
      const ifSpecialCharactersRegex = /[^a-zA-Z0-9\s]/;
      const nomenclatureRegex = new RegExp("^[A-Z0-9]{3,4}$").test(name);
      if (name.length === 0) {
        setIsValidNomenclature(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          nomenclature: "Required field",
        }));
      } else if (!nomenclatureRegex) {
        if (ifSpecialCharactersRegex.test(name)) {
          setIsValidNomenclature(false);
          setErrors((prevErrors) => ({
            ...prevErrors,
            nomenclature: "No special characters allowed",
          }));
        } else if (name.length < 3) {
          setIsValidNomenclature(false);
          setErrors((prevErrors) => ({
            ...prevErrors,
            nomenclature: "Nomenclature should be 3 or 4 letters or numbers",
          }));
        }
      } else if (nomenclatureRegex) {
        setIsValidNomenclature(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          nomenclature: null,
        }));
      }
    } else if (nameType === "drawing") {
      setIsDirtyInput({
        ...isDirtyInput,
        drawingName: true,
      });
      if (name.length === 0) {
        setIsValidDrawingName(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          drawingName: "Required field",
        }));
        return;
      } else if (!drawingNameRegex.test(name)) {
        setIsValidDrawingName(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          drawingName: "No special characters allowed",
        }));
        return;
      }
      setIsValidDrawingName(true);
      setErrors((prevErrors) => ({
        ...prevErrors,
        drawingName: null,
      }));
      if (name.length > 0) {
        for (let i = 0; i < currentDrawings?.length; i++) {
          if (
            currentDrawings[i].name === name.trim().toLowerCase() &&
            currentDrawings[i].id !== originalDrawing.id
          ) {
            setIsValidDrawingName(false);
            setErrors((prevErrors) => ({
              ...prevErrors,
              drawingName: "Duplicated Name",
            }));
            break;
          } else {
            setIsValidDrawingName(true);
            setErrors((prevErrors) => ({
              ...prevErrors,
              drawingName: null,
            }));
          }
        }
      }
    }
  };

  const setDrawingNameInRelatedStateVars = _.debounce((value) => {
    setDrawingName(value);
    setDrawingToBeUploaded((prevDrawing) => ({
      ...prevDrawing,
      name: value,
    }));
  }, 500);

  const isAlreadyExistedOccupancyField = (newValue) => {
    const newValueId = newValue?.id || newValue?.use?.id;
    return !!drawingOccupancies.find((o) => o.use.id === newValueId);
  };

  const drawingNameHandler = (event) => {
    const { value } = event.target;
    if (missedDrawingData.includes("drawingName") && value) {
      removeFromMissedData("drawingName", setMissedDrawingData);
    }
    addFieldToChange({
      valueFor: "drawing title",
      value: event.target.value,
      originalName: "name",
    });
    const appCustomData = getFromLocalStorage("appCustomData");
    setToLocalStorage("appCustomData", {
      ...appCustomData,
      drawingToUpload: {
        ...appCustomData?.drawingToUpload,
        name: event.target.value,
      },
    });
    setDrawingNameInRelatedStateVars(event.target.value);
  };

  const setNomenclatureInRelatedStateVars = _.debounce((value) => {
    setNomenclature(value);
    setDrawingToBeUploaded((prevDrawing) => ({
      ...prevDrawing,
      nomenclature: value,
    }));
  }, 500);

  const nomenclatureHandler = (event) => {
    const value = event.target.value.trim().toUpperCase().slice(0, 4);
    if (missedDrawingData.includes("nomenclature") && value) {
      removeFromMissedData("nomenclature", setMissedDrawingData);
    }
    event.target.value = value;

    if (isEditMode) {
      addFieldToChange({
        valueFor: "nomenclature",
        value,
        originalName: "nomenclature",
      });
    }
    const appCustomData = getFromLocalStorage("appCustomData");
    setToLocalStorage("appCustomData", {
      ...appCustomData,
      drawingToUpload: {
        ...appCustomData?.drawingToUpload,
        nomenclature: value,
      },
    });

    setNomenclatureInRelatedStateVars(value);
  };

  const floorAreaHandler = (value) => {
    const valueToUse = String(value).replace(/[^\d.]/g, "");
    if (missedDrawingData.includes("floorArea") && value) {
      removeFromMissedData("floorArea", setMissedDrawingData);
    }
    setFloorArea(valueToUse);
    setIsDirtyInput({ ...isDirtyInput, floorArea: true });
    if (isEditMode) {
      addFieldToChange({
        valueFor: "floorArea",
        value: valueToUse,
        originalName: "floorArea",
      });
    }
    setDrawingToBeUploaded((prevDrawing) => ({
      ...prevDrawing,
      floorArea: valueToUse,
    }));
    const appCustomData = getFromLocalStorage("appCustomData");
    setToLocalStorage("appCustomData", {
      ...appCustomData,
      drawingToUpload: {
        ...appCustomData?.drawingToUpload,
        floorArea: valueToUse,
      },
    });
  };

  const selectedAreaUnitHandler = (value) => {
    setSelectedAreaUnit(value);
    //Set the new value in the local storage in case of not edit mode.
    if (!isEditMode) {
      setToLocalStorage(
        "appCustomData",
        { selectedAreaUnit: value },
        { merge: true },
      );
    }
    floorAreaHandler(String(convertFromToSFT(floorArea, value)));
  };

  /**
   * @param {fieldObject}
   * @returns an object that holds 3 values:
   *  1. value: the change & 2. valueFor: the field name inside the frame & 3. originalValue: the state variable
   */
  const addFieldToChange = (fieldObject) => {
    if (!isEditMode) {
      return;
    }
    const index = fieldsToChange.findIndex(
      (el) => el.valueFor === fieldObject.valueFor,
    );
    let isSameAsOriginal =
      fieldObject.value === originalDrawing[fieldObject.originalName];
    let adjustedFields = _.cloneDeep(fieldsToChange);
    if (fieldObject.valueFor === "project") {
      isSameAsOriginal =
        fieldObject.value.id === originalDrawing[fieldObject.originalName];
    } else if (fieldObject.valueFor === "pixelsPerUnit") {
      isSameAsOriginal =
        fieldObject.value === Infinity ||
        (fieldObject.value === originalDrawing[fieldObject.originalName] &&
          selectedUnit === originalDrawing.correspondingUnit);
    }

    if (index !== -1) {
      if (isSameAsOriginal) {
        //If the new value equals the original value -> remove the field from the array of changes.
        adjustedFields = fieldsToChange.filter(
          (el) => el.valueFor !== adjustedFields[index].valueFor,
        );
      } else {
        //Otherwise, update the value of the field.
        adjustedFields[index].value = fieldObject.value;
      }
    } else {
      adjustedFields.push(fieldObject);
    }
    setToLocalStorage(
      "appCustomData",
      { fieldsToChange: adjustedFields },
      { merge: true },
    );
    setFieldsToChange(adjustedFields);
  };

  const getLastPage = () => {
    if (!currentDrawings?.length) {
      return "2".padStart(3, "0");
    }
    let lastNo =
      (currentDrawings?.filter((el) => el.order > 1).length || 0) + 1;
    !isEditMode && lastNo++;
    return lastNo.toString().padStart(3, "0");
  };

  const setDefaultPageNo = () => {
    setPageNo(getLastPage());
    setToLocalStorage(
      "appCustomData",
      { pageNo: getLastPage() },
      { merge: true },
    );
  };

  const lowestLevelCheckHandler = (status) => {
    setIsDirtyInput({ ...isDirtyInput, isLowestLevel: true });

    addFieldToChange({
      valueFor: "isLowestLevel",
      value: status,
      originalName: "isLowestLevel",
    });
    setIsLowestLevel(status);
    setDrawingToBeUploaded((prevDrawing) => ({
      ...prevDrawing,
      isLowestLevel: status,
    }));
    const appCustomData = getFromLocalStorage("appCustomData");

    setToLocalStorage("appCustomData", {
      ...appCustomData,
      drawingToUpload: {
        ...appCustomData?.drawingToUpload,
        isLowestLevel: status,
      },
    });
  };

  const expandedPanelHandler = (panel) => {
    setExpandedPanel(expandedPanel === panel ? false : panel);
  };

  const gridModeHandler = (status) => {
    if (status === "start-point-selection") {
      if (gridModeValue) {
        setGridModeValue(!gridModeValue);
      }
    } else if (status === "end-point-selection") {
      setGridModeValue(initialGridModeValue);
    }
  };

  const connectTwoPointsHandler = () => {
    gridModeHandler("start-point-selection");
    setIsOpened(false);
    setIsDetectingPixelsPerUnit(true);
    setToLocalStorage(
      "appCustomData",
      { isUploadPopupOpen: false, isDetectingPixelsPerUnit: true },
      { merge: true },
    );
    //To fix the issue of infinite zoom if existed.
    centerTheDrawing();
    excalidrawAPI?.zoomToFitAllDrawing();
    document.querySelector('[title="Line — L or 6"]').click();
    excalidrawCanvas?.addEventListener("click", detectLine);
    excalidrawCanvas?.addEventListener("touchstart", detectLine);
    excalidrawCanvas?.addEventListener("touchend", detectLine);
    document.addEventListener("keyup", keysHandlerOnUploadDialog);
  };

  const detectedDataHandler = () => {
    const excalidrawElements = excalidrawAPI?.getSceneElements();
    const lineWidth = Math.ceil(
      excalidrawElements[excalidrawElements.length - 1]?.width,
    );
    const lineHeight = Math.ceil(
      excalidrawElements[excalidrawElements.length - 1]?.height,
    );
    const pixelsOfDetectedLine = Math.sqrt(
      Math.pow(lineWidth, 2) + Math.pow(lineHeight, 2),
    );

    const editedDrawingProps =
      getFromLocalStorage("appCustomData")?.editedDrawingProps || {};
    Object.assign(editedDrawingProps, {
      pixelsOfDetectedLine,
      detectedLineWidth: "",
    });
    const detectedObjInLocalStorage = location.hash
      ? { editedDrawingProps }
      : { pixelsOfDetectedLine, detectedLineWidth: "" };

    setToLocalStorage("appCustomData", detectedObjInLocalStorage, {
      merge: true,
    });
    location.hash &&
      setEditedDrawingProps((prev) => ({ ...prev, ...editedDrawingProps })); //Edit mode
    setPixelsOfDetectedLine(Math.ceil(pixelsOfDetectedLine));
    endDetectingPixelsPerFeet({ discard: false });
  };

  const detectLine = () => {
    //To detect the two points clicks.
    scaleLineCoords.current.push(1);
    const excalidrawElements = excalidrawAPI?.getSceneElements();
    const { width, height } = excalidrawElements[excalidrawElements.length - 1];
    if (!width && !height) {
      return;
    }
    detectedDataHandler();
  };

  const endDetectingPixelsPerFeet = ({ discard }) => {
    if (!discard) {
      const excalidrawElements = excalidrawAPI?.getSceneElements();
      updateScene(
        "elements",
        isEditMode
          ? excalidrawElements.splice(0, excalidrawElements.length - 1)
          : [excalidrawElements[0]],
      );
      updateScene("appState", { editingElement: null });
    }

    setIsOpened(true);
    setIsDetectingPixelsPerUnit(false);
    setExpandedPanel("drawing-panel");
    excalidrawCanvas?.removeEventListener("click", detectLine);
    excalidrawCanvas?.removeEventListener("touchstart", detectLine);
    excalidrawCanvas?.removeEventListener("touchend", detectLine);
    document.removeEventListener("keyup", keysHandlerOnUploadDialog);
    excalidrawAPI.setActiveTool({ type: "selection" });
    gridModeHandler("end-point-selection");
    !discard &&
      setTimeout(
        () => scrollToBottomAfterSelection("detected-line-width-textfield"),
        150,
      );
  };

  const discardDrawnLine = (activeDialog) => {
    setActiveConfirmDialog(activeDialog);
    const sceneElements = excalidrawAPI.getSceneElements();
    const elementsToSet = _.cloneDeep(sceneElements.slice(0, -1));
    updateScene("elements", elementsToSet);
    excalidrawAPI.setActiveTool({ type: "selection" });
    document.querySelector('[title="Line — L or 6"]').click();
  };

  const pointsToUse = (start, points) => {
    if (!points?.length) {
      return [];
    }
    return points.map(([coordX, coordY]) => [
      coordX + start.x,
      coordY + start.y,
    ]);
  };

  const detectDragToDrawLine = () => {
    const appState = excalidrawAPI.getAppState();
    const sceneElements = excalidrawAPI.getSceneElements();
    const { points, x, y } = sceneElements[sceneElements.length - 1];
    if (
      (appState.activeTool.type === "selection" && points.length < 4) ||
      checkLineIntersections(pointsToUse({ x, y }, points))
    ) {
      discardDrawnLine(
        checkLineIntersections(pointsToUse({ x, y }, points))
          ? "intersectedLines"
          : "draggedLine",
      );
    }
  };

  const floorAreaElHandler = (el) => {
    setFloorAreaEl(el);
    setToLocalStorage("appCustomData", { floorAreaEl: el }, { merge: true });
  };

  const detectAreaBoundaries = () => {
    const sceneElements = excalidrawAPI?.getSceneElements();
    const boundariesPolyLine = sceneElements[sceneElements.length - 1];
    floorAreaElHandler(boundariesPolyLine);
    if (boundariesPolyLine.type === "image") {
      document.querySelector('[title="Line — L or 6"]').click();
    }
    if (boundariesPolyLine.points?.length > 3) {
      const startPoint = boundariesPolyLine.points[0];
      const endPoint =
        boundariesPolyLine.points[boundariesPolyLine.points.length - 1];

      const isClosed =
        startPoint[0] === 0 &&
        startPoint[1] === 0 &&
        endPoint[0] === 0 &&
        endPoint[1] === 0;

      if (isClosed) {
        const calculatedArea = calculateAreaFromCoordinates({
          coordinates: boundariesPolyLine.points,
          pixelsPerUnit: isEditMode
            ? currentDrawing.pixelsPerUnit
            : pixelsOfDetectedLine / detectedLineWidth,
          unit: isEditMode ? currentDrawing.correspondingUnit : selectedUnit,
        }).toFixed(globalSettings.decimalPoints);
        const appCustomData = getFromLocalStorage("appCustomData");
        setToLocalStorage("appCustomData", {
          ...appCustomData,
          drawingToUpload: {
            ...appCustomData?.drawingToUpload,
            floorArea: calculatedArea,
          },
          ...(isEditMode
            ? {
                editedDrawingProps: {
                  ...appCustomData?.editedDrawingProps,
                  floorArea: calculatedArea,
                },
              }
            : {}),
        });

        if (isEditMode) {
          addFieldToChange({
            valueFor: "floorArea",
            value: calculatedArea,
            originalName: "floorArea",
          });
        }
        setFloorArea(Number(calculatedArea));
        endSelectingFloorBoundaries({ discard: false });
      }
    }
  };

  const endSelectingFloorBoundaries = ({
    discard = false,
    polylineEl = null,
  }) => {
    const polylineElToUse = polylineEl || floorAreaEl;
    doAfterLoadingScene(() => {
      const excalidrawElements = excalidrawAPI?.getSceneElements();
      const elementsToSet = _.cloneDeep(
        discard
          ? excalidrawElements.filter((el) => el.id !== polylineElToUse?.id)
          : excalidrawElements.slice(0, -1),
      );
      setToLocalStorage("excalidraw", elementsToSet);
      updateScene("elements", elementsToSet);
      updateScene("appState", { editingElement: null });
    });

    setIsOpened(true);
    setIsSelectingBoundaries(false);
    setExpandedPanel("drawing-panel");
    setToLocalStorage(
      "appCustomData",
      { isSelectingBoundaries: false },
      { merge: true },
    );
    excalidrawCanvas?.removeEventListener("click", detectAreaBoundaries);
    excalidrawCanvas?.removeEventListener("mouseup", detectDragToDrawLine);
    excalidrawCanvas?.removeEventListener("touchstart", detectAreaBoundaries);
    excalidrawCanvas?.removeEventListener("touchend", detectAreaBoundaries);
    document.removeEventListener("keyup", keysHandlerOnUploadDialog);
    excalidrawAPI.setActiveTool({ type: "selection" });
    gridModeHandler("end-point-selection");
    floorAreaElHandler(null);
    !discard &&
      setTimeout(
        () => scrollToBottomAfterSelection("floor-area-textfield"),
        150,
      );
  };

  const selectFloorBoundariesHandler = () => {
    if (!detectedLineWidth && !currentDrawing?.pixelsPerUnit) {
      return;
    }
    gridModeHandler("start-point-selection");
    setIsOpened(false);
    setIsSelectingBoundaries(true);
    setToLocalStorage(
      "appCustomData",
      { isUploadPopupOpen: false, isSelectingBoundaries: true },
      { merge: true },
    );
    centerTheDrawing();
    excalidrawAPI?.zoomToFitAllDrawing();
    // excalidrawAPI.setActiveTool({ type: "line" });
    document.querySelector('[title="Line — L or 6"]').click();
    excalidrawCanvas?.addEventListener("click", detectAreaBoundaries);
    excalidrawCanvas?.addEventListener("mouseup", detectDragToDrawLine);
    excalidrawCanvas?.addEventListener("touchstart", detectAreaBoundaries);
    excalidrawCanvas?.addEventListener("touchend", detectAreaBoundaries);
    document.addEventListener("keyup", keysHandlerOnUploadDialog);
  };

  const keysHandlerOnUploadDialog = ({ key }) => {
    //TODO - DO NOT depend on the local storage
    const {
      isDetectingPixelsPerUnit: detectingPixelsStatus,
      isPlacingOnMap: placingOnMapStatus,
      isSelectingBoundaries: selectingBoundaries,
      floorAreaEl: polylineEl,
    } = getFromLocalStorage("appCustomData") ?? {};
    if (key === "Backspace" && detectingPixelsStatus) {
      scaleLineCoords.current.pop();
      const excalidrawElements = excalidrawAPI?.getSceneElements();
      updateScene("elements", [{ ...excalidrawElements[0] }]);
      excalidrawAPI.setActiveTool({ type: "line" });
      excalidrawAPI.setCursor("crosshair");
    } else if (key === "Escape") {
      detectingPixelsStatus && endDetectingPixelsPerFeet({ discard: true });
      selectingBoundaries &&
        endSelectingFloorBoundaries({ discard: true, polylineEl });
      placingOnMapStatus && closePlacingOnMap({ discard: true });
      onCloseHandler({ isPixelsPerUnitDetectingMode: true });
    } else if (key === "Enter" && placingOnMapStatus) {
      closePlacingOnMap({ discard: false });
    }
  };

  const selectUnitHandler = (event) => {
    setSelectedUnit(event.target.value);
    setToLocalStorage(
      "appCustomData",
      { selectedUnit: event.target.value },
      { merge: true },
    );
  };

  const detectedLineWidthHandler = (event) => {
    const { value } = event.target;
    if (missedDrawingData.includes("detectedLineWidth") && value) {
      removeFromMissedData("detectedLineWidth", setMissedDrawingData);
    }
    if (value > 0 || value === "") {
      setIsDirtyInput({ ...isDirtyInput, detectedLineWidth: true });
      setDetectedLineWidth(value);
      setToLocalStorage(
        "appCustomData",
        { detectedLineWidth: value },
        { merge: true },
      );

      addFieldToChange({
        valueFor: "pixelsPerUnit",
        value: pixelsOfDetectedLine / value,
        originalName: "pixelsPerUnit",
      });
    }
  };

  const getDrawingUsers = (drawing) => {
    let creator = drawing.creator ?? "";
    if (!isEditMode) {
      creator = currentUser.authId;
    }
    let designers = drawing.designers || [];
    designers.push(currentUser.authId);
    designers = Array(...new Set(designers));

    return [creator, designers];
  };

  const updateProjectSummaryHandler = () => {
    if (!isEditMode) {
      return;
    }

    const propertyOccupancyType = occupancyTypes.find(
      (el) => el.id === selectedDominantPropertyUse.occupancyTypeId,
    );
    const projectOccupancyType = occupancyTypes.find(
      (el) => el.id === selectedDominantUse.occupancyTypeId,
    );
    const floorsOfWork = getFloorsOfWorkAsString(currentDrawings);
    const totalWorkingArea = currentDrawings
      .filter((el) => el.type !== "coverSheet")
      .reduce((accArea, floor) => {
        return accArea + Number(floor.floorArea);
      }, 0);

    const dataForTable = {
      dominantPropertyOccupancyTypeGroup: `${selectedDominantPropertyGroup}/${propertyOccupancyType.name}`,
      dominantPropertyOccupancyUse: selectedDominantPropertyUse.occupancyUse,
      dominantProjectOccupancyTypeGroup: `${selectedDominantGroup}/${projectOccupancyType.name}`,
      dominantProjectOccupancyUse: selectedDominantUse.occupancyUse,
      stage: coverSheetData.stage,
      pageSize: coverSheetData.pageSize,
      typeOfConstruction: coverSheetData.typeOfConstruction,
      floorsOfWork,
      isOccupyingEntireProperty: checkIfOccupyingEntireProperty(
        currentDrawings,
        coverSheetData,
      ),
      propertyLevelsAboveGround: coverSheetData.propertyLevelsAboveGround,
      propertyLevelsBelowGround: coverSheetData.propertyLevelsBelowGround,
      totalPropertyLevels: String(
        Number(coverSheetData.propertyLevelsAboveGround) +
          Number(coverSheetData.propertyLevelsBelowGround),
      ),
      totalPropertyHeight: coverSheetData.totalPropertyHeight,
      propertyType:
        coverSheetData.totalPropertyHeight > 75 ? "HIGH-RISE" : "LOW-RISE",
      totalOccupancyLoad: coverSheetData.totalOccupancyLoad,
      totalBuildingArea: `${coverSheetData.totalBuildingArea} (${totalWorkingArea} IN PROJECT)`,
      isFloodHazardArea: coverSheetData.isFloodHazardArea ? "YES" : "NO",
    };

    updateProjectSummary(null, dataForTable);
  };

  const checkIfDuplicatedOccupancies = (occupanciesIds) => {
    const uniqueIds = Array.from(new Set(occupanciesIds));
    return uniqueIds.length !== occupanciesIds.length;
  };

  const addDrawingToCoverSheet = async (drawing) => {
    //Check if cover sheet existed and the drawing to be added is not the cover sheet.
    if (
      currentProject.coverSheetRoom &&
      drawing.roomKey !== currentProject.coverSheetRoom
    ) {
      const allElements = await getCoverSheetElements();
      addDrawingHandler(drawing, allElements);
    }
  };

  const submitBtnHandler = async () => {
    if (errors?.nomenclature || errors?.drawingName) {
      triggerToast("Fix the errors first", "error");
      return;
    }
    setExpandedPanel(null);
    const missedData = checkIfMissedData();
    const isUploadAllowed = checkIfUploadAllowed(missedData);

    try {
      if (
        checkIfDuplicatedOccupancies(drawingOccupancies.map((el) => el.use.id))
      ) {
        setIsBtnLoading(false);
        triggerToast("Duplicated drawing occupancies!", "error");
        return;
      } else if (!isUploadAllowed) {
        setIsBtnLoading(false);
        triggerToast("Missing Data", "error");
        //This setTimeout is just for UX
        setTimeout(() => {
          setExpandedPanel(openPanelOnError(missedData));
        }, 1000);
        return;
      }
      setIsOpened(false);
      setActiveLoader("uploading");
      const drawingId = isEditMode ? currentDrawing.id : randomId();
      const drawingToUse = drawingToBeUploaded?.id
        ? drawingToBeUploaded
        : currentDrawing;

      let order = Number(pageNo);

      if (fieldsToChange.some((el) => el.valueFor === "project")) {
        const drawingsOfNewProject = allDrawings.filter(
          (el) => el.projectId === selectedProject,
        );
        const orderOfNewAssignedDrawing = Number(
          getLargestOrder(drawingsOfNewProject),
        );
        order = `${Number(orderOfNewAssignedDrawing) - 1}`;
      }

      let drawingToUpload = {
        ...drawingToUse,
        ...drawingToBeUploaded,
        ...(drawingToUse.type !== "coverSheet"
          ? {
              pixelsPerUnit: isEditMode
                ? editedDrawingProps.pixelsOfDetectedLine / detectedLineWidth ||
                  drawingToUse.pixelsPerUnit
                : pixelsOfDetectedLine / detectedLineWidth,
              correspondingUnit: selectedUnit,
              floorArea:
                selectedAreaUnit === "SFT"
                  ? floorArea
                  : convertFromToSFT(floorArea, "SFT"),
              floorId: nomenclature,
            }
          : {}),
        drawingStage: coverSheetData.stage,
        pageSize: coverSheetData.pageSize,
        id: drawingId,
        projectId: currentProject.id,
      };

      if (drawingToUse.type !== "coverSheet") {
        const drawingAsImage =
          excalidrawAPI?.getFiles()[drawingToUse.fileId].dataURL;
        const { thumbImageUrl, thumbFilePath, drawingUrl } =
          await uploadDrawingToFireStorage(
            drawingAsImage,
            drawingId,
            selectedProject,
          );

        drawingToUpload = {
          ...drawingToUpload,
          thumbImageUrl,
          thumbFilePath,
          drawingUrl,
        };
      }

      await collabAPI?.startCollaboration();

      const drawingOccupancyIds = setOccupancyIdsOnUpload();
      let barcode = currentProject.barcode;
      if (!barcode) {
        barcode = await getBarcode(currentProject.id, drawingId);
      }
      const [creator, designers] = getDrawingUsers(drawingToUpload);
      drawingToUpload = {
        ...drawingToUpload,
        ...(drawingToUse.type !== "coverSheet"
          ? { occupancyIds: drawingOccupancyIds, order }
          : {}),
        creator,
        designers,
      };

      const drawingFromFirebase = await createOrUpdateDrawing(
        drawingToUpload,
        isEditMode,
        () => {
          triggerToast(
            `Successfully ${isEditMode ? "updated" : "uploaded"}`,
            "success",
          );
          isEditMode &&
            editFrame({ drawing: drawingToUpload, changes: fieldsToChange });
        },
        () =>
          triggerToast(
            `Failed to ${isEditMode ? "update" : "upload"}`,
            "error",
          ),
      );

      addDrawingToCoverSheet(drawingFromFirebase);

      if (
        !currentProject.coverSheetRoom ||
        drawingToUse.type === "coverSheet"
      ) {
        createOrUpdateCoverSheet(
          { id: selectedProject, ...coverSheetData },
          { update: isEditMode },
        );
        updateProjectById(selectedProject, {
          coverSheetDataId: selectedProject,
        });
      }

      const isDifferentDominantPropertyOccupancy =
        !currentProject.dominantPropertyOccupancy ||
        selectedDominantPropertyType !==
          currentProject.dominantPropertyOccupancy.type ||
        selectedDominantPropertyGroup !==
          currentProject.dominantPropertyOccupancy.group ||
        selectedDominantPropertyUse !==
          currentProject.dominantPropertyOccupancy.use;

      (recordId !== currentProject.recordId ||
        barcode !== currentProject.barcode ||
        northHeading !== currentProject.northHeading ||
        isDifferentDominantPropertyOccupancy ||
        systemDescription.toLowerCase() !==
          currentProject.systemDescription.toLowerCase()) &&
        updateProjectById(selectedProject, {
          recordId,
          barcode,
          northHeading,
          dominantPropertyOccupancy: selectedDominantPropertyUse.id,
          systemDescription,
        });

      if (!isEditMode) {
        const projectToUse = {
          ...currentProject,
          recordId,
          barcode,
          northHeading,
        };
        try {
          drawFullFrame({ drawingToUse: drawingToUpload, projectToUse });
        } catch (error) {
          console.error(error);
        }
      }

      onCreateOrUpdateDrawing();
      setCurrentDrawing(drawingToUpload);

      excalidrawCanvas?.removeEventListener("click", detectLine);
      setToLocalStorage("currentDrawing", drawingToUpload);

      setToLocalStorage("appCustomData", { isReadyToDesign: true });
      setIsReadyToDesign(true);
      if (currentDrawing?.type === "coverSheet") {
        updateProjectSummaryHandler();
        updateCodeReference(relatedCodes);
      }
      setActiveLoader(null);
    } catch (error) {
      setIsBtnLoading(false);
      triggerToast("Couldn't Upload the drawing", "error");
      console.error(error);
      setActiveLoader(null);
    }
  };

  const setOccupancyIdsOnUpload = () => {
    const drawingOccupancyUses = drawingOccupancies.map((d) => d.use);
    const occupancyIds = [];
    for (const use of drawingOccupancyUses) {
      currentProject.iconAndCover.id !== selectedDominantUse.id &&
        updateProjectById(selectedProject, {
          iconAndCover: selectedDominantUse,
        });
      use.id && occupancyIds.push(use.id);
    }

    return occupancyIds;
  };

  const cancelBtnHandler = () => {
    checkBeforeDiscardChanges();
  };

  const discardChangesHandler = () => {
    setIsOpened(false);
    setToLocalStorage("appCustomData", {});
    onCloseHandler();
    excalidrawAPI.setActiveTool({ type: "selection" });
    if (isEditMode) {
      setCurrentDrawing(originalDrawing);
    }
  };

  const checkBeforeDiscardChanges = () => {
    if (checkForChangesToDiscard()) {
      setIsDiscardConfirmDialogOpen(true);
    } else {
      discardChangesHandler();
    }
  };

  const checkForChangesToDiscard = () => {
    if (isEditMode) {
      return !!Object.keys(fieldsToChange).length;
    }
    return Object.values(isDirtyInput).some((el) => el === true);
  };

  const checkOnCoverSheetData = () => {
    const missedData = [];

    //Put every single property in the missedData object
    if (!coverSheetData) {
      for (const obj of ALL_UPLOAD_INPUTS) {
        obj.parent === "coverSheet" && missedData.push(obj.key);
      }
      return missedData;
    }

    for (const obj of ALL_UPLOAD_INPUTS) {
      if (obj.parent !== "coverSheet") {
        continue;
      }
      if (
        obj.type === "boolean" &&
        [true, false].includes(coverSheetData[obj.key])
      ) {
        continue;
      } else if (
        obj.type === "number" &&
        ["propertyLevelsAboveGround", "propertyLevelsBelowGround"].includes(
          obj.key,
        ) &&
        Number(coverSheetData[obj.key]) >= 0 &&
        coverSheetData[obj.key] !== ""
      ) {
        continue;
      } else if (!coverSheetData[obj.key]) {
        missedData.push(obj.key);
      }
    }
    return missedData;
  };

  const getMissedDrawingOccupancies = () => {
    const missedFields = [];
    for (const occ of drawingOccupancies) {
      for (const field in occ) {
        if (!occ[field]) {
          missedFields.push(`${field}-${occ.id}`);
        }
      }
    }
    return missedFields;
  };

  const resetMissedData = () => {
    setMissedProjectData([]);
    setMissedPropertyData([]);
    setMissedCoverSheetData([]);
    setMissedDrawingData([]);
  };

  const checkIfMissedData = () => {
    const missedProjectDataToSet = [];
    const missedPropertyDataToSet = [];
    const missedCoverSheetDataToSet = checkOnCoverSheetData();
    const missedDrawingDataToSet = [...getMissedDrawingOccupancies()];
    if (!selectedDominantType) {
      missedProjectDataToSet.push("selectedDominantType");
    }
    if (!selectedDominantGroup) {
      missedProjectDataToSet.push("selectedDominantGroup");
    }
    if (!selectedDominantUse) {
      missedProjectDataToSet.push("selectedDominantUse");
    }
    if (!recordId) {
      missedProjectDataToSet.push("recordId");
    }
    if (!systemDescription) {
      missedProjectDataToSet.push("systemDescription");
    }
    if (!mapImage) {
      missedProjectDataToSet.push("mapImage");
    }
    if (!selectedDominantPropertyType) {
      missedPropertyDataToSet.push("selectedDominantPropertyType");
    }
    if (!selectedDominantPropertyGroup) {
      missedPropertyDataToSet.push("selectedDominantPropertyGroup");
    }
    if (!selectedDominantPropertyUse) {
      missedPropertyDataToSet.push("selectedDominantPropertyUse");
    }
    if (!isEditMode) {
      if (!drawingName) {
        missedDrawingDataToSet.push("drawingName");
      }
      if (!nomenclature) {
        missedDrawingDataToSet.push("nomenclature");
      }
      if (
        (isEditMode &&
          editedDrawingProps.pixelsOfDetectedLine &&
          !detectedLineWidth) ||
        (!isEditMode && !detectedLineWidth)
      ) {
        missedDrawingDataToSet.push("detectedLineWidth");
      }
      if (!floorArea) {
        missedDrawingDataToSet.push("floorArea");
      }
    }
    setMissedProjectData(missedProjectDataToSet);
    setMissedPropertyData(missedPropertyDataToSet);
    setMissedCoverSheetData(missedCoverSheetDataToSet);
    setMissedDrawingData(missedDrawingDataToSet);
    const missedData = {
      missedProjectData: missedProjectDataToSet,
      missedPropertyData: missedPropertyDataToSet,
      missedCoverSheetData: missedCoverSheetDataToSet,
      missedDrawingData: missedDrawingDataToSet,
    };
    // setToLocalStorage("appCustomData", missedData, { merge: true });
    return missedData;
  };

  const removeFromMissedData = (field, parentSetter) => {
    parentSetter((prevData) => prevData.filter((el) => el !== field));
  };

  const removeFromCoverSheetMissedData = (field) => {
    setMissedCoverSheetData((prevData) =>
      prevData.filter((el) => el !== field),
    );
  };

  const removeFromDrawingMissedData = (field) => {
    setMissedDrawingData((prevData) => prevData.filter((el) => el !== field));
  };

  const getMissedClassName = (field, missedData) => {
    return missedData.includes(field) ? "missed" : "";
  };

  const checkIfUploadAllowed = (missedData) => {
    for (const key in missedData) {
      if (missedData[key].length > 0) {
        return false;
      }
    }

    return true;
  };

  //Common
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const inputLabelToResponse = {
    fontSize: isSmallScreen ? 14 : 16,
    lineHeight: isSmallScreen ? 2 : 1.6,
  };

  //Render
  return (
    <main ref={uploadPopupMainRef} style={{ zIndex: isClosedBehavior ? 2 : 5 }}>
      {isDiscardConfirmDialogOpen ? (
        <ConfirmDialog
          title={t("discardChangesDialog.title")}
          onConfirm={onConfirmDialogHandler}
          onCancel={() => {
            setIsDiscardConfirmDialogOpen(false);
          }}
        >
          <p className="clear-canvas__content"> {t("alerts.discardChanges")}</p>
        </ConfirmDialog>
      ) : (
        <div>
          <Resizable
            className="upload-manager-dialog"
            height={Infinity}
            width={drawerWidth}
            onResize={(_, { size }) => {
              const iconEl = document.querySelector(".upload-manager-icon");
              iconEl.classList.remove("upload-manager-puller_box_open");
              iconEl.classList.remove("upload-manager-puller_box_close");
              setDrawerWidth(size.width);
            }}
            minConstraints={[
              currentViewWidth <= 422 ? currentViewWidth - PULLER_MARGIN : 450,
              Infinity,
            ]}
            resizeHandles={["e"]}
          >
            <SwipeableDrawer
              container={container}
              open={isOpened}
              onClose={(_, status) => {
                if (status === "backdropClick" && location.hash) {
                  setIsOpened(false);
                }
                isEditMode &&
                  fieldsToChange.length === 0 &&
                  checkBeforeDiscardChanges();
              }}
              onOpen={() => setIsOpened(true)}
              swipeAreaWidth={0}
              transitionDuration={{ enter: 500, exit: 500 }}
              sx={{
                "& .MuiDrawer-paper": {
                  display: "flex",
                  justifyContent: "space-between",
                },
                "& .MuiBackdrop-root": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <div className="upload-popup-controllers">
                <h3>{`${location.hash ? "Data" : "Upload"}`} Manager</h3>
                <CloseButton
                  onClick={() => {
                    checkBeforeDiscardChanges();
                  }}
                  classes={["upload-popup-close-btn"]}
                  style={{
                    backgroundColor: "transparent",
                    color: "#444",
                    borderColor: "transparent",
                    borderRadius: "50%",
                    "&:hover": {
                      backgroundColor: "#ebebeb",
                      borderColor: "#ebebeb",
                    },
                  }}
                />
              </div>
              <div
                className="upload-popup-content"
                style={{ width: drawerWidth }}
              >
                <div className="info-inputs-container">
                  {isLoadingProjects ? (
                    <Skeleton
                      variant="rounded"
                      height={66}
                      sx={{
                        marginBottom: {
                          lg: "24px !important",
                          xs: "6px !important",
                        },
                      }}
                    />
                  ) : (
                    <Accordion
                      sx={{
                        marginBottom: {
                          lg: "24px !important",
                          xs: "6px !important",
                        },
                        boxShadow:
                          expandedPanel === "project-panel" ? "auto" : "none",
                        "& .MuiButtonBase-root-MuiAccordionSummary-root": {
                          minHeight: "56px",
                          padding: "4px 16px",
                        },
                      }}
                      expanded={expandedPanel === "project-panel"}
                      onChange={() => {
                        expandedPanelHandler("project-panel");
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="project-content"
                        disabled={isBtnLoading}
                        id="project-header"
                        className={`upload-accordion-summary ${
                          !!missedProjectData.length
                            ? "blinking-accordion-summary"
                            : ""
                        }`}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Typography>Project Details</Typography>
                        <Indicator number={missedProjectData.length} />
                      </AccordionSummary>

                      <AccordionDetails
                        sx={{
                          padding: isSmallScreen ? "8px 0 0" : " 8px 16px 16px",
                        }}
                      >
                        {isLoadingProjects ? (
                          <Skeleton
                            variant="rounded"
                            height={56}
                            sx={{ marginBottom: "15px" }}
                          />
                        ) : (
                          <>
                            <div className="project-select">
                              <FormControl
                                id="projects-dropdown-container_uploader"
                                fullWidth={true}
                              >
                                <InputLabel
                                  id="projects-dropdown-label"
                                  style={inputLabelToResponse}
                                >
                                  Project
                                </InputLabel>
                                <Select
                                  disabled={isEditMode}
                                  labelId="projects-dropdown-label"
                                  id="projects-dropdown"
                                  value={selectedProject}
                                  label="Project"
                                  onChange={(e) =>
                                    selectProjectHandler(e.target.value)
                                  }
                                  sx={selectComponentToResponse}
                                >
                                  {projects
                                    ?.sort(
                                      (a, b) =>
                                        -b.projectName.localeCompare(
                                          a.projectName,
                                        ),
                                    )
                                    .map((p) => (
                                      <MenuItem key={p.id} value={p.id}>
                                        {p.projectName}{" "}
                                        {p.projectName ? "-" : ""} {p.id}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </div>

                            {isLoadingCurrentProject ? (
                              <Skeleton
                                variant="rounded"
                                height={56}
                                sx={{ marginBottom: "15px" }}
                              />
                            ) : (
                              <div className="project-address-input">
                                <TextField
                                  id="project-address-textfield"
                                  label="Address"
                                  variant="outlined"
                                  value={(projectAddress || "").toLowerCase()}
                                  fullWidth={true}
                                  disabled={true}
                                  autoComplete="off"
                                />
                              </div>
                            )}

                            {isLoadingCurrentProject ? (
                              <Skeleton
                                variant="rounded"
                                height={56}
                                sx={{ marginBottom: "15px" }}
                              />
                            ) : (
                              <div className="record-id-input">
                                <TextField
                                  id="record-id-textfield"
                                  label="AHJ Document ID No."
                                  variant="outlined"
                                  value={recordId}
                                  fullWidth={true}
                                  onChange={(ev) => recordIdHandler({ ev })}
                                  disabled={!selectedProject || isEditMode}
                                  autoComplete="off"
                                />
                                <Tooltip
                                  title="ID number provided by the Authority Having Jurisdiction (AHJ) to track the document submittal (ex. job filling application number)"
                                  placement="right"
                                  arrow={true}
                                >
                                  <HelpOutlineOutlinedIcon
                                    sx={{
                                      fill: "rgba(0, 0, 0, 0.5)",
                                      marginLeft: "5px",
                                      fontSize: "1.8rem",
                                      position: "absolute",
                                      right: "12px",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            )}

                            {isLoadingCurrentProject ? (
                              <Skeleton
                                variant="rounded"
                                height={56}
                                sx={{ marginBottom: "15px" }}
                              />
                            ) : (
                              <div className="system-description-input">
                                <TextField
                                  id="system-description-textfield"
                                  title={systemDescription}
                                  label="System Description"
                                  variant="outlined"
                                  value={systemDescription.toLowerCase()}
                                  fullWidth={true}
                                  disabled={!selectedProject || isEditMode}
                                  onChange={({ target: { value } }) =>
                                    systemDescriptionHandler(value)
                                  }
                                  autoComplete="off"
                                />
                                <Tooltip
                                  title="Provide a brief work description"
                                  placement="right"
                                  arrow={true}
                                >
                                  <HelpOutlineOutlinedIcon
                                    sx={{
                                      fill: "rgba(0, 0, 0, 0.5)",
                                      marginLeft: "5px",
                                      fontSize: "1.8rem",
                                      position: "absolute",
                                      right: "12px",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            )}
                            <div className="dominant-occupancy">
                              <div className="dominant-occupancy-details">
                                {isLoadingCurrentProject ? (
                                  <Skeleton
                                    variant="rounded"
                                    height={56}
                                    width={"68%"}
                                  />
                                ) : (
                                  <FormControl
                                    id="project-dominant-type-dropdown-container"
                                    sx={{ width: "68%" }}
                                    className={getMissedClassName(
                                      "selectedDominantType",
                                      missedProjectData,
                                    )}
                                  >
                                    <InputLabel
                                      id="project-dominant-type-dropdown-label"
                                      style={inputLabelToResponse}
                                    >
                                      Type
                                    </InputLabel>
                                    <Select
                                      labelId="project-dominant-type-dropdown-label"
                                      id="project-dominant-type-dropdown"
                                      value={selectedDominantType}
                                      label="Type"
                                      disabled={!selectedProject || isEditMode}
                                      onChange={selectDominantTypeHandler}
                                      sx={selectComponentToResponse}
                                    >
                                      {occupancyTypes.length !== 0 ? (
                                        occupancyTypes
                                          ?.sort(
                                            (a, b) =>
                                              -b.name.localeCompare(a.name),
                                          )
                                          .map((el) => (
                                            <MenuItem key={el.id} value={el.id}>
                                              {el.name ?? "Default type name"}
                                            </MenuItem>
                                          ))
                                      ) : (
                                        <MenuItem
                                          key={selectedDominantType}
                                          value={selectedDominantType}
                                        >
                                          {selectedDominantType}
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                )}

                                {isLoadingCurrentProject ? (
                                  <Skeleton
                                    variant="rounded"
                                    height={56}
                                    width={"30%"}
                                  />
                                ) : (
                                  <FormControl
                                    id="dominant-groups-dropdown-container"
                                    sx={{ width: "30%" }}
                                    className={getMissedClassName(
                                      "selectedDominantGroup",
                                      missedProjectData,
                                    )}
                                  >
                                    <InputLabel
                                      id="dominant-groups-dropdown-label"
                                      style={inputLabelToResponse}
                                    >
                                      Group
                                    </InputLabel>
                                    <Select
                                      labelId="dominant-groups-dropdown-label"
                                      id="dominant-groups-dropdown"
                                      value={selectedDominantGroup}
                                      label="Group"
                                      disabled={
                                        !selectedDominantType ||
                                        isEditMode ||
                                        selectedDominantGroup === "Default"
                                      }
                                      onChange={selectDominantGroupHandler}
                                      sx={selectComponentToResponse}
                                    >
                                      {occupancyGroups?.length > 0 ? (
                                        occupancyGroups
                                          ?.sort((a, b) => a - b)
                                          .map((el) => (
                                            <MenuItem key={el} value={el}>
                                              {el}
                                            </MenuItem>
                                          ))
                                      ) : (
                                        <MenuItem
                                          key={selectedDominantGroup}
                                          value={selectedDominantGroup}
                                        >
                                          {selectedDominantGroup}
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                )}
                              </div>

                              <React.Fragment>
                                {isLoadingCurrentProject ? (
                                  <Skeleton
                                    variant="rounded"
                                    height={56}
                                    width={"100%"}
                                    sx={{ marginTop: "12px" }}
                                  />
                                ) : (
                                  <Autocomplete
                                    className={getMissedClassName(
                                      "selectedDominantUse",
                                      missedProjectData,
                                    )}
                                    sx={{
                                      marginTop: "12px",
                                      textTransform: "capitalize",
                                      ...selectComponentToResponse,
                                    }}
                                    value={selectedDominantUse}
                                    disabled={
                                      !selectedDominantGroup ||
                                      isEditMode ||
                                      selectedDominantGroup === "Default"
                                    }
                                    onChange={(_, newValue) => {
                                      selectedDominantUseHandler(newValue);
                                    }}
                                    filterOptions={filterDominantUseOptions}
                                    id="dominant-use-select"
                                    options={
                                      projectIconsAndCovers?.length === 0
                                        ? [
                                            {
                                              id: "No options",
                                              label: "No options",
                                            },
                                          ]
                                        : projectIconsAndCovers.sort(
                                            (a, b) =>
                                              -b.label.localeCompare(a.label),
                                          )
                                    }
                                    getOptionDisabled={(option) => {
                                      if (option.id === "No options") {
                                        return true;
                                      }
                                      return false;
                                    }}
                                    getOptionLabel={getOptionLabel}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    renderOption={(props, option) => {
                                      return (
                                        <li
                                          {...props}
                                          key={option.id}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {option.label}
                                        </li>
                                      );
                                    }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Use" />
                                    )}
                                  />
                                )}
                                <Dialog
                                  open={open}
                                  onClose={closeNewOccupancyUseDialogHandler}
                                >
                                  <form
                                    onSubmit={(e) =>
                                      submitNewOccupancyUseHandler(e, "project")
                                    }
                                  >
                                    <DialogTitle>
                                      Add a new occupancy use
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText>
                                        Didn't you find the required occupancy
                                        use? Please, add it!
                                      </DialogContentText>
                                      <TextField
                                        autoFocus
                                        id="name"
                                        fullWidth
                                        value={dialogValue.label}
                                        onChange={(event) => {
                                          setDialogValue({
                                            ...dialogValue,
                                            label: event.target.value,
                                          });
                                        }}
                                        label="Use"
                                        type="text"
                                        variant="outlined"
                                        inputProps={{
                                          style: {
                                            textTransform: "capitalize",
                                          },
                                        }}
                                        sx={{ marginTop: "12px" }}
                                      />
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        onClick={
                                          closeNewOccupancyUseDialogHandler
                                        }
                                      >
                                        Cancel
                                      </Button>
                                      <Button type="submit">Add</Button>
                                    </DialogActions>
                                  </form>
                                </Dialog>
                              </React.Fragment>
                            </div>
                          </>
                        )}

                        {isLoadingProjects || isLoadingCurrentProject ? (
                          <Skeleton
                            variant="rounded"
                            height={24}
                            sx={{ marginTop: "15px", marginBottom: "12px" }}
                          />
                        ) : (
                          <FormControlLabel
                            id="occupy-entire-property_label"
                            value="occupy-entire-property"
                            control={
                              <CheckboxItem
                                checked={isOccupyingEntireProperty}
                                className="order-drawer_cell_checkbox"
                                onChange={isOccupyingEntirePropertyCheckHandler}
                              />
                            }
                            label="Does the project occupy the entire property?"
                            labelPlacement="start"
                            sx={checkBoxLabelToResponse}
                          />
                        )}

                        {isLoadingCurrentProject ? (
                          <Skeleton
                            variant="rounded"
                            height={37}
                            width={155}
                            sx={{ margin: "16px auto 0" }}
                          />
                        ) : (
                          <Tooltip
                            title={
                              !selectedProject
                                ? "Select a project at first"
                                : "Place the drawing on the map, relocate and set the north heading"
                            }
                            placement="right"
                            arrow={true}
                          >
                            <Button
                              id="place-on-map_button"
                              variant="outlined"
                              style={{
                                opacity: selectedProject ? "1" : "0.5",
                                cursor: selectedProject ? "pointer" : "auto",
                              }}
                              startIcon={<LocationSearchingOutlinedIcon />}
                              disableRipple={!selectedProject}
                              onClick={
                                selectedProject ? placeOnMapHandler : null
                              }
                            >
                              <span style={{ marginTop: "3px" }}>
                                LAYOUT LOCATION PLAN
                              </span>
                            </Button>
                          </Tooltip>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )}

                  {isLoadingProjects ? (
                    <Skeleton
                      variant="rounded"
                      height={66}
                      sx={{
                        marginBottom: {
                          lg: "24px !important",
                          xs: "6px !important",
                        },
                      }}
                    />
                  ) : (
                    <Accordion
                      sx={{
                        marginBottom: {
                          lg: "24px !important",
                          xs: "6px !important",
                        },
                        boxShadow:
                          expandedPanel === "property-panel" ? "auto" : "none",
                        "&.Mui-expanded": {
                          margin: "0px",
                        },
                        "& .MuiButtonBase-root-MuiAccordionSummary-root": {
                          minHeight: "56px",
                          padding: "4px 16px",
                        },
                      }}
                      expanded={expandedPanel === "property-panel"}
                      onChange={() => {
                        expandedPanelHandler("property-panel");
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="property-content"
                        id="property-header"
                        disabled={!selectedProject || isBtnLoading}
                        className={`upload-accordion-summary ${
                          !!missedPropertyData.length
                            ? "blinking-accordion-summary"
                            : ""
                        }`}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Typography>Property Details</Typography>
                        <Indicator number={missedPropertyData.length} />
                      </AccordionSummary>

                      <AccordionDetails
                        sx={{
                          padding: isSmallScreen ? "8px 0 0" : " 8px 16px 16px",
                        }}
                      >
                        {isLoadingProjects ? (
                          <Skeleton
                            variant="rounded"
                            height={56}
                            sx={{ marginBottom: "15px" }}
                          />
                        ) : (
                          <>
                            <div className="property-dominant-occupancy">
                              <div className="property-dominant-occupancy-details">
                                {isLoadingCurrentProject ? (
                                  <Skeleton
                                    variant="rounded"
                                    height={56}
                                    width={"68%"}
                                  />
                                ) : isOccupyingEntireProperty ? (
                                  <TextField
                                    type="text"
                                    label="Type"
                                    variant="outlined"
                                    disabled={true}
                                    value={
                                      occupancyTypes.find(
                                        (el) => el.id === selectedDominantType,
                                      ).name
                                    }
                                    fullWidth={true}
                                    sx={{ width: "68%" }}
                                  />
                                ) : (
                                  <FormControl
                                    id="property-dominant-type-dropdown-container"
                                    sx={{ width: "68%" }}
                                    className={getMissedClassName(
                                      "selectedDominantPropertyType",
                                      missedPropertyData,
                                    )}
                                  >
                                    <InputLabel
                                      id="property-dominant-type-dropdown-label"
                                      style={inputLabelToResponse}
                                    >
                                      Type
                                    </InputLabel>
                                    <Select
                                      labelId="property-dominant-type-dropdown-label"
                                      id="property-dominant-type-dropdown"
                                      value={selectedDominantPropertyType}
                                      label="Type"
                                      onChange={
                                        selectDominantPropertyTypeHandler
                                      }
                                      sx={selectComponentToResponse}
                                    >
                                      {occupancyTypes.length !== 0 ? (
                                        occupancyTypes?.map((el) => (
                                          <MenuItem key={el.id} value={el.id}>
                                            {el.name ?? "Default type name"}
                                          </MenuItem>
                                        ))
                                      ) : (
                                        <MenuItem
                                          key={selectedDominantPropertyType}
                                          value={selectedDominantPropertyType}
                                        >
                                          {selectedDominantPropertyType}
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                )}

                                {isLoadingCurrentProject ? (
                                  <Skeleton
                                    variant="rounded"
                                    height={56}
                                    width={"30%"}
                                  />
                                ) : isOccupyingEntireProperty ? (
                                  <TextField
                                    type="text"
                                    variant="outlined"
                                    value={selectedDominantGroup}
                                    disabled={true}
                                    label="Group"
                                    sx={{ width: "30%" }}
                                  />
                                ) : (
                                  <FormControl
                                    id="dominant-groups-dropdown-container"
                                    sx={{ width: "30%" }}
                                    className={getMissedClassName(
                                      "selectedDominantPropertyGroup",
                                      missedPropertyData,
                                    )}
                                  >
                                    <InputLabel
                                      id="dominant-groups-dropdown-label"
                                      style={inputLabelToResponse}
                                    >
                                      Group
                                    </InputLabel>
                                    <Select
                                      labelId="dominant-groups-dropdown-label"
                                      id="dominant-groups-dropdown"
                                      value={selectedDominantPropertyGroup}
                                      label="Group"
                                      disabled={
                                        !selectedDominantPropertyType ||
                                        selectedDominantPropertyGroup ===
                                          "Default"
                                      }
                                      onChange={
                                        selectDominantPropertyGroupHandler
                                      }
                                      sx={selectComponentToResponse}
                                    >
                                      {propertyOccupancyGroups?.length > 0 ? (
                                        propertyOccupancyGroups?.map((el) => (
                                          <MenuItem key={el} value={el}>
                                            {el}
                                          </MenuItem>
                                        ))
                                      ) : (
                                        <MenuItem
                                          key={selectedDominantPropertyGroup}
                                          value={selectedDominantPropertyGroup}
                                        >
                                          {selectedDominantPropertyGroup}
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                )}
                              </div>

                              <React.Fragment>
                                {isLoadingCurrentProject ? (
                                  <Skeleton
                                    variant="rounded"
                                    height={56}
                                    width={"100%"}
                                    sx={{ marginTop: "12px" }}
                                  />
                                ) : isOccupyingEntireProperty ? (
                                  <TextField
                                    label="Use"
                                    type="text"
                                    variant="outlined"
                                    disabled={true}
                                    value={
                                      selectedDominantUse.occupancyUse || ""
                                    }
                                    fullWidth={true}
                                    sx={{ marginTop: "12px" }}
                                  />
                                ) : (
                                  <Autocomplete
                                    sx={{
                                      marginTop: "12px",
                                      textTransform: "capitalize",
                                      ...selectComponentToResponse,
                                    }}
                                    className={getMissedClassName(
                                      "selectedDominantPropertyUse",
                                      missedPropertyData,
                                    )}
                                    value={selectedDominantPropertyUse}
                                    disabled={
                                      !selectedDominantPropertyGroup ||
                                      selectedDominantPropertyGroup ===
                                        "Default"
                                    }
                                    onChange={(_, newValue) => {
                                      selectedDominantPropertyUseHandler(
                                        newValue,
                                      );
                                    }}
                                    filterOptions={filterDominantUseOptions}
                                    id="dominant-use-select"
                                    options={
                                      propertyIconsAndCovers.length === 0
                                        ? [
                                            {
                                              id: "No options",
                                              label: "No options",
                                            },
                                          ]
                                        : propertyIconsAndCovers.sort(
                                            (a, b) =>
                                              -b.label.localeCompare(a.label),
                                          )
                                    }
                                    getOptionDisabled={(option) => {
                                      if (option.id === "No options") {
                                        return true;
                                      }
                                      return false;
                                    }}
                                    getOptionLabel={getOptionLabel}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    renderOption={(props, option) => {
                                      return (
                                        <li
                                          {...props}
                                          key={option.id}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {option.label}
                                        </li>
                                      );
                                    }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Use" />
                                    )}
                                  />
                                )}
                                <Dialog
                                  open={openNewPropertyOccDialog}
                                  onClose={closeNewOccupancyUseDialogHandler}
                                >
                                  <form
                                    onSubmit={(e) =>
                                      submitNewOccupancyUseHandler(
                                        e,
                                        "property",
                                      )
                                    }
                                  >
                                    <DialogTitle>
                                      Add a new occupancy use
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText>
                                        Didn't you find the required occupancy
                                        use? Please, add it!
                                      </DialogContentText>
                                      <TextField
                                        autoFocus
                                        id="name"
                                        fullWidth
                                        value={dialogValue.label}
                                        onChange={(event) => {
                                          setDialogValue({
                                            ...dialogValue,
                                            label: event.target.value,
                                          });
                                        }}
                                        label="Use"
                                        type="text"
                                        variant="outlined"
                                        inputProps={{
                                          style: {
                                            textTransform: "capitalize",
                                          },
                                        }}
                                        sx={{ marginTop: "12px" }}
                                      />
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        onClick={
                                          closeNewOccupancyUseDialogHandler
                                        }
                                      >
                                        Cancel
                                      </Button>
                                      <Button type="submit">Add</Button>
                                    </DialogActions>
                                  </form>
                                </Dialog>
                              </React.Fragment>
                            </div>
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )}

                  <CoverSheetSection
                    isLoading={isLoadingProjects}
                    isDisabled={!selectedProject || isBtnLoading}
                    isExpanded={expandedPanel === "cover-sheet-panel"}
                    missedData={missedCoverSheetData}
                    coverSheetData={coverSheetData}
                    expandedPanelHandler={expandedPanelHandler}
                    removeFromMissedData={removeFromCoverSheetMissedData}
                    addFieldToChange={addFieldToChange}
                    setCoverSheetData={setCoverSheetData}
                  />

                  {isLoadingProjects ? (
                    <Skeleton
                      variant="rounded"
                      height={66}
                      sx={{
                        marginBottom: {
                          lg: "24px !important",
                          xs: "6px !important",
                        },
                      }}
                    />
                  ) : (
                    <Accordion
                      sx={{
                        display:
                          currentDrawing?.type === "coverSheet"
                            ? "none"
                            : "block",
                        marginBottom: {
                          lg: "24px !important",
                          xs: "6px !important",
                        },

                        boxShadow:
                          expandedPanel === "drawing-panel" ? "auto" : "none",
                        "& .MuiButtonBase-root-MuiAccordionSummary-root": {
                          minHeight: "56px",
                          padding: "4px 16px",
                        },
                      }}
                      expanded={expandedPanel === "drawing-panel"}
                      onChange={() => {
                        expandedPanelHandler("drawing-panel");
                      }}
                    >
                      <AccordionSummary
                        disabled={!selectedProject || isBtnLoading}
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="floors-content"
                        id="floors-header"
                        className={`upload-accordion-summary ${
                          !!missedDrawingData.length
                            ? "blinking-accordion-summary"
                            : ""
                        }`}
                      >
                        <Typography>Floor Details</Typography>
                        <Indicator number={missedDrawingData.length} />
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          "& .MuiDialogContent-root": {
                            overflow: "scroll",
                          },
                          padding: isSmallScreen ? "8px 0 0" : " 8px 16px 16px",
                        }}
                      >
                        <div className="drawing-occupancies-container">
                          {drawingOccupancies.map((field, index) => (
                            <OccupancyField
                              key={field.id}
                              index={index}
                              id={field.id}
                              field={field}
                              isLoadingCurrentProject={isLoadingCurrentProject}
                              occupancyTypes={occupancyTypes}
                              allIconsAndCovers={allIconsAndCovers}
                              setIsDirtyOccupancyInput={
                                setIsDirtyOccupancyInput
                              }
                              isEditMode={isEditMode}
                              addFieldToChange={addFieldToChange}
                              isRemoveDisabled={drawingOccupancies.length < 2}
                              removeOccupancyField={removeOccupancyField}
                              setOccupancies={setDrawingOccupancies}
                              localStorageKey="drawingOccupancies"
                              occupancyLabel="Floor"
                              missedData={missedDrawingData}
                              removeFromMissedData={removeFromDrawingMissedData}
                              isAlreadyExisted={isAlreadyExistedOccupancyField}
                            />
                          ))}
                          <p
                            className="add-occupancy_button"
                            onClick={addOccupancyField}
                          >
                            <AddCircleOutlineRoundedIcon
                              fontSize="small"
                              sx={{
                                position: "absolute",
                                left: "0",
                                bottom: "2px",
                              }}
                            />
                            Add Occupancy
                          </p>
                        </div>
                        {isLoadingProjects ? (
                          <Skeleton
                            variant="rounded"
                            height={56}
                            sx={{ marginBottom: "15px" }}
                          />
                        ) : (
                          <ReadOnlyFieldWithTooltip
                            id="stages-dropdown"
                            value={coverSheetData.stage || ""}
                            tooltip="Drawing stage always follows the stage of the cover sheet."
                            label="Stage"
                            style={{ width: "100%", marginBottom: "12px" }}
                          />
                        )}
                        {isLoadingProjects ? (
                          <Skeleton
                            variant="rounded"
                            height={56}
                            sx={{ marginBottom: "15px" }}
                          />
                        ) : (
                          <ReadOnlyFieldWithTooltip
                            id="page-sizes-dropdown"
                            value={coverSheetData.pageSize || ""}
                            tooltip="Drawing page size always follows the stage of the cover sheet."
                            label="Page Size"
                            style={{ width: "100%", marginBottom: "12px" }}
                          />
                        )}
                        {isLoadingProjects ? (
                          <Skeleton
                            variant="rounded"
                            height={56}
                            sx={{ marginBottom: "15px" }}
                          />
                        ) : (
                          <div className="drawing-name-input">
                            <TextField
                              color={isValidDrawingName ? "success" : "error"}
                              id="floor-name-textfield"
                              className={getMissedClassName(
                                "drawingName",
                                missedDrawingData,
                              )}
                              label="Floor Label"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "capitalize" },
                              }}
                              defaultValue={drawingName || currentDrawing?.name}
                              fullWidth={true}
                              onChange={drawingNameHandler}
                              onFocus={() =>
                                setIsDirtyInput({
                                  ...isDirtyInput,
                                  drawingName: false,
                                })
                              }
                              onBlur={(e) =>
                                checkIfValidName(e.target.value, "drawing")
                              }
                              disabled={!selectedProject}
                              autoComplete="off"
                            />
                            <Tooltip
                              title="The floor label designates the specific level of story of the project (Ex. CELLAR, FIRST FLOOR, SECOND FLOOR...)"
                              placement="right"
                              arrow={true}
                            >
                              <HelpOutlineOutlinedIcon
                                sx={{
                                  fill: "rgba(0, 0, 0, 0.5)",
                                  marginLeft: "5px",
                                  fontSize: "1.8rem",
                                  position: "absolute",
                                  right: "12px",
                                }}
                              />
                            </Tooltip>
                            <ValidationTooltip
                              hidden={!isDirtyInput.drawingName}
                              isValid={isValidDrawingName}
                              successMessage="Valid Name"
                              errorMessage={errors.drawingName}
                              style={{ position: "absolute", right: "50px" }}
                            />
                          </div>
                        )}
                        {isLoadingProjects ? (
                          <Skeleton
                            variant="rounded"
                            height={56}
                            sx={{ marginBottom: "15px" }}
                          />
                        ) : (
                          <div className="drawing-nomenclature-input">
                            <TextField
                              id="floor-nomenclature-textfield"
                              className={getMissedClassName(
                                "nomenclature",
                                missedDrawingData,
                              )}
                              label="Floor Nomenclature"
                              variant="outlined"
                              defaultValue={
                                nomenclature || currentDrawing?.nomenclature
                              }
                              onFocus={() =>
                                setIsDirtyInput({
                                  ...isDirtyInput,
                                  nomenclature: false,
                                })
                              }
                              onBlur={(e) =>
                                checkIfValidName(e.target.value, "nomenclature")
                              }
                              disabled={!selectedProject}
                              fullWidth={true}
                              autoComplete="off"
                              onChange={nomenclatureHandler}
                            />

                            <Tooltip
                              title="The floor nomenclature is a short 3 or 4 letter or number that represents the floor label (ex. CEL, 001, 002...)"
                              placement="right"
                              arrow={true}
                            >
                              <HelpOutlineOutlinedIcon
                                sx={{
                                  fill: "rgba(0, 0, 0, 0.5)",
                                  marginLeft: "5px",
                                  fontSize: "1.8rem",
                                  position: "absolute",
                                  right: "12px",
                                }}
                              />
                            </Tooltip>

                            <ValidationTooltip
                              hidden={!isDirtyInput.nomenclature}
                              isValid={isValidNomenclature}
                              successMessage="Valid Nomenclature"
                              errorMessage={errors.nomenclature}
                              style={{ position: "absolute", right: "50px" }}
                            />
                          </div>
                        )}

                        {((!isEditMode && !!pixelsOfDetectedLine) ||
                          !!editedDrawingProps.pixelsOfDetectedLine) && (
                          <section className="detected-info">
                            <span className="detected-info-header">
                              Pixels per {selectedUnit}
                            </span>

                            {isLoadingProjects ? (
                              <Skeleton
                                variant="rounded"
                                height={35}
                                width={"100%"}
                                sx={{ marginTop: "15px" }}
                              />
                            ) : (
                              <div className="real-line-field">
                                <TextField
                                  id="detected-line-width-textfield"
                                  label={`Line width (${selectedUnit})`}
                                  variant="outlined"
                                  size="small"
                                  fullWidth={true}
                                  value={formatNumber(detectedLineWidth)}
                                  className={getMissedClassName(
                                    "detectedLineWidth",
                                    missedDrawingData,
                                  )}
                                  onChange={detectedLineWidthHandler}
                                  autoComplete="off"
                                />

                                <FormControl
                                  id="units-dropdown-container"
                                  sx={{ width: "34%", marginLeft: "14px" }}
                                >
                                  <InputLabel
                                    id="units-dropdown-label"
                                    title={selectedUnit}
                                    style={inputLabelToResponse}
                                  >
                                    Unit
                                  </InputLabel>
                                  <Select
                                    labelId="units-dropdown-label"
                                    id="units-dropdown"
                                    value={selectedUnit}
                                    label="Unit"
                                    onChange={selectUnitHandler}
                                    sx={{ height: "40px" }}
                                  >
                                    {UNITS.map((u) => (
                                      <MenuItem key={u} value={u}>
                                        {u}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            )}
                          </section>
                        )}

                        {isLoadingProjects ? (
                          <Skeleton
                            variant="rounded"
                            width={178.5}
                            height={36.5}
                            sx={{
                              display: pixelsOfDetectedLine ? "none" : "block",
                              margin: "0 auto 0",
                            }}
                          />
                        ) : (
                          <Button
                            id="connect-two-points"
                            className={
                              !pixelsOfDetectedLine
                                ? `connect-two-points ${getMissedClassName(
                                    "detectedLineWidth",
                                    missedDrawingData,
                                  )}`
                                : ""
                            }
                            variant="outlined"
                            disabled={!selectedProject}
                            fullWidth={true}
                            startIcon={<LinearScaleRoundedIcon />}
                            onClick={connectTwoPointsHandler}
                          >
                            <span style={{ marginTop: "3px" }}>
                              {`${
                                isEditMode || pixelsOfDetectedLine ? "Re" : ""
                              }Scale Drawing`}
                            </span>
                            <Tooltip
                              title="Select two points on the drawing to automatically scale the drawing"
                              placement="right"
                              arrow={true}
                            >
                              <HelpOutlineOutlinedIcon
                                sx={{
                                  fill: "rgb(74, 71, 177)",
                                  marginLeft: "5px",
                                  fontSize: "1.8rem",
                                  position: "absolute",
                                  right: "12px",
                                }}
                              />
                            </Tooltip>
                          </Button>
                        )}

                        <div className="select-floor-area-btn-container">
                          <Button
                            id="select-floor-area_btn"
                            variant="outlined"
                            fullWidth={true}
                            sx={
                              !detectedLineWidth &&
                              !currentDrawing?.pixelsPerUnit
                                ? {
                                    opacity: 0.6,
                                    cursor: "auto !important",
                                    "&:hover": {
                                      backgroundColor:
                                        "rgba(74, 71, 177, 0.03) !important",
                                    },
                                  }
                                : {}
                            }
                            disableRipple={
                              !detectedLineWidth &&
                              !currentDrawing?.pixelsPerUnit
                            }
                            startIcon={<FormatShapesRoundedIcon />}
                            onClick={selectFloorBoundariesHandler}
                          >
                            <span style={{ marginTop: "3px" }}>
                              {floorArea > 0 ? "Re" : ""}Select Work Area
                            </span>
                            <Tooltip
                              title={
                                !detectedLineWidth &&
                                !currentDrawing?.pixelsPerUnit
                                  ? "You can't select the area without getting the scale"
                                  : "Outline the work area, complete the polygon to enclose the space, and the area will be calculated automatically"
                              }
                              placement="right"
                              arrow={true}
                            >
                              <HelpOutlineOutlinedIcon
                                sx={{
                                  fill: "rgb(74, 71, 177)",
                                  marginLeft: "5px",
                                  fontSize: "1.8rem",
                                  position: "absolute",
                                  right: "12px",
                                }}
                              />
                            </Tooltip>
                          </Button>
                        </div>
                        <div className="floor-area-container">
                          <div className="work-area-input-container">
                            <TextField
                              label={`Work Area (${selectedAreaUnit})`}
                              id="floor-area-textfield"
                              className={getMissedClassName(
                                "floorArea",
                                missedDrawingData,
                              )}
                              variant="outlined"
                              fullWidth={true}
                              value={formatNumber(floorArea)}
                              onChange={({ target }) =>
                                floorAreaHandler(target.value)
                              }
                              autoComplete="off"
                            />

                            {/* <FormControl
                              id="area-unit-dropdown-container"
                              sx={{
                                width: "20%",
                                height: "40px",
                                position: "absolute",
                                right: "55px",
                              }}
                            >
                              <InputLabel
                                id="area-unit-dropdown-label"
                                style={inputLabelToResponse}
                              >
                                Unit²
                              </InputLabel>

                              <Select
                                labelId="project-dominant-type-dropdown-labarea-unit-dropdown-labelel"
                                id="project-dominant-type-dropdown"
                                value={selectedAreaUnit}
                                disabled={!floorArea}
                                label="Unit²"
                                onChange={selectedAreaUnitHandler}
                                sx={{
                                  ...selectComponentToResponse,
                                  height: "40px",
                                }}
                              >
                                {ALLOWED_SQUARED_UNITS.map((el) => (
                                  <MenuItem key={el} value={el}>
                                    {el}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}

                            <Tooltip
                              title="Total work area selected"
                              placement="right"
                              arrow={true}
                            >
                              <HelpOutlineOutlinedIcon
                                sx={{
                                  fill: "rgba(0, 0, 0, 0.5)",
                                  marginLeft: "5px",
                                  fontSize: "1.8rem",
                                  position: "absolute",
                                  right: "12px",
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>

                        {isLoadingProjects ? (
                          <Skeleton
                            variant="rounded"
                            height={24}
                            sx={{ marginTop: "15px", marginBottom: "12px" }}
                          />
                        ) : (
                          <FormControlLabel
                            id="is-lowest-level_label"
                            value="lowest-level"
                            control={
                              <CheckboxItem
                                checked={isLowestLevel}
                                className="order-drawer_cell_checkbox"
                                onChange={lowestLevelCheckHandler}
                              />
                            }
                            label="Lowest level of fire department vehicle access"
                            labelPlacement="start"
                            sx={checkBoxLabelToResponse}
                          />
                        )}
                        {isLoadingProjects || isLoadingDrawings ? (
                          <Skeleton
                            variant="rounded"
                            height={56}
                            sx={{ marginBottom: "15px" }}
                          />
                        ) : (
                          <div className="drawing-page-no_container">
                            <div className="drawing-page-no-input">
                              <TextField
                                id="page-no-textfield"
                                label="Page No"
                                variant="outlined"
                                size="small"
                                value={pageNo}
                                disabled={true}
                                autoComplete="off"
                                inputProps={{
                                  style: {
                                    textAlign: "center",
                                    WebkitTextFillColor: "rgba(0, 0, 0, 0.63)",
                                  },
                                }}
                                sx={{ width: "30%" }}
                              />
                              <p className="page-no_OF_last">
                                OF {getLastPage()}
                              </p>
                            </div>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )}
                </div>
              </div>
              <section className="buttons-section">
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ marginRight: "16.5px" }}
                  disabled={isBtnLoading || isLoadingProjects}
                  onClick={() => {
                    cancelBtnHandler();
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  ref={submitBtn}
                  disabled={
                    !selectedProject ||
                    isLoadingCurrentProject ||
                    isLoadingProjects ||
                    (isEditMode && !fieldsToChange.length)
                  }
                  onClick={submitBtnHandler}
                  label={isEditMode ? "Save" : "Upload"}
                  classes={["upload-popup-submit-btn"]}
                  isBtnLoading={isBtnLoading}
                  setIsBtnLoading={setIsBtnLoading}
                />
              </section>
            </SwipeableDrawer>
          </Resizable>
        </div>
      )}
      <div
        style={{
          transform: `translate(${
            isOpened ? `${drawerWidth}px` : "0px"
          }, -50%)`,
        }}
        className={`upload-manager-icon ${
          isOpened
            ? "upload-manager-puller_box_open"
            : "upload-manager-puller_box_close"
        }`}
      >
        {location.hash ? <ArticleIcon /> : <BackupIcon />}
      </div>
    </main>
  );
};

export default UploadPopup;
