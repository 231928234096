import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Switcher = ({
  style,
  width = "100%",
  height = "2rem",
  fontSize = "0.875rem",
  color = "#5b57d1",
  margin = "0",
  labels = [],
  value,
  valueHandler,
  disabled,
  onClick,
}) => {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      // disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: color,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#5b57d1",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent={"space-between"}
      width={width}
      height={height}
      margin={margin}
    >
      <Typography fontSize={fontSize}>{labels[0] || ""}</Typography>
      <IOSSwitch
        checked={value}
        sx={{ m: 1, ...style }}
        onChange={(e) => {
          if (disabled) {
            return;
          }
          onClick && onClick();
          valueHandler && valueHandler(e);
        }}
      />
      <Typography fontSize={fontSize}>{labels[1] || ""}</Typography>
    </Stack>
  );
};

export default Switcher;
