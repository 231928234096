// React
import React, { useState } from "react";

// Material
import { TextField } from "@mui/material";
import ConfirmDialog from "../ExcalidrawAPI/components/ConfirmDialog";

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties

// Services

// Styles

// Ad-Hoc Components

/**
 * @name DevDialog
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const DevDialog = ({ onConfirm }) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Form

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <ConfirmDialog
      title="Sign in"
      buttonsPlacement="center"
      onConfirm={() => onConfirm(email, password)}
    >
      <TextField
        id="Email-textfield"
        label="Email"
        variant="outlined"
        value={email}
        onChange={({ target }) => setEmail(target.value)}
        autoComplete="off"
        sx={{
          width: "100%",
          marginBottom: "16px",
        }}
      />
      <TextField
        id="Password-textfield"
        label="Password"
        variant="outlined"
        type="password"
        value={password}
        onChange={({ target }) => setPassword(target.value)}
        autoComplete="off"
        sx={{
          width: "100%",
          marginBottom: "16px",
        }}
      />
    </ConfirmDialog>
  );
};

export default DevDialog;
