const ExportIcon = ({ width, height, color }) => {
  //Render
  return (
    <p style={{ width, height }}>
      <svg
        aria-hidden="true"
        focusable="false"
        role="img"
        viewBox="0 0 24 24"
        className=""
        fill="none"
        strokeWidth="2"
        stroke={color || "currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g strokeWidth="1.25">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M15 8h.01"></path>
          <path d="M12 20h-5a3 3 0 0 1 -3 -3v-10a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v5"></path>
          <path d="M4 15l4 -4c.928 -.893 2.072 -.893 3 0l4 4"></path>
          <path d="M14 14l1 -1c.617 -.593 1.328 -.793 2.009 -.598"></path>
          <path d="M19 16v6"></path>
          <path d="M22 19l-3 3l-3 -3"></path>
        </g>
      </svg>
    </p>
  );
};

export default ExportIcon;
