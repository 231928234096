import firebase from "firebase/app";

const secondaryAppConfig = {
  apiKey: process.env.REACT_APP_SECONDARY_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_SECONDARY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_SECONDARY_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_SECONDARY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_SECONDARY_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_SECONDARY_FIREBASE_APP_ID,
};
const secondary = firebase.initializeApp(secondaryAppConfig, "secondary");

export default secondary;
