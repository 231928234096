import { getFontString } from "../ExcalidrawAPI/utils";
import { measureText } from "../ExcalidrawAPI/element/textElement";
import { getHeaderOrFooter } from "./iconsAndCovers";

export const preventTextWrapping = (
  textElement,
  containerElement,
  decrement = 0.25,
) => {
  if (!containerElement) {
    return;
  }
  const maxWidth = 0.95 * containerElement?.width;
  const textFont = getFontString(textElement);
  const textElementWidth = measureText(
    textElement.originalText,
    textFont,
    containerElement.width,
  ).width;

  if (textElementWidth > maxWidth) {
    let textElementSize = textElement.fontSize;
    let newTextWidth = measureText(
      textElement.originalText,
      textElementSize,
    ).width;
    let newTextFont = "";
    do {
      textElementSize -= decrement;
      Object.assign(textElement, { fontSize: textElementSize });
      newTextFont = getFontString(textElement);
      newTextWidth = measureText(textElement.originalText, newTextFont).width;
    } while (newTextWidth > maxWidth);
  }

  return { textElement, rectElement: containerElement };
};

export const getUpdateTimeElement = () => {
  const date = new Date(Date.now());
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return { valueFor: "DATE UPDATED:", value: formattedDate };
};

export const getUsedHeader = async () => {
  const allHeaderAndFooterSettings = await getHeaderOrFooter();
  const {
    footerCopyrightStartYear,
    manualLastYear,
    footerCopyrightLastYear,
    footerCopyright,
    footerWebsite,
  } = allHeaderAndFooterSettings;

  const header = `© ${
    footerCopyrightStartYear ? footerCopyrightStartYear : "[Start Year]"
  }-${
    manualLastYear
      ? footerCopyrightLastYear
        ? footerCopyrightLastYear
        : new Date().getFullYear()
      : new Date().getFullYear()
  } ${
    footerCopyright ? footerCopyright : "EZ FIRE. ALL RIGHTS RESERVED"
  } HTTPS://${footerWebsite}
    `;

  return header;
};

export const getFirstLettersFromCurrentUser = (currentUser, allUsers) => {
  let user = currentUser;
  if (allUsers) {
    user = allUsers.find((el) => el.authId === currentUser);
  }
  if (!user) {
    return "";
  }
  return `${user.fn.substring(0, 1).toUpperCase()}${user.ln
    .substring(0, 1)
    .toUpperCase()}`;
};

export const convertDesignersObjToText = (designers, allUsers) => {
  const usersToUse = [];
  for (const d of designers) {
    const user = allUsers.find((el) => el.authId === d);
    usersToUse.push(getFirstLettersFromCurrentUser(user));
  }
  return `${usersToUse.join("/")}`;
};

export const getUnwrappedText = (textElement, container, fontSize) => {
  const textFont = getFontString(textElement);
  const textWidth = measureText(
    textElement.originalText,
    textFont,
    container.width,
  ).width;

  if (textWidth > 0.95 * container.width) {
    let textFontSize = fontSize;
    let newTextWidth = measureText(
      textElement.originalText,
      textFontSize,
    ).width;
    let newTextFont = "";
    do {
      textFontSize -= 0.25;
      Object.assign(textElement, { fontSize: textFontSize });
      newTextFont = getFontString(textElement);
      newTextWidth = measureText(textElement.originalText, newTextFont).width;
    } while (newTextWidth > 0.95 * container.width);
  }

  return textElement;
};

export const extractFromObjectChange = (change) => {
  if (change.valueFor === "project") {
    return [
      {
        valueFor: "BLOCK NO:",
        value: change.value.block,
      },
      {
        valueFor: "LOT NO:",
        value: change.value.lot,
      },
      {
        valueFor: "PROJECT NAME:",
        value: change.value.projectName,
      },
      {
        valueFor: "PROJECT ADDRESS:",
        value: change.value.address,
      },
      {
        valueFor: "PROJECT NO:",
        value: change.value.id,
      },
    ];
  }
};

export const getMappedChange = (change, users) => {
  const { value, valueFor } = change;
  if (valueFor === "project") {
    return extractFromObjectChange(change);
  } else if (valueFor === "isLowestLevel") {
    return {
      ...change,
      value: value ? "(LOWEST LEVEL OF FIRE DEPT. VEHICLE ACCESS)" : "",
    };
  } else if (valueFor === "drawingOccupancies") {
    const occupancyGroups = value.map((occupancy) => occupancy.group);
    const occupancyText = Array.from(new Set(occupancyGroups)).join(" / ");
    return {
      ...change,
      value: `(OCCUPANCY = ${occupancyText})`,
    };
  } else if (valueFor === "DESIGNED BY:") {
    const designerAsText = convertDesignersObjToText(change.value, users);
    return {
      ...change,
      value: designerAsText,
    };
  }
  return {
    ...change,
    valueFor: `${valueFor.toUpperCase()}:`,
  };
};

export const renderImageElement = ({
  excalidrawAPI,
  url,
  imageProps,
  customData,
}) => {
  const imagePropsToUse = {
    width: imageProps.width,
    height: imageProps.height,
  };

  const imageElement = excalidrawAPI.createImageElement({
    sceneX: imageProps.offsetX,
    sceneY: imageProps.offsetY,
  });

  Object.assign(imageElement, {
    fileId: url,
    customData: { type: "vertical-ruler", ...customData },
    width: imagePropsToUse.width,
    height: imagePropsToUse.height,
    locked: imageProps.locked ?? true,
    angle: imageProps.angle ?? 0,
  });

  return imageElement;
};
