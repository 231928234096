import { useAtom } from "jotai";
import { excalidrawApiAtom } from "../store/variables";
import { randomId, randomInteger } from "../ExcalidrawAPI/random";
import { getUpdatedTimestamp } from "../ExcalidrawAPI/utils";
import { useCommonFunctionsHook } from "./useCommonFunctionsHook";
import { mutateElement } from "../ExcalidrawAPI/element/mutateElement";

const basicEl = {
  angle: 0,
  backgroundColor: "transparent",
  boundElements: [],
  customData: { isImmutable: true },
  fillStyle: "solid",
  isDeleted: false,
  link: null,
  locked: true,
  opacity: 100,
  roughness: 0,
  roundness: null,
  strokeColor: "#000000",
  strokeStyle: "solid",
  strokeWidth: 1,
  seed: randomInteger(),
  version: randomInteger(),
  versionNonce: randomInteger(),
  updated: getUpdatedTimestamp(),
};

export const useElementMethods = () => {
  //Custom Hooks

  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);

  //State

  //Functions
  const createRectElement = () => {
    const newId = randomId();
    return mutateElement(basicEl, { id: newId, type: "rectangle" });
  };

  //Return
  return { createRectElement, basicEl };
};
