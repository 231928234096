import React from "react";

import { Skeleton, TextField } from "@mui/material";

const SkeletonTextField = ({
  value,
  classes,
  label,
  textFieldStyle,
  skeletonStyle,
  isLoading,
}) => {
  //Functions
  const getFieldId = () => {
    return `${label.toLowerCase().split(" ").join("-")}-textfield`;
  };

  //Render
  return isLoading ? (
    <Skeleton
      variant="rounded"
      height={56}
      sx={{ ...skeletonStyle, marginBottom: textFieldStyle.marginBottom }}
    />
  ) : (
    <TextField
      title={value}
      id={getFieldId()}
      label={label}
      variant="outlined"
      value={value}
      disabled={true}
      className={classes.disabledTextFieldOfCodes}
      autoComplete="off"
      sx={textFieldStyle}
    />
  );
};

export default SkeletonTextField;
