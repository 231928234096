import React from "react";

import WelcomeScreen from "../ExcalidrawAPI/components/welcome-screen/WelcomeScreen";
import AnimatedWelcomeScreen from "./animatedSVGs/AnimatedWelcomeScreen";
import staticWelcomeScreen from "../assets/icon_FiCAD_welcome.png";
import "../scss/AnimatedWelcomeScreen.scss";

const CustomWelcomeScreen = ({ isLoading }) => {
  return (
    <>
      <WelcomeScreen.Center.Logo>
        <div className="animated_welcome_screen_container">
          {isLoading ? (
            <div style={{ width: "25rem", background: "#fff" }}>
              <AnimatedWelcomeScreen />
            </div>
          ) : (
            <img
              src={staticWelcomeScreen}
              alt="welcome screen"
              style={{ width: "20rem" }}
            />
          )}
        </div>
      </WelcomeScreen.Center.Logo>
      <WelcomeScreen.Center.Heading></WelcomeScreen.Center.Heading>
      <WelcomeScreen.Center.Menu></WelcomeScreen.Center.Menu>
    </>
  );
};

export default CustomWelcomeScreen;
