import React, { useEffect, useRef, useState } from "react";
import { SketchPicker, HuePicker } from "react-color";
import { useAtom } from "jotai";

import {
  mutateElement,
  newElementWith,
} from "../ExcalidrawAPI/element/mutateElement";
import { CheckboxItem } from "../ExcalidrawAPI/components/CheckboxItem";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Button,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardDoubleArrowUpOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowUpOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ColorLensRoundedIcon from "@mui/icons-material/ColorLensRounded";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import NearMeRoundedIcon from "@mui/icons-material/NearMeRounded";
import FormatSizeRoundedIcon from "@mui/icons-material/FormatSizeRounded";

import FloatingSaveDiscard from "./FloatingSaveDiscard";
import ReadOnlyFieldWithTooltip from "./ReadOnlyFieldWithTooltip";
import TextFieldWithUnit from "./TextFieldWithUnit";
import { recentAddedAreaAtom } from "./AreasSlider";
import ValueSlider from "./ValueSlider";

import {
  currentDrawingAtom,
  excalidrawApiAtom,
  iconsAndCoversAtom,
  isSelectingPartAreaAtom,
  occupancyTypesAtom,
} from "../store/variables";
import { createOrUpdateArea, deleteArea } from "../helpers/areas";
import { getFromLocalStorage, setToLocalStorage } from "../helpers/common";
import { AREA_OCCUPANCY_GROUPS, MENU_PROPS } from "../helpers/constants";
import { deleteDevicesByBatch } from "../helpers/devices";
import { useCommonFunctionsHook } from "../hooks/useCommonFunctionsHook";
import { useAreaMethods } from "../hooks/useAreaMethods";

import "../scss/AreaField.scss";

const AreaField = ({
  area,
  isExpanded,
  isSelected,
  setSelectedAreas,
  setSaveDiscardShown,
  setNewAreas,
  setIsParentOpen,
  setCustomLoaderShown,
  expandedToggler,
}) => {
  //Persisted Data
  const defaultValues =
    getFromLocalStorage("appCustomData")?.updatedAreas?.find(
      (a) => a.id === area.id,
    ) ?? {};
  const DEFAULT_VALUES = {
    AREA_LABEL: defaultValues.label || "",
    AREA_TYPE: defaultValues.areaType || "",
    ELEVATION: defaultValues.elevation || null,
    DEPTH: defaultValues.depth || null,
    WIDTH: defaultValues.width || null,
    HEIGHT: defaultValues.height || null,
    OCCUPANCY_GROUP: defaultValues.occupancyGroup || "",
    BACKGROUND_COLOR: defaultValues.backgroundColor || "",
  };

  //Custom Hooks
  const { doAfterLoadingScene, triggerToast } = useCommonFunctionsHook();
  const { addOrUpdateAreaLabel, deleteAreaElementsByIds } = useAreaMethods();

  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [, setRecentAddedArea] = useAtom(recentAddedAreaAtom);
  const [, setIsSelectingPartArea] = useAtom(isSelectingPartAreaAtom);
  const [currentDrawing] = useAtom(currentDrawingAtom);
  const [iconsAndCovers] = useAtom(iconsAndCoversAtom);
  const [occupancyTypes] = useAtom(occupancyTypesAtom);

  //State
  const [areaObject, setAreaObject] = useState({
    areaLabel: DEFAULT_VALUES.AREA_LABEL || area.label || "",
    areaType: DEFAULT_VALUES.AREA_TYPE || area.areaType || "",
    elevation: DEFAULT_VALUES.ELEVATION || {
      value: area.elevation?.value || "",
      unit: "ft",
    },
    depth: DEFAULT_VALUES.DEPTH || {
      value: area.depth?.value || "",
      unit: "ft",
    },
    width: DEFAULT_VALUES.WIDTH || {
      value: area.width?.value || area.width || "",
      unit: "ft",
    },
    height: DEFAULT_VALUES.HEIGHT || {
      value: area.height?.value || area.height || "",
      unit: "ft",
    },
    occupancyGroup: DEFAULT_VALUES.OCCUPANCY_GROUP || area.occupancyGroup || "",
    backgroundColor:
      DEFAULT_VALUES.BACKGROUND_COLOR || area.backgroundColor || "​#faab4b",
  });
  const [areaUnit] = useState("SFT");
  const [labelFontSize, setLabelFontSize] = useState(15);
  const [labelColor, setLabelColor] = useState("#000");
  const [isLabelSettingsSpread, setIsLabelSettingsSpread] = useState(false);
  const [isFloatingSaveDiscard, setIsFloatingSaveDiscard] = useState(
    area.isChanged,
  );
  const [isColorPickerShown, setIsColorPickerShown] = useState(false);
  const [isActiveSelectBtn] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  //constants

  //Refs
  const colorsComponentRef = useRef(null);

  //Effects
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (excalidrawAPI) {
      doAfterLoadingScene(() => {
        const labelSceneEl = excalidrawAPI
          .getSceneElements()
          .find((el) => el.type === "text" && el.customData?.areaId);

        setLabelFontSize(labelSceneEl?.fontSize || 15);
        setLabelColor(labelSceneEl?.strokeColor || "#000");
      });
    }
  }, [excalidrawAPI]);

  //Functions
  const handleClickOutside = (event) => {
    const parentButton = document.getElementById(
      `area-field-cell-action_${area.id}`,
    );
    if (
      event.target.closest(`#area-field-cell-action_${area.id}`) ===
      parentButton
    ) {
      setIsColorPickerShown((prevStatus) => !prevStatus);
    } else if (
      colorsComponentRef.current &&
      !colorsComponentRef.current.contains(event.target)
    ) {
      setIsColorPickerShown(false);
    }
  };

  const selectAreaHandler = (status, e) => {
    e.stopPropagation();
    setSelectedAreas((prevSelectedAreas) => {
      if (status) {
        return [...prevSelectedAreas, area.id];
      }
      return prevSelectedAreas.filter((el) => el !== area.id);
    });
  };

  const renderActionsMenu = () => (
    <div className="area-field-cell-actions">
      <Tooltip title="Select" placement="right" arrow={true}>
        <button
          className="area-field-cell-action"
          onClick={selectPartAreaHandler}
        >
          {isActiveSelectBtn ? (
            <NearMeRoundedIcon className="area-field-cell-action_start_icon" />
          ) : (
            <NearMeOutlinedIcon className="area-field-cell-action_start_icon" />
          )}
          <NearMeRoundedIcon className="area-field-cell-action_end_icon" />
        </button>
      </Tooltip>

      <Tooltip title="Color" placement="right" arrow={true}>
        <div className="area-field-pick_color_action">
          <button
            id={`area-field-cell-action_${area.id}`}
            className="area-field-cell-action"
          >
            {isColorPickerShown ? (
              <ColorLensRoundedIcon className="area-field-cell-action_start_icon" />
            ) : (
              <ColorLensOutlinedIcon className="area-field-cell-action_start_icon" />
            )}
            <ColorLensRoundedIcon
              className={`area-field-cell-action_end_icon${
                isColorPickerShown ? "" : ""
              }`}
            />
          </button>
          {isColorPickerShown && (
            <div className="area-field-color_picker" ref={colorsComponentRef}>
              <SketchPicker
                color={areaObject.backgroundColor}
                onChange={applyBackgroundToArea}
              />
            </div>
          )}
        </div>
      </Tooltip>
      <Tooltip title="Delete" placement="right" arrow={true}>
        <button
          className="area-field-cell-action area-field-cell-action_delete"
          onClick={deleteAreaCellHandler}
        >
          <DeleteOutlineRoundedIcon
            className="area-field-cell-action_start_icon"
            color="error"
          />
          <DeleteIcon
            className="area-field-cell-action_end_icon"
            color="error"
          />
        </button>
      </Tooltip>
    </div>
  );

  const selectPartAreaHandler = (isToSelect = true) => {
    const areaDevices = excalidrawAPI
      .getSceneElements()
      .filter((el) => el.customData?.areaId === area.id);
    const devicesAsSelectedElements = areaDevices.reduce((acc, d) => {
      acc[d.id] = true;
      return acc;
    }, {});
    excalidrawAPI.updateScene({
      elements: [
        ...excalidrawAPI.getSceneElements().map((el) => {
          if (el.id === area.id) {
            return mutateElement(el, { locked: false });
          }
          return el;
        }),
      ],
      appState: {
        ...(isToSelect
          ? {
              selectedElementIds: {
                [area.id]: true,
                ...devicesAsSelectedElements,
              },
            }
          : { selectedElementIds: null, editingElement: null }),
      },
    });
    setIsParentOpen(!isToSelect);
    setSaveDiscardShown("areaField");
    setIsSelectingPartArea(true);
    setToLocalStorage(
      "appCustomData",
      { selectedPartArea: area.id },
      { merge: true },
    );
  };

  const deleteAreaCellHandler = async () => {
    try {
      setIsSaving(true);
      const assignedDevicesArray = excalidrawAPI
        .getSceneElements()
        .filter((el) => el.customData?.areaId === area.id);
      const assignedDevices = assignedDevicesArray.length
        ? assignedDevicesArray.map((d) => d.customData?.docId)
        : null;
      if (!area.isNew) {
        await deleteArea(area);
      } else {
        setNewAreas((prevAreas) => {
          deleteAreaElementsByIds([area.id, ...assignedDevices]);
          return prevAreas.filter((el) => el.id !== area.id);
        });
      }
      assignedDevices?.length && (await deleteDevicesByBatch(assignedDevices));
      triggerToast("Deleted area cell", "success");
      setIsSaving(false);
    } catch (error) {
      triggerToast("Couldn't delete the cell", "error");
      setIsSaving(false);
      console.error(error);
    }
  };

  const labelFontSizeHandler = (ev) => {
    excalidrawAPI.updateScene({
      elements: excalidrawAPI.getSceneElements().map((el) => {
        if (el.type === "text" && el.customData?.areaId) {
          return newElementWith(el, {
            fontSize: ev.target.value,
          });
        }
        return el;
      }),
    });
    setLabelFontSize(ev.target.value);
  };

  const labelColorHandler = (color) => {
    excalidrawAPI.updateScene({
      elements: excalidrawAPI.getSceneElements().map((el) => {
        if (el.type === "text" && el.customData?.areaId) {
          return newElementWith(el, {
            strokeColor: color.hex,
          });
        }
        return el;
      }),
    });
    setLabelColor(color.hex);
  };

  const repositionLabelHandler = () => {
    setIsParentOpen(false);
    const allSceneElements = excalidrawAPI.getSceneElements();
    const labelSceneEl = allSceneElements.find(
      (el) => el.type === "text" && area.id === el.customData?.areaId,
    );
    excalidrawAPI.updateScene({
      elements: allSceneElements.map((el) => {
        if (el.id === labelSceneEl.id) {
          mutateElement(el, { locked: false });
          return el;
        }
        return el;
      }),
      appState: {
        selectedElementIds: { [labelSceneEl.id]: true },
      },
    });
  };

  const updateAreasInLocalStorage = (key, value) => {
    const areasInLocalStorage =
      getFromLocalStorage("appCustomData")?.updatedAreas || [];
    if (
      !areasInLocalStorage.length ||
      !areasInLocalStorage.find((a) => a.id === area.id)
    ) {
      areasInLocalStorage.push(area);
    }
    const updatedAreas = areasInLocalStorage.map((a) => {
      if (a.id === area.id) {
        return { ...a, [key]: value, isChanged: true };
      }
      return a;
    });
    setToLocalStorage("appCustomData", { updatedAreas }, { merge: true });
  };

  const areaLabelHandler = (e) => {
    setAreaObject((prevArea) => ({ ...prevArea, areaLabel: e.target.value }));
    // setAreaLabel(e.target.value);
    setIsFloatingSaveDiscard(true);
    updateAreasInLocalStorage("label", e.target.value);
  };

  const unitChangeHandler = (e) => {
    const conversionFactor = 3.28; //to convert from ft to m.
    let newValue = {};
    const multiplier =
      e.target.value === "M" ? 1 / conversionFactor : conversionFactor;
    setAreaObject((prevValue) => {
      newValue = {
        value: prevValue[e.target.name].value * multiplier,
        unit: e.target.value,
      };
      return { ...prevValue, [e.target.name]: newValue };
    });
    updateAreasInLocalStorage(e.target.name.toLowerCase(), newValue);
  };

  const areaElevationHandler = (e) => {
    let newValue = {};
    setAreaObject((prevArea) => {
      newValue = { ...prevArea.elevation, value: e.target.value };
      return {
        ...prevArea,
        elevation: { ...prevArea.elevation, value: e.target.value },
      };
    });

    setIsFloatingSaveDiscard(true);
    updateAreasInLocalStorage(e.target.name.toLowerCase(), newValue);
  };

  const areaDepthHandler = (e) => {
    let newValue = {};
    setAreaObject((prevArea) => {
      newValue = { ...prevArea.depth, value: e.target.value };
      return {
        ...prevArea,
        depth: { ...prevArea.depth, value: e.target.value },
      };
    });
    setIsFloatingSaveDiscard(true);
    updateAreasInLocalStorage(e.target.name.toLowerCase(), newValue);
  };

  const occupancyGroupHandler = (value) => {
    setAreaObject((prevArea) => ({
      ...prevArea,
      occupancyGroup: value,
    }));
    setIsFloatingSaveDiscard(true);
    updateAreasInLocalStorage("occupancyGroup", value);
  };

  const convertToFt = (dim) => {
    if (areaObject[dim].unit !== "ft") {
      return { value: areaObject[dim].value * 3.28, unit: "ft" };
    }
    return areaObject[dim];
  };

  const addNewAreaHandler = async () => {
    setIsSaving(true);
    setCustomLoaderShown(true);
    //1. Update area details.
    const updatedArea = {
      ...area,
      label: areaObject.areaLabel,
      areaType: areaObject.areaType,
      elevation: convertToFt("elevation"),
      depth: convertToFt("depth"),
      occupancyGroup: areaObject.occupancyGroup,
      backgroundColor: areaObject.backgroundColor,
    };
    delete updatedArea.isChanged;
    try {
      //2. Add new area to the DB.
      const uploadedArea = await createOrUpdateArea(updatedArea, !area.isNew);
      setNewAreas((prevAreas) => {
        const newAreas = prevAreas.filter((el) => el.id !== uploadedArea.id);
        setToLocalStorage("appCustomData", { newAreas }, { merge: true });
        return newAreas;
      });
      addOrUpdateAreaLabel(areaObject.areaLabel, area.id, !area.isNew);
      triggerToast("Added part area successfully!", "success");
      setRecentAddedArea(null);
      setIsFloatingSaveDiscard(false);
      setIsSaving(false);
      setCustomLoaderShown(false);
    } catch (error) {
      triggerToast("Failed to add new area!", "error");
      setIsFloatingSaveDiscard(false);
      setIsSaving(false);
      setCustomLoaderShown(false);
      console.error(error);
    }
  };

  const discardAllChanges = () => {
    setAreaObject((prevArea) => ({
      ...prevArea,
      areaLabel: area.label,
      areaType: area.areaType,
      depth: area.depth,
      elevation: area.elevation,
      occupancyGroup: area.occupancyGroup,
    }));

    applyBackgroundToArea({ hex: area.backgroundColor });
    setIsFloatingSaveDiscard(false);
  };

  const backgroundColorHandler = (color) => {
    setIsFloatingSaveDiscard(true);
    setAreaObject((prevArea) => ({ ...prevArea, backgroundColor: color.hex }));

    setNewAreas((prevAreas) => {
      const updatedAreas = prevAreas.map((el) => {
        if (el.id === area.id) {
          return {
            ...el,
            strokeColor: color.hex,
            backgroundColor: color.hex,
          };
        }
        return el;
      });
      return updatedAreas;
    });

    updateAreasInLocalStorage("backgroundColor", color.hex);
  };

  const applyBackgroundToArea = (color) => {
    backgroundColorHandler(color);
    excalidrawAPI.updateScene({
      elements: [
        ...excalidrawAPI.getSceneElements().map((el) => {
          if (el.id === area.id) {
            return newElementWith(el, {
              backgroundColor: color.hex,
              strokeColor: color.hex,
            });
          }
          return el;
        }),
      ],
    });
  };

  const getOccupancyTypeAsText = (typeId) => {
    const foundOccupancyType =
      occupancyTypes?.find((el) => el.id === typeId) || [];
    return foundOccupancyType.name || "";
  };

  const getOccupancyGroupAsText = () => {
    if (!currentDrawing?.id || currentDrawing?.type === "coverSheet") {
      return "";
    }
    const currentOccupancyObjects =
      iconsAndCovers?.filter((el) =>
        currentDrawing.occupancyIds.includes(el.id),
      ) || [];
    const mappedCurrentOccupancyObjects = currentOccupancyObjects.map(
      (el) =>
        `${el.occupancyGroup.replace(/-/g, "")} - ${getOccupancyTypeAsText(
          el.occupancyTypeId,
        )}`,
    );

    return mappedCurrentOccupancyObjects.join("/");
  };

  //Return
  return (
    <div className="area-field-main-container">
      <Accordion
        id="area-field-accordion"
        expanded={isExpanded}
        sx={{
          border: area.isNew ? "1px solid #f7931e" : "none",
        }}
        onChange={() => {
          expandedToggler(area.id);
        }}
      >
        <AccordionSummary
          id="area-field-accordion-summary"
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="area-content"
          sx={{
            backgroundColor: isSelected ? "#e3e2fe" : "#fff",
            width: "100%",
          }}
        >
          <CheckboxItem
            id="cell-checkbox"
            title="Select"
            checked={isSelected}
            className="area-field-select-box"
            onChange={selectAreaHandler}
          />
          <span
            className="area-field-color-circle"
            style={{
              backgroundColor: areaObject.backgroundColor,
              ...(isSelected ? { display: "none" } : {}),
            }}
          ></span>
          <span
            style={{
              color: area.isNew && !areaObject.areaLabel ? "#f7931e" : "#222",
            }}
          >
            {area.isNew && !areaObject.areaLabel
              ? "New area"
              : areaObject.areaLabel}{" "}
            <span style={{ color: "#f7931e" }}>
              {area.isNew ? "(UNSAVED)" : ""}
            </span>
          </span>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "16px" }}>
          <section className="area-field-container">
            <div className="area-field-content">
              <div className="area-field-label-settings">
                <TextField
                  id={`area-field-textfield`}
                  label="Label"
                  size="small"
                  variant="outlined"
                  value={`${areaObject.areaLabel}`}
                  fullWidth={true}
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    },
                  }}
                  onChange={areaLabelHandler}
                />
                <Button
                  className={`area-field-label-settings_content_spread_btn ${
                    isLabelSettingsSpread ? "active_spread_btn" : ""
                  }`}
                  variant="outlined"
                  fullWidth={true}
                  onClick={() =>
                    setIsLabelSettingsSpread((prevStatus) => !prevStatus)
                  }
                >
                  <KeyboardDoubleArrowUpOutlinedIcon />
                </Button>

                <div
                  className={`area-field-label-settings_content ${
                    isLabelSettingsSpread ? "spread" : ""
                  }`}
                >
                  <ValueSlider
                    label="Area label size"
                    value={Number(labelFontSize)}
                    disabled={!areaObject.areaLabel}
                    icon={<FormatSizeRoundedIcon />}
                    step={1}
                    min={15}
                    max={35}
                    classes={["area-field-value-slider_fontsize"]}
                    onChange={labelFontSizeHandler}
                  />

                  <div className="area-field-value_color">
                    <HuePicker
                      width="100%"
                      color={labelColor}
                      onChange={labelColorHandler}
                    />
                  </div>

                  <Button
                    id="area-field-label-settings_reposition_btn"
                    className="area-field-label-settings_reposition_btn"
                    variant="outlined"
                    fullWidth={true}
                    onClick={repositionLabelHandler}
                  >
                    <p className="area-field-label-settings_reposition_btn_start">
                      Reposition
                    </p>
                    <p className="area-field-label-settings_reposition_btn_end">
                      <NearMeRoundedIcon />
                    </p>
                  </Button>
                </div>
              </div>

              <FormControl
                id="area-field-occupancies-container"
                sx={{ marginBottom: "10px" }}
                fullWidth={true}
              >
                <InputLabel id="area-field-occupancies-dropdown-label">
                  Use
                </InputLabel>
                <Select
                  labelId="area-field-occupancies-dropdown-label"
                  id="area-field-occupancies-dropdown"
                  value={areaObject.occupancyGroup}
                  label="Use"
                  MenuProps={MENU_PROPS}
                  onChange={(e) => occupancyGroupHandler(e.target.value)}
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {AREA_OCCUPANCY_GROUPS?.sort(
                    (a, b) => -b.localeCompare(a),
                  ).map((o) => (
                    <MenuItem key={o} value={o}>
                      {o}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <ReadOnlyFieldWithTooltip
                id="area-field-area-textfield"
                value={getOccupancyGroupAsText()}
                tooltip="It follows the occupancy of the page"
                label="Occupancy Group"
                inputSize="small"
                style={{ width: "100%", border: "none", marginBottom: "10px" }}
                iconStyle={{ marginBottom: "8px" }}
              />

              <ReadOnlyFieldWithTooltip
                id="area-field-area-textfield"
                value={
                  excalidrawAPI
                    .getSceneElements()
                    .filter(
                      (el) =>
                        el.customData?.imageType === "device" &&
                        el.customData?.areaId === area.id,
                    ).length
                }
                tooltip="Automatically calculated"
                label="Number of devices"
                inputSize="small"
                style={{ width: "100%", border: "none" }}
                iconStyle={{ marginBottom: "0px" }}
              />

              <div className="area-field-dimensions-container">
                <div className="area-field-dimensions-row">
                  <TextFieldWithUnit
                    width="49%"
                    textValue={areaObject.elevation.value}
                    mainLabel="Elevation"
                    unitLabel="Unit"
                    selectedUnit={areaObject.elevation.unit.toLowerCase()}
                    unitOptions={["ft", "m"]}
                    inputType="number"
                    inputSize="medium"
                    onChange={areaElevationHandler}
                    onUnitChange={unitChangeHandler}
                  />
                  <TextFieldWithUnit
                    width="49%"
                    textValue={areaObject.depth.value}
                    mainLabel="Depth"
                    unitLabel="Unit"
                    selectedUnit={areaObject.depth.unit}
                    unitOptions={["ft", "m"]}
                    inputType="number"
                    inputSize="medium"
                    onChange={areaDepthHandler}
                    onUnitChange={unitChangeHandler}
                  />
                </div>
                <div className="area-field-dimensions-row">
                  <TextFieldWithUnit
                    width="49%"
                    textValue={areaObject.width.value}
                    mainLabel="Width"
                    unitLabel="Unit"
                    disabled={true}
                    selectedUnit={areaObject.width.unit}
                    unitOptions={["ft", "m"]}
                    inputType="number"
                    inputSize="medium"
                    onUnitChange={unitChangeHandler}
                  />
                  <TextFieldWithUnit
                    width="49%"
                    textValue={areaObject.height.value}
                    mainLabel="Height"
                    unitLabel="Unit"
                    disabled={true}
                    selectedUnit={areaObject.height.unit}
                    unitOptions={["ft", "m"]}
                    inputType="number"
                    inputSize="medium"
                    onUnitChange={unitChangeHandler}
                  />
                </div>
              </div>
              <ReadOnlyFieldWithTooltip
                id="area-field-area-textfield"
                value={area.area}
                tooltip="Automatically calculated"
                label={`Area (${areaUnit})`}
                inputSize="small"
                style={{ width: "100%", border: "none", marginBottom: "10px" }}
              />
            </div>

            {renderActionsMenu()}
          </section>

          <FloatingSaveDiscard
            classes={["area-field-floating-save-discard"]}
            buttonToShow={area.isNew ? "save" : "both"}
            isShown={isFloatingSaveDiscard || area.isNew}
            isLoading={isSaving}
            onSave={addNewAreaHandler}
            onDiscard={discardAllChanges}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AreaField;
