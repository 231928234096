import React from "react";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { styled } from "@mui/system";

const DevicesSidebarDropdown = ({
  options,
  isDisabled,
  label,
  value,
  defaultValueId = null,
  isLoading,
  onChange,
}) => {
  //Sub-Components
  const GroupHeader = styled("div")(() => ({
    position: "sticky",
    top: "-8px",
    padding: "2px 10px",
    color: "#625ee0",
    backgroundColor: "#edecff",
  }));

  const GroupItems = styled("ul")({
    padding: 0,
    textTransform: "capitalize",
  });

  //Common
  const optionsToRender = options
    ?.map((el) => {
      const firstLetter = el.name[0].toUpperCase();
      return {
        ...el,
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        label: el.name.toLowerCase(),
      };
    })
    .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));

  const defaultValue =
    optionsToRender?.find((el) => el.id === defaultValueId) ?? null;

  //Render
  return isLoading ? (
    <Skeleton
      height={56}
      width="92%"
      sx={{ margin: "0px auto 20px", transform: "scale(1)" }}
    />
  ) : (
    <Autocomplete
      disablePortal
      id={`${label}-dropdown`}
      disabled={isDisabled}
      value={value ?? defaultValue}
      groupBy={(option) => option.firstLetter}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={optionsToRender?.length > 0 ? optionsToRender : []}
      renderGroup={(params) => {
        return optionsToRender?.length > 0 ? (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        ) : (
          <li
            style={{
              opacity: 0.5,
              pointerEvents: "none",
              paddingLeft: "10px",
            }}
            key="No Options"
          >
            No Options
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
      sx={{
        width: "92%",
        margin: " 0 auto 20px ",
        "& .MuiAutocomplete-input": {
          border: "0 !important",
          textTransform: "capitalize",
        },
        "& .MuiAutocomplete-input:hover": {
          backgroundColor: "transparent !important",
        },
        "& .MuiAutocomplete-input:focus": {
          boxShadow: "none !important",
        },
      }}
    />
  );
};

export default DevicesSidebarDropdown;
