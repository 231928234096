import React from "react";
import AnimatedWelcomeScreen from "./animatedSVGs/AnimatedWelcomeScreen";
import CreatingImagesScreen from "./animatedSVGs/CreatingImagesScreen";
import UpdatingDrawingsScreen from "./animatedSVGs/UpdatingDrawingsScreen";
import UploadingDrawingsScreen from "./animatedSVGs/UploadingDrawingScreen";
import OpeningDrawingScreen from "./animatedSVGs/OpeningDrawingScreen";
import SavingChangesScreen from "./animatedSVGs/SavingChangesScreen";
import { useAtom } from "jotai";
import { activeLoaderAtom } from "../store/UI";

const CustomLoader = ({ status, width = "20rem" }) => {
  //Atom
  const [activeLoader] = useAtom(activeLoaderAtom);

  //Functions
  const getRequiredScreen = () => {
    switch (activeLoader || status) {
      case "home": //When going home.
        return <AnimatedWelcomeScreen />;
      case "uploading": //When uploading a new drawing.
        return <UploadingDrawingsScreen />;
      case "updating": //When updating some details.
        return <UpdatingDrawingsScreen />;
      case "opening": //When opening a drawing.
        return <OpeningDrawingScreen />;
      case "saving": //When leaving a drawing.
        return <SavingChangesScreen />;
      case "images": //When adding new images.
        return <CreatingImagesScreen />;
      default:
        return <AnimatedWelcomeScreen />;
    }
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width }}>{getRequiredScreen()}</div>
    </div>
  );
};

export default CustomLoader;
