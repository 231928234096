import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "jotai";
import Main from "./Main";
import Initializer from "./factories/Initializer";
import { jotaiStore } from "./ExcalidrawAPI/jotai";

import "./ExcalidrawAPI/excalidraw-app/pwa";
import "./ExcalidrawAPI/excalidraw-app/sentry";

window.__EXCALIDRAW_SHA__ = process.env.REACT_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <Provider unstable_createStore={() => jotaiStore}>
      <Initializer>
        <Main />
      </Initializer>
    </Provider>
  </StrictMode>,
);
