import React from "react";

import { useAtom } from "jotai";
import {
  excalidrawApiAtom,
  isActiveEditTextModeAtom,
} from "../store/variables";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { mutateElement } from "../ExcalidrawAPI/element/mutateElement";

const EditTextButton = () => {
  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [isActiveEditTextMode, setIsActiveEditTextMode] = useAtom(
    isActiveEditTextModeAtom,
  );

  //General
  const buttonClassNames = `polygon-tool-button ${
    isActiveEditTextMode ? "polygon-tool-button_active" : ""
  }`;

  //Functions
  const editSceneTextsHandler = () => {
    const isActivated = !isActiveEditTextMode; //We used this ! as the state isn't changed yet
    // const lockedTextElements = excalidrawAPI
    //   .getSceneElements()
    //   .filter((el) => el.type === "text" && el.customData?.isEditableText);
    // const lockedTextElementsIds = lockedTextElements.reduce(
    //   (accumulatedObj, currentObj) => {
    //     accumulatedObj[currentObj.id] = true;
    //     return accumulatedObj;
    //   },
    //   {},
    // );
    excalidrawAPI.updateScene({
      elements: excalidrawAPI.getSceneElements().map((el) => {
        if (el.type === "text" && el.customData?.isEditableText) {
          mutateElement(el, { locked: !isActivated });
        }
        return el;
      }),
      //   appState: {
      //     selectedElementIds: !isActiveEditTextMode ? lockedTextElementsIds : {},
      //   },
    });
    excalidrawAPI.setToast({
      message: `Edit text mode: ${isActivated ? "activated" : "deactivated"}${
        isActivated ? "\nDouble click on the text you want to edit" : ""
      }`,
      closable: true,
      duration: 3500,
      options: {
        position: "topRight",
        status: "info",
      },
    });
    setIsActiveEditTextMode((prevStatus) => !prevStatus);
  };

  //Render
  return (
    <button
      className={buttonClassNames}
      title="Edit texts in scene"
      onClick={editSceneTextsHandler}
    >
      {isActiveEditTextMode ? (
        <ModeEditOutlineIcon />
      ) : (
        <ModeEditOutlinedIcon />
      )}
    </button>
  );
};

export default EditTextButton;
