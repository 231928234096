import React from "react";

import { Slider } from "@mui/material";
import { setToLocalStorage } from "../helpers/common";

const GridSizeSlider = ({
  updateScene,
  disabled,
  gridSizeValue,
  setGridSizeValue,
  fontSize = "0.75rem",
  size = "small",
  classes = [],
}) => {
  const usedClasses = `grid-size-slider ${classes.join(" ")}`;
  return (
    <div className={usedClasses}>
      <p style={{ textAlign: "left", fontSize }}>Grid size</p>
      <Slider
        disabled={disabled}
        aria-label="Temperature"
        valueLabelDisplay="auto"
        step={5}
        marks
        min={5}
        max={50}
        size={size}
        sx={{
          "& .MuiSlider-rail": {
            backgroundColor: "#5b57d19c",
          },
          "& .MuiSlider-track": {
            color: "#5b57d1",
          },
          "& .MuiSlider-thumb": {
            backgroundColor: "#5b57d1",
          },
          "& .MuiSlider-thumb:hover": {
            boxShadow: "0px 0px 0px 8px #5b57d156",
          },
          "& .MuiSlider-mark ": {
            backgroundColor: "#5b57d1",
          },
        }}
        value={gridSizeValue}
        onChange={(event) => {
          setGridSizeValue(event.target.value);
          setToLocalStorage(
            "appCustomData",
            { gridSizeValue: event.target.value },
            { merge: true },
          );
          updateScene("appState", { gridSize: event.target.value });
        }}
      />
    </div>
  );
};

export default GridSizeSlider;
