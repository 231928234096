/* Copyright (C) EZ Fire, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Hamza Younes <hamza@ezfirecontrols.com>, Jan 18th, 2023
 */

import firebase from "firebase/app";
import "firebase/firestore";
import secondaryFirebaseProject from "./secondaryProjectDB";
import { newElementWith } from "../ExcalidrawAPI/element/mutateElement";
import { checkIfDroppedInArea } from "./exportedAreasMethods";
import { DELETED_PROPS } from "../helpers/constants";
import { jotaiStore } from "../ExcalidrawAPI/jotai";
import { excalidrawApiAtom } from "../store/variables";

export const getAllDevices = async () => {
  try {
    return secondaryFirebaseProject
      .firestore()
      .collection("newDevices")
      .where("active", "==", true)
      .onSnapshot(
        (snap) => {
          let allDevices = [];
          if (!snap.empty) {
            allDevices = snap.docs
              .filter((el) => !el.data().type)
              .map((doc) => doc.data());

            return allDevices;
          }
        },
        (error) => {
          console.error(error);
        },
      );
  } catch (error) {
    console.error(error);
  }
};

export const getDevicesIndustries = () => {
  try {
    return secondaryFirebaseProject
      .firestore()
      .collection("industries")
      .where("active", "==", true)
      .get()
      .then((snapshot) => {
        const result = [];
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            result.push({ ...doc.data(), id: doc.id });
          });
        }
        return result;
      });
  } catch (error) {
    console.error("Couldn't get devices industries: ", error);
  }
};

export const getDevicesCategories = () => {
  try {
    return secondaryFirebaseProject
      .firestore()
      .collection("categories")
      .where("active", "==", true)
      .get()
      .then((snapshot) => {
        const result = [];
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            result.push({ ...doc.data(), id: doc.id });
          });
        }
        return result;
      });
  } catch (error) {
    console.error("Couldn't get devices categories: ", error);
  }
};

export const getDevicesCategoriesByParentId = (industryId) => {
  return secondaryFirebaseProject
    .firestore()
    .collection("categories")
    .where("active", "==", true)
    .where("parentId", "==", industryId)
    .get()
    .then((snapshot) => {
      const result = [];
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          result.push({ ...doc.data(), id: doc.id });
        });
      }
      return result;
    });
};

export const getDevicesSubCategories = () => {
  try {
    return secondaryFirebaseProject
      .firestore()
      .collection("subCategories")
      .where("active", "==", true)
      .get()
      .then((snapshot) => {
        const result = [];
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            result.push({ ...doc.data(), id: doc.id });
          });
        }
        return result;
      });
  } catch (error) {
    console.error("Couldn't get devices sub-categories: ", error);
  }
};

export const getDevicesSubCategoriesByParentId = (categoryId) => {
  return secondaryFirebaseProject
    .firestore()
    .collection("subCategories")
    .where("active", "==", true)
    .where("parentId", "==", categoryId)
    .get()
    .then((snapshot) => {
      const result = [];
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          result.push({ ...doc.data(), id: doc.id });
        });
      }
      return result;
    });
};

export const getDevicesSubSubCategories = () => {
  try {
    return secondaryFirebaseProject
      .firestore()
      .collection("subSubCategories")
      .where("active", "==", true)
      .get()
      .then((snapshot) => {
        const result = [];
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            result.push({ ...doc.data(), id: doc.id });
          });
        }
        return result;
      });
  } catch (error) {
    console.error("Couldn't get devices sub-sub-categories: ", error);
  }
};

export const getDevicesSubSubCategoriesByParentId = (subCategoryId) => {
  return secondaryFirebaseProject
    .firestore()
    .collection("subSubCategories")
    .where("active", "==", true)
    .where("parentId", "==", subCategoryId)
    .get()
    .then((snapshot) => {
      const result = [];
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          result.push({ ...doc.data(), id: doc.id });
        });
      }
      return result;
    });
};

export const getCategorizedDevices = ({
  industryId,
  categoryId,
  subCategoryId,
  subSubCategoryId,
}) => {
  try {
    return secondaryFirebaseProject
      .firestore()
      .collection("newDevices")
      .where("active", "==", true)
      .where("industryId", "==", industryId)
      .where("categoryId", "==", categoryId)
      .where("subCategoryId", "==", subCategoryId)
      .where("subSubCategoryId", "==", subSubCategoryId)
      .get()
      .then((snapshot) => {
        const result = [];
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            result.push({ ...doc.data(), id: doc.id });
          });
        }
        return result;
      });
  } catch (error) {
    console.error("Couldn't get devices: ", error);
  }
};

export const getAllDevicesSymbols = async () => {
  try {
    return secondaryFirebaseProject
      .firestore()
      .collection("newSymbols")
      .where("active", "==", true)
      .get()
      .then((snapshot) => {
        const result = [];
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            result.push({ ...doc.data(), id: doc.id });
          });
        }
        return result;
      });
  } catch (error) {
    console.error("Couldn't get symbols of devices: ", error);
  }
};

export const createDeviceInDB = async ({
  docId,
  deviceId,
  drawingId,
  areaId,
}) => {
  if (!docId || !deviceId || !drawingId || !areaId) {
    return;
  }
  const devicesDB = secondaryFirebaseProject.firestore();
  const deviceToUpload = {
    active: true,
    id: docId, //ID of an instance of some device.
    createdAt: firebase.firestore.Timestamp.now(),
    createdBy: firebase.auth().currentUser.uid,
    deviceId, //ID of the original device.
    drawingId,
    areaId,
  };
  await devicesDB.collection("drawingsDevices").doc(docId).set(deviceToUpload);
};

export const deleteDeviceById = (docId) => {
  const devicesDB = secondaryFirebaseProject.firestore();
  devicesDB.collection("drawingsDevices").doc(docId).set(
    {
      active: false,
      modifiedAt: firebase.firestore.Timestamp.now(),
      modifiedBy: firebase.auth().currentUser.uid,
    },
    { merge: true },
  );
};

export const deleteDevicesByBatch = (selectedDevicesIds) => {
  const db = secondaryFirebaseProject.firestore();

  const batch = db.batch();
  const collectionRef = db.collection("drawingsDevices");
  selectedDevicesIds.forEach((id) => {
    const documentRef = collectionRef.doc(id);
    batch.update(documentRef, {
      active: false,
      modifiedAt: firebase.firestore.Timestamp.now(),
      modifiedBy: firebase.auth().currentUser.uid,
    });
  });

  // Commit the batch write operation & Return a promise to be waited.
  return batch.commit();
};

const triggerToast = (message, status, options = {}) => {
  const excalidrawAPI = jotaiStore.get(excalidrawApiAtom);
  excalidrawAPI.setToast({
    message,
    duration: options.duration || 2000,
    closable: true,
    options: {
      position: "topRight",
      status,
      ...options,
    },
  });
};

export const checkIfDroppedInAreaHandler = (deviceSceneEl) => {
  const excalidrawAPI = jotaiStore.get(excalidrawApiAtom);

  //Get the current areas.
  const allSceneElements = excalidrawAPI.getSceneElements();
  const currentAreas = allSceneElements.filter(
    (el) => el.customData?.isSelectedArea,
  );

  let updatedDeviceSceneEl = null;

  for (const a of currentAreas) {
    if (checkIfDroppedInArea(deviceSceneEl, a)) {
      updatedDeviceSceneEl = newElementWith(deviceSceneEl, {
        locked: true,
        customData: { ...deviceSceneEl.customData, areaId: a.id },
      });
      deviceSceneEl.customData?.areaId !== a.id &&
        triggerToast(
          `Device re-assigned to ${a.customData.label || "UNSAVED"} area`,
          "success",
        );
      break;
    }
  }

  return updatedDeviceSceneEl;
};

export const assignDroppedDeviceToArea = (element, updatedDeviceSceneEl) => {
  const excalidrawAPI = jotaiStore.get(excalidrawApiAtom);
  const allSceneElements = excalidrawAPI.getSceneElements();
  let isAllowedToBeAssigned = true;
  excalidrawAPI.updateScene({
    elements: allSceneElements.map((el) => {
      if (el.customData?.docId === element.customData?.docId) {
        if (updatedDeviceSceneEl) {
          return updatedDeviceSceneEl;
        }
        isAllowedToBeAssigned = false;
        triggerToast("Device dropped outside all areas", "error");
        return el;
        // return newElementWith(el, { ...DELETED_PROPS });
      }
      return el;
    }),
  });
  return isAllowedToBeAssigned;
};

export const addAssignedDeviceToDB = async (element) => {
  const excalidrawAPI = jotaiStore.get(excalidrawApiAtom);
  const { drawingId, deviceId, docId, areaId } = element.customData ?? {};
  try {
    await createDeviceInDB({ docId, deviceId, drawingId, areaId });
    // addDesignerId();
  } catch (error) {
    console.error("Couldn't save the device in the DB: ", error);
    //Remove the dropped device in case of an error happened during saving.
    const excalidrawElements = excalidrawAPI?.getSceneElements();
    excalidrawAPI.updateScene({
      elements: excalidrawElements.map((el) => {
        if (el.customData?.docId !== docId) {
          return newElementWith(el, { ...DELETED_PROPS });
        }
        return el;
      }),
    });
  }
};

export const checkAreaAndAssignDevice = (deviceSceneEl) => {
  //Check if the device was dropped in an area
  const updatedDeviceSceneEl = checkIfDroppedInAreaHandler(deviceSceneEl);

  //Update the dropped device to have the area ID.
  const isAllowedToBeAssigned = assignDroppedDeviceToArea(
    deviceSceneEl,
    updatedDeviceSceneEl,
  );

  //Upload device to the database
  updatedDeviceSceneEl &&
    isAllowedToBeAssigned &&
    addAssignedDeviceToDB(updatedDeviceSceneEl);

  return isAllowedToBeAssigned;
};
