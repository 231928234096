import React, { useCallback, useEffect } from "react";

import DeviceHubIcon from "@mui/icons-material/DeviceHub";

import "../scss/SidebarToggler.scss";

const SidebarToggler = ({ excalidrawAPI = null, isMobile, setAppState }) => {
  //Refs

  //State

  //Constants
  const isSidebarOpen = !!document.querySelector(".customized_sidebar");
  const excalidrawCanvasRef = document.querySelector(".excalidraw__canvas");
  const isSidebarDocked = excalidrawAPI?.getAppState().isSidebarDocked;

  //Callbacks
  const clickOutsideMenuHandler = useCallback(() => {
    if (excalidrawCanvasRef && isSidebarOpen && !isSidebarDocked) {
      excalidrawCanvasRef.onclick = () => {
        if (isMobile) {
          setAppState({ openSidebar: null });
        } else {
          excalidrawAPI?.toggleMenu("customSidebar");
        }
      };
    }
  }, [isSidebarOpen, isSidebarDocked]);

  //Effects
  useEffect(() => {
    clickOutsideMenuHandler();
    return () => {
      if (excalidrawCanvasRef) {
        excalidrawCanvasRef.onclick = null;
      }
    };
  }, [clickOutsideMenuHandler]);

  //Functions

  //Common

  //Constants
  const libraryTogglerClasses = `library-toggler ${
    isMobile ? "library-toggler_mobile" : ""
  }`;

  //Render
  return (
    <div
      className={libraryTogglerClasses}
      title="Devices Library"
      onClick={() => {
        if (isMobile) {
          setAppState({ openSidebar: "customSidebar" });
        } else {
          excalidrawAPI?.toggleMenu("customSidebar");
        }
      }}
    >
      <p className="library-toggler-icon">
        <DeviceHubIcon
          sx={{ filter: "contrast(0.2)", width: "16px", height: "16px" }}
        />
      </p>
      <button className="library-toggler-btn ">
        {isMobile ? "" : "Devices Library"}
      </button>
    </div>
  );
};

export default SidebarToggler;
