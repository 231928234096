import React, { useRef, useState, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";

import { useAtom } from "jotai";
import { currentDrawingAtom, currentProjectAtom } from "../store/variables";

import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Box, SpeedDial, SpeedDialAction } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Spinner from "../ExcalidrawAPI/components/Spinner";
import { createOrUpdateDrawing } from "../helpers/drawings";

import "../scss/DrawerCell.scss";
import { CheckboxItem } from "../ExcalidrawAPI/components/CheckboxItem";
import { COVER_SHEET_CELL } from "../helpers/constants";
import { collabAPIAtom } from "../ExcalidrawAPI/excalidraw-app/collab/Collab";
import { activeLoaderAtom, isLeavingSceneAtom } from "../store/UI";
import { actionClearCanvas } from "../ExcalidrawAPI/actions";
import { useExcalidrawActionManager } from "../ExcalidrawAPI/components/App";
import { useCoverSheetDrawer } from "../hooks/useCoverSheetDrawer";
import { useFrameMethods } from "../hooks/useFrameMethods";

const ItemTypes = {
  CELL: "drawingCell",
};

const useStyles = makeStyles(() => ({
  speedDialAction: {
    fontSize: "19px",
  },
}));

const DrawerCell = ({
  id,
  element,
  originalItem,
  deleteCell,
  index,
  lastIndex,
  moveCell,
  setSelectedItems,
  setIfParentOpen,
  isDefaultSelected,
  isActiveCell,
  setCells,
  openScene,
  setIsOpeningScene,
  triggerToast,
  canDelete,
  isDraggable,
  cellTooltip,
}) => {
  const classes = useStyles();

  //Atom
  const [collabAPI] = useAtom(collabAPIAtom);
  const [currentDrawing] = useAtom(currentDrawingAtom);
  const [currentProject] = useAtom(currentProjectAtom);
  const [, setIsLeavingScene] = useAtom(isLeavingSceneAtom);
  const [, setActiveLoader] = useAtom(activeLoaderAtom);

  //Excalidraw Hooks
  const actionManager = useExcalidrawActionManager();

  //Custom Hooks
  const { updateNameInDrawingsList } = useCoverSheetDrawer();
  const { editFrame } = useFrameMethods();

  //State
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isSelectedCell] = useState(!!isDefaultSelected || false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isHiddenActionsButtons, setIsHiddenActionsButtons] = useState(true);
  const [actionProp, setActionProp] = useState({});

  //Refs
  const drawerCellRef = useRef(null);
  const coverSheetCellRef = useRef(null);
  const cellNameInputRef = useRef(null);

  //Draggable Hooks
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CELL,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!drawerCellRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = drawerCellRef.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCell(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CELL,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  //Constants
  const cellStyle = {
    opacity: isDragging ? 0 : 1,
    cursor: "grab",
  };

  const cellClasses = `order-drawer_cell ${
    isActiveCell ? "order_drawer_cell_active" : ""
  } ${isSelectedCell ? "order_drawer_cell_selected" : ""}`.trim();

  const drawerCellButtonsContainerClasses = `drawer-cell_actions_container ${
    isHiddenActionsButtons ? "" : "drawer-cell_actions_container_on"
  }`.trim();

  const saveNameBtnClasses = `order-drawer_cell_btn save_cell_btn ${
    isProcessing ? "cell_btn_freezed cell_btn_freezed_save" : ""
  }`.trim();

  const discardNameBtnClasses = `order-drawer_cell_btn discard_cell_btn ${
    isProcessing ? "cell_btn_freezed" : ""
  }`.trim();

  const actions = [
    {
      icon: canDelete ? (
        <OpenInNewRoundedIcon className={classes.speedDialAction} />
      ) : (
        <NoteAddOutlinedIcon className={classes.speedDialAction} />
      ),
      name: canDelete ? "Open" : "Create",
      isToRender: true,
      onClick: () => openDrawingHandler(),
    },
    {
      icon: (
        <DriveFileRenameOutlineRoundedIcon
          className={classes.speedDialAction}
        />
      ),
      name: "Rename",
      isToRender: isDraggable,
      onClick: () => activateCellInput(),
    },
    {
      icon: <DeleteForeverRoundedIcon className={classes.speedDialAction} />,
      name: "Delete",
      isToRender: canDelete,
      onClick: () => {
        setIsHiddenActionsButtons(false);
        setActionProp({ key: "active", value: false });
      },
    },
  ];

  //Effects
  useEffect(() => {
    !isReadOnly && cellNameInputRef.current.focus();
  }, [isReadOnly]);

  //Functions
  const selectedItemsHandler = (status) => {
    setSelectedItems((prevItems) => {
      if (status) {
        return [...prevItems, id];
      }
      return prevItems.filter((el) => el !== id);
    });
  };

  const formatOrder = () => {
    return `${String(element.order).padStart(3, "0")} OF ${String(
      lastIndex,
    ).padStart(3, "0")}`;
  };

  const openDrawingHandler = async (e = null) => {
    if (
      e?.target.id === "cell-input" ||
      !isReadOnly ||
      !!e?.target.closest("#cell-checkbox") ||
      !isHiddenActionsButtons
    ) {
      return;
    }
    if (currentDrawing.id === id) {
      //This drawing is already opened.
      triggerToast(`${currentDrawing.name} is already opened!`, "error");
      return;
    }
    setIfParentOpen(false);
    setIsLeavingScene(true);
    setActiveLoader("saving");
    await collabAPI?.stopCollaboration();
    if (drawerCellRef.current) {
      drawerCellRef.current?.classList.add("order_drawer_cell_active");
    } else {
      coverSheetCellRef.current?.classList.add("order_drawer_cell_active");
    }
    setIsOpeningScene(true);
    setActiveLoader("opening");
    setTimeout(async () => {
      actionManager.executeAction(actionClearCanvas);
      await openScene(
        id,
        element.name.toLowerCase() === "cover sheet",
        currentProject,
      );
      setIsOpeningScene(false);
      setActiveLoader(null);
      if (drawerCellRef.current) {
        drawerCellRef.current.classList.remove("order_drawer_cell_active");
      } else {
        coverSheetCellRef.current?.classList.remove("order_drawer_cell_active");
      }
    }, 1000);
  };

  const activateCellInput = () => {
    setIsReadOnly(false);
  };

  const updateCellName = async () => {
    try {
      await createOrUpdateDrawing(
        { id, [actionProp.key]: actionProp.value },
        true,
      );
      triggerToast(`Updated Page Successfully!`, "success");
      cellNameHandler({ target: { value: element.name.trim() } });
      editFrame({
        changes: [
          {
            valueFor: "drawing title",
            value: actionProp.value,
            originalName: "name",
          },
        ],
        update: true,
      });
    } catch (error) {
      triggerToast(`Failed To Update Page!`, "error");
      discardCellPropHandler();
      console.error(error);
    }
  };

  //TODO - These lines are to add new pages using pages Manager component.
  //TODO - You can see the options are commented too.
  // const insertPageHandler = async (position) => {
  //   const pageOrder = position === "before" ? element.order : element.order + 1;
  //   const uploadPageOptions = { showConfirm: false, pageOrder };
  //   await shiftCellsOnInsert(position, pageOrder);
  //   uploadPage(uploadPageOptions);
  // };

  // const shiftCellsOnInsert = async (position, order) => {
  // const shiftedPages = allElements
  //   .filter((el) => el.order >= order)
  //   .map((el) => Object.assign(el, { order: el.order + 1 }));
  // try {
  //   await updatePagesOrderByBatch(shiftedPages);
  // } catch (error) {
  //   console.error("Couldn't shift the pages: ", error);
  // }
  // };

  const cellNameHandler = ({ target }) => {
    const { value } = target;
    setCells((prevCells) => {
      return prevCells.map((el) => {
        if (el.id === id) {
          el.name = value;
          return el;
        }
        return el;
      });
    });
  };

  const confirmActionHandler = async () => {
    setIsProcessing(true);
    if (actionProp.key === "active") {
      await deleteCell([id]);
    } else {
      await updateCellName();
      updateNameInDrawingsList(element.name, id);
    }

    setActionProp({});
    setIsReadOnly(true);
    setIsProcessing(false);
    setIsHiddenActionsButtons(true);
  };

  const discardCellPropHandler = () => {
    setCells((prevCells) => {
      return prevCells.map((el) => {
        if (el.id === id) {
          el[actionProp.key] = originalItem[actionProp.key];
          return el;
        }
        return el;
      });
    });
    setIsHiddenActionsButtons(true);
    setActionProp({});
  };

  const checkIfNameChanged = () => {
    //Not to open the drawing when click on it to blur the input field.
    setTimeout(() => {
      setIsReadOnly(true);
    }, 1000);
    if (element.type === COVER_SHEET_CELL) {
      return;
    }
    if (originalItem?.name.trim() !== element.name.trim()) {
      setIsHiddenActionsButtons(false);
      setActionProp({ key: "name", value: element.name });
    } else {
      setIsHiddenActionsButtons(true);
    }
  };

  const cellOverflowHandler = (e) => {
    const currentCell = drawerCellRef.current || coverSheetCellRef.current;
    currentCell.style.overflow = e.type === "mouseenter" ? "visible" : "hidden";
  };

  //General
  drag(drop(drawerCellRef));

  //Render
  return (
    <div
      draggable={isDraggable}
      ref={isDraggable ? drawerCellRef : coverSheetCellRef}
      title={currentDrawing.id === id ? "Active Drawing" : cellTooltip}
      className="drawer-cell_container"
      data-handler-id={handlerId}
      style={cellStyle}
    >
      <div
        draggable={isDraggable}
        className={cellClasses}
        onClick={openDrawingHandler}
        style={{ cursor: isDraggable ? "move" : "pointer" }}
      >
        <img
          draggable={isDraggable}
          className="order-drawer_cell_image"
          src={element.thumbImageUrl}
          alt="thumbnail"
        />

        <div className="order-drawer-cell_texts_menu_btn">
          <input
            id="cell-input"
            type="text"
            title={
              isDraggable
                ? "Double Click To EDIT"
                : currentDrawing.id === id
                ? "Active Drawing"
                : cellTooltip
            }
            draggable={true}
            onDragStart={(event) => !isReadOnly && event.preventDefault()}
            ref={cellNameInputRef}
            className="order-drawer-cell_name_input"
            value={element.name.toLowerCase()}
            disabled={!isDraggable}
            readOnly={isReadOnly}
            onKeyDown={(ev) => ev.key === "Enter" && checkIfNameChanged()}
            onDoubleClick={(ev) => {
              ev.stopPropagation();
              if (element.type !== COVER_SHEET_CELL) {
                setIsReadOnly(false);
              }
            }}
            onBlur={checkIfNameChanged}
            onChange={cellNameHandler}
          />
          <p>{formatOrder()}</p>
        </div>
        <div className={drawerCellButtonsContainerClasses}>
          <div className="order-drawer_cell_actions">
            <button
              className={discardNameBtnClasses}
              title="Discard"
              onClick={() => discardCellPropHandler("name")}
            >
              <ClearRoundedIcon className="order-drawer_discard_order_icon" />
            </button>

            <button
              className={saveNameBtnClasses}
              title="Confirm"
              onClick={confirmActionHandler}
            >
              <div
                className={`order-drawer_spinner_container ${
                  isProcessing ? "spinner_on" : ""
                }`}
              >
                <Spinner size="1.1rem" />
              </div>
              <CheckRoundedIcon className="order-drawer_save_order_icon" />
            </button>
          </div>
        </div>
        {isDraggable ? (
          <CheckboxItem
            id="cell-checkbox"
            title="Select"
            checked={isDefaultSelected || isSelectedCell}
            className="order-drawer_cell_checkbox"
            onChange={(status) => selectedItemsHandler(status)}
          />
        ) : null}

        {isActiveCell ? (
          <div
            className="order_drawer_cell_active_sign"
            title="Active Page"
          ></div>
        ) : null}
      </div>
      <Box
        sx={{
          height: "auto",
          width: "28px",
          position: "absolute",
          top: "calc(100% - 36px)",
          right: "8px",
          ...cellStyle,
        }}
      >
        <SpeedDial
          onMouseEnter={cellOverflowHandler}
          onMouseLeave={cellOverflowHandler}
          ariaLabel="Drawer Cell Options Menu"
          title="Actions"
          sx={{
            "& .MuiSpeedDial-actions": {
              zIndex: 1,
            },
          }}
          FabProps={{
            variant: "circular",
            size: "small",
            sx: {
              width: "28px",
              height: "28px",
              minHeight: "28px",
              backgroundColor: "#6965db",
              boxShadow: "none",
              transition:
                "225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms all !important",
              "&:hover": {
                backgroundColor: "#6965db",
                boxShadow: "0px 0px 4px 0px #666",
              },
            },
          }}
          icon={<MoreHorizRoundedIcon />}
          direction="down"
        >
          {actions
            .filter((el) => el.isToRender)
            .map((action) => (
              <SpeedDialAction
                disabled={action.name === "Open" && currentDrawing.id === id}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.onClick}
                FabProps={{
                  sx: {
                    width: "28px",
                    height: "28px",
                    minHeight: "28px",
                    margin: "0",
                    marginBottom: "6px",
                  },
                }}
              />
            ))}
        </SpeedDial>
      </Box>
    </div>
  );
};

export default DrawerCell;
