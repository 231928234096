import firebase from "firebase/app";
import "firebase/auth";

export const getAllUsers = (onSuccess, onError) => {
  const db = firebase.firestore();
  try {
    db.collection("users")
      .where("active", "==", true)
      .onSnapshot(
        (snap) => {
          if (snap) {
            const allUsers = snap.docs.map((el) => el.data());
            onSuccess && onSuccess(allUsers);
          }
        },
        (error) => {
          onError && onError(error);
          console.error(error);
        },
      );
  } catch (error) {
    console.error(error);
  }
};
