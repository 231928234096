import React, { useState, useEffect } from "react";

import { MIME_TYPES } from "../ExcalidrawAPI/constants";
import { serializeLibraryAsJSON } from "../ExcalidrawAPI/data/json";

import CircularProgress from "@mui/material/CircularProgress";

import { convertToFeet } from "../helpers/common";

import "../scss/CustomizedLibraryItem.scss";
import { randomId } from "../ExcalidrawAPI/random";
import { useAtom } from "jotai";
import { excalidrawApiAtom } from "../store/variables";

const CustomizedLibraryItem = ({
  item,
  drawingId,
  pixelsPerUnit,
  correspondingUnit,
}) => {
  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);

  //State
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [elementToDrag, setElementToDrag] = useState({});

  //Effects
  useEffect(() => {
    setHeightAccordingToAspectRatio();
  }, []);

  //Functions
  const getImageDimensionsFromDataURL = (dataURL) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
          aspectRatio: img.width / img.height,
        });
      };
      img.onerror = () => {
        reject(new Error("Failed to load image"));
      };
      img.src = dataURL;
    });
  };

  const setHeightAccordingToAspectRatio = async () => {
    const elementDataURL = excalidrawAPI?.getFiles()[item.iconId]?.dataURL;
    if (!elementDataURL) {
      setIsImageLoading(false);
      return;
    }
    const itemWidth = convertToFeet(pixelsPerUnit, correspondingUnit) * 1.5;
    try {
      const { aspectRatio } = await getImageDimensionsFromDataURL(
        elementDataURL,
      );
      adjustedElement.elements[0].height = itemWidth / aspectRatio;
      setElementToDrag(adjustedElement);
    } catch (error) {
      console.error("Couldn't get the element dims:", error);
    }
  };

  //Constants
  const adjustedElement = {
    created: Date.now(),
    id: item.iconId,
    status: "unpublished",
    elements: [
      {
        type: "image",
        customData: {
          drawingId,
          imageType: "device",
          deviceId: item.id,
          docId: randomId(),
          isImmutable: true,
        },
        icon: item.icon,
        id: item.iconId,
        fileId: item.iconId,
        width: convertToFeet(pixelsPerUnit, correspondingUnit) * 1.5,
        height: convertToFeet(pixelsPerUnit, correspondingUnit) * 1.5,
      },
    ],
  };

  return (
    <div className="customized-library-item " title={item.description}>
      {isImageLoading && (
        <CircularProgress
          color="inherit"
          size={25}
          sx={{ position: "absolute" }}
        />
      )}

      <img
        className={`${item.description.toLowerCase().split(" ").join("-")}-img`}
        onLoad={() => setIsImageLoading(false)}
        src={item.icon}
        alt={item.description}
        title={item.description}
        draggable="true"
        onDragStart={async (event) => {
          event.dataTransfer.setData(
            MIME_TYPES.excalidrawlib,
            serializeLibraryAsJSON([elementToDrag]),
          );
        }}
      />
    </div>
  );
};

export default CustomizedLibraryItem;
