import React, { forwardRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";

import "../scss/LoadingButton.scss";

const LoadingButton = forwardRef(
  (
    {
      disabled,
      onClick,
      label,
      classes,
      isBtnLoading,
      setIsBtnLoading,
      title,
      ...rest
    },
    ref,
  ) => {
    //State

    //Constants
    const usedClasses = `loading-button ${classes.map((c) => c)}`;

    //Render
    return (
      <button
        title={title}
        ref={ref}
        className={usedClasses}
        disabled={disabled || isBtnLoading}
        onClick={() => {
          setIsBtnLoading && setIsBtnLoading(true);
          onClick();
        }}
        {...rest}
      >
        {isBtnLoading && <CircularProgress color="inherit" size={25} />}
        {!isBtnLoading && (label || "Open")}
      </button>
    );
  },
);

export default LoadingButton;
