import React from "react";
import AreaField from "./AreaField";

const AreasContainer = ({
  classes = [],
  areas,
  expandedArea,
  expandedAreaHandler,
  selectedAreas,
  setSelectedAreas,
  setNewAreas,
  setSaveDiscardShown,
  setIsParentOpen,
  setCustomLoaderShown,
}) => {
  //Atom

  //State

  //Functions

  //Render
  return (
    <div className={`areas-container_content ${classes.join(" ")}`}>
      {!areas?.length && <p>No Areas Yet</p>}
      {areas
        ?.sort((a, b) => {
          const dateA = new Date(
            a.createdAt?.seconds * 1000 + a.createdAt?.nanoseconds / 1000000,
          );
          const dateB = new Date(
            b.createdAt?.seconds * 1000 + b.createdAt?.nanoseconds / 1000000,
          );

          return dateA - dateB;
        })
        .map((a) => {
          return (
            <AreaField
              key={`${a.id}${a.isNew ? "_new" : ""}`}
              area={a}
              isExpanded={expandedArea === a.id}
              isSelected={selectedAreas.includes(a.id)}
              setSelectedAreas={setSelectedAreas}
              setSaveDiscardShown={setSaveDiscardShown}
              setIsParentOpen={setIsParentOpen}
              setNewAreas={setNewAreas}
              setCustomLoaderShown={setCustomLoaderShown}
              expandedToggler={expandedAreaHandler}
            />
          );
        })}
    </div>
  );
};

export default AreasContainer;
