import clsx from "clsx";
import { Popover } from "../ExcalidrawAPI/components/Popover";

import { useExcalidrawAppState } from "../ExcalidrawAPI/components/App";
import React from "react";
import { useAtom } from "jotai";
import { pagesManagerContextMenuAtom } from "./PagesManager";

import "../scss/PagesManagerContextMenu.scss";

type PagesManagerMenuItem = {
  type: string;
  label?: string;
  icon?: JSX.Element;
  action?: () => void;
  isDisabled?: boolean;
};

export type PagesManagerMenuItems = PagesManagerMenuItem[];

type ContextMenuProps = {
  items: PagesManagerMenuItems;
  top: number;
  left: number;
};

export const CONTEXT_MENU_SEPARATOR = "separator";

export const PagesManagerContextMenu = React.memo(
  ({ items, top, left }: ContextMenuProps) => {
    const [, setIsShown] = useAtom(pagesManagerContextMenuAtom);
    const appState = useExcalidrawAppState();

    return (
      <Popover
        onCloseRequest={() => setIsShown(false)}
        top={top}
        left={left}
        fitInViewport={true}
        offsetLeft={appState.offsetLeft}
        offsetTop={appState.offsetTop}
        viewportWidth={appState.width}
        viewportHeight={appState.height}
      >
        <ul
          className="context-menu"
          onContextMenu={(event) => event.preventDefault()}
        >
          {items.map((item, idx) => {
            if (item?.type === CONTEXT_MENU_SEPARATOR) {
              return <hr key={idx} className="context-menu-item-separator" />;
            }

            return (
              <li
                key={idx}
                onClick={() => {
                  item?.action?.();
                  setIsShown(false);
                }}
                className={`${
                  item.isDisabled ? "disabled-context-menu-item" : ""
                }`}
              >
                <button
                  className={clsx("context-menu-item", {
                    dangerous: item?.type === "deleteAll",
                  })}
                >
                  <div className="context-menu-item__label">{item?.label}</div>
                  {item?.icon}
                </button>
              </li>
            );
          })}
        </ul>
      </Popover>
    );
  },
);
