import React, { useState } from "react";

import { t } from "../i18n";

import useReactiveUIVars from "../../hooks/useReactiveUIVars";
import { setToLocalStorage } from "../../helpers/common";

import { actionClearCanvas } from "../actions";
import { ButtonsToShowType } from "../types";
import { useExcalidrawActionManager } from "./App";
import ConfirmDialog from "./ConfirmDialog";

import "./ActiveConfirmDialog.scss";

export const ActiveConfirmDialog = () => {
  //CustomHook
  const { activeConfirmDialog, setActiveConfirmDialog } = useReactiveUIVars();

  //Atom

  //State
  const [, setAction] = useState<string | null>(null);

  //Effect

  //Excalidraw Hooks
  const actionManager = useExcalidrawActionManager();

  //Functions
  const dialogTitleHandler = () => {
    switch (activeConfirmDialog) {
      case "clearCanvas":
        return "clearCanvasDialog.title";
      case "northHeading":
        return "northHeadingDialog.title";
      case "notAllowedUse":
        return "notAllowedUseDialog.title";
      case "notAllowedImageInsert":
        return "notAllowedImageInsertDialog.title";
      case "intersectedLines":
        return "intersectedLinesDialog.title";
      case "draggedLine":
        return "draggedLineDialog.title";
      case "invalidCoords":
        return "invalidCoordsDialog.title";
      case "deletedCurrentDrawing":
        return "deletedCurrentDrawingDialog.title";
      case "generalError":
        return "generalErrorDialog.title";
      case "notCompletedPolygon":
        return "notCompletedPolygonDialog.title";
      default:
        return "generalErrorDialog.title";
    }
  };

  const dialogAlertHandler = () => {
    switch (activeConfirmDialog) {
      case "clearCanvas":
        return "alerts.clearReset";
      case "northHeading":
        return "alerts.northHeading";
      case "notAllowedUse":
        return "alerts.notAllowedUse";
      case "notAllowedImageInsert":
        return "alerts.notAllowedImageInsert";
      case "intersectedLines":
        return "alerts.intersectedLines";
      case "draggedLine":
        return "alerts.draggedLine";
      case "invalidCoords":
        return "alerts.invalidCoords";
      case "deletedCurrentDrawing":
        return "alerts.deletedCurrentDrawing";
      case "generalError":
        return "alerts.generalError";
      case "notCompletedPolygon":
        return "alerts.notCompletedPolygon";
      default:
        return "alerts.generalError";
    }
  };

  const buttonsToShowHandler: () => ButtonsToShowType = () => {
    let status: ButtonsToShowType = "both";
    switch (activeConfirmDialog) {
      case "clearCanvas":
      case "northHeading":
        status = "both";
        break;
      case "notAllowedUse":
      case "notAllowedImageInsert":
      case "intersectedLines":
      case "draggedLine":
      case "invalidCoords":
      case "deletedCurrentDrawing":
      case "generalError":
      case "notCompletedPolygon":
        status = "none";
        break;
      default:
        status = "both";
        break;
    }
    return status;
  };

  const clearCanvasHandler = () => {
    setToLocalStorage("appCustomData", {});
    actionManager.executeAction(actionClearCanvas);
    setActiveConfirmDialog(null);
    //Refresh to re-render welcome screen
    location.href = "";
  };

  const confirmHandler = () => {
    if (activeConfirmDialog === "clearCanvas") {
      clearCanvasHandler();
    }
    setActiveConfirmDialog(null);
  };

  const discardHandler = () => {
    setActiveConfirmDialog(null);
    setAction(null);
  };

  //General
  if (!activeConfirmDialog) {
    return null;
  }

  if (activeConfirmDialog) {
    return (
      <ConfirmDialog
        buttonsPlacement="end"
        buttonsToShow={buttonsToShowHandler()}
        onConfirm={confirmHandler}
        onCancel={discardHandler}
        title={t(dialogTitleHandler())}
      >
        <p className="clear-canvas__content">{t(dialogAlertHandler())}</p>
      </ConfirmDialog>
    );
  }

  return null;
};
