import { useState } from "react";
import { useAtom } from "jotai";
import {
  currentDrawingsAtom,
  currentProjectAtom,
  globalSettingsAtom,
  excalidrawApiAtom,
} from "../store/variables";

import {
  AREA_OF_WORK_TEXT,
  CODE_REFERENCE_TABLE_COLUMNS,
  COVER_SHEET_CONTENT_HEIGHT,
  COVER_SHEET_CONTENT_WIDTH,
  COVER_SHEET_LABEL_FONT_SIZE,
  COVER_SHEET_LABEL_HEIGHT,
  COVER_SHEET_TEXT_FONT_SIZE,
  DRAWING_LIST_TABLE_COLUMNS,
  ENTRANCE_EXIT,
  GRID_RULER_SHORT_DIM,
  LEFT_SECTION_PARTS_LABELS,
  LEFT_SECTION_PROJECT_LOCATION,
  MARGIN_UNIT,
  PROJECT_SUMMARY_DATA,
  MIDDLE_SECTION_PARTS_LABELS,
  MIDDLE_SECTION_SECOND_TABLE_DATA,
  NORTH_ICON,
  PROJECT_SUMMARY_TABLE_COLUMNS,
  RIGHT_SECTION_PART_LABEL,
} from "../helpers/constants";
import { formatDrawingOrder, getLargestOrder } from "../helpers/common";
import { getCoverSheetById } from "../helpers/coverSheets";
import { getOccupancyTypes } from "../helpers/projects";
import { getIconAndCoverById } from "../helpers/iconsAndCovers";
import { getCodeItemById, getCodesGroupById } from "../helpers/codeReferences";

import { randomId, randomInteger } from "../ExcalidrawAPI/random";
import { getFontString, getUpdatedTimestamp } from "../ExcalidrawAPI/utils";
import { newElementWith } from "../ExcalidrawAPI/element/mutateElement";
import { redrawTextBoundingBox } from "../ExcalidrawAPI/element";
import { measureText } from "../ExcalidrawAPI/element/textElement";

import { useTableMethods } from "./useTableMethods";
import { useFrameMethods } from "./useFrameMethods";
import { useSceneMethods } from "./useSceneMethods";
import { useImageMethods } from "./useImageMethods";
import { useCommonFunctionsHook } from "./useCommonFunctionsHook";

const propsInCommon = {
  angle: 0,
  backgroundColor: "transparent",
  boundElements: [],
  customData: { isImmutable: true },
  fillStyle: "solid",
  isDeleted: false,
  link: null,
  locked: true,
  opacity: 100,
  roughness: 0,
  roundness: null,
  strokeColor: "#000000",
  strokeStyle: "solid",
  strokeWidth: 1,
  seed: randomInteger(),
  version: randomInteger(),
  versionNonce: randomInteger(),
  updated: getUpdatedTimestamp(),
};

const groupId = randomId();

const rectangleElement = {
  ...propsInCommon,
  id: randomId(),
  locked: true,
  type: "rectangle",
  groupId,
};

const lineElement = {
  ...propsInCommon,
  id: randomId(),
  lastCommittedPoint: null,
  roundness: { type: 2 },
  type: "line",
  groupId,
};

export const useCoverSheetDrawer = () => {
  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [currentProject] = useAtom(currentProjectAtom);
  const [currentDrawings] = useAtom(currentDrawingsAtom);
  const [globalSettings] = useAtom(globalSettingsAtom);

  //State
  const [intervalId, setIntervalId] = useState(null);

  //Effects

  //Custom Hooks
  const { createCell, getTableProps, deleteRow, addRow } = useTableMethods();
  const { drawCoverSheetFrame } = useFrameMethods();
  const { getCurrentElements, updateRemoteScene } = useSceneMethods();
  const { renderImageElement } = useImageMethods();
  const { formatNumbersInsideText, triggerToast } = useCommonFunctionsHook();

  //Constants

  //Functions
  const updateScene = (destination, update) => {
    const sceneData = {
      [destination]: update,
    };
    excalidrawAPI?.updateScene(sceneData);
  };

  const checkIfOccupyingEntireProperty = (currentDrawings, coverSheetData) => {
    const floorsOfWorkNo = currentDrawings?.filter(
      (el) => el.type !== "coverSheet",
    ).length;
    if (!floorsOfWorkNo) {
      return "NO";
    }
    return floorsOfWorkNo ===
      Number(coverSheetData.propertyLevelsAboveGround) +
        Number(coverSheetData.propertyLevelsBelowGround)
      ? "YES"
      : "NO";
  };

  /**
   * @param {string} orientation - "vertical" OR "horizontal"
   * @param {object} start - { x: number, y: number}
   * @returns Excalidraw line element
   */
  const createLine = ({
    dimensions,
    start,
    lineCustomData = {},
    style = {},
  }) => {
    const { width, height } = dimensions;
    return newElementWith(lineElement, {
      x: start.x,
      y: start.y,
      id: randomId(),
      customData: { ...propsInCommon.customData, ...lineCustomData },
      height,
      points: [
        [0, 0],
        [width, height],
      ],
      width,
      ...style,
    });
  };

  const getAccumulativeRatios = (ratios) => {
    let cumulativeSum = 0;

    return ratios.reduce((transformedRatios, ratio) => {
      cumulativeSum += ratio;
      transformedRatios.push(cumulativeSum);
      return transformedRatios;
    }, []);
  };

  const createTableVerticalLine = ({ offset, height, lineCustomData }) => {
    return createLine({
      dimensions: { height, width: 0 },
      start: {
        x: offset.x,
        y: offset.y,
      },
      lineCustomData,
    });
  };

  const addPartLabel = ({ start, label, width, offset }) => {
    const [textEl, containerEl] = createCell({
      start,
      offset,
      width,
      height: COVER_SHEET_LABEL_HEIGHT,
      textProps: {
        textString: label,
        fontSize: COVER_SHEET_LABEL_FONT_SIZE,
        textAlign: "left",
      },
    });
    redrawTextBoundingBox(textEl, containerEl);
    return [textEl, containerEl];
  };

  const createTableLabelsCells = ({
    start,
    textMargin,
    width,
    tableKey,
    columns,
  }) => {
    let offsetX = 0;
    const [texts, containers] = [[], []];
    for (let i = 0; i < columns.length; i++) {
      if (!columns[i].active) {
        break;
      }
      const [text, container] = createCell({
        start,
        offset: { x: offsetX, y: 0 },
        width: columns[i].widthRatio * width - textMargin,
        height: COVER_SHEET_LABEL_HEIGHT,
        cellProps: { tableKey, columnKey: columns[i].key, rowNo: 0 },
        style: columns[i].style,
        textProps: {
          textMarginX: textMargin,
          fontSize: COVER_SHEET_LABEL_FONT_SIZE,
          textAlign: "center",
          textString: columns[i].label,
        },
      });
      redrawTextBoundingBox(text, container);
      offsetX += columns[i].widthRatio * width;
      texts.push(text);
      containers.push(container);
    }
    const rowHeight = Math.max(...containers.map((c) => c.height));
    return [texts, containers, rowHeight];
  };

  const createTableCells = ({
    width,
    height,
    start,
    data,
    textAlign,
    hasLabels,
    tableKey,
    columns,
    orientation,
  }) => {
    let offsetY = 0;
    const [rectangles, texts, allHeights, maxHeights] = [[], [], [], []];

    if (hasLabels) {
      const [labelsTexts, labelsContainers, rowHeight] = createTableLabelsCells(
        {
          start,
          textMargin: textAlign === "left" ? 2 : 0,
          width,
          tableKey,
          columns,
        },
      );
      rectangles.push(...labelsContainers);
      texts.push(...labelsTexts);
      maxHeights.push(rowHeight);
      offsetY += rowHeight;
    }
    let rowIndex = 1;
    for (const rowData of data) {
      const textMargin = textAlign === "left" ? 2 : 0;
      let offsetX = 0;
      let i = 0;
      for (const rowCellKey in rowData) {
        if (!columns[i].active) {
          break;
        }
        if (rowCellKey === "rowKey" && orientation === "horizontal") {
          continue;
        }
        const [text, container] = createCell({
          start,
          offset: { x: offsetX, y: offsetY },
          width: columns[i].widthRatio * width - textMargin,
          height,
          cellProps: {
            tableKey,
            columnKey: rowCellKey,
            rowNo: rowIndex,
            rowId: rowData.rowId || "",
            ...(orientation === "horizontal" && rowCellKey !== "label"
              ? { rowKey: rowData.rowKey }
              : {}),
          },
          style: columns[i].style,
          textProps: {
            textMarginX: textMargin,
            fontSize: COVER_SHEET_TEXT_FONT_SIZE,
            textAlign,
            textString: rowData[rowCellKey],
          },
        });

        rectangles.push(container);
        texts.push(text);

        offsetX += columns[i].widthRatio * width;
        redrawTextBoundingBox(text, container);
        allHeights.push(container.height);
        i++;
      }
      const lastNRects = rectangles.slice(-Object.keys(rowData).length); // Extract the last n objects
      const maxRowHeight = Math.max(...lastNRects.map((r) => r.height));
      maxHeights.push(maxRowHeight);
      offsetY += maxRowHeight;
      rowIndex++;
    }

    return [rectangles, texts, maxHeights];
  };

  const fillData = ({
    data,
    rowHeight,
    textAlign,
    width,
    start,
    hasLabels,
    tableKey,
    columns,
    orientation,
  }) => {
    if (!data || !width) {
      return;
    }
    const [textContainers, textElements, cellsHeights] = createTableCells({
      start: { x: start.x, y: start.y },
      width,
      height: rowHeight,
      data,
      textAlign,
      hasLabels,
      tableKey,
      columns,
      orientation,
    });
    const tableHeight = cellsHeights.reduce(
      (accumulativeHeight, height) => accumulativeHeight + height,
      0,
    );
    const accumulatedRatios = getAccumulativeRatios(
      columns.map((el) => el.widthRatio),
    );

    const [horizontalLines, verticalLines] = [[], []];
    //Horizontal Lines
    for (let i = 0; i < cellsHeights.length; i++) {
      const startY =
        i === 0 ? start.y : horizontalLines[horizontalLines.length - 1].y;
      if (i === 0) {
        horizontalLines.push(
          createLine({
            start: { x: start.x, y: start.y },
            dimensions: { height: 0, width },
            lineCustomData: { tableKey, rowNo: hasLabels ? i : i + 1 },
          }),
        );
      }
      horizontalLines.push(
        createLine({
          start: {
            x: start.x,
            y: startY + cellsHeights[i],
          },
          dimensions: { height: 0, width },
          lineCustomData: { tableKey, rowNo: hasLabels ? i : i + 1 },
        }),
      );
    }

    //Vertical Lines
    for (let i = 0; i < accumulatedRatios.length; i++) {
      if (!columns[i].active) {
        continue;
      }
      if (i === 0) {
        verticalLines.push(
          createTableVerticalLine({
            offset: { x: start.x, y: start.y },
            height: tableHeight,
            lineCustomData: { tableKey, leftBorder: true },
          }),
        );
      }
      verticalLines.push(
        createTableVerticalLine({
          offset: {
            x: start.x + accumulatedRatios[i] * width,
            y: start.y,
          },
          height: tableHeight,
          lineCustomData: { tableKey, leftBorder: false },
        }),
      );
    }

    const excalidrawElements = excalidrawAPI.getSceneElements();
    updateScene("elements", [
      ...horizontalLines,
      ...verticalLines,
      ...textContainers,
      ...textElements,
      ...excalidrawElements,
    ]);
  };

  /**
   * @param {object} tableProps - { textAlign, height, width, rowsCount, rowHeight }
   */
  const createTableLayout = ({ start, tableProps }) => {
    const {
      width,
      columns,
      rowHeight,
      textAlign,
      marginX,
      data = [],
      hasLabels,
      key,
      orientation = "vertical",
    } = tableProps;

    fillData({
      data,
      textAlign,
      start: {
        x: start.x + marginX,
        y: start.y,
      },
      rowHeight,
      width: width - 2 * marginX,
      tableKey: key,
      hasLabels,
      columns,
      orientation,
    });
  };

  const createDescriptionCell = ({ start, width, height, description }) => {
    const [descriptionEl, descriptionContainer] = createCell({
      start,
      offset: { x: 0, y: 0 },
      width,
      height,
      textProps: {
        textString: description || "",
        fontSize: 24,
        textAlign: "center",
        fontFamily: "Arial",
      },
    });
    redrawTextBoundingBox(descriptionEl, descriptionContainer);
    return [descriptionEl, descriptionContainer];
  };

  const createAddressCell = ({ start, width, height, address }) => {
    const [addressTextEl, containerEl] = createCell({
      start,
      offset: { x: 0, y: 0 },
      width,
      height,
      textProps: {
        textString: address,
        fontSize: COVER_SHEET_LABEL_FONT_SIZE + 2,
        textAlign: "center",
      },
    });
    redrawTextBoundingBox(addressTextEl, containerEl);
    return [addressTextEl, containerEl];
  };

  const createEntranceExitElements = ({
    elementDims,
    containerDims,
    start,
    offset,
  }) => {
    const textMarginLeft = 15;
    const entranceExitShape = newElementWith(lineElement, {
      points: [
        [0, 0],
        [elementDims.width / 2, elementDims.height],
        [-elementDims.width / 2, elementDims.height],
        [0, 0],
      ],
      backgroundColor: "#000",
      strokeColor: "#000",
      fillStyle: "solid",
      height: elementDims.height,
      width: elementDims.width,
      x: start.x + offset.x,
      y: start.y + offset.y + 15,
      roundness: null,
    });
    const [entranceText, entranceTextContainer] = createCell({
      start: {
        x: start.x + offset.x + entranceExitShape.width / 2 + textMarginLeft,
        y: start.y + offset.y + 13,
      },
      offset: { x: 0, y: 0 },
      width: containerDims.width - offset.x - (3 * elementDims.width) / 2, // offsetX - exitShape / 2 = 50 - 46 / 2 = 27
      height: elementDims.height,
      textProps: {
        textString: ENTRANCE_EXIT,
        textAlign: "left",
        fontSize: 18,
      },
    });

    redrawTextBoundingBox(entranceText, entranceTextContainer);

    return [entranceExitShape, entranceText, entranceTextContainer];
  };

  const createAreaOfWorkElements = ({
    elementDims,
    containerDims,
    start,
    offset,
  }) => {
    const textMarginLeft = 15;
    const areaOfWorkShape = newElementWith(rectangleElement, {
      backgroundColor: "#000",
      strokeColor: "#000",
      fillStyle: "hachure",
      height: elementDims.height,
      width: elementDims.width,
      x: start.x + offset.x,
      y: start.y + offset.y - 15,
      roundness: null,
    });
    const [areaOfWorkText, areaOfWorkTextContainer] = createCell({
      start: {
        x: start.x + offset.x + elementDims.width + textMarginLeft,
        y: start.y + offset.y - 15,
      },
      offset: { x: 0, y: 0 },
      width: containerDims.width - 27 - elementDims.width, // offsetX - exitShape / 2 = 50 - 46 / 2 = 27
      height: elementDims.height,
      textProps: {
        textString: AREA_OF_WORK_TEXT,
        textAlign: "left",
        fontSize: 18,
      },
    });

    redrawTextBoundingBox(areaOfWorkText, areaOfWorkTextContainer);

    return [areaOfWorkShape, areaOfWorkText, areaOfWorkTextContainer];
  };

  //This section contains: project name, project address, google maps location, location plan/legend
  const createContentLeftSection = async (project) => {
    const projectToUse = project || currentProject;
    const partElements = [];
    const ratiosOfSectionParts = [0.2, 0.3, 0.8];
    const start = {
      x: 2 * MARGIN_UNIT + GRID_RULER_SHORT_DIM,
      y: 2 * MARGIN_UNIT + GRID_RULER_SHORT_DIM + 1,
    };
    const totalWidth = COVER_SHEET_CONTENT_WIDTH / 3;
    const totalHeight = COVER_SHEET_CONTENT_HEIGHT - 1;
    //Vertical Line
    partElements.push(
      createLine({
        dimensions: { height: COVER_SHEET_CONTENT_HEIGHT - 1, width: 0 },
        start: {
          x: start.x + COVER_SHEET_CONTENT_WIDTH / 3,
          y: start.y,
        },
      }),
    );

    //Create project address part
    const addressElements = createAddressCell({
      start: { x: start.x, y: start.y + ratiosOfSectionParts[0] * totalHeight },
      width: totalWidth,
      height: 0.1 * totalHeight,
      address: projectToUse.addr,
    });
    partElements.push(...addressElements);

    //FIXME - The image that's rendered first => has "pending" status.

    //Create the entrance / exit indication
    const entranceExitPartHeight = 0.2 * totalHeight;
    const entranceExitShapeWidth = 46;
    const entranceExitOffsetX = 50;
    const entranceExitElements = createEntranceExitElements({
      elementDims: { width: entranceExitShapeWidth, height: 16 },
      containerDims: { width: totalWidth, height: entranceExitPartHeight },
      start: {
        x: start.x,
        y: start.y + 0.8 * totalHeight,
      },
      offset: { x: entranceExitOffsetX, y: 0.3 * entranceExitPartHeight },
    });
    partElements.push(...entranceExitElements);

    //Create site / area of work indication
    const areaOfWorkShapeDim = 35;
    const areaOfWorkElements = createAreaOfWorkElements({
      elementDims: { width: areaOfWorkShapeDim, height: areaOfWorkShapeDim },
      containerDims: { width: totalWidth, height: entranceExitPartHeight },
      start: {
        x:
          start.x -
          entranceExitShapeWidth / 2 +
          (entranceExitShapeWidth - areaOfWorkShapeDim) / 2,
        y: start.y + 0.8 * totalHeight - areaOfWorkShapeDim,
      },
      offset: { x: entranceExitOffsetX, y: 0.7 * entranceExitPartHeight },
    });
    partElements.push(...areaOfWorkElements);

    //Horizontal Lines & Labels
    ratiosOfSectionParts.forEach((ratio, index) => {
      const newStart = {
        x: start.x,
        y: start.y + ratio * totalHeight,
      };
      if (index === 0) {
        const [labelTextEl, labelContainerEl] = addPartLabel({
          start: { x: start.x, y: start.y },
          label: LEFT_SECTION_PARTS_LABELS[index],
          width: totalWidth,
          offset: { x: 3, y: 0 },
        });
        partElements.push(...[labelTextEl, labelContainerEl]);
      }
      partElements.push(
        createLine({
          dimensions: { height: 0, width: totalWidth },
          start: newStart,
        }),
      );
      const [labelTextEl, labelContainerEl] = addPartLabel({
        start: newStart,
        label: LEFT_SECTION_PARTS_LABELS[index + 1],
        width: totalWidth,
        offset: { x: 3, y: 0 },
      });
      partElements.push(...[labelTextEl, labelContainerEl]);
    });

    return partElements;
  };

  const convertSeparateUpperCaseWordsToCamelCase = (key) => {
    const words = key.toLowerCase().split(" ");
    const convertedKey = words
      .map((word, index) => {
        if (index === 0) {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join("");
    return convertedKey;
  };

  const isExistingInCoverSheetData = (key, coverSheetData) => {
    const keysOfData = Object.keys(coverSheetData);
    let isExisted = false;
    for (let i = 0; i < keysOfData.length; i++) {
      if (key.toLowerCase().includes(keysOfData[i].toLowerCase())) {
        isExisted = true;
        return isExisted;
      }
      isExisted = false;
    }
    return isExisted;
  };

  const getProjectSummaryData = async (coverSheetData, projectToUse) => {
    if (!coverSheetData || !projectToUse?.id) {
      return;
    }
    const occupancyTypes = await getOccupancyTypes();
    const dominantPropertyOccupancy = await getIconAndCoverById(
      projectToUse.dominantPropertyOccupancy,
    );
    const dominantProjectOccupancy = projectToUse.iconAndCover;
    const dominantProjectOccupancyType = occupancyTypes.find(
      (el) => el.id === dominantProjectOccupancy.occupancyTypeId,
    );
    const dominantPropertyOccupancyType = occupancyTypes.find(
      (el) => el.id === dominantPropertyOccupancy.occupancyTypeId,
    );
    const {
      typeOfConstruction,
      propertyLevelsAboveGround,
      propertyLevelsBelowGround,
      totalPropertyHeight,
      totalBuildingArea,
      isFloodHazardArea,
    } = coverSheetData;

    return PROJECT_SUMMARY_DATA.map((el) => {
      const floorsOfWork = currentDrawings?.map((el) => el.nomenclature) || [];
      if (el.label === "DOMINANT PROJECT OCCUPANCY TYPE/GROUP") {
        const result = `${dominantProjectOccupancy.occupancyGroup}/${
          dominantProjectOccupancyType?.name || ""
        }`;
        return { ...el, value: result };
      } else if (el.label === "DOMINANT PROJECT OCCUPANCY USE") {
        const result = `${dominantProjectOccupancy.occupancyUse}`;
        return {
          ...el,
          value: result,
        };
      } else if (el.label === "DOMINANT PROPERTY OCCUPANCY TYPE/GROUP") {
        const result = `${dominantPropertyOccupancy.occupancyGroup}/${dominantPropertyOccupancyType.name}`;
        return { ...el, value: result };
      } else if (el.label === "DOMINANT PROPERTY OCCUPANCY USE") {
        const result = `${dominantPropertyOccupancy.occupancyUse}`;
        return { ...el, value: result };
      } else if (el.label === "TYPE OF CONSTRUCTION") {
        return { ...el, value: typeOfConstruction };
      } else if (el.label === "PROJECT FLOOR(S) OF WORK") {
        return { ...el, value: floorsOfWork.join(", ") };
      } else if (el.label === "DOES THIS PROJECT OCCUPY ENTIRE PROPERTY?") {
        const result =
          Number(propertyLevelsAboveGround) +
            Number(propertyLevelsBelowGround) ===
          floorsOfWork.length
            ? "YES"
            : "NO";
        return { ...el, value: result };
      } else if (el.label === "TOTAL PROPERTY LEVELS") {
        const result =
          Number(propertyLevelsAboveGround) + Number(propertyLevelsBelowGround);
        return { ...el, value: String(result) };
      } else if (el.label === "PROPERTY TYPE") {
        const result =
          Number(totalPropertyHeight) > 75 ? "High-RISE" : "LOW-RISE";
        return { ...el, value: result };
      } else if (el.label === "TOTAL BUILDING AREA (SQR. FEET)") {
        const result =
          currentDrawings?.reduce((accumulatedArea, currentDrawing) => {
            return accumulatedArea + Number(currentDrawing.floorArea);
          }, 0) || 0;
        return {
          ...el,
          value: `${totalBuildingArea} (${result.toFixed(
            globalSettings.decimalPoints,
          )} IN PROJECT)`,
        };
      } else if (el.label === "SPECIAL FLOOD HAZARD AREA?") {
        const result = isFloodHazardArea ? "YES" : "NO";
        return { ...el, value: result };
      } else if (
        isExistingInCoverSheetData(
          convertSeparateUpperCaseWordsToCamelCase(el.label),
          coverSheetData,
        )
      ) {
        const keyToUse = Object.keys(coverSheetData).find((k) =>
          convertSeparateUpperCaseWordsToCamelCase(el.label)
            .toLowerCase()
            .includes(k.toLowerCase()),
        );
        return {
          ...el,
          value: coverSheetData[keyToUse] || "",
        };
      }
      return el;
    });
  };

  const relatedCodesHandler = async (codesCombinationId) => {
    try {
      if (!codesCombinationId) {
        return;
      }
      const codesCombination = await getCodesGroupById(codesCombinationId);
      const buildingCode = await getCodeItemById(
        codesCombination.buildingCodeId,
      );
      const electricalCode = await getCodeItemById(
        codesCombination.electricalCodeId,
      );
      const nfpaCode = await getCodeItemById(codesCombination.nfpaCodeId);
      return { buildingCode, electricalCode, nfpaCode };
    } catch (error) {
      console.error(
        "Couldn't set the codes related to this combination ",
        error,
      );
    }
  };

  const getCodeReferenceData = async (codesCombinationId) => {
    if (!codesCombinationId) {
      return;
    }
    const relatedCodes = await relatedCodesHandler(codesCombinationId);
    return MIDDLE_SECTION_SECOND_TABLE_DATA.map((el) => ({
      ...el,
      title: relatedCodes[el.rowKey]?.name || "",
    }));
  };

  //This section contains: system description, project summary, code reference
  const createContentMiddleSection = async (project) => {
    const projectToUse = project || currentProject;
    const coverSheetDataId = projectToUse.coverSheetDataId;
    const coverSheetData = await getCoverSheetById(coverSheetDataId);
    const partElements = [];
    const start = {
      x: 2 * MARGIN_UNIT + GRID_RULER_SHORT_DIM + COVER_SHEET_CONTENT_WIDTH / 3,
      y: 2 * MARGIN_UNIT + GRID_RULER_SHORT_DIM + 1,
    };
    const totalWidth = COVER_SHEET_CONTENT_WIDTH / 3;
    const totalHeight = COVER_SHEET_CONTENT_HEIGHT - 1;

    //Vertical Line
    partElements.push(
      createLine({
        dimensions: { height: COVER_SHEET_CONTENT_HEIGHT - 1, width: 0 },
        start: {
          x: start.x + COVER_SHEET_CONTENT_WIDTH / 3,
          y: start.y,
        },
      }),
    );

    const descriptionElements = createDescriptionCell({
      start,
      width: totalWidth,
      height: 0.2 * totalHeight,
      description: projectToUse.systemDescription,
    });
    partElements.push(...descriptionElements);

    //Horizontal Lines & Labels
    [0.2, 0.8].forEach((ratio, index) => {
      const newStart = {
        x: start.x,
        y: start.y + ratio * totalHeight,
      };
      if (index === 0) {
        const [labelTextEl, labelContainerEl] = addPartLabel({
          start: { x: start.x, y: start.y },
          label: MIDDLE_SECTION_PARTS_LABELS[index],
          width: totalWidth,
          offset: { x: 3, y: 0 },
        });
        partElements.push(...[labelTextEl, labelContainerEl]);
      }
      const [labelTextEl, labelContainerEl] = addPartLabel({
        start: newStart,
        label: MIDDLE_SECTION_PARTS_LABELS[index + 1],
        width: totalWidth,
        offset: { x: 3, y: 0 },
      });
      partElements.push(...[labelTextEl, labelContainerEl]);

      partElements.push(
        createLine({
          dimensions: { height: 0, width: totalWidth },
          start: newStart,
        }),
      );
    });

    const projectSummaryData = await getProjectSummaryData(
      coverSheetData,
      projectToUse,
    );

    const codeReferencesData = await getCodeReferenceData(
      coverSheetData.codesCombinationId,
    );

    createTableLayout({
      start: {
        x:
          2 * MARGIN_UNIT +
          GRID_RULER_SHORT_DIM +
          COVER_SHEET_CONTENT_WIDTH / 3 +
          1,
        y:
          2 * MARGIN_UNIT +
          GRID_RULER_SHORT_DIM +
          COVER_SHEET_LABEL_HEIGHT +
          0.2 * totalHeight +
          1,
      },
      tableProps: {
        width: COVER_SHEET_CONTENT_WIDTH / 3 - 2,
        columns: PROJECT_SUMMARY_TABLE_COLUMNS,
        rowsCount: 15,
        rowHeight: COVER_SHEET_LABEL_HEIGHT,
        marginX: 5,
        textAlign: "left",
        data: projectSummaryData,
        key: "projectSummary",
        orientation: "horizontal",
      },
    });

    createTableLayout({
      start: {
        x:
          2 * MARGIN_UNIT +
          GRID_RULER_SHORT_DIM +
          COVER_SHEET_CONTENT_WIDTH / 3 +
          1,
        y:
          2 * MARGIN_UNIT +
          GRID_RULER_SHORT_DIM +
          COVER_SHEET_LABEL_HEIGHT +
          0.8 * totalHeight +
          1,
      },
      tableProps: {
        width: COVER_SHEET_CONTENT_WIDTH / 3 - 2,
        columns: CODE_REFERENCE_TABLE_COLUMNS,
        rowsCount: 5,
        rowHeight: COVER_SHEET_LABEL_HEIGHT,
        marginX: 5,
        textAlign: "left",
        data: codeReferencesData,
        hasLabels: true,
        key: "codeReference",
        orientation: "horizontal",
      },
    });

    return partElements;
  };

  //This section contains: drawing list
  const createContentRightSection = (currentDrawings) => {
    const partElements = [];
    const start = {
      x:
        2 * MARGIN_UNIT +
        GRID_RULER_SHORT_DIM +
        (2 * COVER_SHEET_CONTENT_WIDTH) / 3,
      y: 2 * MARGIN_UNIT + GRID_RULER_SHORT_DIM + 1,
    };
    const width = COVER_SHEET_CONTENT_WIDTH / 3;

    const [labelTextEl, labelContainerEl] = addPartLabel({
      start,
      label: RIGHT_SECTION_PART_LABEL,
      width,
      offset: { x: 3, y: 0 },
    });
    partElements.push(...[labelTextEl, labelContainerEl]);

    const data = [
      {
        sheetNumber: formatDrawingOrder(1, currentDrawings?.length + 1),
        fileCode: "FA-001",
        sheetTitle: "COVER SHEET",
      },
      ...currentDrawings?.map((d) => {
        return {
          sheetNumber: formatDrawingOrder(d.order, currentDrawings?.length + 1),
          fileCode: `FA-${String(d.order).padStart(3, "0")}`,
          sheetTitle: d.name.toUpperCase(),
          rowId: d.id,
        };
      }),
    ];
    createTableLayout({
      start: {
        x:
          2 * MARGIN_UNIT +
          GRID_RULER_SHORT_DIM +
          (2 * COVER_SHEET_CONTENT_WIDTH) / 3 +
          1,
        y:
          2 * MARGIN_UNIT + GRID_RULER_SHORT_DIM + COVER_SHEET_LABEL_HEIGHT + 1,
      },
      tableProps: {
        width: COVER_SHEET_CONTENT_WIDTH / 3 + 2 * MARGIN_UNIT - 2,
        columns: DRAWING_LIST_TABLE_COLUMNS,
        rowsCount: 17,
        rowHeight: COVER_SHEET_LABEL_HEIGHT,
        marginX: 5,
        textAlign: "center",
        data,
        hasLabels: true,
        key: "drawingsList",
        orientation: "vertical",
      },
    });

    return partElements;
  };

  const getDrawingsToDelete = (currentDrawings, previousDrawings) => {
    const drawingsToDelete = [];
    for (const prevDrawing of previousDrawings) {
      let isToDelete = true;
      for (const currDrawing of currentDrawings) {
        if (prevDrawing.id === currDrawing.id) {
          isToDelete = false;
        }
      }
      isToDelete &&
        drawingsToDelete.push({
          action: "toDelete",
          drawing: prevDrawing,
        });
    }
    return drawingsToDelete;
  };

  const getDrawingsToAdd = (currentDrawings, previousDrawings) => {
    const drawingsToAdd = [];
    for (const currDrawing of currentDrawings) {
      let isToAdd = true;
      for (const prevDrawing of previousDrawings) {
        if (prevDrawing.id === currDrawing.id) {
          isToAdd = false;
        }
      }
      isToAdd &&
        drawingsToAdd.push({
          action: "toAdd",
          drawing: currDrawing,
        });
    }
    return drawingsToAdd;
  };
  const isDrawingsListChanged = (tableDrawings, currentDrawings) => {
    const drawingsToUse =
      currentDrawings?.filter((el) => el.type !== "coverSheet") || [];
    const isSameDrawings = checkIfNoNewElements(drawingsToUse, tableDrawings);
    if (
      !tableDrawings ||
      (drawingsToUse.length === tableDrawings.length && isSameDrawings)
    ) {
      return false;
    }
    const changes = [
      ...getDrawingsToAdd(drawingsToUse, tableDrawings),
      ...getDrawingsToDelete(drawingsToUse, tableDrawings),
    ];
    return !changes.length ? false : changes;
  };

  const convertDrawingToCellObject = (drawing) => {
    const drawingsToUse =
      currentDrawings?.filter((el) => el.type !== "coverSheet") || [];
    return {
      sheetNumber: formatDrawingOrder(
        drawing.order,
        Number(getLargestOrder(drawingsToUse)) + 1,
      ),
      fileCode: `FA-${String(drawing.order).padStart(3, "0")}`,
      sheetTitle: drawing.name.toUpperCase(),
      rowId: drawing.id,
    };
  };

  const addDrawingHandler = (drawingToAdd, sceneElements) => {
    const data = convertDrawingToCellObject(drawingToAdd);
    const newAddedElements = addRow({
      tableKey: "drawingsList",
      rowNo: drawingToAdd.order,
      data,
      textProps: {},
      sceneElements,
    });
    updateCoverSheetScene(newAddedElements);
  };

  const deleteRows = ({
    drawingsToDelete,
    currentDrawingsLength,
    allElements,
    tableProps,
  }) => {
    const updatedElements = deleteRow({
      tableKey: "drawingsList",
      rowsToDelete: drawingsToDelete.map((d) => d.order),
      currentDrawingsLength,
      allElements,
      tableProps,
    });
    return updatedElements;
  };

  const checkIfNoNewElements = (array1, array2) => {
    if (array1?.length !== array2?.length) {
      return false;
    }
    const similarDrawings = [];
    for (const el1 of array1) {
      for (const el2 of array2) {
        if (el1.id === el2.id) {
          similarDrawings.push(el1);
        }
      }
    }
    if (similarDrawings.length === array1.length) {
      return true;
    }
    return false;
  };

  const checkIfJustDifferentNumbering = (previousDrawings, currentDrawings) => {
    const drawingsToUse =
      currentDrawings?.filter((el) => el.type !== "coverSheet") || [];
    const isSameElements = checkIfNoNewElements(
      drawingsToUse,
      previousDrawings,
    );

    if (
      !drawingsToUse?.length ||
      !previousDrawings?.length ||
      !isSameElements ||
      drawingsToUse.length !== previousDrawings.length
    ) {
      return [];
    }
    const changedDrawings = [];
    const sortAndFilter = (drawings) => {
      return drawings.sort((a, b) => a.id.localeCompare(b.id));
    };
    const sortedCurrentDrawings = sortAndFilter(drawingsToUse);
    const sortedPreviousDrawings = sortAndFilter(previousDrawings);

    for (let i = 0; i < drawingsToUse.length; i++) {
      if (sortedCurrentDrawings[i].order !== sortedPreviousDrawings[i].order) {
        changedDrawings.push(sortedCurrentDrawings[i]);
      }
    }
    return changedDrawings;
  };

  const reOrderTableDrawings = async (changedDrawings) => {
    if (!changedDrawings?.length) {
      return;
    }
    let sceneElements = await getCoverSheetElements();
    sceneElements = sceneElements.map((el) => {
      let updatedElement = null;
      for (const changedDrawing of changedDrawings) {
        const { tableKey, rowNo, columnKey } = el.customData || {};
        const isElementToUpdate =
          tableKey === "drawingsList" && rowNo === changedDrawing.order;
        if (isElementToUpdate) {
          updatedElement = newElementWith(el, {
            customData: {
              ...el.customData,
              rowId: changedDrawing.id,
            },
          });
          if (el.type === "text" && columnKey === "sheetTitle") {
            updatedElement = {
              ...updatedElement,
              text: changedDrawing.name.toUpperCase(),
              originalText: changedDrawing.name.toUpperCase(),
            };
            const updatedContainerEl = sceneElements.find(
              (ele) => ele.id === updatedElement.containerId,
            );
            Object.assign(updatedContainerEl, {
              height: updatedContainerEl.height - 20,
            });
            redrawTextBoundingBox(updatedElement, updatedContainerEl);
          }
        }
      }
      return updatedElement || el;
    });
    return sceneElements;
  };

  const doAfterLoadingScene = (functionToCall) => {
    let elapsedTime = 0;
    const timeLimit = 60000; // 1 minute (in milliseconds)
    const openingIntervalId = setInterval(() => {
      const elements = excalidrawAPI.getSceneElements();

      if (elements.length !== 0) {
        // Scene loaded
        clearInterval(openingIntervalId);
        functionToCall();
      } else {
        elapsedTime += 100;
        if (elapsedTime >= timeLimit) {
          clearInterval(intervalId);
        }
      }
    }, 100);
    setIntervalId(openingIntervalId);
  };

  const getUpdatedEl = (oldEl, updatedValue, options) => {
    if (
      !updatedValue &&
      (oldEl.customData?.rowKey === "propertyLevelsAboveGround" ||
        oldEl.customData?.rowKey === "propertyLevelsBelowGround")
    ) {
      updatedValue = "0";
    }
    updatedValue = formatNumbersInsideText(updatedValue);

    const newTextFontString = getFontString(oldEl);
    const newTextWidth = measureText(updatedValue, newTextFontString)?.width;
    return newElementWith(oldEl, {
      text: updatedValue,
      originalText: updatedValue,
      width: newTextWidth,
      ...options,
    });
  };

  const updateNameInDrawingsList = async (name, drawingId) => {
    let sceneElements = await getCoverSheetElements();
    const isDrawingNameCell = ({ type, customData }) => {
      return (
        customData?.tableKey === "drawingsList" &&
        customData?.columnKey === "sheetTitle" &&
        customData?.rowId === drawingId &&
        type === "text"
      );
    };
    sceneElements = sceneElements.map((el) => {
      if (isDrawingNameCell(el)) {
        return getUpdatedEl(el, String(name).trim().toUpperCase(), {
          textAlign: "center",
        });
      }
      return el;
    });
    updateScene("elements", sceneElements);
  };

  const updateCodeReference = async (updates) => {
    doAfterLoadingScene(async () => {
      let sceneElements = await getCoverSheetElements();
      if (!Object.keys(updates).length) {
        return;
      }
      const isInTable = ({ type, customData }) =>
        customData?.tableKey === "codeReference" &&
        type === "text" &&
        customData?.rowKey;
      const fieldsToUpdate = Object.keys(updates);
      sceneElements = sceneElements.map((el) => {
        const { rowKey: elementRowKey } = el.customData || {};
        if (isInTable(el) && fieldsToUpdate.includes(elementRowKey)) {
          const updatedValue = updates[elementRowKey];
          return getUpdatedEl(el, updatedValue);
        }
        return el;
      });

      updateScene("elements", sceneElements);
    });
  };

  const getCoverSheetElements = async () => {
    return await getCurrentElements(currentProject.coverSheetRoom);
  };

  const updateProjectSummary = async (updates, allData = null) => {
    doAfterLoadingScene(async () => {
      let sceneElements = await getCoverSheetElements();
      if ((!updates?.length && !allData) || !sceneElements.length) {
        return;
      }
      const isInTable = ({ type, customData }) =>
        customData?.tableKey === "projectSummary" &&
        type === "text" &&
        customData?.rowKey;
      const fieldsToUpdate = updates?.map((u) => u.key) || Object.keys(allData);
      sceneElements = sceneElements.map((el) => {
        const { rowKey: elementRowKey } = el.customData || {};
        if (isInTable(el) && fieldsToUpdate.includes(elementRowKey)) {
          const updatedValue =
            updates?.find((u) => u.key === elementRowKey)?.value ||
            allData[elementRowKey];
          return getUpdatedEl(el, updatedValue);
        }
        return el;
      });

      updateScene("elements", sceneElements);
    });
  };

  const updateCoverSheetScene = async (updatedSceneElements) => {
    if (currentProject.coverSheetRoom === location.hash) {
      //Current scene => local update
      updateScene("elements", updatedSceneElements);
    } else {
      //Remote scene => remote update
      await updateRemoteScene({
        updates: updatedSceneElements,
        roomLink: currentProject.coverSheetRoom,
        isUpdateAll: true,
      });
    }
  };

  const updateDrawingsList = async (currentDrawings) => {
    const allElements = await getCoverSheetElements();
    const tableProps = getTableProps("drawingsList", allElements);
    const tableDrawings = tableProps.data
      ?.filter((d) => d.order > 1)
      .map((d) => ({
        id: d.rowId,
        name: d.sheetTitle,
        order: d.order,
        rowId: d.order,
      }));
    const changes = isDrawingsListChanged(tableDrawings, currentDrawings);
    const drawingsWithDifferentNumbers = checkIfJustDifferentNumbering(
      tableDrawings,
      currentDrawings,
    );
    if (!!drawingsWithDifferentNumbers.length) {
      const reorderedElements = await reOrderTableDrawings(
        drawingsWithDifferentNumbers,
      );
      await updateCoverSheetScene(reorderedElements);
    } else {
      if (!changes?.length) {
        return;
      }
      const [drawingsToAdd, drawingsToDelete] = [[], []];
      for (const c of changes) {
        if (c.action === "toDelete") {
          drawingsToDelete.push(c.drawing);
        }
      }

      if (drawingsToDelete.length) {
        const updatedSceneElements = deleteRows({
          drawingsToDelete,
          currentDrawingsLength: currentDrawings?.length,
          allElements,
          tableProps,
        });
        await updateCoverSheetScene(updatedSceneElements);
      }
      for (const c of changes) {
        if (c.action === "toAdd") {
          drawingsToAdd.push(c.drawing);
          addDrawingHandler(c.drawing, allElements);
        }
      }
    }
  };

  const renderLeftSectionImages = (project) => {
    const start = {
      x: 2 * MARGIN_UNIT + GRID_RULER_SHORT_DIM,
      y: 2 * MARGIN_UNIT + GRID_RULER_SHORT_DIM + 1,
    };
    const totalWidth = COVER_SHEET_CONTENT_WIDTH / 3;
    const totalHeight = COVER_SHEET_CONTENT_HEIGHT - 1;
    const projectHeaderPart = 0.2 * totalHeight;
    const northIconWidth = 0.2 * totalHeight;

    //Render project logo
    renderImageElement({
      url: project.projectImage,
      width: "auto",
      height: 0.8 * projectHeaderPart,
      start: { x: start.x, y: start.y },
      offset: { x: 0, y: 0 },
      justify: "center",
      align: "center",
      imageType: "project-logo",
      container: { height: projectHeaderPart, width: totalWidth },
    });

    //Render map logo
    renderImageElement({
      url: project.mapImage || LEFT_SECTION_PROJECT_LOCATION,
      height: "auto",
      width: 0.95 * totalWidth,
      start: { x: start.x, y: start.y + 0.3 * totalHeight },
      offset: { x: 0, y: 0 },
      justify: "center",
      align: "center",
      imageType: "map",
      container: { height: 0.5 * totalHeight, width: totalWidth },
    });

    //Render north image
    renderImageElement({
      url: NORTH_ICON,
      height: 0.4 * northIconWidth,
      width: "auto",
      start: { x: start.x, y: start.y + 0.8 * totalHeight },
      offset: { x: -20, y: 0 },
      justify: "right",
      align: "center",
      imageType: "north-icon",
      container: { height: northIconWidth, width: totalWidth },
    });
  };

  const renderCoverSheet = async (
    drawingToUse,
    projectToUse,
    currentDrawings,
  ) => {
    try {
      const leftSectionLines = await createContentLeftSection(projectToUse);
      const middlePartLines = await createContentMiddleSection(projectToUse);
      const rightPartLines = createContentRightSection(currentDrawings);
      const frameElements = await drawCoverSheetFrame({
        drawingToUse,
        projectToUse,
      });
      const currentSceneElements = excalidrawAPI?.getSceneElements();

      renderLeftSectionImages(projectToUse);

      updateScene("elements", [
        ...leftSectionLines,
        ...middlePartLines,
        ...rightPartLines,
        ...frameElements,
        ...currentSceneElements,
      ]);
      excalidrawAPI.zoomToFitAllDrawing();
    } catch (error) {
      console.error(error);
      triggerToast(
        "Couldn't load the cover sheet. Please, remove it from the pages manager and try again.",
        "error",
      );
    }
  };

  //Return
  return {
    renderCoverSheet,
    getCoverSheetElements,
    updateNameInDrawingsList,
    updateCodeReference,
    updateProjectSummary,
    updateDrawingsList,
    deleteRows,
    addDrawingHandler,
    checkIfOccupyingEntireProperty,
  };
};
