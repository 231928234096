import React from "react";

import { Tooltip } from "@mui/material";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";

const BASIC_STYLE = { marginLeft: "5px", fontSize: "1.8rem" };

const ValidationTooltip = ({
  hidden,
  isValid,
  successMessage,
  errorMessage,
  style = {},
}) => {
  return !hidden ? (
    <Tooltip title={isValid ? successMessage : errorMessage}>
      {isValid ? (
        <DoneAllRoundedIcon
          sx={{
            fill: "green",
            ...BASIC_STYLE,
            ...style,
          }}
        />
      ) : (
        <WarningAmberRoundedIcon
          sx={{
            fill: "red",
            ...BASIC_STYLE,
            ...style,
          }}
        />
      )}
    </Tooltip>
  ) : null;
};

export default ValidationTooltip;
