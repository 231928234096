import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Button } from "@mui/material";

import "../scss/CloseButton.scss";

const CloseButton = ({ onClick, style, classes = [] }) => {
  const classesToApply = `close-button-component ${classes.join(" ")}`;
  return (
    <p className={classesToApply}>
      <Button
        color="error"
        variant="outlined"
        title="Close"
        size="small"
        sx={{ minWidth: "32px", padding: "3px", ...style }}
        onClick={onClick}
      >
        <CloseOutlinedIcon />
      </Button>
    </p>
  );
};

export default CloseButton;
