import _ from "lodash";
import earcut from "earcut";
import { getCommonBounds } from "../ExcalidrawAPI/element";
import { jotaiStore } from "../ExcalidrawAPI/jotai";
import { excalidrawApiAtom } from "../store/variables";

const intersects = require("intersects");

/**
 * @param {{x: number; y: number}} p
 * @param {object} polygon
 * @returns boolean
 */
export const isOnePointInPolygon = (p, polygon) => {
  p = [p.x, p.y];
  const vertices = polygon.points.map((p) => [
    p[0] + polygon.x,
    p[1] + polygon.y,
  ]);

  let odd = false;
  for (let i = 0, j = vertices.length - 1; i < vertices.length; i++) {
    if (
      vertices[i][1] > p[1] !== vertices[j][1] > p[1] &&
      p[0] <
        ((vertices[j][0] - vertices[i][0]) * (p[1] - vertices[i][1])) /
          (vertices[j][1] - vertices[i][1]) +
          vertices[i][0]
    ) {
      odd = !odd;
    }
    j = i;
  }
  return odd;
};

export const isOnePointInRectangle = (point, rect) => {
  const { x, y } = point;
  return (
    x >= rect.x &&
    x <= rect.x + rect.width &&
    y >= rect.y &&
    y <= rect.y + rect.height
  );
};

export const isPointInEllipse = (point, ellipse) => {
  const { x, y } = point;
  const a = ellipse.width / 2; // Semi-major axis
  const b = ellipse.height / 2; // Semi-minor axis
  const h = ellipse.x + a; // x-coordinate of the center
  const k = ellipse.y + b; // y-coordinate of the center

  const formula = (x - h) ** 2 / a ** 2 + (y - k) ** 2 / b ** 2;

  return formula <= 1;
};

export const checkIfPointInArea = (point) => {
  const excalidrawAPI = jotaiStore.get(excalidrawApiAtom);
  const allSceneElements = excalidrawAPI.getSceneElements();
  const currentAreas = allSceneElements.filter(
    (el) => el.customData?.isSelectedArea,
  );

  for (const a of currentAreas) {
    if (
      (a.type === "rectangle" && isOnePointInRectangle(point, a)) ||
      (a.type === "ellipse" && isPointInEllipse(point, a)) ||
      (a.type === "line" && isPointInPolygon(point, a))
    ) {
      return a;
    }
  }
  return false;
};

export const checkIfDblClickedOnArea = (point, el) => {
  if (el.type === "rectangle" || el.type === "image" || el.type === "text") {
    return isOnePointInRectangle(point, el);
  } else if (el.type === "ellipse") {
    return isPointInEllipse(point, el);
  } else if (el.type === "line") {
    return isOnePointInPolygon({ x: point.x, y: point.y }, el);
  }
};

//------ COLLISION LOGIC ------
export const getRectanglePoints = (rect) => {
  const { x, y, width, height } = rect;
  return [x, y, x + width, y, x + width, y + height, x, y + height];
};

const getPolygonPoints = (polygon) => {
  if (polygon.type !== "line") {
    return [];
  }
  return _.flatten(
    polygon.points.map((p) => [p[0] + polygon.x, p[1] + polygon.y]),
  );
};

const ellipsePolygonClues = [
  "ellipse-rectangle",
  "rectangle-ellipse",
  "ellipse-line",
  "line-ellipse",
];

const polygonPolygonClues = [
  "line-line",
  "rectangle-rectangle",
  "rectangle-line",
  "line-rectangle",
  "image-image", //For the devices Collision with each other
];
const ellipseEllipseClues = ["ellipse-ellipse"];

const createPairsOfPoints = (flattenedPoints) => {
  const arrayOfPairs = [];
  for (let i = 0; i < flattenedPoints.length; i += 2) {
    if (flattenedPoints[i + 1]) {
      arrayOfPairs.push([flattenedPoints[i], flattenedPoints[i + 1]]);
    }
  }
  return arrayOfPairs;
};

const convertAreaPointsToTriangles = (areaPoints) => {
  const rawAreaTriangles = earcut(areaPoints);
  const areaPointsAsPairs = createPairsOfPoints(areaPoints);
  const movingTrianglesToUse = [];

  for (let i = 0; i < rawAreaTriangles.length; i += 3) {
    movingTrianglesToUse.push([
      areaPointsAsPairs[rawAreaTriangles[i]],
      areaPointsAsPairs[rawAreaTriangles[i + 1]],
      areaPointsAsPairs[rawAreaTriangles[i + 2]],
    ]);
  }

  return movingTrianglesToUse;
};

const isPolygonPolygonIntersectingUsingTriangles = (
  movingPoints,
  checkingPoints,
) => {
  const movingTrianglesToUse = convertAreaPointsToTriangles(movingPoints);
  const checkingTrianglesToUse = convertAreaPointsToTriangles(checkingPoints);

  for (let i = 0; i < movingTrianglesToUse.length; i++) {
    for (let j = 0; j < checkingTrianglesToUse.length; j++) {
      if (
        intersects.polygonPolygon(
          _.flatten(movingTrianglesToUse[i]),
          _.flatten(checkingTrianglesToUse[j]),
        )
      ) {
        return true;
      }
    }
  }

  return false;
};

const isPolygonEllipseIntersecting = (movingArea, checkingArea) => {
  let checkingPoints = [];
  const notEllipseArea =
    movingArea.type !== "ellipse" ? movingArea : checkingArea;
  const ellipseArea = movingArea.type === "ellipse" ? movingArea : checkingArea;
  if (notEllipseArea.type === "line") {
    checkingPoints = getPolygonPoints(notEllipseArea);
  } else {
    checkingPoints = getRectanglePoints(notEllipseArea);
  }
  return intersects.ellipsePolygon(
    ellipseArea.x + ellipseArea.width / 2,
    ellipseArea.y + ellipseArea.height / 2,
    ellipseArea.width / 2,
    ellipseArea.height / 2,
    checkingPoints,
  );
};

const isEllipseEllipseIntersecting = (movingArea, checkingArea) => {
  return intersects.ellipseEllipse(
    movingArea.x + movingArea.width / 2,
    movingArea.y + movingArea.height / 2,
    movingArea.width / 2,
    movingArea.height / 2,
    checkingArea.x + checkingArea.width / 2,
    checkingArea.y + checkingArea.height / 2,
    checkingArea.width / 2,
    checkingArea.height / 2,
  );
};

const isPolygonPolygonIntersecting = (movingArea, checkingArea) => {
  let [movingPoints, checkingPoints] = [[], []];
  if (movingArea.type === "rectangle") {
    movingPoints = getRectanglePoints(movingArea);
  } else {
    movingPoints = getPolygonPoints(movingArea);
  }
  if (checkingArea.type === "rectangle") {
    checkingPoints = getRectanglePoints(checkingArea);
  } else {
    checkingPoints = getPolygonPoints(checkingArea);
  }
  return isPolygonPolygonIntersectingUsingTriangles(
    movingPoints,
    checkingPoints,
  );
};

export const detectCollision = (movingArea, checkingArea) => {
  const detectionClue = `${movingArea.type}-${checkingArea.type}`;
  //Ellipse with Polygon
  if (ellipsePolygonClues.includes(detectionClue)) {
    return isPolygonEllipseIntersecting(movingArea, checkingArea);
  }
  //Ellipse with Ellipse
  else if (ellipseEllipseClues.includes(detectionClue)) {
    return isEllipseEllipseIntersecting(movingArea, checkingArea);
  }
  //Polygon with Polygon
  else if (polygonPolygonClues.includes(detectionClue)) {
    return isPolygonPolygonIntersecting(movingArea, checkingArea);
  }
};

export const checkIfAreasCollided = (
  selectedElements,
  allElements,
  pointerX = 0,
  pointerY = 0,
) => {
  const [x1, y1] = getCommonBounds(selectedElements);
  let offset = { x: 0, y: 0 };
  if (pointerX || pointerY) {
    offset = { x: pointerX - x1, y: pointerY - y1 };
  }

  const movingArea = selectedElements.find(
    (el) => el.customData?.isSelectedArea,
  );
  const allAreas = allElements.filter(
    (el) =>
      el.customData?.isSelectedArea &&
      el.type !== "text" &&
      el.id !== movingArea?.id,
  );

  let isValidToDrag = true;

  if (movingArea) {
    for (const a of allAreas) {
      if (
        detectCollision(
          {
            ...movingArea,
            x: movingArea.x + offset.x,
            y: movingArea.y + offset.y,
          },
          a,
        )
      ) {
        isValidToDrag = false;
        break;
      }
    }
  }
  return isValidToDrag;
};

export const checkIfDevicesCollided = (
  selectedElements,
  allElements,
  pointerX,
  pointerY,
) => {
  const [x1, y1] = getCommonBounds(selectedElements);
  const offset = { x: pointerX - x1, y: pointerY - y1 };

  const movingDevice =
    selectedElements.length ===
      1 /* To make sure that the user is moving the device only*/ &&
    selectedElements.find((el) => el.customData?.imageType === "device");
  const allDevices = allElements.filter(
    (el) => el.customData?.imageType === "device",
  );

  let isValidToDrag = true;

  if (movingDevice) {
    for (const d of allDevices) {
      if (
        detectCollision(
          {
            ...movingDevice,
            x: movingDevice.x + offset.x,
            y: movingDevice.y + offset.y,
          },
          d,
        )
      ) {
        isValidToDrag = false;
        break;
      }
    }
  }
  return isValidToDrag;
};

export const checkIfAreaCollidedWithItsDevices = (
  selectedElements,
  allElements,
) => {
  const excalidrawAPI = jotaiStore.get(excalidrawApiAtom);
  if (!allElements) {
    allElements = excalidrawAPI?.getSceneElements();
  }
  const movingArea = selectedElements.find(
    (el) => el.customData?.isSelectedArea,
  );

  const allDevices = allElements.filter(
    (el) =>
      el.type === "image" &&
      el.customData?.imageType === "device" &&
      el.customData?.areaId === movingArea.id,
  );

  let devicesInside = 0;
  if (movingArea) {
    for (const d of allDevices) {
      if (checkIfDroppedInArea(d, movingArea)) {
        devicesInside++;
      }
    }
  }
  return devicesInside === allDevices.length;
};

const isPointInRectangle = (droppedObj, rect) => {
  const { x, y, width, height } = droppedObj;
  return (
    x >= rect.x &&
    x <= rect.x + rect.width &&
    x + width <= rect.x + rect.width &&
    y >= rect.y &&
    y <= rect.y + rect.height &&
    y + height <= rect.y + rect.height
  );
};

const isWholeObjectInEllipse = (object, polygon) => {
  const { x, y, width, height } = object;
  const objectPoints = [
    { x, y },
    { x: x + width, y },
    { x, y: y + height },
    { x: x + width, y: y + height },
  ];
  let truthCount = 0; //If truthCount equals objectPoints.length => whole object located ellipse

  for (const p of objectPoints) {
    isPointInEllipse(p, polygon) && truthCount++;
  }

  return truthCount === objectPoints.length;
};

const isPointInPolygon = (p, polygon) => {
  p = [p.x, p.y];
  const vertices = polygon.points.map((p) => [
    p[0] + polygon.x,
    p[1] + polygon.y,
  ]);

  let odd = false;
  for (let i = 0, j = vertices.length - 1; i < vertices.length; i++) {
    if (
      vertices[i][1] > p[1] !== vertices[j][1] > p[1] &&
      p[0] <
        ((vertices[j][0] - vertices[i][0]) * (p[1] - vertices[i][1])) /
          (vertices[j][1] - vertices[i][1]) +
          vertices[i][0]
    ) {
      odd = !odd;
    }
    j = i;
  }
  return odd;
};

const isWholeObjectInPolygon = (object, polygon) => {
  const { x, y, width, height } = object || {};
  const objectPoints = [
    { x, y },
    { x: x + width, y },
    { x, y: y + height },
    { x: x + width, y: y + height },
  ];
  let truthCount = 0; //If truthCount equals objectPoints.length => whole object located ellipse

  for (const p of objectPoints) {
    isPointInPolygon(p, polygon) && truthCount++;
  }

  return truthCount === objectPoints.length;
};

export const checkIfDroppedInArea = (droppedObj, container) => {
  if (container.type === "rectangle") {
    return isPointInRectangle(droppedObj, container);
  } else if (container.type === "ellipse") {
    return isWholeObjectInEllipse(droppedObj, container);
  } else if (container.type === "line") {
    return isWholeObjectInPolygon(droppedObj, container);
  }
};
