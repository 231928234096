/* Copyright (C) EZ Fire, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Hamza Younes <hamza@ezfirecontrols.com>, Jan 18th, 2023
 */

import firebase from "firebase/app";
import "firebase/firestore";
import secondaryFirebaseProject from "./secondaryProjectDB";

export const getAllProjects = async (onSuccess, onError) => {
  try {
    await secondaryFirebaseProject.auth().signInAnonymously();
    return secondaryFirebaseProject
      .firestore()
      .collection("projects")
      .where("disabled", "==", false)
      .onSnapshot(
        (snap) => {
          let allProjects = [];
          if (!snap.empty) {
            allProjects = snap.docs
              .filter((el) => !el.data().type)
              .map((doc) => doc.data());
          }
          onSuccess(allProjects);
        },
        (error) => {
          onError();
          console.error(error);
        },
      );
  } catch (error) {
    console.error(error);
  }
};

export const updateProjectById = async (projectId, update, options) => {
  try {
    if (!projectId) {
      throw new Error("Project ID is missing");
    }
    secondaryFirebaseProject
      .firestore()
      .collection("projects")
      .doc(projectId)
      .update({
        ...update,
        modifiedAt: firebase.firestore.Timestamp.now(),
        modifiedBy: firebase.auth().currentUser.uid,
      });
    options?.onSuccess && options.onSuccess();
  } catch (error) {
    options?.onError && options.onError();
    console.error(`${options?.errorMessage || ""} ${error}`);
  }
};

export const getProjectById = (projectId) => {
  try {
    return secondaryFirebaseProject
      .firestore()
      .collection("projects")
      .doc(projectId)
      .get();
  } catch (error) {
    console.error(error);
  }
};

export const getOccupancyTypes = async (onSuccess, onError) => {
  try {
    return secondaryFirebaseProject
      .firestore()
      .collection("occupancyTypes")
      .get()
      .then((snapshot) => {
        const result = [];
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            result.push({ ...doc.data(), id: doc.id });
          });
        }
        if (onSuccess) {
          onSuccess();
        }
        return result;
      });
  } catch (error) {
    if (onError) {
      onError();
    }
    console.error(error);
  }
};
