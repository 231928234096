import _ from "lodash";

import { redrawTextBoundingBox } from "../ExcalidrawAPI/element";
import { newElementWith } from "../ExcalidrawAPI/element/mutateElement";
import { actionDeleteFrame } from "../ExcalidrawAPI/actions";

import {
  generateId,
  convertToFeet,
  getDataFromTimestamp,
  checkIfObjectRepresentsDate,
  formatDrawingOrder,
  getLargestOrder,
} from "./common";
import { drawGridRuler } from "./drawGridRuler";
import {
  NUMBER_OF_PLAYERS,
  KEY_CONTACTS_ROLES,
  DEFAULT_TYPES_VALUES,
  NORTH_ICON,
  MARGIN_UNIT,
  BAR_CODE_WIDTH,
  BAR_CODE_HEIGHT,
} from "./constants";
import { legalText } from "./staticPlayersData";
import {
  convertDesignersObjToText,
  getFirstLettersFromCurrentUser,
  getMappedChange,
  getUnwrappedText,
  getUpdateTimeElement,
  getUsedHeader,
  preventTextWrapping,
  renderImageElement,
} from "./commonFrameFunctions";
import { deleteScaleRuler } from "./rulers";

//DETAILED_DESCRIPTION:
//  FRAME:
//    WIDTH = 2550px
//    HEIGHT = 1360px
//  NORTH_HEADING:
//    MARGIN = 70px;
//    DIMENSION = 85px;
//  SIDE_DATA:
//    WIDTH = 490px
//  HORIZONTAL_RULER:
//    HEIGHT = 95px
//    MARGIN = 25px
//  CELL:
//    HEIGHT = 290px
//  BAR_CODE:
//    WIDTH = 322px
//    HEIGHT = 22px
//  DRAWING:
//    MAX_WIDTH = 1835px
//    MAX_HEIGHT = 990px

const FRAME_WIDTH = 2550;
const FRAME_HEIGHT = 1650;
const CONTENT_HEIGHT = 1360;
const NORTH_ICON_DIM = 85;
const NORTH_ICON_MARGIN_LEFT = 70;
const NORTH_ICON_MARGIN_RIGHT = 25;
const SIDE_DATA_WIDTH = 490;
const HORIZONTAL_RULER_WIDTH = 95;
const HORIZONTAL_RULER_MARGIN_X = 70;
const HORIZONTAL_RULER_MARGIN_Y = 25;
const CELL_HEIGHT = 290;
const MAX_DRAWING_WIDTH = 1835;
const MAX_DRAWING_HEIGHT = 990;

const PIXELS_PER_FEET = 12;

//Standard Elements
const textElement = {
  angle: 0,
  backgroundColor: "#000",
  baseline: 0,
  boundElements: [],
  customData: { type: "vertical-ruler" },
  containerId: null,
  fillStyle: "hachure",
  fontFamily: 3,
  id: generateId(),
  isDeleted: false,
  link: null,
  locked: true,
  opacity: 100,
  roughness: 0,
  roundness: null,
  seed: 1748768552,
  strokeColor: "#000000",
  strokeStyle: "solid",
  strokeWidth: 1,
  type: "text",
  updated: Date.now(),
  version: 986,
  versionNonce: 487420504,
};

const lineElement = {
  angle: 0,
  backgroundColor: "transparent",
  boundElements: [],
  customData: { type: "horizontal-ruler" },
  endArrowhead: null,
  endBinding: null,
  fillStyle: "hachure",
  id: generateId(),
  isDeleted: false,
  lastCommittedPoint: null,
  link: null,
  locked: true,
  opacity: 100,
  roughness: 0,
  roundness: { type: 2 },
  seed: 1215551153,
  startArrowhead: null,
  startBinding: null,
  strokeColor: "#000000",
  strokeStyle: "solid",
  strokeWidth: 3,
  type: "line",
  updated: Date.now(),
  version: 92,
  versionNonce: 1453500113,
};

const rectangleElement = {
  angle: 0,
  backgroundColor: "transparent",
  boundElements: [],
  customData: { type: "vertical-ruler" },
  fillStyle: "solid",
  id: generateId(), //Variable= generateId()
  isDeleted: false,
  link: null,
  locked: true,
  opacity: 50,
  roughness: 0,
  roundness: null,
  seed: 179286104,
  strokeColor: "transparent",
  strokeStyle: "solid",
  strokeWidth: 0,
  type: "rectangle",
  updated: Date().now,
  version: 544,
  versionNonce: 778443864,
};

const getLabelHeight = () => {
  const HEIGHT_IN_FEET = 2;
  return HEIGHT_IN_FEET * PIXELS_PER_FEET;
};

const getDataHeight = () => {
  const HEIGHT_IN_FEET = 22;
  return HEIGHT_IN_FEET * PIXELS_PER_FEET;
};

const getFontSize = (cellWidth, cellHeight) => {
  let fontSize = 0;
  if (cellHeight / cellWidth >= 1.75) {
    fontSize = 0.055 * cellWidth;
  } else if (cellWidth / cellHeight >= 1.75) {
    fontSize = 0.055 * cellHeight;
  } else {
    fontSize = 0.045 * Math.min(cellHeight, cellWidth);
  }
  return fontSize;
};

const fillDrawingDetails = ({
  groupId,
  rectProps,
  offset,
  content,
  solid,
  reverse,
  currentProject,
  excalidrawAPI,
}) => {
  const basicTextProps = {
    groupIds: [groupId],
    boundElements: [],
  };

  const TEXT_MARGIN = 5;
  const LABEL_HEIGHT = 0.25 * rectProps.unitHeight;
  const CONTENT_HEIGHT = rectProps.height - LABEL_HEIGHT;

  const elementsToReturn = [];
  const [textId, cellId] = [generateId(), generateId()];
  const [key, value] = Object.entries(content);
  let labelToRender = `${key[1]}:`;
  if (!key[1]) {
    labelToRender = "";
  }

  const labelText = newElementWith(textElement, {
    ...basicTextProps,
    id: textId,
    containerId: cellId,
    textAlign: reverse ? "right" : "left",
    verticalAlign: "middle",
    fontSize: 0.18 * rectProps.unitHeight,
    text: labelToRender,
    originalText: labelToRender,
    height: LABEL_HEIGHT,
    baseline: 0.65 * LABEL_HEIGHT,
  });

  const labelRect = newElementWith(rectangleElement, {
    id: cellId,
    groupIds: [groupId],
    boundElements: [{ id: textId, type: "text" }],
    height: LABEL_HEIGHT,
    width: rectProps.width,
    x: offset.x,
    y: offset.y,
    backgroundColor: "transparent",
  });

  redrawTextBoundingBox(labelText, labelRect);

  if (key[1] === "PROJECT NO") {
    const imageProps = {
      width: BAR_CODE_WIDTH,
      height: BAR_CODE_HEIGHT,
      offsetX: offset.x + (rectProps.width - BAR_CODE_WIDTH) / 2,
      offsetY: offset.y + (rectProps.height - BAR_CODE_HEIGHT - 4),
    };

    const imageElement = renderImageElement({
      excalidrawAPI,
      url: currentProject.barcode,
      imageProps,
    });
    elementsToReturn.push(imageElement);
  }
  let contentToRender = value[1];
  if (typeof contentToRender === "object") {
    if (checkIfObjectRepresentsDate(contentToRender)) {
      contentToRender = getDataFromTimestamp(contentToRender);
    }
  }

  const [contentRectId, contentTextId] = [generateId(), generateId()];
  let contentText = newElementWith(textElement, {
    ...basicTextProps,
    id: contentTextId,
    containerId: contentRectId,
    textAlign: key[1] === "PROJECT NO" ? "center" : "left",
    verticalAlign: "top",
    fontSize: 0.21 * rectProps.unitHeight,
    text: contentToRender,
    originalText: contentToRender,
    height: CONTENT_HEIGHT,
    width: rectProps.width,
    baseline: 0.65 * CONTENT_HEIGHT,
    customData: {
      type: "vertical-ruler",
      order: key[1] === "PAGE NO" ?? false,
      valueFor: labelToRender,
    },
  });

  const contentRect = newElementWith(rectangleElement, {
    id: contentRectId,
    groupIds: [groupId],
    boundElements: [{ id: contentTextId, type: "text" }],
    height: CONTENT_HEIGHT,
    width: rectProps.width,
    x: offset.x,
    y: offset.y + LABEL_HEIGHT,
    backgroundColor: "transparent",
  });

  if (solid) {
    contentText = getUnwrappedText(
      contentText,
      contentRect,
      0.21 * rectProps.unitHeight,
    );
  }

  redrawTextBoundingBox(contentText, contentRect);
  elementsToReturn.push(contentText, contentRect);

  elementsToReturn.push(labelText, labelRect);
  return elementsToReturn;
};

const createCellsWithContent = (
  type,
  { groupId, rectProps, offset, solid, content, excalidrawAPI },
) => {
  const [textId, cellId] = [generateId(), generateId()];

  const basicTextProps = {
    groupIds: [groupId],
    boundElements: [],
  };

  if (type === "label") {
    let labelText = newElementWith(textElement, {
      ...basicTextProps,
      id: textId,
      containerId: cellId,
      textAlign: "left",
      verticalAlign: "middle",
      fontSize: 0.4 * rectProps.height,
      text: content,
      originalText: content,
      height: 0.5 * rectProps.height,
      baseline: 0.65 * 0.5 * rectProps.height,
    });

    const labelRect = newElementWith(rectangleElement, {
      id: cellId,
      groupIds: [groupId],
      boundElements: [{ id: textId, type: "text" }],
      height: rectProps.height,
      width: rectProps.width,
      x: offset.x,
      y: offset.y,
      backgroundColor: "transparent",
    });

    if (solid) {
      labelText = getUnwrappedText(
        labelText,
        labelRect,
        0.4 * rectProps.height,
      );
    }

    redrawTextBoundingBox(labelText, labelRect);
    return [labelText, labelRect];
  } else if (type === "data") {
    const [part1RectId, part1TextId, part2RectId, part2TextId] = [
      generateId(),
      generateId(),
      generateId(),
      generateId(),
    ];

    const part1Text = newElementWith(textElement, {
      ...basicTextProps,
      id: part1TextId,
      containerId: part1RectId,
      textAlign: "center",
      verticalAlign: "top",
      fontSize: 0.065 * rectProps.height,
      text: !content.logo
        ? `${content.customerName?.trim().toUpperCase() || ""}`
        : "",
      originalText: !content.logo
        ? `${content.customerName?.toUpperCase() || ""}`
        : "",
      height: rectProps.height,
      baseline: 0.65 * rectProps.height,
    });

    const part1Rect = newElementWith(rectangleElement, {
      id: part1RectId,
      groupIds: [groupId],
      boundElements: [{ id: part1TextId, type: "text" }],
      height: rectProps.height / 2,
      width: rectProps.width,
      x: offset.x,
      y: offset.y,
      backgroundColor: "transparent",
    });

    let imageElement = {};
    if (content && content.logo) {
      const IMAGE_MARGIN = 0.2 * part1Rect.height;
      const imageDimension =
        Math.min(rectProps.width, part1Rect.height) - IMAGE_MARGIN;
      const imageProps = {
        width: imageDimension,
        height: imageDimension,
        offsetX: offset.x + (rectProps.width - imageDimension) / 2,
        offsetY: offset.y + IMAGE_MARGIN / 2,
      };

      imageElement = renderImageElement({
        excalidrawAPI,
        url: content.logo,
        imageProps: {
          ...imageProps,
          offsetX: offset.x + (rectProps.width - imageProps.width) / 2,
          offsetY: offset.y + IMAGE_MARGIN / 2,
        },
      });
    }

    const getCustomerInfoAsString = (customerInfo) => {
      if (customerInfo.empty) {
        return "";
      }
      const { addresses, phones, website, logo, customerName } = customerInfo;
      const primaryAddress = addresses.find((el) => el.primary);
      const primaryPhone = phones.find((el) => el.primary);
      const { house, streetName, city, state, zipCode } = primaryAddress;
      return `${
        logo ? customerName.trim() : ""
      }\n\n ${house} ${streetName}\n ${city}, ${state} ${zipCode}\n ${
        primaryPhone.number ?? ""
      }\n ${website ?? ""}`;
    };

    const customerInfoAsString = getCustomerInfoAsString(content);

    const part2Text = newElementWith(textElement, {
      ...basicTextProps,
      id: part2TextId,
      containerId: part2RectId,
      textAlign: "center",
      verticalAlign: "bottom",
      fontSize: 0.04 * rectProps.height,
      text: customerInfoAsString,
      originalText: customerInfoAsString,
      height: rectProps.height,
      baseline: 0.65 * rectProps.height,
    });

    const part2Rect = newElementWith(rectangleElement, {
      id: part2RectId,
      groupIds: [groupId],
      boundElements: [{ id: part2TextId, type: "text" }],
      height: rectProps.height / 2,
      width: rectProps.width,
      x: offset.x,
      y: offset.y + rectProps.height / 2,
      backgroundColor: "transparent",
    });

    redrawTextBoundingBox(part1Text, part1Rect);
    redrawTextBoundingBox(part2Text, part2Rect);

    const result = [part1Text, part2Text, part1Rect, part2Rect];
    if (imageElement.id) {
      result.push(imageElement);
    }
    return result;
  }
};

const drawVerticalDividers = ({
  groupId,
  prevDividers,
  cellProps,
  start,
  iterator,
}) => {
  return {
    ...lineElement,
    id: generateId(),
    groupIds: [groupId],
    x:
      iterator === 0
        ? start.x + cellProps.width
        : prevDividers[prevDividers.length - 1].x + cellProps.width,
    y: start.y,
    points: [
      [0, 0],
      [0, cellProps.height],
    ],
    width: 0,
    height: cellProps.height,
  };
};
const createCellsOfPlayersSection = ({
  start,
  cellWidth,
  groupId,
  numberOfCells,
  options,
  currentProject,
  excalidrawAPI,
}) => {
  const verticalDividers = [];
  const horizontalDividers = [];
  const rectangleCells = [];
  const drawingDetailsCells = [];
  const SECTION_HEIGHT = 24 * PIXELS_PER_FEET;
  const DATA_HEIGHT = getDataHeight(PIXELS_PER_FEET);
  const LABEL_HEIGHT = getLabelHeight(PIXELS_PER_FEET);
  const startInY = FRAME_HEIGHT - SECTION_HEIGHT;
  for (let i = 0; i < numberOfCells; i++) {
    const verticalDivider = drawVerticalDividers({
      groupId,
      prevDividers: verticalDividers,
      cellProps: { width: cellWidth, height: SECTION_HEIGHT },
      start: { x: start, y: startInY },
      iterator: i,
    });
    verticalDividers.push(verticalDivider);
    const MARGIN_LEFT = 6;
    const offsetX =
      i === 0
        ? start + MARGIN_LEFT
        : verticalDividers[verticalDividers.length - 1].x -
          cellWidth +
          MARGIN_LEFT;
    if (options.hasContent) {
      const cellsWithLabels = createCellsWithContent("label", {
        groupId,
        rectProps: { width: cellWidth - MARGIN_LEFT, height: LABEL_HEIGHT },
        offset: { x: offsetX, y: startInY },
        content: options.data[i]?.role || "",
        solid: options.solid,
        excalidrawAPI,
      });

      const cellsWithData = createCellsWithContent("data", {
        groupId,
        rectProps: { width: cellWidth, height: DATA_HEIGHT },
        offset: { x: offsetX - MARGIN_LEFT, y: startInY + LABEL_HEIGHT },
        content: options.data[i] || "",
        solid: options.solid,
        excalidrawAPI,
      });
      rectangleCells.push(...cellsWithLabels, ...cellsWithData);
    } else if (options.isDivided) {
      let unitHeight = 0;
      if (options.divisions) {
        unitHeight =
          SECTION_HEIGHT / options.divisions.reduce((acc, suc) => acc + suc);

        for (let i = 0; i < options.divisionsCount; i++) {
          const offsetY =
            i === 0
              ? startInY + options.divisions[i] * unitHeight
              : horizontalDividers[i - 1].y + options.divisions[i] * unitHeight;

          if (i < options.divisionsCount - 1) {
            horizontalDividers.push(
              newElementWith(lineElement, {
                id: generateId(),
                groupIds: [groupId],
                x: start,
                y: offsetY,
                points: [
                  [0, 0],
                  [cellWidth, 0],
                ],
                width: cellWidth,
                height: 0,
              }),
            );
          }

          drawingDetailsCells.push(
            ...fillDrawingDetails({
              groupId,
              rectProps: {
                width: cellWidth - MARGIN_LEFT,
                height: options.divisions[i] * unitHeight,
                unitHeight,
              },
              offset: {
                x: start + MARGIN_LEFT,
                y: offsetY - options.divisions[i] * unitHeight,
              },
              content: options.data[i] || "",
              currentProject,
              excalidrawAPI,
            }),
          );
        }
      } else {
        unitHeight = SECTION_HEIGHT / options.divisionsCount;

        for (let i = 0; i < options.divisionsCount; i++) {
          const offsetY =
            i === 0
              ? startInY + unitHeight
              : horizontalDividers[i - 1].y + unitHeight;

          if (i < options.divisionsCount - 1) {
            horizontalDividers.push(
              newElementWith(lineElement, {
                id: generateId(),
                groupIds: [groupId],
                x: start,
                y: offsetY,
                points: [
                  [0, 0],
                  [cellWidth, 0],
                ],
                width: cellWidth,
                height: 0,
              }),
            );
          }

          drawingDetailsCells.push(
            ...fillDrawingDetails({
              groupId,
              rectProps: {
                width: cellWidth - MARGIN_LEFT,
                height: unitHeight,
                unitHeight,
              },
              offset: {
                x: start + MARGIN_LEFT,
                y: offsetY - unitHeight,
              },
              content: options.data[i] || "",
              solid: options.solid,
              reverse: options.reverse,
              currentProject,
              excalidrawAPI,
            }),
          );
        }
      }
    } else if (options.isLegalCell) {
      const [textId, cellId] = [generateId(), generateId()];

      const fontSize = getFontSize(cellWidth, SECTION_HEIGHT);

      const container = newElementWith(rectangleElement, {
        id: cellId,
        groupIds: [groupId],
        boundElements: [{ id: textId, type: "text" }],
        height: SECTION_HEIGHT,
        width: cellWidth,
        x:
          i === 0
            ? start
            : verticalDividers[verticalDividers.length - 1].x - cellWidth,
        y: startInY,
      });
      const textItself = newElementWith(textElement, {
        id: textId,
        groupIds: [groupId],
        containerId: cellId,
        boundElements: [],
        text: options.legalText,
        originalText: options.legalText,
        textAlign: "center",
        verticalAlign: "middle",
        fontSize,
        x:
          i === 0
            ? start + 3
            : verticalDividers[verticalDividers.length - 1].x - cellWidth + 3,
        y: startInY,
        height: 0.8 * SECTION_HEIGHT,
        width: 0.75 * cellWidth,
      });

      rectangleCells.push(container, textItself);
      redrawTextBoundingBox(textItself, container);
    }
  }

  return [
    ...rectangleCells,
    ...verticalDividers,
    ...horizontalDividers,
    ...drawingDetailsCells,
  ];
};

const drawFrame = async ({ groupId, excalidrawAPI, updateScene }) => {
  const headerText = await getUsedHeader();
  //NOTE - The frame consists of three perpendicular lines Π
  const [textId, containerId] = [generateId(), generateId()];

  const headerTextElement = {
    ...textElement,
    id: textId,
    groupIds: [groupId],
    containerId,
    boundElements: [],
    fontSize: 1 * PIXELS_PER_FEET,
    height: 1 * PIXELS_PER_FEET,
    width: FRAME_WIDTH,
    textAlign: "left",
    verticalAlign: "middle",
    text: `${headerText}`,
    originalText: `${headerText}`,
  };

  const headerRectElement = {
    ...rectangleElement,
    id: containerId,
    groupIds: [groupId],
    height: 2 * MARGIN_UNIT,
    width: FRAME_WIDTH,
    x: MARGIN_UNIT,
    y: 0,
  };
  redrawTextBoundingBox(headerTextElement, headerRectElement);

  const frameElements = [
    //Line #1 left vertical line
    {
      ...lineElement,
      id: generateId(),
      groupIds: [groupId],
      x: 0,
      y: 0,
      points: [
        [0, 0],
        [0, FRAME_HEIGHT],
      ],
      width: 0,
      height: FRAME_HEIGHT,
    },
    //Line #2 top horizontal line
    {
      ...lineElement,
      id: generateId(),
      groupIds: [groupId],
      x: 0,
      y: 0,
      points: [
        [0, 0],
        [FRAME_WIDTH, 0],
      ],
      width: FRAME_WIDTH,
      height: 0,
    },
    //Line #3 right vertical line
    {
      ...lineElement,
      id: generateId(),
      groupIds: [groupId],
      x: FRAME_WIDTH,
      y: 0,
      points: [
        [0, 0],
        [0, FRAME_HEIGHT],
      ],
      width: 0,
      height: FRAME_HEIGHT,
    },
  ];

  const currentSceneElements = excalidrawAPI?.getSceneElements();
  updateScene("elements", [
    ...currentSceneElements,
    ...frameElements,
    headerTextElement,
    headerRectElement,
  ]);
};

const drawNorthDirection = ({
  excalidrawAPI,
  updateScene,
  start,
  currentProject,
  pixelsPerFeet,
}) => {
  const IMAGE_MARGIN = 6 * PIXELS_PER_FEET;
  const imageDimension = 7 * PIXELS_PER_FEET;
  const imageProps = {
    width: imageDimension,
    height: imageDimension,
    offsetX: start.x + IMAGE_MARGIN,
    offsetY: start.y + IMAGE_MARGIN,
    angle: currentProject.northHeading ?? 0,
  };
  const customData = {
    valueFor: "North Icon",
  };

  const imageElement = renderImageElement({
    excalidrawAPI,
    url: NORTH_ICON,
    imageProps,
    customData,
  });

  const currentSceneElements = excalidrawAPI?.getSceneElements();
  updateScene("elements", [...currentSceneElements, imageElement]);
};

const getOffsetX = (cells) => {
  return cells.reduce((acc, suc) => acc + suc.count * suc.width, 0);
};

const completePlayersArrayToSeven = (playersArray) => {
  let defaultPlayer = {};
  if (playersArray.length > 0) {
    const firstPlayer = playersArray[0];
    const keys = Object.keys(playersArray[0]);

    defaultPlayer = keys.reduce((playerObj, key) => {
      let currentDefaultValue = DEFAULT_TYPES_VALUES[typeof firstPlayer[key]];
      if (Array.isArray(firstPlayer[key])) {
        currentDefaultValue = [];
      }
      playerObj[key] = currentDefaultValue;
      return playerObj;
    }, {});
  }

  while (playersArray.length < 7) {
    playersArray.push({ ...defaultPlayer, active: true, empty: true });
  }

  return playersArray;
};

const drawPlayersSection = ({
  users,
  currentDrawing,
  drawingsPerProject,
  currentProject,
  groupId,
  excalidrawAPI,
  updateScene,
  playersToRender,
}) => {
  const SECTION_HEIGHT = 24 * PIXELS_PER_FEET;
  const LABEL_HEIGHT = getLabelHeight(PIXELS_PER_FEET);

  //The total width contains NUMBER_OF_PLAYERS + 8.5 cells (1 * 1.25 + 1 x 1 + 1 x 2.5 + 1 x 1.25 + 1 x 2.5 )
  //Cell Area = cell width x 24' (SECTION_HEIGHT)
  // const NUMBER_OF_PLAYERS = 7;
  const spaceSections = NUMBER_OF_PLAYERS * 1.25 + 8.5;
  const cellWidth = FRAME_WIDTH / spaceSections;
  const lastOrderOfDrawings = getLargestOrder(drawingsPerProject);
  const creator = getFirstLettersFromCurrentUser(currentDrawing.creator, users);
  const designers = convertDesignersObjToText(currentDrawing.designers, users);
  const frameOuterLines = [
    {
      ...lineElement,
      id: generateId(),
      groupIds: [groupId],
      x: 0,
      y: FRAME_HEIGHT - SECTION_HEIGHT,
      points: [
        [0, 0],
        [FRAME_WIDTH, 0],
      ],
      width: FRAME_WIDTH,
      height: 0,
    },
    {
      ...lineElement,
      id: generateId(),
      groupIds: [groupId],
      x: 0,
      y: FRAME_HEIGHT - (SECTION_HEIGHT - LABEL_HEIGHT),
      points: [
        [0, 0],
        [NUMBER_OF_PLAYERS * (1.25 * cellWidth), 0],
      ],
      width: FRAME_WIDTH,
      height: 0,
    },
    {
      ...lineElement,
      id: generateId(),
      groupIds: [groupId],
      x: 0,
      y: FRAME_HEIGHT,
      points: [
        [0, 0],
        [FRAME_WIDTH, 0],
      ],
      width: FRAME_WIDTH,
      height: 0,
    },
  ];

  const cellsArray = [
    {
      offsetX: getOffsetX([]),
      cellWidth: 1.25 * cellWidth,
      cellsCount: NUMBER_OF_PLAYERS,
      options: {
        hasContent: true,
        labels: KEY_CONTACTS_ROLES,
        data: playersToRender,
        solid: true,
      },
    },
    {
      offsetX: getOffsetX([
        { count: NUMBER_OF_PLAYERS, width: 1.25 * cellWidth },
      ]),
      cellWidth: 1.25 * cellWidth,
      cellsCount: 1,
      options: {
        isLegalCell: true,
        legalText,
      },
    },
    {
      offsetX: getOffsetX([
        { count: NUMBER_OF_PLAYERS + 1, width: 1.25 * cellWidth },
      ]),
      cellWidth: 1 * cellWidth,
      cellsCount: 1,
      options: {
        isDivided: true,
        divisionsCount: 6,
        solid: true,
        data: [
          { label: "MAP NO", content: "12c" },
          {
            label: "BLOCK NO",
            content: currentProject.blocK || "DEFAULT BLOCK",
          },
          { label: "LOT NO", content: currentProject.lot || "DEFAULT LOT" },
          { label: "DESIGNED BY", content: "REA" },
          // { label: "DESIGNED BY", content: designers },
          { label: "DRAWN BY", content: designers },
          { label: "REVIEWED BY", content: creator },
        ],
      },
    },
    {
      offsetX: getOffsetX([
        { count: NUMBER_OF_PLAYERS + 1, width: 1.25 * cellWidth },
        { count: 1, width: cellWidth },
      ]),
      cellWidth: 2.5 * cellWidth,
      cellsCount: 1,
      options: {
        isDivided: true,
        divisionsCount: 4,
        divisions: [2, 1, 2, 1],
        data: [
          {
            label: "DRAWING TITLE",
            content:
              currentDrawing.name.trim().toUpperCase() || "DEFAULT TITLE",
          },
          {
            label: "PROJECT NAME",
            content: currentProject.projectName || "DEFAULT NAME",
          },
          {
            label: "PROJECT ADDRESS",
            content: currentProject.address || "DEFAULT ADDRESS",
          },
          {
            label: "PROJECT NO",
            content: currentProject.id || "DEFAULT NUMBER",
          },
        ],
      },
    },
    {
      offsetX: getOffsetX([
        { count: NUMBER_OF_PLAYERS + 1, width: 1.25 * cellWidth },
        { count: 1, width: cellWidth },
        { count: 1, width: 2.5 * cellWidth },
      ]),
      cellWidth: 1.25 * cellWidth,
      cellsCount: 1,
      options: {
        isDivided: true,
        divisionsCount: 6,
        reverse: true,
        solid: true,
        data: [
          { label: "RECORD ID", content: currentProject.recordId || "" },
          {
            label: "DRAWING NO",
            content: `FA-00${currentDrawing.order}.00`,
          },
          {
            label: "STAGE",
            content: currentDrawing.drawingStage || "",
          },
          { label: "PAGE SIZE", content: currentDrawing.pageSize || "" },
          {
            label: "DATE UPDATED",
            content:
              currentDrawing.modifiedAt || currentDrawing.createdAt || "",
          },
          {
            label: "PAGE NO",
            content:
              formatDrawingOrder(currentDrawing.order, lastOrderOfDrawings) ||
              "",
          },
        ],
      },
    },
  ];

  const verticalDividers = [];

  cellsArray.forEach(({ offsetX, cellWidth, cellsCount, options }) => {
    verticalDividers.push(
      ...createCellsOfPlayersSection({
        start: offsetX,
        cellWidth,
        groupId,
        numberOfCells: cellsCount,
        options,
        currentProject,
        excalidrawAPI,
      }),
    );
  });

  const currentSceneElements = excalidrawAPI?.getSceneElements();
  updateScene("elements", [
    ...currentSceneElements,
    ...frameOuterLines,
    ...verticalDividers,
  ]);
};

export const drawBorder = ({
  users,
  playersToRender,
  groupId,
  currentDrawing,
  currentProject,
  drawingsPerProject,
  excalidrawAPI,
  updateScene,
}) => {
  drawFrame({ groupId, excalidrawAPI, updateScene });
  drawPlayersSection({
    users,
    currentDrawing,
    currentProject,
    excalidrawAPI,
    updateScene,
    groupId,
    playersToRender,
    drawingsPerProject,
  });

  drawGridRuler({ excalidrawAPI, updateScene });
};

export const drawFrameWithPlayersSection = (
  users,
  currentDrawing,
  currentProject,
  groupId,
  excalidrawAPI,
  updateScene,
  keyContacts,
  drawingsPerProject,
) => {
  const floorDrawing = currentDrawing.name ? currentDrawing : currentDrawing[0];
  const pixelsPerFeet = convertToFeet(
    floorDrawing.pixelsPerUnit,
    floorDrawing.correspondingUnit,
  );
  const sectionHeight = 24 * PIXELS_PER_FEET;
  const frameProps = {
    width: FRAME_WIDTH,
    height: FRAME_HEIGHT,
    start: {
      x: 0,
      y: 0,
    },
  };
  const playersToRender = completePlayersArrayToSeven(keyContacts);

  drawFrame({ groupId, excalidrawAPI, updateScene });

  drawNorthDirection({
    excalidrawAPI,
    updateScene,
    start: frameProps.start,
    pixelsPerFeet,
    currentProject,
  });

  drawPlayersSection({
    users,
    currentDrawing,
    currentProject,
    excalidrawAPI,
    updateScene,
    groupId,
    playersToRender,
    drawingsPerProject,
  });

  drawGridRuler({ excalidrawAPI, updateScene });
};

const getDrawingDimensionsToFit = (floorDrawing) => {
  const { width: drawingWidth, height: drawingHeight } = floorDrawing;
  if (drawingWidth > MAX_DRAWING_WIDTH || drawingHeight > MAX_DRAWING_HEIGHT) {
    const dimensionToChange =
      drawingWidth > MAX_DRAWING_WIDTH ? "width" : "height";
    const aspectRatio = drawingWidth / drawingHeight;
    if (dimensionToChange === "width") {
      let newWidth = MAX_DRAWING_WIDTH;
      let newHeight = newWidth / aspectRatio;
      //Check if the newHeight > MAX_DRAWING_HEIGHT
      if (newHeight > MAX_DRAWING_HEIGHT) {
        do {
          newWidth -= 50;
          newHeight = newWidth / aspectRatio;
        } while (newHeight > MAX_DRAWING_HEIGHT);
      }
      return {
        drawingWidth: newWidth,
        drawingHeight: newHeight,
      };
    }
    const newWidth = MAX_DRAWING_HEIGHT * aspectRatio;
    return {
      drawingWidth: newWidth,
      drawingHeight: MAX_DRAWING_HEIGHT,
    };
  }
  return { drawingWidth, drawingHeight };
};

export const setDrawingPropsInScene = (
  floorDrawing,
  updateScene,
  excalidrawAPI,
) => {
  const { drawingWidth, drawingHeight } =
    getDrawingDimensionsToFit(floorDrawing);
  const exclidrawElements = excalidrawAPI?.getSceneElements();
  const drawingElement = exclidrawElements.find(
    (el) => el.customData?.imageType === "floor-drawing",
  );
  const marginX =
    NORTH_ICON_MARGIN_LEFT + NORTH_ICON_MARGIN_RIGHT + NORTH_ICON_DIM;
  const marginY =
    CONTENT_HEIGHT -
    drawingHeight -
    (NORTH_ICON_MARGIN_LEFT + NORTH_ICON_MARGIN_RIGHT + NORTH_ICON_DIM) -
    (MAX_DRAWING_HEIGHT - drawingHeight) / 2;
  Object.assign(drawingElement, {
    x: marginX,
    y: marginY,
    width: drawingWidth,
    height: drawingHeight,
  });
  updateScene("elements", [...exclidrawElements]);
  excalidrawAPI.zoomToFitAllDrawing();
  return { drawingWidth, drawingHeight, marginX, marginY };
};

const checkIfNorthChangeIncluded = (changes) => {
  return changes.some((el) => el.valueFor === "North Icon");
};

export const deleteFrame = (excalidrawAPI) => {
  const actionManager = excalidrawAPI.getActionManager();
  actionManager.executeAction(actionDeleteFrame);
};

export const editDataInFrame = ({
  excalidrawAPI,
  collabAPI,
  updateScene,
  changes,
  update = true,
  users = null,
}) => {
  if (changes.length > 0) {
    const timeOfUpdate = getUpdateTimeElement();
    for (const change of changes) {
      if (change.valueFor === "pixelsPerUnit") {
        deleteScaleRuler(excalidrawAPI, collabAPI, updateScene);
        return;
      }
    }
    let adjustedChanges = changes.map((change) => {
      return getMappedChange(change, users);
    });

    adjustedChanges = _.flatten(adjustedChanges);
    update && adjustedChanges.push(timeOfUpdate);
    setTimeout(() => {
      let adjustedElements = _.cloneDeep(excalidrawAPI?.getSceneElements());
      adjustedElements = adjustedElements.map((el) => {
        if (
          el.customData?.valueFor === "North Icon" &&
          checkIfNorthChangeIncluded(changes)
        ) {
          return newElementWith(el, {
            angle: changes.find((c) => c.valueFor === "North Icon").value,
          });
        }
        //Check if the current element needs to be updated according to the changes
        const fieldToChange = adjustedChanges.find(
          (change) => change.valueFor === el.customData?.valueFor,
        );
        if (fieldToChange) {
          const textContainer = newElementWith(
            adjustedElements.find((p) => p.id === el.containerId),
          );
          const textElement = newElementWith(el, {
            text: fieldToChange.value?.toUpperCase() ?? "",
            originalText: fieldToChange.value?.toUpperCase() ?? "",
            verticalAlign: "top",
          });
          const { textElement: newOccText, rectElement: newOccRect } =
            preventTextWrapping(textElement, textContainer);

          redrawTextBoundingBox(newOccText, newOccRect, true);

          return textElement;
        }
        return el;
      });
      updateScene("elements", [...adjustedElements]);
    }, 1500);
  }
};
