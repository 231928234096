// React
import React, { useState, useEffect } from "react";

// Material
import { Box, Typography, CircularProgress } from "@mui/material";

// Globals
import { useSetAtom } from "jotai";
import { userPermissionsAtom, userClaimsAtom } from "../store/auth";

// Helpers

// Components
import DevDialog from "./DevDialog";

// Factories

// Screens

// Assets

// Third Parties
import firebase from "firebase/app";
import "firebase/auth";
import { authStatus, logoutUser } from "../services/login";

// Services

// Styles

// Ad-Hoc Components
firebase.initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));

/**
 * @name Initializer
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Initializer = ({ children }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const setUserPermissions = useSetAtom(userPermissionsAtom);
  const setUserClaims = useSetAtom(userClaimsAtom);

  // State Hooks
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  // Effect Hooks
  useEffect(() => {
    const redirectOnError = async (error) => {
      console.error(error);
      if (error?.response?.status === 400) {
        setErrorMessage("Please log in Outer Cloud!");
      } else {
        setErrorMessage("There was an error. Please try again later!");
      }
      setTimeout(() => {
        setErrorMessage("Redirecting to login page...");
        logoutUser();

        setTimeout(() => {
          goToSignInPage();
        }, 1000);
      }, 2000);
    };

    const fetchAuthStatus = async () => {
      if (process.env.REACT_APP_ENV === "dev") {
        setIsLoginDialogOpen(true);
      } else {
        setIsLoading(true);
        let customToken = true;
        try {
          const status = await authStatus();
          customToken = status.customToken;
        } catch (error) {
          redirectOnError(error);
        }

        if (customToken) {
          try {
            await firebase.auth().signInWithCustomToken(customToken);

            setIsLoggedIn(true);
          } catch (error) {
            setIsLoggedIn(false);
            redirectOnError(error);
          }
        }

        setIsLoading(false);
      }
    };

    const getUserPermissions = (claims, roles) => {
      const result = new Set();

      claims.roles.forEach((id) => {
        const foundRole = roles.find((role) => role.id === id);
        if (!foundRole) {
          return;
        }
        Object.keys(foundRole.permissions).map((el) => result.add(el));
      });
      return Array.from(result);
    };

    const fetchRoles = async () => {
      return firebase
        .firestore()
        .collection("roles")
        .where("active", "==", true)
        .get()
        .then((snap) => {
          return snap.docs.map((el) => ({ id: el.id, ...el.data() }));
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const getRolesAndPermissions = async () => {
      try {
        const roles = await fetchRoles();
        const idTokenResult = await firebase
          .auth()
          .currentUser.getIdTokenResult();
        const { claims } = idTokenResult;
        setUserClaims(claims);
        const result = getUserPermissions(claims, roles);
        setUserPermissions(result);
      } catch (error) {
        console.error(error);
      }
    };

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user && !user.isAnonymous) {
        await getRolesAndPermissions();
        setIsLoggedIn(true);
      } else {
        fetchAuthStatus();
        setIsLoggedIn(false);
      }
    });
  }, []);

  // Other Hooks

  // Event Handlers
  const handleConfirm = async (email, password) => {
    setIsLoginDialogOpen(false);

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      setIsLoggedIn(true);
    } catch (error) {
      setIsLoggedIn(false);
      console.log(error);
    }
  };

  // Other
  const goToSignInPage = () => {
    window.location.href =
      "https://outer.cloud/#/login?redirect=https://ficad.outer.cloud";
  };

  // Component Render
  return (
    <>
      {isLoginDialogOpen ? <DevDialog onConfirm={handleConfirm} /> : null}

      {isLoggedIn ? (
        children
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          height={"100%"}
        >
          <Typography variant="h4" component="h2">
            {isLoading ? (
              <CircularProgress color="inherit" />
            ) : errorMessage ? (
              errorMessage
            ) : (
              "Redirecting to login page..."
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Initializer;
