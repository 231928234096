import React from "react";

import { TextField, Tooltip } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { makeStyles } from "@mui/styles";

//MUI styling
const useStyles = makeStyles(() => ({
  disabledTextField: {
    backgroundColor: "rgba(0, 0, 0, 0.03)",
    "& .MuiInputLabel-root": {
      color: "rgba(0, 0, 0, 0.7)",
    },
    "& .MuiInputBase-input": {
      "-webkit-text-fill-color": "rgba(0, 0, 0, 0.7) !important",
    },
  },
  disabledTextFieldOfCodes: {
    backgroundColor: "rgba(0, 0, 0, 0.03)",
    "& .MuiInputLabel-root": {
      color: "rgba(0, 0, 0, 0.7)",
    },
    "& .MuiInputBase-input": {
      "-webkit-text-fill-color": "rgba(0, 0, 0, 0.7) !important",
      fontSize: "13px",
    },
  },
}));

const ReadOnlyFieldWithTooltip = ({
  id,
  value,
  tooltip,
  label,
  inputSize = "medium",
  style = {},
  iconStyle = {},
}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <TextField
        id={id}
        label={label}
        value={value}
        disabled={true}
        size={inputSize}
        className={classes.disabledTextField}
        autoComplete="off"
        sx={style}
      />
      <Tooltip title={tooltip} placement="right" arrow={true}>
        <HelpOutlineOutlinedIcon
          color="red"
          sx={{
            marginBottom:
              iconStyle?.marginBottom || style?.marginBottom || "16px",
            fill: "rgba(0, 0, 0, 0.5)",
            position: "absolute",
            right: "12px",
            fontSize: "1.8rem",
            ...iconStyle,
          }}
        />
      </Tooltip>
    </div>
  );
};

export default ReadOnlyFieldWithTooltip;
