import { useCallback, useEffect, useRef } from "react";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { t } from "../i18n";
import { CloseIcon } from "./icons";
import "./Toast.scss";
import { ToolButton } from "./ToolButton";

const DEFAULT_TOAST_TIMEOUT = 5000;

export const Toast = ({
  message,
  onClose,
  closable = true,
  // To prevent autoclose, pass duration as Infinity
  duration = DEFAULT_TOAST_TIMEOUT,
  classes = [],
  options,
}: {
  message: string;
  onClose: () => void;
  closable?: boolean;
  duration?: number;
  classes?: Array<string>;
  options?: { position?: string; status?: string };
}) => {
  const timerRef = useRef<number>(0);
  const shouldAutoClose = duration !== Infinity;
  const scheduleTimeout = useCallback(() => {
    if (!shouldAutoClose) {
      return;
    }
    timerRef.current = window.setTimeout(() => onClose(), duration);
  }, [onClose, duration, shouldAutoClose]);

  useEffect(() => {
    if (!shouldAutoClose) {
      return;
    }
    scheduleTimeout();
    return () => clearTimeout(timerRef.current);
  }, [scheduleTimeout, message, duration, shouldAutoClose]);

  const onMouseEnter = shouldAutoClose
    ? () => clearTimeout(timerRef?.current)
    : undefined;
  const onMouseLeave = shouldAutoClose ? scheduleTimeout : undefined;

  const usedClasses = `Toast ${t(
    `toastPosition.${options?.position ?? "top"}`,
  )} ${options?.status ?? "normal"}-toast ${classes.join(" ")}`;

  const renderIndicator = () => {
    if (options?.status === "success") {
      return (
        <CheckCircleOutlineOutlinedIcon
          sx={{ fill: "#fff", fontSize: "1.7rem" }}
        />
      );
    } else if (options?.status === "info") {
      return <InfoOutlinedIcon />;
    } else if (options?.status === "error") {
      return <ErrorOutlineOutlinedIcon />;
    }
    return <ErrorOutlineOutlinedIcon />;
  };

  return (
    <div
      className={usedClasses}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="Toast__icon">{renderIndicator()}</div>
      <div className="Toast__texts">
        <p className="Toast__status">{options?.status}</p>
        <p className="Toast__message">{message}</p>
      </div>
      {closable && (
        <ToolButton
          icon={CloseIcon}
          aria-label="close"
          type="icon"
          onClick={onClose}
          className="close"
        />
      )}
    </div>
  );
};
