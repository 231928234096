import React, { useRef, useState, useCallback, useEffect } from "react";

import { atom, useAtom } from "jotai";
import _ from "lodash";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";

import {
  currentDrawingAtom,
  currentDrawingsAtom,
  currentProjectAtom,
  excalidrawApiAtom,
  projectToPreloadAtom,
} from "../store/variables";

import { collabAPIAtom } from "../ExcalidrawAPI/excalidraw-app/collab/Collab";
import ConfirmDialog from "../ExcalidrawAPI/components/ConfirmDialog";
import Spinner from "../ExcalidrawAPI/components/Spinner";

import {
  activeLoaderAtom,
  isLeavingSceneAtom,
  isOpeningSceneAtom,
} from "../store/UI";
import {
  deleteDrawingsByBatch,
  getDrawingsByProjectId,
  updatePagesOrderByBatch,
} from "../helpers/drawings";
import { formatDrawingOrder } from "../helpers/common";
import { INITIAL_COVER_SHEET_OBJ, PULLER_MARGIN } from "../helpers/constants";

import update from "immutability-helper";

import CloseButton from "./CloseButton";
import DrawerCell from "./DrawerCell";
import DeleteButton from "./DeleteButton";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { Badge } from "@mui/material";

import { useFrameMethods } from "../hooks/useFrameMethods";
import {
  CONTEXT_MENU_SEPARATOR,
  PagesManagerContextMenu,
} from "./PagesManagerContextMenu";
import ExportIcon from "./Icons/ExportIcon";

import SelectAllRoundedIcon from "@mui/icons-material/SelectAllRounded";
import DeselectRoundedIcon from "@mui/icons-material/DeselectRounded";
import LibraryAddRoundedIcon from "@mui/icons-material/LibraryAddRounded";
import { CheckboxItem } from "../ExcalidrawAPI/components/CheckboxItem";
import { useCoverSheetDrawer } from "../hooks/useCoverSheetDrawer";
import CustomLoader from "./CustomLoader";
import { useSceneMethods } from "../hooks/useSceneMethods";
import { useTableMethods } from "../hooks/useTableMethods";
import { updateProjectById } from "../helpers/projects";

import "../scss/PagesManager.scss";

const deleteIcon = (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    viewBox="0 0 22 22"
    className="context-menu-delete-icon"
    fill="none"
    stroke="#fa5252"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      strokeWidth="1.25"
      d="M3.333 5.833h13.334M8.333 9.167v5M11.667 9.167v5M4.167 5.833l.833 10c0 .92.746 1.667 1.667 1.667h6.666c.92 0 1.667-.746 1.667-1.667l.833-10M7.5 5.833v-2.5c0-.46.373-.833.833-.833h3.334c.46 0 .833.373.833.833v2.5"
    ></path>
  </svg>
);

export const pagesManagerContextMenuAtom = atom(false);

const PagesManager = ({
  isOpen,
  setIsOpen,
  selectedProject,
  resetScene,
  openScene,
  uploadPage,
}) => {
  //Atom
  const [collabAPI] = useAtom(collabAPIAtom);
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [currentProject] = useAtom(currentProjectAtom);
  const [, setProjectToPreload] = useAtom(projectToPreloadAtom);
  const [currentDrawing] = useAtom(currentDrawingAtom);
  const [currentDrawings] = useAtom(currentDrawingsAtom);
  const [isLeavingScene, setIsLeavingScene] = useAtom(isLeavingSceneAtom);
  const [isOpeningScene, setIsOpeningScene] = useAtom(isOpeningSceneAtom);
  const [isContextMenuShown, setIsContextMenuShown] = useAtom(
    pagesManagerContextMenuAtom,
  );
  const [, setActiveLoader] = useAtom(activeLoaderAtom);

  //Custom Hooks
  const { editFrame } = useFrameMethods();
  const { deleteRows, updateDrawingsList } = useCoverSheetDrawer();
  const { getCurrentElements } = useSceneMethods();
  const { getTableProps } = useTableMethods();

  //State
  const [drawerCells, setDrawerCells] = useState(
    _.cloneDeep(
      currentDrawings?.sort((el1, el2) => el1.order - el2.order) || [],
    ) || [],
  );
  const [originalPages, setOriginalPages] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [scrollStatus, setScrollStatus] = useState("up");
  const [confirmDialogStatus, setConfirmDialogStatus] = useState(null);
  const [drawerWidth, setDrawerWidth] = useState(
    window.innerWidth <= 422 ? window.innerWidth - PULLER_MARGIN : 450,
  );
  const [isHiddenScrollBtn, setIsHiddenScrollBtn] = useState(
    currentDrawings?.length === 0,
  );
  const [isShownOrderButtons, setIsShownOrderButtons] = useState(false);
  const [isUpdatingOrdersInDB, setIsUpdatingOrdersInDB] = useState(false);
  const [contextMenuProps, setContextMenuProps] = useState({});
  const [currentViewWidth, setCurrentViewWidth] = useState(
    window.innerWidth <= 422 ? window.innerWidth - PULLER_MARGIN : 450,
  );
  const [isClosedBehavior, setIsClosedBehavior] = useState(true);

  //Callbacks
  const checkIfOrderChanged = useCallback(
    (drawerCells) => {
      let isChanged = false;
      for (const page of drawerCells) {
        for (const originalPage of originalPages) {
          if (page.id === originalPage.id) {
            if (page.order !== originalPage.order) {
              setIsShownOrderButtons(true);
              isChanged = true;
              break;
            }
          }
        }
      }
      if (!isChanged) {
        setIsShownOrderButtons(false);
      }
      return isChanged;
    },
    [originalPages],
  );

  const moveCell = useCallback(
    (dragIndex, hoverIndex) => {
      if (dragIndex === 0) {
        return;
      }
      setDrawerCells((prevCells) => {
        const updatedCells = update(prevCells, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCells[dragIndex]],
          ],
        }).map((el, index) => ({
          ...el,
          order: index + 1,
        }));
        checkIfOrderChanged(updatedCells);
        return updatedCells;
      });
    },
    [checkIfOrderChanged],
  );

  const renderDrawerCell = useCallback(
    (element, index, lastIndex) => {
      return (
        <DrawerCell
          key={element.id}
          index={index}
          lastIndex={lastIndex}
          cellTooltip={element.id !== currentDrawing.id ? "Click To OPEN" : ""}
          id={element.id}
          element={element}
          deleteCell={deleteDrawingsHandler}
          originalItem={originalPages.find((el) => el.id === element.id)}
          moveCell={moveCell}
          setSelectedItems={setSelectedItems}
          isActiveCell={element.id === currentDrawing?.id}
          isDefaultSelected={selectedItems.find((el) => el === element.id)}
          setCells={setDrawerCells}
          openScene={openScene}
          canDelete={
            element.type === "coverSheet"
              ? !!currentDrawings.find((el) => el.id === element.id)
              : true
          }
          setIsOpeningScene={setIsOpeningScene}
          setIfParentOpen={setIsOpen}
          uploadPage={uploadPageHandler}
          isDraggable={element.type !== "coverSheet"}
          triggerToast={triggerToast}
          excalidrawAPI={excalidrawAPI}
        />
      );
    },
    [
      currentDrawing,
      originalPages,
      currentDrawings,
      currentProject,
      selectedItems,
    ],
  );

  //Refs
  const drawerContainerRef = useRef(null);
  const drawerComponentRef = useRef(null);
  const drawerContentRef = useRef(null);

  //Constants
  const contextMenuItems = [
    {
      type: "addNew",
      label: "Add Page",
      icon: <LibraryAddRoundedIcon fontSize="small" />,
      action: () => uploadPageHandler(),
    },
    {
      type: "selectAll",
      label: "Select All",
      icon: <SelectAllRoundedIcon fontSize="small" />,
      isDisabled: selectedItems.length === drawerCells.length - 1,
      action: () =>
        setSelectedItems(
          drawerCells.filter((el) => el.order > 1).map((el) => el.id),
        ),
    },
    {
      type: "deselectAll",
      label: "Deselect All",
      icon: <DeselectRoundedIcon fontSize="small" />,
      isDisabled: !selectedItems.length,
      action: () => setSelectedItems([]),
    },
    {
      type: CONTEXT_MENU_SEPARATOR,
    },
    {
      type: "deleteAll",
      label: "Delete",
      icon: deleteIcon,
      isDisabled: !selectedItems.length,
      action: () => setConfirmDialogStatus("delete"),
    },
  ];

  const drawerButtonsContainerClasses = `order-drawer_buttons_container ${
    isShownOrderButtons ? "" : "hidden"
  }`.trim();

  const saveOrderBtnClasses = `order-drawer_btn save_order_btn`;

  const discardOrderBtnClasses = `order-drawer_btn discard_order_btn`;

  const scrollBtnClasses = `order-drawer_btn order-drawer_btn_scroll ${
    isHiddenScrollBtn ? "hidden" : ""
  }`.trim();

  //Effects
  useEffect(() => {
    if (isOpen === false) {
      setTimeout(() => {
        setIsClosedBehavior(true);
      }, 400);
    } else {
      setIsClosedBehavior(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setCurrentViewWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getCurrentOriginalPages = async () => {
      const originalDrawings = await getDrawingsByProjectId(selectedProject);
      setOriginalPages(originalDrawings);
    };
    selectedProject && getCurrentOriginalPages();
  }, [selectedProject]);

  useEffect(() => {
    if (!currentDrawings) {
      return;
    }
    if (!currentDrawings?.some((c) => c.type === "coverSheet")) {
      setDrawerCells([INITIAL_COVER_SHEET_OBJ, ...currentDrawings]);
    } else {
      setDrawerCells(currentDrawings);
    }
  }, [currentDrawings]);

  useEffect(() => {
    if (!drawerContentRef.current) {
      return;
    }
    drawerContentRef.current.addEventListener("scroll", contentScrollListener);

    return () => {
      drawerContentRef.current?.removeEventListener(
        "scroll",
        contentScrollListener,
      );
    };
  }, [drawerContentRef]);

  //Functions
  const isAllSelected = () => {
    const validPages = drawerCells.filter((el) => el.order > 1);
    return (
      !!validPages.length &&
      !!selectedItems.length &&
      validPages.length === selectedItems.length
    );
  };

  const container =
    drawerContainerRef !== null ? () => drawerContainerRef.current : null;

  const toggleDrawer = (status) => {
    setIsOpen(status);
  };

  /**
   * This function is to find the available previous order
   * @param {Array} currentPages - An array of the remained pages after deleting
   * @returns {number} - The order of the page that should be opened
   */
  const getOrderOfPageToOpen = (currentPages) => {
    return currentPages.reduce((max, obj) => {
      return Math.max(max, obj.order);
    }, -Infinity);
  };

  const getShiftedPages = (pagesToShift) => {
    const offset = pagesToShift.some((el) => el.type === "coverSheet") ? 1 : 2; //Added 2 as there's always one place taken by the cover sheet + index starts from 0

    return pagesToShift.map((el, index) =>
      Object.assign(el, { order: index + offset }),
    );
  };

  const shiftAllPages = async (currentPages, selectedItems) => {
    if (currentPages.length === 0) {
      return;
    }
    const pagesToShift = currentPages.filter(
      (el) => !selectedItems.includes(el.id),
    );
    const shiftedPages = getShiftedPages(pagesToShift);

    if (pagesToShift.some((el) => el.id === currentDrawing.id)) {
      const currentDrawingOrder = pagesToShift.find(
        (el) => el.id === currentDrawing.id,
      ).order;
      editFrame({
        changes: [
          {
            valueFor: "PAGE NO",
            value: formatDrawingOrder(currentDrawingOrder, shiftedPages.length),
          },
        ],
        update: false,
      });
    }
    return updatePagesOrderByBatch(shiftedPages);
  };

  const updateInCoverSheet = async () => {
    if (currentProject.coverSheetRoom) {
      const allSceneElements = await getCurrentElements(
        currentProject.coverSheetRoom,
      );
      const tableProps = getTableProps("drawingsList", allSceneElements);
      deleteRows({
        drawingsToDelete: currentDrawings?.filter((el) =>
          selectedItems.includes(el.id),
        ),
        currentDrawingsLength: currentDrawings?.length - selectedItems.length,
        allElements: allSceneElements,
        tableProps,
      });
    }
  };

  const openDrawingHandler = async (drawingId, cellIndex) => {
    setIsLeavingScene(true);
    setActiveLoader("saving");
    await collabAPI?.stopCollaboration();
    const drawerCell = [
      ...document.getElementsByClassName("order-drawer_cell"),
    ][cellIndex];
    drawerCell?.classList.add("order_drawer_cell_active");
    setIsOpeningScene(true);
    setActiveLoader("opening");
    setTimeout(async () => {
      await openScene(drawingId);
      setIsOpeningScene(false);
      setActiveLoader(null);
      drawerCell?.classList.remove("order_drawer_cell_active");
    }, 1000);
  };

  const currentPageDeletedHandler = async (currentPages) => {
    //1. Current page is the first and the only element -> Go Home
    if (currentPages.length <= 1) {
      setIsOpen(false);
      await collabAPI?.stopCollaboration();
      resetScene();
    }
    //2. Current page is the first element -> open the next
    //Note that -> The numbering starts from 2, as number 1 is always taken for the cover sheet.
    else if (currentDrawing.order === 2) {
      const { id, order } =
        currentPages.find((el) => el.order === currentDrawing.order) ?? {};
      openDrawingHandler(id, order - 1, currentPages);
    }
    //3. Current page is in between -> Open the one before
    else {
      const orderOfPageToOpen = getOrderOfPageToOpen(currentPages);
      const { id, order } =
        currentPages.find((el) => el.order === orderOfPageToOpen) ?? {};
      openDrawingHandler(id, order - 1, currentPages);
    }
  };

  const deleteDrawingsHandler = async (selectedItems) => {
    if (selectedItems.length === 0) {
      return;
    }
    try {
      await deleteDrawingsByBatch(selectedItems);
      triggerToast(
        `Deleted Page${selectedItems.length > 1 ? "s" : ""} Successfully!`,
        "success",
      );
      // Check if deleting the cover sheet.
      if (
        selectedItems?.length === 1 &&
        currentDrawings.find((el) => el.id === selectedItems[0]).type ===
          "coverSheet"
      ) {
        //Check if the cover sheet is opened.
        if (currentProject.coverSheetRoom === location.hash) {
          await collabAPI?.stopCollaboration();
          resetScene();
          setIsOpen(false);
        }
        await updateProjectById(currentProject.id, { coverSheetRoom: "" });
        triggerToast(`Deleted Cover Sheet`, "success");
        return;
      }
      updateInCoverSheet();
      await shiftAllPages(currentDrawings, selectedItems);
      setIsShownOrderButtons(false);
      const remainedPages = currentDrawings?.filter(
        (el) => !selectedItems.includes(el.id),
      );
      //Handles the case of deleting the opened drawing
      if (selectedItems.some((el) => el === currentDrawing.id)) {
        currentPageDeletedHandler(remainedPages);
      }
      updateDrawingsList(remainedPages);
    } catch (error) {
      console.error(error);
      setDrawerCells(_.cloneDeep(originalPages));
      triggerToast(`Failed To Delete Pages`, "error");
    }
    setConfirmDialogStatus(null);
    setSelectedItems([]);
  };

  const saveOrderHandler = async () => {
    const currentUpdates = getOrderChanges();
    try {
      setIsUpdatingOrdersInDB(true);
      setActiveLoader("updating");
      await updatePagesOrderByBatch(currentUpdates);
      //Update the drawings list in the cover sheet.
      updateDrawingsList(drawerCells);
      if (currentUpdates.some((el) => el.id === currentDrawing.id)) {
        const currentDrawingOrder = currentUpdates.find(
          (el) => el.id === currentDrawing.id,
        ).order;
        editFrame({
          changes: [
            {
              valueFor: "PAGE NO",
              value: formatDrawingOrder(
                currentDrawingOrder,
                currentDrawings?.length || 0,
              ),
            },
          ],
          update: false,
        });
      }
      triggerToast(`Updated Pages Successfully!`, "success");
      setOriginalPages(_.cloneDeep(drawerCells));
      setActiveLoader(null);
    } catch (error) {
      console.error("Couldn't save the order: ", error);
      setDrawerCells(_.cloneDeep(originalPages));
      triggerToast(`Failed To Update Pages`, "error");
      setActiveLoader(null);
    }
    setIsUpdatingOrdersInDB(false);
    setIsShownOrderButtons(false);
    setConfirmDialogStatus(null);
  };

  const discardOrderHandler = () => {
    let elementsToSet = _.cloneDeep(originalPages);
    if (!originalPages.find((el) => el.type === "coverSheet")) {
      const coverSheetPage = drawerCells.find((el) => el.type === "coverSheet");
      coverSheetPage.order = 1;
      elementsToSet = [coverSheetPage, ...elementsToSet];
    }

    setDrawerCells(elementsToSet);
    setConfirmDialogStatus(null);
    setIsShownOrderButtons(false);
  };

  const contentScrollHandler = () => {
    setScrollStatus((prevStatus) => {
      if (prevStatus === "up") {
        drawerContentRef.current.scrollTop = 0;
        return "down";
      }
      drawerContentRef.current.scrollTop =
        drawerContentRef.current.scrollHeight;
      return "up";
    });
  };

  const contentScrollListener = () => {
    const { scrollTop, scrollHeight, offsetHeight } = drawerContentRef.current;
    const contentHeight = scrollHeight - offsetHeight;
    if (0.5 * contentHeight <= scrollTop) {
      setScrollStatus("up");
      setIsHiddenScrollBtn(false);
    } else {
      setScrollStatus("down");
      setIsHiddenScrollBtn(true);
    }
  };

  const getOrderChanges = () => {
    //Check if there's a change in order
    return drawerCells
      .filter((cell) =>
        originalPages.some(
          (originalPage) =>
            originalPage.id === cell.id && originalPage.order !== cell.order,
        ),
      )
      .map((el) => {
        //Just return the values to edit
        return { id: el.id, order: el.order };
      });
  };

  const triggerToast = (message, status, options = {}) => {
    excalidrawAPI.setToast({
      message,
      duration: 2000,
      closable: true,
      options: {
        position: "topRight",
        status,
        ...options,
      },
    });
  };

  const confirmDialogActionHandler = () => {
    deleteDrawingsHandler(selectedItems);
  };

  const uploadPageHandler = (uploadOptions) => {
    toggleDrawer(false);
    setProjectToPreload(currentProject.id);
    uploadPage({ clicked: "upload", collabAPI, options: uploadOptions });
  };

  const contextMenuHandler = (ev) => {
    if (ev.button === 2 && ev.target === drawerContentRef.current) {
      const { top: offsetTop, left: offsetLeft } =
        drawerComponentRef.current.getBoundingClientRect();
      const left = ev.clientX - offsetLeft;
      const top = ev.clientY - offsetTop;
      setContextMenuProps({ left, top });
      setIsContextMenuShown(true);
    }
  };

  //Render
  return (
    <main ref={drawerContainerRef} style={{ zIndex: isClosedBehavior ? 2 : 5 }}>
      <section
        ref={drawerComponentRef}
        className="order-drawer-component"
        onContextMenu={(e) => {
          if (isOpen) {
            e.preventDefault();
          }
        }}
        onAuxClick={contextMenuHandler}
      >
        <Resizable
          className="box"
          height={Infinity}
          width={drawerWidth}
          onResize={(_, { size }) => {
            const iconEl = document.querySelector(".order-drawer-icon");
            iconEl.classList.remove("order-drawer-puller_box_open");
            iconEl.classList.remove("order-drawer-puller_box_close");
            setDrawerWidth(size.width);
          }}
          minConstraints={[
            currentViewWidth <= 422 ? currentViewWidth - PULLER_MARGIN : 450,
            Infinity,
          ]}
          resizeHandles={["e"]}
        >
          <SwipeableDrawer
            container={container}
            open={isOpen}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
            swipeAreaWidth={0}
            transitionDuration={{ enter: 500, exit: 500 }}
            sx={{
              "& .MuiBackdrop-root": {
                backgroundColor: "transparent",
              },
              "& .MuiDrawer-paper": {
                overflow: "hidden",
              },
              zIndex: confirmDialogStatus ? "9" : "1300",
            }}
          >
            <div className="order-drawer_header">
              <Badge
                badgeContent={selectedItems.length}
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "tomato",
                    minWidth: "15px",
                    height: "17px",
                    width: "17px",
                    fontSize: "10px",
                    border: "1px solid #fff",
                    color: "#fff",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <DeleteButton
                  title={"Delete selected drawings"}
                  disabled={selectedItems.length === 0}
                  onDelete={() => setConfirmDialogStatus("delete")}
                />
              </Badge>
              <h3>Pages Manager</h3>
              <CloseButton
                onClick={() => toggleDrawer(false)}
                classes={["order-drawer_close_btn"]}
                style={{
                  backgroundColor: "transparent",
                  color: "#444",
                  borderColor: "transparent",
                  borderRadius: "50%",
                  "&:hover": {
                    backgroundColor: "#ebebeb",
                    borderColor: "#ebebeb",
                  },
                }}
              />
            </div>

            <div
              className="order-drawer_content"
              ref={drawerContentRef}
              style={{
                overflow: isOpeningScene ? "hidden" : "scroll",
                width:
                  currentViewWidth <= 422
                    ? currentViewWidth - PULLER_MARGIN
                    : drawerWidth,
              }}
            >
              <div className="pages-manager-actions-container">
                <div className="selected-checkbox_container">
                  <CheckboxItem
                    title="Select All"
                    checked={isAllSelected()}
                    className="order-drawer_select_checkbox"
                    onChange={(status) => {
                      if (status) {
                        const validPages = drawerCells.filter(
                          (el) => el.order > 1,
                        );
                        setSelectedItems(validPages.map((el) => el.id));
                      } else {
                        setSelectedItems([]);
                      }
                    }}
                  />
                  <p
                    className="selected-keyword"
                    style={{ opacity: !selectedItems.length ? 0.7 : 1 }}
                  >
                    Selected ({selectedItems.length})
                  </p>
                </div>
                <button
                  className="page-manager-export-btn"
                  title="Export active drawing"
                  onClick={() =>
                    excalidrawAPI.updateScene({
                      appState: { openDialog: "imageExport" },
                    })
                  }
                >
                  <ExportIcon width="25px" height="25px" color="#6965db" />
                </button>
              </div>

              {drawerCells.length === 0 || isLeavingScene || isOpeningScene ? (
                <div className="order-drawer_no_drawings">
                  <h3>No Pages Available</h3>
                </div>
              ) : (
                drawerCells.map(
                  (el, index) =>
                    currentDrawing &&
                    !isUpdatingOrdersInDB &&
                    renderDrawerCell(el, index, drawerCells.length),
                )
              )}

              {(isLeavingScene || isOpeningScene || isUpdatingOrdersInDB) &&
              isOpen ? (
                <div className="order-drawer_loader_container">
                  <CustomLoader />
                </div>
              ) : null}
            </div>

            <div className={drawerButtonsContainerClasses}>
              <button
                className={saveOrderBtnClasses}
                title="Save"
                disabled={isUpdatingOrdersInDB}
                onClick={saveOrderHandler}
              >
                <CheckRoundedIcon className="order-drawer_save_order_icon" />
                <div className="order-drawer_save_order_spinner">
                  <Spinner />
                </div>
              </button>

              <button
                className={discardOrderBtnClasses}
                title="Discard"
                disabled={isUpdatingOrdersInDB}
                onClick={discardOrderHandler}
              >
                <ClearRoundedIcon className="order-drawer_discard_order_icon" />
              </button>
            </div>

            <button
              className={scrollBtnClasses}
              title={`scroll ${scrollStatus}`}
              onClick={contentScrollHandler}
            >
              <ExpandLessRoundedIcon
                className={`order-drawer_scroll_icon ${
                  scrollStatus === "down" ? "scroll_icon_down" : ""
                }`.trim()}
              />
            </button>
          </SwipeableDrawer>
        </Resizable>

        {isContextMenuShown ? (
          <PagesManagerContextMenu
            items={contextMenuItems}
            top={contextMenuProps.top}
            left={contextMenuProps.left}
          />
        ) : null}
      </section>

      <div
        style={{
          transform: `translate(${isOpen ? `${drawerWidth}px` : "0px"}, -50%)`,
        }}
        className={`order-drawer-icon ${
          isOpen
            ? "order-drawer-puller_box_open"
            : "order-drawer-puller_box_close"
        }`}
      >
        <BookmarksIcon />
      </div>

      {confirmDialogStatus ? (
        <ConfirmDialog
          buttonsPlacement="end"
          title={`Delete Drawing${selectedItems.length > 1 ? "s" : ""}`}
          onConfirm={confirmDialogActionHandler}
          onCancel={() => {
            setConfirmDialogStatus(null);
          }}
        >
          <p className="pages_manager_actions_confirm">
            {`You are about to delete ${selectedItems.length} drawing${
              selectedItems.length > 1 ? "s" : ""
            }. Are you sure?`}
          </p>
        </ConfirmDialog>
      ) : null}
    </main>
  );
};

export default PagesManager;
