import { useAtom } from "jotai";
import clsx from "clsx";
import { t } from "../../i18n";
import { excalidrawApiAtom } from "../../../store/variables";
import { actionShortcuts } from "../../actions";
import { ActionManager } from "../../actions/manager";
import {
  AppState,
  UIChildrenComponents,
  UIWelcomeScreenComponents,
} from "../../types";
import {
  ExitZenModeAction,
  FinalizeAction,
  UndoRedoActions,
  ZoomActions,
} from "../Actions";
import { useDevice } from "../App";
import { HelpButton } from "../HelpButton";
import { Section } from "../Section";
import Stack from "../Stack";
import MyLocationRoundedIcon from "@mui/icons-material/MyLocationRounded";
import { Tooltip } from "../Tooltip";
import { isReadyToDesignAtom } from "../../../store/UI";

const Footer = ({
  appState,
  actionManager,
  showExitZenModeBtn,
  footerCenter,
  welcomeScreenHelp,
}: {
  appState: AppState;
  actionManager: ActionManager;
  showExitZenModeBtn: boolean;
  footerCenter: UIChildrenComponents["FooterCenter"];
  welcomeScreenHelp: UIWelcomeScreenComponents["HelpHint"];
}) => {
  const device = useDevice();
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [isReadyToDesign] = useAtom(isReadyToDesignAtom);
  const showFinalize =
    !appState.viewModeEnabled && appState.multiElement && device.isTouchScreen;

  return (
    <footer
      role="contentinfo"
      className="layer-ui__wrapper__footer App-menu App-menu_bottom"
    >
      <div
        className={clsx("layer-ui__wrapper__footer-left zen-mode-transition", {
          "layer-ui__wrapper__footer-left--transition-left":
            appState.zenModeEnabled,
        })}
      >
        <Stack.Col gap={2}>
          <Section heading="canvasActions">
            <ZoomActions
              renderAction={actionManager.renderAction}
              zoom={appState.zoom}
            />
            {/* {!appState.viewModeEnabled && (
              <UndoRedoActions
                renderAction={actionManager.renderAction}
                className={clsx("zen-mode-transition", {
                  "layer-ui__wrapper__footer-left--transition-bottom":
                    appState.zenModeEnabled,
                })}
              />
            )} */}

            <Tooltip
              label={t("buttons.center")}
              style={{
                opacity: isReadyToDesign ? 1 : 0.6,
                pointerEvents: isReadyToDesign ? "auto" : "none",
              }}
            >
              <button
                style={{
                  opacity: isReadyToDesign ? 1 : 0.6,
                  pointerEvents: isReadyToDesign ? "auto" : "none",
                }}
                onClick={() => excalidrawAPI?.zoomToFitAllDrawing()}
                className="center-button"
              >
                <MyLocationRoundedIcon />
              </button>
            </Tooltip>

            {showFinalize && (
              <FinalizeAction
                renderAction={actionManager.renderAction}
                className={clsx("zen-mode-transition", {
                  "layer-ui__wrapper__footer-left--transition-left":
                    appState.zenModeEnabled,
                })}
              />
            )}
          </Section>
        </Stack.Col>
      </div>
      {footerCenter}
      <div
        className={clsx("layer-ui__wrapper__footer-right zen-mode-transition", {
          "transition-right disable-pointerEvents": appState.zenModeEnabled,
        })}
      >
        <div style={{ position: "relative" }}>
          {welcomeScreenHelp}
          <p
            style={{
              position: "absolute",
              left: "-49px",
              top: "23px",
              fontSize: "12px",
            }}
          >
            V {process.env.REACT_APP_APP_VERSION}
          </p>
          <HelpButton
            onClick={() => actionManager.executeAction(actionShortcuts)}
          />
        </div>
      </div>
      <ExitZenModeAction
        actionManager={actionManager}
        showExitZenModeBtn={showExitZenModeBtn}
      />
    </footer>
  );
};

export default Footer;
Footer.displayName = "Footer";
