import React from "react";

import { Slider, TextField } from "@mui/material";

import "../scss/ValueSlider.scss";

const ValueSlider = ({
  value,
  disabled,
  step = 5,
  min = 5,
  max = 50,
  size = "small",
  classes = [],
  color = "#5b57d1",
  icon = null,
  onChange,
}) => {
  //Atom

  //Constants
  const usedClasses = `${classes.join(" ")}`;
  const inlineStyling = {
    "& .MuiSlider-rail": {
      backgroundColor: `${color}9c`,
    },
    "& .MuiSlider-track": {
      color: `${color}9c`,
    },
    "& .MuiSlider-thumb": {
      backgroundColor: `${color}9c`,
    },
    "& .MuiSlider-thumb:hover": {
      boxShadow: `0px 0px 0px 8px ${color}56`,
    },
    "& .MuiSlider-mark ": {
      backgroundColor: `${color}`,
    },
  };

  //Render
  return (
    <div className={`value-slider-container ${usedClasses}`}>
      <div className="value-slider-icon">{icon ? icon : null}</div>

      <Slider
        className="value-slider-container-slider"
        step={step}
        min={min}
        max={max}
        disabled={disabled}
        value={typeof value === "number" ? value : min}
        size={size}
        sx={inlineStyling}
        aria-labelledby="input-slider"
        onChange={onChange}
      />

      <TextField
        className="value-slider_textfield"
        value={value}
        size="small"
        onChange={onChange}
        variant="outlined"
        sx={{
          width: "20%",
          "& input:focus": {
            boxShadow: `0 0 0 2px ${color}56`,
          },
        }}
        inputProps={{
          step,
          min,
          max,
          type: "number",
          "aria-labelledby": "input-slider",
        }}
      />
    </div>
  );
};

export default ValueSlider;
