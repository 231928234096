export const MARGIN_UNIT = 12.5;
export const FRAME_WIDTH = 2550;
export const FRAME_HEIGHT = 1650;
export const CONTENT_HEIGHT = 1360;
export const NORTH_ICON_DIM = 85;
export const NORTH_ICON_MARGIN_LEFT = 70;
export const NORTH_ICON_MARGIN_RIGHT = 25;
export const SIDE_DATA_WIDTH = 490;
export const NOMENCLATURE_ELLIPSE_DIM = 55;
export const HORIZONTAL_RULER_WIDTH = 95;
export const HORIZONTAL_RULER_MARGIN_X = 70;
export const CELL_HEIGHT = 290;
export const BAR_CODE_WIDTH = 310;
export const BAR_CODE_HEIGHT = 15;
export const MAX_DRAWING_WIDTH = 1835;
export const MAX_DRAWING_HEIGHT = 990;
export const HORIZONTAL_MEASURE_TEXTS_MARGIN_BOTTOM = 1 * MARGIN_UNIT;
export const NOMENCLATURE_ELLIPSE_MARGIN_BOTTOM = 1 * MARGIN_UNIT;
export const HORIZONTAL_RULER_MARGIN_BOTTOM = 2 * MARGIN_UNIT;
export const ELEMENT_STROKE_WIDTH = 1;
export const MARGIN_PICK_VALUE = 5;

export const PIXELS_PER_FEET = 12;

export const GRID_RULER_SHORT_DIM = 25;
export const GRIDE_RULER_HEIGHT =
  CONTENT_HEIGHT - 2 * MARGIN_UNIT + 2 * ELEMENT_STROKE_WIDTH;
export const GRIDE_RULER_WIDTH =
  FRAME_WIDTH -
  4 * MARGIN_UNIT -
  GRID_RULER_SHORT_DIM -
  1 * ELEMENT_STROKE_WIDTH;

export const COVER_SHEET_CONTENT_WIDTH =
  GRIDE_RULER_WIDTH - GRID_RULER_SHORT_DIM;
export const COVER_SHEET_CONTENT_HEIGHT =
  GRIDE_RULER_HEIGHT - GRID_RULER_SHORT_DIM;

export const PLAYER_CELL_LABEL_HEIGHT = 25;
export const COVER_SHEET_LABEL_HEIGHT = 25;
export const COVER_SHEET_CELL_HEIGHT = 22;

export const COVER_SHEET_LABEL_FONT_SIZE = 18;
export const COVER_SHEET_TEXT_FONT_SIZE = 16;

export const RESTRICTED_KEYS = [
  ...Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode("a".charCodeAt(0) + i),
  ),
  ...Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode("A".charCodeAt(0) + i),
  ),
  ...Array.from({ length: 10 }, (_, i) =>
    String.fromCharCode("0".charCodeAt(0) + i),
  ),
];

export const COVER_SHEET_CELL = "coverSheet";

export const DELETED_PROPS = {
  x: 0,
  y: 0,
  width: 0.1,
  height: 0.1,
  opacity: 0,
  locked: true,
  customData: { isDeleted: true },
};

export const ALL_UPLOAD_INPUTS = [
  { key: "recordId", type: "string", parent: "project" },
  { key: "systemDescription", type: "string", parent: "project" },
  { key: "selectedDominantUse", type: "object", parent: "project" },
  { key: "mapImage", type: "string", parent: "project" },
  {
    key: "selectedDominantPropertyUse",
    type: "object",
    parent: "property",
  },
  { key: "stage", type: "string", parent: "coverSheet" },
  { key: "pageSize", type: "string", parent: "coverSheet" },
  { key: "typeOfConstruction", type: "string", parent: "coverSheet" },
  {
    key: "propertyLevelsAboveGround",
    type: "number",
    parent: "coverSheet",
  },
  {
    key: "propertyLevelsBelowGround",
    type: "number",
    parent: "coverSheet",
  },
  { key: "totalPropertyHeight", type: "number", parent: "coverSheet" },
  { key: "totalOccupancyLoad", type: "string", parent: "coverSheet" },
  { key: "totalBuildingArea", type: "number", parent: "coverSheet" },
  { key: "isFloodHazardArea", type: "boolean", parent: "coverSheet" },
  { key: "codesCombinationId", type: "string", parent: "coverSheet" },
  { key: "drawingOccupancies", type: "array", parent: "drawing" },
  { key: "drawingStage", type: "string", parent: "drawing" },
  { key: "pageSize", type: "string", parent: "drawing" },
  { key: "drawingName", type: "string", parent: "drawing" },
  { key: "nomenclature", type: "string", parent: "drawing" },
  { key: "detectedLineWidth", type: "number", parent: "drawing" },
  { key: "floorArea", type: "number", parent: "drawing" },
];

export const UNITS = ["inch", "feet", "m", "cm", "mm"];
export const SQR_UNITS = [
  { label: "inch²", value: "inch2" },
  { label: "feet²", value: "feet2" },
  { label: "m²", value: "m2" },
  { label: "cm²", value: "cm2" },
  { label: "mm²", value: "mm2" },
];
export const DOMINANT_OCCUPANCY_GROUPS = [
  { ASSEMBLY: ["A-1", "A-2", "A-3", "A-4", "A-5"] },
  { BUSINESS: ["B"] },
  { EDUCATIONAL: ["E"] },
  { FACTORY_INDUSTRIAL: ["F-1", "F-2"] },
  { HIGH_HAZARD: ["H-1", "H-2", "H-3", "H-4", "H-5"] },
  { INSTITUTIONAL: ["I-1", "I-2", "I-3", "I-4"] },
  { MERCANTILE: ["M"] },
  { RESIDENTIAL: ["R-1", "R-2", "R-3", "R-4"] },
  { STORAGE: ["S-1", "S-2"] },
  { UTILITY_MISCELLANEOUS: ["U"] },
];

export const NUMBER_OF_PLAYERS = 7;

export const KEY_CONTACTS_ROLES = [
  "OWNER",
  "ARCHITECT",
  "",
  "GENERAL CONTRACTOR",
  "SYSTEM DESIGN",
  "FILING REPRESENTATIVE",
  "SYSTEM VENDOR",
];

export const DRAWING_STAGES = [
  "SHOP DRAWINGS",
  "CONSTRUCTION DRAWINGS",
  "FILING SET DRAWINGS",
  "AS-BUILT DRAWINGS",
  "RECORD SET DRAWINGS",
];

export const DEFAULT_TYPES_VALUES = {
  string: "",
  number: 0,
  boolean: false,
  object: {},
  array: [],
};

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;

export const PULLER_MARGIN = 35;

export const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const NORTH_ICON =
  "https://firebasestorage.googleapis.com/v0/b/outercloud-40c46.appspot.com/o/icon_north.svg?alt=media&token=7ce68b6a-e94c-47a5-9ebb-0be52c6e9dac&_gl=1*1xyuvvo*_ga*NjMxNjcxMzMxLjE2OTQ5NTU0MTM.*_ga_CW55HF8NVT*MTY5NjE4MTc5NC40NC4xLjE2OTYxODMxNzIuOC4wLjA.";
export const TIMBERLAND_LOGO =
  "https://outercloud-icons.s3.us-west-2.amazonaws.com/timberland-logo.png";
export const COVER_SHEET_THUMBNAIL =
  "https://outercloud-icons.s3.us-west-2.amazonaws.com/cover_sheet-thumbnail.png";

export const INITIAL_COVER_SHEET_OBJ = {
  id: COVER_SHEET_CELL,
  order: 1,
  name: "Cover Sheet",
  thumbImageUrl: COVER_SHEET_THUMBNAIL,
  type: COVER_SHEET_CELL,
};

export const LEFT_SECTION_PARTS_LABELS = [
  "PROJECT NAME:",
  "PROJECT ADDRESS:",
  "LOCATION PLAN (NOT TO SCALE)",
  "LOCATION PLAN LEGEND:",
];

export const LEFT_SECTION_PROJECT_ADDRESS =
  "550 BROADWAY, STORE #267, NEW YORK, NY 10012-3912";

export const LEFT_SECTION_PROJECT_LOCATION =
  "https://outercloud-icons.s3.us-west-2.amazonaws.com/test-location-image.png";

export const ENTRANCE_EXIT = "ENTRANCE / EXIT";
export const AREA_OF_WORK_TEXT = "SITE / AREA OF WORK";

export const MIDDLE_SECTION_PARTS_LABELS = [
  "SYSTEM DESCRIPTION:",
  "PROJECT SUMMARY:",
  "CODE REFERENCE:",
];

export const SYSTEM_DESCRIPTION =
  "INSTALLATION OF MANUAL, AUTOMATIC SMOKE/ HEAT DETECTION & SPRINKLER FIRE ALARM SYSTEM (TIMBERLAND)";

export const PROJECT_SUMMARY_TABLE_COLUMNS = [
  {
    active: true,
    key: "projectSummaryKey",
    label: "",
    widthRatio: 0.5,
  },
  {
    active: true,
    key: "projectSummaryValue",
    label: "",
    widthRatio: 0.5,
  },
];

export const PROJECT_SUMMARY_DATA = [
  {
    rowKey: "dominantProjectOccupancyTypeGroup",
    label: "DOMINANT PROJECT OCCUPANCY TYPE/GROUP",
    value: "M/MERCANTILE",
  },
  {
    rowKey: "dominantProjectOccupancyUse",
    label: "DOMINANT PROJECT OCCUPANCY USE",
    value: "DISPLAY AND SALE OF MERCHANDISE.",
  },
  {
    rowKey: "dominantPropertyOccupancyTypeGroup",
    label: "DOMINANT PROPERTY OCCUPANCY TYPE/GROUP",
    value: "B/BUSINESS",
  },
  {
    rowKey: "dominantPropertyOccupancyUse",
    label: "DOMINANT PROPERTY OCCUPANCY USE",
    value:
      "OFFICE, PROFESSIONAL, SERVICE-TYPE TRANSACTION, PUBLIC OR CIVIC SERVICES.",
  },
  {
    rowKey: "typeOfConstruction",
    label: "TYPE OF CONSTRUCTION",
    value: "EXISTING",
  },
  {
    rowKey: "floorsOfWork",
    label: "PROJECT FLOOR(S) OF WORK",
    value: "SC1, CEL, 001",
  },
  {
    rowKey: "isOccupyingEntireProperty",
    label: "DOES THIS PROJECT OCCUPY ENTIRE PROPERTY?",
    value: "NO",
  },
  {
    rowKey: "propertyLevelsAboveGround",
    label: "PROPERTY LEVELS ABOVE GROUND",
    value: "5",
  },
  {
    rowKey: "propertyLevelsBelowGround",
    label: "PROPERTY LEVELS BELOW GROUND",
    value: "2",
  },
  { rowKey: "totalPropertyLevels", label: "TOTAL PROPERTY LEVELS", value: "7" },
  {
    rowKey: "totalPropertyHeight",
    label:
      "TOTAL PROPERTY HEIGHT FROM LOWEST LEVEL OF FIRE DEPARTMENT VEHICLE ACCESS (FEET)",
    value: "70",
  },
  { rowKey: "propertyType", label: "PROPERTY TYPE", value: "LOW-RISE" },
  {
    rowKey: "totalOccupancyLoad",
    label: "TOTAL PROPERTY OCCUPANCY LOAD (PERSONS)",
    value: "194 (119 IN PROJECT)",
  },
  {
    rowKey: "totalBuildingArea",
    label: "TOTAL BUILDING AREA (SQR. FEET)",
    value: "16,125 (8,828.48 IN PROJECT)",
  },
  {
    rowKey: "isFloodHazardArea",
    label: "SPECIAL FLOOD HAZARD AREA?",
    value: "NO",
  },
];

export const MIDDLE_SECTION_SECOND_TABLE_LABELS = ["CODE", "TITLE"];
export const CODE_REFERENCE_TABLE_COLUMNS = [
  {
    active: true,
    key: "code",
    label: "CODE",
    widthRatio: 0.3,
  },
  {
    active: true,
    key: "title",
    label: "TITLE",
    widthRatio: 0.7,
  },
];
export const MIDDLE_SECTION_SECOND_TABLE_DATA = [
  {
    rowKey: "buildingCode",
    label: "BUILDING CODE",
    title:
      "2014 NYC BUILDING CODE (APPLICATION FILED AFTER RCNY 908-01 10/01/2019)",
  },
  {
    rowKey: "electricalCode",
    label: "ELECTRICAL CODE",
    title: "2011 NYC ELECTRICAL CODE",
  },
  {
    rowKey: "nfpaCode",
    label: "NFPA CODE",
    title:
      "NFPA 72-2010 (AS MODIFIED BY 1 RCNY §3616-04, EFFECTIVE 10/08/2015)",
  },
];

export const RIGHT_SECTION_PART_LABEL = "DRAWING LIST:";

export const DRAWING_LIST_TABLE_COLUMNS = [
  {
    active: true,
    key: "sheetNumber",
    label: "SHEET NUMBER",
    widthRatio: 0.3,
  },
  {
    active: true,
    key: "fileCode",
    label: "FILE CODE",
    widthRatio: 0.25,
  },
  {
    active: true,
    key: "sheetTitle",
    label: "SHEET TITLE",
    widthRatio: 0.45,
  },
  {
    active: false,
    key: "rowId",
    label: "ROW ID",
    widthRatio: 0,
  },
];
export const RIGHT_SECTION_TABLE_DATA = [
  {
    sheet_number: "001 OF 039",
    file_code: "FA-001",
    sheet_title: "COVER SHEET",
  },
  {
    sheet_number: "002 OF 039",
    file_code: "FA-002",
    sheet_title: "IO MATRIX",
  },
  {
    sheet_number: "003 OF 039",
    file_code: "FA-003",
    sheet_title: "EQUIPMENT SUMMARY & COUNTS",
  },
  {
    sheet_number: "004 OF 039",
    file_code: "FA-004",
    sheet_title: "REFERENCE FILES",
  },
  {
    sheet_number: "005 OF 039",
    file_code: "FA-005",
    sheet_title: "REFLECTED CEILING - CEL",
  },
  {
    sheet_number: "006 OF 039",
    file_code: "FA-006",
    sheet_title: "REFLECTED CEILING - 001",
  },
  {
    sheet_number: "007 OF 039",
    file_code: "FA-007",
    sheet_title: "TABLES",
  },
  {
    sheet_number: "008 OF 039",
    file_code: "FA-008",
    sheet_title: "RISER DIAGRAM - SC1 TO 001",
  },
  {
    sheet_number: "009 OF 039",
    file_code: "FA-009",
    sheet_title: "FLOOR PLAN - SC1",
  },
  {
    sheet_number: "010 OF 039",
    file_code: "FA-010",
    sheet_title: "FLOOR PLAN - CEL",
  },
  {
    sheet_number: "011 OF 039",
    file_code: "FA-011",
    sheet_title: "FLOOR PLAN - 001",
  },
  {
    sheet_number: "012 OF 039",
    file_code: "FA-012",
    sheet_title: "FIRE ALARM SPECIFICATIONS 1",
  },
  {
    sheet_number: "013 OF 039",
    file_code: "FA-013",
    sheet_title: "FIRE ALARM SPECIFICATIONS 2",
  },
  {
    sheet_number: "014 OF 039",
    file_code: "FA-014",
    sheet_title: "FIRE ALARM SPECIFICATIONS 3",
  },
  {
    sheet_number: "015 OF 039",
    file_code: "FA-015",
    sheet_title: "FIRE ALARM SPECIFICATIONS 4",
  },
  {
    sheet_number: "016 OF 039",
    file_code: "FA-016",
    sheet_title: "DETAIL DRAWING INDEX",
  },
];

export const TYPES_OF_CONSTRUCTION = ["NEW", "EXISTING"];

export const CONVERSION_RATES = {
  millimeter: {
    centimeter: 0.1,
    meter: 0.001,
    feet: 0.00328084,
    inch: 0.0393701,
  },
  centimeter: {
    millimeter: 10,
    meter: 0.01,
    feet: 0.0328084,
    inch: 0.393701,
  },
  meter: {
    millimeter: 1000,
    centimeter: 100,
    feet: 3.28084,
    inch: 39.3701,
  },
  feet: {
    millimeter: 304.8,
    centimeter: 30.48,
    meter: 0.3048,
    inch: 12,
  },
  inch: {
    millimeter: 25.4,
    centimeter: 2.54,
    meter: 0.0254,
    feet: 0.0833333,
  },
  mm: {
    cm: 0.1,
    m: 0.001,
    ft: 0.00328084,
    in: 0.0393701,
  },
  cm: {
    mm: 10,
    m: 0.01,
    ft: 0.0328084,
    in: 0.393701,
  },
  m: {
    mm: 1000,
    cm: 100,
    ft: 3.28084,
    in: 39.3701,
  },
  ft: {
    mm: 304.8,
    cm: 30.48,
    m: 0.3048,
    in: 12,
  },
  in: {
    mm: 25.4,
    cm: 2.54,
    m: 0.0254,
    ft: 0.0833333,
  },
};

export const ALLOWED_SQUARED_UNITS = ["M²", "SFT"];

export const SQUARED_CONVERSION_RATES = {
  mm2: {
    centimeter2: 0.01,
    meter2: 0.000001,
    inch2: 0.0015500031,
    feet2: 0.0000107639,
    cm2: 0.01,
    m2: 0.000001,
    in2: 0.0015500031,
    ft2: 0.0000107639,
  },
  cm2: {
    millimeter2: 100,
    meter2: 0.0001,
    inch2: 0.15500031,
    feet2: 0.001076391,
    mm2: 100,
    m2: 0.0001,
    in2: 0.15500031,
    ft2: 0.001076391,
  },
  m2: {
    millimeter2: 1000000,
    centimeter2: 10000,
    inch2: 1550.0031,
    feet2: 10.76391,
    mm2: 1000000,
    cm2: 10000,
    in2: 1550.0031,
    ft2: 10.76391,
  },
  in2: {
    millimeter2: 645.16,
    centimeter2: 6.4516,
    meter2: 0.00064516,
    feet2: 0.0069444444,
    mm2: 645.16,
    cm2: 6.4516,
    m2: 0.00064516,
    ft2: 0.0069444444,
  },
  ft2: {
    millimeter2: 92903.04,
    centimeter2: 929.0304,
    meter2: 0.09290304,
    inch2: 144,
    mm2: 92903.04,
    cm2: 929.0304,
    m2: 0.09290304,
    in2: 144,
  },
  millimeter2: {
    centimeter2: 0.01,
    meter2: 0.000001,
    inch2: 0.0015500031,
    feet2: 0.0000107639,
    cm2: 0.01,
    m2: 0.000001,
    in2: 0.0015500031,
    ft2: 0.0000107639,
  },
  centimeter2: {
    millimeter2: 100,
    meter2: 0.0001,
    inch2: 0.15500031,
    feet2: 0.001076391,
    mm2: 100,
    m2: 0.0001,
    in2: 0.15500031,
    ft2: 0.001076391,
  },
  meter2: {
    millimeter2: 1000000,
    centimeter2: 10000,
    inch2: 1550.0031,
    feet2: 10.76391,
    mm2: 1000000,
    cm2: 10000,
    in2: 1550.0031,
    ft2: 10.76391,
  },
  inch2: {
    millimeter2: 645.16,
    centimeter2: 6.4516,
    meter2: 0.00064516,
    feet2: 0.0069444444,
    mm2: 645.16,
    cm2: 6.4516,
    m2: 0.00064516,
    ft2: 0.0069444444,
  },
  feet2: {
    millimeter2: 92903.04,
    centimeter2: 929.0304,
    meter2: 0.09290304,
    inch2: 144,
    mm2: 92903.04,
    cm2: 929.0304,
    m2: 0.09290304,
    in2: 144,
  },
};

export const IS_FLOOD_HAZARD_AREA_OPTIONS = [
  { label: "YES", value: true },
  { label: "NO", value: false },
];

export const EXIT_ENTRANCE_SYMBOL = {
  id: "35nmckaBarq3gBLVPZHZO",
  status: "unpublished",
  elements: [
    {
      type: "line",
      version: 347,
      versionNonce: 477751926,
      isDeleted: false,
      id: "engAXpoiO2cZbBnEx7T5y",
      customData: { type: "custom-library-item" },
      fillStyle: "solid",
      strokeWidth: 1,
      strokeStyle: "solid",
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 766.6452895083129,
      y: 988.442021777287,
      strokeColor: "#000",
      backgroundColor: "#000",
      width: 44.312525438795205,
      height: 12.801341621524202,
      seed: 2090803062,
      groupIds: [],
      roundness: null,
      boundElements: [],
      updated: 1693762309654,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-22.172574093526123, -12.801341621524202],
        [-44.312525438795205, -0.01883475248784805],
        [0, 0],
      ],
    },
  ],
  created: Date.now(),
};

export const SHAPES_KEYS = [
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "r",
  "d",
  "o",
  "a",
  "l",
  "p",
  "t",
  "e",
  "v",
  "h",
];

export const AREA_OCCUPANCY_GROUPS = [
  "Electrical Equipment",
  "Low-Voltage Equipment",
  "Mechanical Ventilation",
  "Cooking Meals",
  "Heating Meals",
  "Eating",
  "Drinking",
  "Meeting",
  "Lounge",
  "Bathroom",
  "Sleeping",
  "Passageway",
  "Emergency Exit",
  "Recreation",
  "Garage",
  "Storage",
  "Changing",
];
