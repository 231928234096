import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";

export const authStatus = () => {
  const url = `https://outer.cloud/authStatus`;

  return axios
    .get(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((data) => data.data);
};

export const logoutUser = () => {
  const url = `https://outer.cloud/logoutUser`;

  return axios
    .get(url, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((data) => data.data);
};

export const signOut = async (onSuccess, onError) => {
  if (process.env.REACT_APP_ENV !== "dev") {
    firebase
      .auth()
      .signOut()
      .then(() => {
        logoutUser();
        onSuccess && onSuccess();
      })
      .catch((error) => {
        onError && onError(error);
      });
  }
};
