import React from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import "../scss/MapScreenshotButton.scss";

const ScreenshotButton = ({ captureMapScreenshot, hidden }) => {
  //Refs

  //General

  //Functions

  //Render
  return (
    <button
      type="button"
      className="map_screenshot_btn"
      onClick={captureMapScreenshot}
      style={{ display: hidden ? "none" : "block" }}
      title="Take a screenshot of the map"
    >
      <AddAPhotoIcon />
    </button>
  );
};

export default ScreenshotButton;
