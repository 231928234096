import React, { useState, useEffect, useRef } from "react";

import { useAtom } from "jotai";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";

import useReactiveUIVars from "../hooks/useReactiveUIVars";

import {
  currentDrawingAtom,
  currentDrawingsAtom,
  currentProjectAtom,
  excalidrawApiAtom,
} from "../store/variables";

import DescriptionIcon from "@mui/icons-material/Description";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import Skeleton from "@mui/material/Skeleton";
import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";

import { actionClearCanvas } from "../ExcalidrawAPI/actions";
import { t } from "../ExcalidrawAPI/i18n";
import ConfirmDialog from "../ExcalidrawAPI/components/ConfirmDialog";

import CloseButton from "./CloseButton";
import {
  createOrUpdateDrawing,
  updatePagesOrderByBatch,
} from "../helpers/drawings";
import { collabAPIAtom } from "../ExcalidrawAPI/excalidraw-app/collab/Collab";
import {
  activeLoaderAtom,
  expandedUploadPopupPanelAtom,
  isLeavingSceneAtom,
} from "../store/UI";

import { PULLER_MARGIN } from "../helpers/constants";
import { useCommonFunctionsHook } from "../hooks/useCommonFunctionsHook";

import "../scss/InfoPopup.scss";
import "../scss/stylingForMobileSizes.scss";

const InfoPopup = ({ isOpen, setIsOpen, resetScene, openScene }) => {
  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [collabAPI] = useAtom(collabAPIAtom);
  const [currentProject] = useAtom(currentProjectAtom);
  const [currentDrawing] = useAtom(currentDrawingAtom);
  const [currentDrawings] = useAtom(currentDrawingsAtom);
  const [, setIsLeavingScene] = useAtom(isLeavingSceneAtom);
  const [, setActiveLoader] = useAtom(activeLoaderAtom);
  const [, setExpandedUploadPopupPanel] = useAtom(expandedUploadPopupPanelAtom);

  //Custom Hooks
  const { setIsUploadPopupOpen } = useReactiveUIVars();
  const { triggerToast } = useCommonFunctionsHook();

  //State
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [currentViewWidth, setCurrentViewWidth] = useState(window.innerWidth);
  const [currentDialogView, setCurrentDialogView] = useState("general");
  const [drawerWidth, setDrawerWidth] = useState(
    window.innerWidth <= 422 ? window.innerWidth - PULLER_MARGIN : 450,
  );
  const [isClosedBehavior, setIsClosedBehavior] = useState(true);

  //Refs
  const cellForwardBtn = useRef(null);
  const infoPopupContainerRef = useRef(null);
  const infoPopupContentRef = useRef(null);

  //Effects
  useEffect(() => {
    if (isOpen === false) {
      setTimeout(() => {
        setIsClosedBehavior(true);
      }, 400);
    } else {
      setIsClosedBehavior(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setCurrentViewWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Constants
  const container =
    infoPopupContainerRef !== null ? () => infoPopupContainerRef.current : null;

  const labelsInfo = [
    {
      view: "project",
      label: "Project",
      text: `${currentProject?.projectName?.toLowerCase()} ${
        currentProject?.projectName ? "-" : ""
      } ${currentProject?.id}`,
    },
    {
      view: "drawing",
      label: "Drawing",
      text: currentDrawing?.name?.toLowerCase(),
    },
  ];

  const allInfo = [
    ...labelsInfo,
    {
      view: "devices",
      label: "Devices",
      text: excalidrawAPI
        ?.getSceneElements()
        .filter((el) => el.customData?.imageType === "device").length,
    },
    {
      label: "Version",
      text: `V ${process.env.REACT_APP_APP_VERSION}`,
    },
  ];

  //Functions
  const getFieldClassName = (el, index, lastIndex, table) => {
    return `info-label info-cell_${index} ${table ? "" : "ellipsis"} ${
      table && index === lastIndex ? "last-info-table-cell" : ""
    } ${!el.view ? "info-label_read_only" : ""}`;
  };
  const addAnimationToForwardBtn = (status, index) => {
    const currentCellBtn = document.querySelector(
      `.info-cell_${index} .drawing-info-dialog_forward_btn`,
    );
    if (status) {
      currentCellBtn?.classList.add("forward-btn_to-right");
    } else {
      currentCellBtn?.classList.remove("forward-btn_to-right");
    }
  };

  const renderInfoField = (el, options) => {
    const { table, index, lastIndex } = options ?? {};
    return (
      <React.Fragment key={el.label}>
        <div
          className={getFieldClassName(el, index, lastIndex, table)}
          onClick={() => {
            if (["project", "drawing"].includes(el.view)) {
              setIsUploadPopupOpen(true);
              setExpandedUploadPopupPanel(`${el.view}-panel`);
            } else {
              el.view && setCurrentDialogView(el.view);
            }
          }}
          onMouseEnter={() => {
            addAnimationToForwardBtn(true, index);
          }}
          onMouseLeave={() => {
            addAnimationToForwardBtn(false, index);
          }}
        >
          <span className="info-label_address">{el.label} </span>
          <span className={`info-label_colon ${table ? "hide" : ""}`}>: </span>
          {!currentProject?.id ? (
            <Skeleton
              width={160}
              height={16}
              sx={{
                display: "inline-block",
                position: "relative",
                bottom: "-1px",
              }}
            />
          ) : (
            <span
              className={`info-label_text${table ? "_table" : ""}`}
              title={table ? "" : el.text}
            >
              {el.text}
            </span>
          )}
          {table && el.view ? (
            <span
              ref={cellForwardBtn}
              className="drawing-info-dialog_forward_btn"
            >
              <ArrowForwardIosTwoToneIcon fontSize="1rem" />
            </span>
          ) : null}
        </div>
        <div className={`base-line ${table ? "hide" : ""}`}></div>
      </React.Fragment>
    );
  };

  const renderCurrentDialogView = () => {
    if (currentDialogView === "project") {
      return <div className="table_page">PROJECT details to be added</div>;
    } else if (currentDialogView === "drawing") {
      return <div className="table_page">DRAWING details to be added</div>;
    } else if (currentDialogView === "devices") {
      return <div className="table_page">DEVICES details to be added</div>;
    }
    return (
      <section className="info-container-table">
        {allInfo.map((el, index, array) =>
          renderInfoField(el, {
            table: true,
            index,
            lastIndex: array.length - 1,
          }),
        )}
      </section>
    );
  };

  const openDrawingHandler = (drawingId, cellIndex, remainedPages) => {
    const drawerCell = [
      ...document.getElementsByClassName("order-drawer_cell"),
    ][cellIndex];
    drawerCell?.classList.add("order_drawer_cell_active");
    setTimeout(async () => {
      await openScene(drawingId, { pagesCount: remainedPages.length });
      drawerCell?.classList.remove("order_drawer_cell_active");
    }, 1000);
  };

  const shiftAllPages = async (currentPages) => {
    if (currentPages.length === 0) {
      return;
    }
    const shiftedPages = currentPages
      .filter((el) => el.id !== currentDrawing.id)
      .map((el, index) => Object.assign(el, { order: index + 1 }));
    return updatePagesOrderByBatch(shiftedPages);
  };

  const getOrderOfPageToOpen = (currentPages) => {
    return currentPages.reduce((max, obj) => {
      return Math.max(max, obj.order);
    }, -Infinity);
  };

  const currentPageDeletedHandler = async (currentPages) => {
    //1. Current page is the first and the only element -> Go Home
    if (currentPages.length === 1) {
      await collabAPI?.stopCollaboration();
      resetScene();
    }
    //2. Current page is the first element -> open the next
    //Note that -> The numbering starts from 2, as number 1 is always taken for the cover sheet.
    else if (currentDrawing.order === 2) {
      const { id, order } =
        currentPages.find((el) => el.order === currentDrawing.order) ?? {};
      openDrawingHandler(id, order - 1, currentPages);
    }
    //3. Current page is in between -> Open the one before
    else {
      const orderOfPageToOpen = getOrderOfPageToOpen(currentPages);
      const { id, order } =
        currentPages.find((el) => el.order === orderOfPageToOpen) ?? {};
      openDrawingHandler(id, order - 1, currentPages);
    }
  };

  const confirmDeleteDialogHandler = async () => {
    const remainedPages =
      currentDrawings?.filter((el) => el.id !== currentDrawing.id) || [];
    try {
      setIsLeavingScene(true);
      setActiveLoader("saving");
      await collabAPI?.stopCollaboration();
      await createOrUpdateDrawing(
        { ...currentDrawing, active: false },
        true,
        onDeleteSuccess,
        onDeleteError,
      );
      triggerToast(`Deleted Drawing Successfully!`, "success");
      await shiftAllPages(currentDrawings);
      currentPageDeletedHandler(remainedPages);
    } catch (error) {
      console.error(error);
      triggerToast(`Failed To Delete Drawing`, "error");
      setActiveLoader(null);
    }
  };

  const closeDeleteDialogHandler = () => {
    setIsDeleteConfirmOpen(false);
  };

  const onDeleteSuccess = () => {
    const actionManager = excalidrawAPI.getActionManager();
    actionManager.executeAction(actionClearCanvas);
    triggerToast("Deleted Drawing Successfully", "success");
    setIsDeleteConfirmOpen(false);
    resetScene();
  };
  const onDeleteError = () => {
    triggerToast("Failed To Delete Drawing.", "error");
  };

  //Render
  return (
    <main
      ref={infoPopupContainerRef}
      style={{ zIndex: isClosedBehavior ? 2 : 5 }}
    >
      <Resizable
        className="drawing-info-dialog"
        height={Infinity}
        width={drawerWidth}
        onResize={(_, { size }) => {
          const iconEl = document.querySelector(".project-summary-icon");
          iconEl.classList.remove("project-summary-puller_box_open");
          iconEl.classList.remove("project-summary-puller_box_close");
          setDrawerWidth(size.width);
        }}
        minConstraints={[
          currentViewWidth <= 422 ? currentViewWidth - PULLER_MARGIN : 450,
          Infinity,
        ]}
        resizeHandles={["e"]}
      >
        <SwipeableDrawer
          container={container}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}
          swipeAreaWidth={0}
          transitionDuration={{ enter: 500, exit: 500 }}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "transparent",
            },
            zIndex: isDeleteConfirmOpen ? "9" : "1300",
          }}
        >
          <section className="drawing-info-dialog_header">
            <h3
              onClick={() => setCurrentDialogView("general")}
              style={{ cursor: "pointer" }}
            >
              Project Summary
            </h3>
            <CloseButton
              onClick={() => setIsOpen(false)}
              classes={["drawing-info-dialog_close_btn"]}
              style={{
                backgroundColor: "transparent",
                color: "#444",
                borderColor: "transparent",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: "#ebebeb",
                  borderColor: "#ebebeb",
                },
              }}
            />
          </section>
          <div className="drawing-info-dialog_divider"></div>

          <div
            className="drawing-info_content"
            ref={infoPopupContentRef}
            style={{
              width: drawerWidth,
            }}
          >
            <p className="drawing-info-path">
              <i
                className={
                  currentDialogView !== "general"
                    ? "drawing-info-path_active"
                    : null
                }
                onClick={() => setCurrentDialogView("general")}
              >
                General Information
              </i>
              <i>
                {currentDialogView !== "general" ? `/${currentDialogView}` : ""}
              </i>
            </p>
            {renderCurrentDialogView()}
          </div>
        </SwipeableDrawer>
      </Resizable>

      <div
        style={{
          transform: `translate(${isOpen ? `${drawerWidth}px` : "0px"}, -50%)`,
        }}
        className={`project-summary-icon ${
          isOpen
            ? "project-summary-puller_box_open"
            : "project-summary-puller_box_close"
        }`}
      >
        <DescriptionIcon />
      </div>

      {isDeleteConfirmOpen && (
        <ConfirmDialog
          title={t("deleteDrawingDialog.title")}
          onConfirm={confirmDeleteDialogHandler}
          onCancel={closeDeleteDialogHandler}
        >
          <p className="clear-canvas__content"> {t("alerts.deleteDrawing")}</p>
        </ConfirmDialog>
      )}
    </main>
  );
};

export default InfoPopup;
