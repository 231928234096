import { useState } from "react";

import { useAtom } from "jotai";
import bwipjs from "bwip-js";

import {
  currentProjectAtom,
  globalSettingsAtom,
  excalidrawApiAtom,
} from "../store/variables";
import { uploadBarcodeToFireStorage } from "../services/firebaseStorage";
import { randomId } from "../ExcalidrawAPI/random";

export const useCommonFunctionsHook = () => {
  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [currentProject] = useAtom(currentProjectAtom);
  const [globalSettings] = useAtom(globalSettingsAtom);

  //State
  const [intervalId, setIntervalId] = useState(null);

  //Functions
  /**
   * A function to return text with formatted numbers (e.g. 12,345 (6,789 IN PROJECT))
   * @param {string} - text
   * @returns string
   */
  const formatNumbersInsideText = (text) => {
    if (typeof text !== "string" || globalSettings.numberFormat !== "Comma") {
      return text;
    }

    // Remove existing commas from numbers
    const textWithoutCommas = text.replace(/(\d)(,)/g, "$1");

    // Use a regular expression to find and replace numbers with commas as thousands separators
    return textWithoutCommas.replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
  };

  /**
   * Function to format the input value
   * @param {Number} value
   * @returns Number - formatted number (ex. 1,234, 567)
   */
  const formatNumber = (value) => {
    if (!value) {
      return "";
    }
    const parts = value.toString().split(".");
    const integerValue = parts[0];
    const formattedValue =
      globalSettings.numberFormat === "Comma"
        ? integerValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : integerValue;

    if (parts.length === 2) {
      return `${formattedValue}.${parts[1].substring(
        0,
        globalSettings.decimalPoints,
      )}`;
    }
    return formattedValue;
  };

  const doAfterLoadingScene = (functionToCall) => {
    let elapsedTime = 0;
    const timeLimit = 60000; // 1 minute (in milliseconds)
    const openingIntervalId = setInterval(() => {
      const elements = excalidrawAPI.getSceneElements();

      if (elements.length !== 0) {
        // Scene loaded
        clearInterval(openingIntervalId);
        functionToCall();
      } else {
        elapsedTime += 100;
        if (elapsedTime >= timeLimit) {
          clearInterval(intervalId);
        }
      }
    }, 100);
    setIntervalId(openingIntervalId);
  };

  const triggerToast = (message, status, options = {}) => {
    excalidrawAPI.setToast({
      message,
      duration: options.duration || 2000,
      closable: true,
      options: {
        position: "topRight",
        status,
        ...options,
      },
    });
  };

  const getNewOccupancyAsString = (occupancyUse) => {
    return `${occupancyUse.occupancyTypeId}-${occupancyUse.occupancyGroup}-${occupancyUse.occupancyUse}`;
  };

  const convertToOccupancyUseFormat = (
    useLabel,
    group,
    typeId,
    allIconsAndCovers,
  ) => {
    const defaultIconAndCover = allIconsAndCovers?.find(
      (el) => el.isDefault === true,
    );

    return {
      id: randomId(),
      icon: defaultIconAndCover,
      cover: defaultIconAndCover.cover,
      label: useLabel,
      inputValue: useLabel,
      isNew: true,
      occupancyTypeId: typeId,
      occupancyGroup: group,
      occupancyUse: useLabel,
    };
  };

  const convertFromToSFT = (valueToConvert, unitToUse) => {
    if (unitToUse === "SFT") {
      return valueToConvert * 10.76391041671;
    }
    return valueToConvert / 10.76391041671;
  };

  const generateBarcodeImage = async (value, drawingId) => {
    const canvas = document.createElement("canvas");
    bwipjs.toCanvas(canvas, {
      bcid: "code128",
      text: value,
      scale: 3,
      height: 2,
      // width: 117,
      textxalign: "center",
      textyoffset: -22,
    });

    const barcodeImageBlob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/png"),
    );

    const barcodeImageUrl = uploadBarcodeToFireStorage(
      barcodeImageBlob,
      drawingId,
      currentProject.id,
    );

    return barcodeImageUrl;
  };

  const getBarcode = (barcodeName, drawingId) => {
    const sanitizedValue = barcodeName.replace(/[^a-zA-Z0-9-]/g, "");
    const barcodeUrl = generateBarcodeImage(sanitizedValue, drawingId);
    return barcodeUrl;
  };

  const fixS3Url = (url) => {
    //Check if the url is related to AWS
    if (!url.includes("amazonaws")) {
      return url;
    }
    // Define a regular expression pattern to match the correct structure
    const correctPattern =
      /^https:\/\/([a-zA-Z0-9.-]+)\.s3\.([a-zA-Z0-9-]+)\.amazonaws\.com\/(.*)$/;

    // Check if the URL matches the correct structure
    const match = url.match(correctPattern);

    if (match) {
      // If it matches, return the original URL
      return url;
    }
    // If it doesn't match, try to transform it into the correct structure
    const parts = url.split("/");
    if (parts.length >= 4) {
      // Reconstruct the URL in the correct structure
      const bucket = parts[3];
      const key = parts.slice(4).join("/");
      const region = parts[2].replace(/^(.*?)-/, "$1.");
      return `https://${bucket}.${region}/${key}`;
    }
    // If the URL doesn't have enough parts to fix, return an error message or handle it as needed
    return "Invalid URL";
  };

  //Return
  return {
    doAfterLoadingScene,
    triggerToast,
    formatNumber,
    convertFromToSFT,
    getBarcode,
    formatNumbersInsideText,
    convertToOccupancyUseFormat,
    getNewOccupancyAsString,
    fixS3Url,
  };
};
