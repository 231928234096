import { useAtom } from "jotai";
import { excalidrawApiAtom } from "../store/variables";
import { mutateElement } from "../ExcalidrawAPI/element/mutateElement";
import { randomId, randomInteger } from "../ExcalidrawAPI/random";
import { getUpdatedTimestamp } from "../ExcalidrawAPI/utils";
import { useCommonFunctionsHook } from "./useCommonFunctionsHook";

const propsInCommon = {
  angle: 0,
  backgroundColor: "transparent",
  boundElements: [],
  customData: { isImmutable: true },
  fillStyle: "solid",
  isDeleted: false,
  link: null,
  locked: true,
  opacity: 100,
  roughness: 0,
  roundness: null,
  strokeColor: "#000000",
  strokeStyle: "solid",
  strokeWidth: 1,
  seed: randomInteger(),
  version: randomInteger(),
  versionNonce: randomInteger(),
  updated: getUpdatedTimestamp(),
};

export const useImageMethods = () => {
  //Custom Hooks
  const { fixS3Url } = useCommonFunctionsHook();

  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);

  //State

  //Functions
  const getRenderImageDimensions = ({ currentDims, originalDims }) => {
    let { width, height } = currentDims;
    if ((width === "auto" || !width) && (height === "auto" || !height)) {
      height = 100;
      width = (height * originalDims.imgWidth) / originalDims.imgHeight || 100;
    }
    if (width === "auto" || !width) {
      width = (height * originalDims.imgWidth) / originalDims.imgHeight || 100;
    }
    if (height === "auto" || !height) {
      height = (width * originalDims.imgHeight) / originalDims.imgWidth || 100;
    }

    return { widthToRender: width, heightToRender: height };
  };

  const getOriginalImageDimensions = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const dimensions = {
          imgWidth: img.naturalWidth,
          imgHeight: img.naturalHeight,
        };
        resolve(dimensions);
      };
      img.src = url;
    });
  };

  const getImagePosition = ({
    start = { x: 0, y: 0 },
    align = "center",
    justify = "center",
    element,
    container = { width: 0, height: 0 },
  }) => {
    const position = { x: 0, y: 0 };
    switch (justify) {
      case "right":
        position.x = start.x + container.width - element.width - 5;
        break;
      case "center":
        position.x = start.x + (container.width - element.width) / 2;
        break;
      default:
        position.x = start.x + 5;
    }
    switch (align) {
      case "bottom":
        position.y = start.y + container.height - element.height - 5;
        break;
      case "center":
        position.y = start.y + (container.height - element.height) / 2;
        break;
      default:
        position.y = start.y + 5;
    }

    return position;
  };

  const getUrlExtension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  const getImageFileObject = async (imgUrl) => {
    imgUrl = fixS3Url(imgUrl);
    const imgExt = getUrlExtension(imgUrl);

    const response = await fetch(imgUrl);
    const blob = await response.blob();
    const file = new File([blob], `generatedImage-${Date.now()}.${imgExt}`, {
      type: blob.type,
    });

    return file;
  };

  const renderImageElement = async (imageProps) => {
    const {
      url,
      width = "auto",
      height,
      offset = { x: 0, y: 0 },
      imageType = "",
      angle = 0,
      customData = {},
      isToReposition = true,
      mainDimension = "width",
    } = imageProps;
    const originalDims = await getOriginalImageDimensions(url);
    const { widthToRender, heightToRender } = getRenderImageDimensions({
      currentDims: { width, height },
      originalDims,
    });
    const position = isToReposition
      ? getImagePosition({
          ...imageProps,
          element: { width: widthToRender, height: heightToRender },
        })
      : { x: 0, y: 0 };
    const dimensionToRender =
      mainDimension === "width" ? widthToRender : heightToRender;
    let imageFile = {};
    let imageElement = {};
    try {
      imageFile = await getImageFileObject(url);
      imageElement = excalidrawAPI.createImageElement({
        sceneX: position.x + offset.x,
        sceneY: position.y + offset.y,
      });
    } catch (error) {
      console.error(error);
      return false;
    }

    mutateElement(imageElement, {
      id: randomId(),
      width: widthToRender,
      height: heightToRender,
      angle,
      ...propsInCommon,
      customData: {
        ...propsInCommon.customData,
        imageType,
        url,
        ...customData,
      },
    });

    excalidrawAPI.insertImageElement(imageElement, imageFile, false, {
      width: widthToRender,
      height: heightToRender,
      angle,
      dimension: dimensionToRender,
    });

    return {
      imageElement,
      imageFile,
      imageElementProps: {
        angle,
        dimension: dimensionToRender,
      },
    };
  };

  //Return
  return {
    getRenderImageDimensions,
    getOriginalImageDimensions,
    getImagePosition,
    renderImageElement,
  };
};
