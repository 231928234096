import "./ToolIcon.scss";

import clsx from "clsx";
import { ToolButton } from "./ToolButton";
import { handIcon } from "./icons";
import { KEYS } from "../keys";
import { useAtom } from "jotai";
import { isActivePolygonToolAtom } from "../../store/variables";

type LockIconProps = {
  title?: string;
  name?: string;
  checked: boolean;
  onChange?(): void;
  isMobile?: boolean;
};

export const HandButton = (props: LockIconProps) => {
  const [isActivePolygonTool, setIsActivePolygonTool] = useAtom(
    isActivePolygonToolAtom,
  );
  return (
    <ToolButton
      className={clsx("Shape", { fillable: false })}
      type="radio"
      icon={handIcon}
      name="editor-current-shape"
      checked={props.checked}
      title={`${props.title} — H`}
      keyBindingLabel={!props.isMobile ? KEYS.H.toLocaleUpperCase() : undefined}
      aria-label={`${props.title} — H`}
      aria-keyshortcuts={KEYS.H}
      data-testid={`toolbar-hand`}
      onChange={() => {
        props.onChange?.();
        if (isActivePolygonTool) {
          setIsActivePolygonTool(false);
          document
            .querySelector(".ToolIcon__icon.regular-button")
            ?.classList.remove("regular-button");
        }
      }}
    />
  );
};
