import React from "react";

import "../scss/DeleteButton.scss";

const DeleteButton = ({
  title,
  disabled,
  classes: overrideClasses,
  onDelete,
}) => {
  //Constants
  const classes = `delete-button-component ${
    disabled ? "delete-button_disabled" : ""
  } ${overrideClasses?.join(" ") ?? ""}`.trim();

  return (
    <button className={classes} title={title} onClick={onDelete}>
      <svg
        aria-hidden="true"
        focusable="false"
        role="img"
        viewBox="0 0 20 20"
        className=""
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          strokeWidth="1.25"
          d="M3.333 5.833h13.334M8.333 9.167v5M11.667 9.167v5M4.167 5.833l.833 10c0 .92.746 1.667 1.667 1.667h6.666c.92 0 1.667-.746 1.667-1.667l.833-10M7.5 5.833v-2.5c0-.46.373-.833.833-.833h3.334c.46 0 .833.373.833.833v2.5"
        ></path>
      </svg>
    </button>
  );
};

export default DeleteButton;
