/* Copyright (C) EZ Fire, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Hamza Younes <hamza@ezfirecontrols.com>, Jan 25th, 2023
 */

import firebase from "firebase/app";
import "firebase/functions";

export const getOneDrawingById = (drawingId) => {
  if (drawingId) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("cadDrawings")
        .where("active", "==", true)
        .where("id", "==", drawingId)
        .onSnapshot(
          (snap) => {
            if (snap) {
              resolve(snap.docs.map((el) => el.data())[0]);
            }
          },
          (error) => {
            console.error(error);
            reject(error);
          },
        );
    });
  }
};

export const getOneDrawingByRoomKey = async (roomKey) => {
  return firebase
    .firestore()
    .collection("cadDrawings")
    .where("active", "==", true)
    .where("roomKey", "==", roomKey)
    .get()
    .then((snapshot) => {
      const result = [];
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          result.push(doc.data());
        });
      }
      return result[0];
    })
    .catch((error) => {
      console.error(error);
    });
};

export const createOrUpdateDrawing = async (
  drawing,
  update = null,
  onSuccess,
  onError,
) => {
  const db = firebase.firestore();
  if (update) {
    Object.assign(drawing, {
      modifiedAt: firebase.firestore.Timestamp.now(),
      modifiedBy: firebase.auth().currentUser.uid,
    });
    try {
      await db
        .collection("cadDrawings")
        .doc(drawing.id)
        .set(drawing, { merge: true });

      onSuccess && onSuccess();
    } catch (error) {
      onError && onError();
      console.error("Error getting document:", error);
    }
    return drawing;
  }
  const docRef = db.collection("cadDrawings").doc();
  Object.assign(drawing, {
    active: true,
    id: docRef.id,
    createdAt: firebase.firestore.Timestamp.now(),
    createdBy: firebase.auth().currentUser.uid,
    roomKey: location.hash,
  });
  try {
    await db.collection("cadDrawings").doc(docRef.id).set(drawing);
    onSuccess && onSuccess();
    return drawing;
  } catch (error) {
    onError && onError();
    console.error("Error getting document:", error);
  }
};

export const getDrawingsByProjectId = (projectId, setIsLoadingDrawings) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("cadDrawings")
      .where("active", "==", true)
      .where("projectId", "==", projectId)
      .orderBy("order", "asc")
      .onSnapshot(
        (snap) => {
          if (snap) {
            if (setIsLoadingDrawings) {
              setIsLoadingDrawings(false);
            }
            resolve(snap.docs.map((el) => el.data()));
          }
        },
        (error) => {
          if (setIsLoadingDrawings) {
            setIsLoadingDrawings(false);
          }
          console.error(error);
          reject(error);
        },
      );
  });
};

export const getAllDrawings = async (onSuccess, onError) => {
  try {
    return firebase
      .firestore()
      .collection("cadDrawings")
      .where("active", "==", true)
      .orderBy("order", "asc")
      .onSnapshot(
        (snap) => {
          if (snap) {
            const allDrawings = snap.docs.map((el) => el.data());
            onSuccess && onSuccess(allDrawings);
          }
        },
        (error) => {
          onError && onError();
          console.error(error);
        },
      );
  } catch (error) {
    console.error(error);
  }
};

export const getDrawingThumbnail = (filePath) => {
  // Get a reference to the Firebase Cloud Functions
  const functions = firebase.functions();

  // Get a reference to the Cloud Function
  const generateThumbnail = functions.httpsCallable("image-generateThumbnail");

  // Call the Cloud Function with the download URL of the image to resize
  return generateThumbnail({ filePath })
    .then((result) => {
      // Get the download URL of the resized image from the Cloud Function response
      const { thumbImageUrl, thumbFilePath } = result.data;
      return { thumbImageUrl, thumbFilePath };
    })
    .catch((error) => {
      // Handle errors from the Cloud Function call
      console.error("Error resizing image:", error);
    });
};

export const updatePagesOrderByBatch = (updates) => {
  // Initialize Firebase Firestore
  const db = firebase.firestore();

  // Create a batch object
  const batch = db.batch();

  // Define the collection and documents you want to modify
  const collectionRef = db.collection("cadDrawings");

  // Iterate over the document IDs and update the documents in the batch
  updates.forEach(({ id, order }) => {
    const documentRef = collectionRef.doc(id);

    // Specify the update operation for each document
    batch.update(documentRef, {
      order,
      modifiedAt: firebase.firestore.Timestamp.now(),
      modifiedBy: firebase.auth().currentUser.uid,
    });
  });

  // Commit the batch write operation & Return a promise to be waited.
  return batch.commit();
};

export const deleteDrawingsByBatch = (selectedDrawings) => {
  const db = firebase.firestore();
  const batch = db.batch();
  const collectionRef = db.collection("cadDrawings");
  selectedDrawings.forEach((id) => {
    const documentRef = collectionRef.doc(id);
    batch.update(documentRef, {
      active: false,
      modifiedAt: firebase.firestore.Timestamp.now(),
      modifiedBy: firebase.auth().currentUser.uid,
    });
  });

  // Commit the batch write operation & Return a promise to be waited.
  return batch.commit();
};

export const getSceneById = (sceneId) => {
  return firebase
    .firestore()
    .collection("scenes")
    .doc(sceneId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      }
      return null; // Return null or another appropriate value for no matching document.
    })
    .catch((error) => {
      console.error(error);
      throw error; // Rethrow the error to propagate it to the caller if needed.
    });
};
