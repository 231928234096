/* Copyright (C) EZ Fire, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Hamza Younes <hamza@ezfirecontrols.com>, July 21st, 2023
 */

import "firebase/firestore";
import secondaryFirebaseProject from "./secondaryProjectDB";
import { convertCodesToObjects } from "./common";

export const getAllCodeCombinations = async (onSuccess, onError) => {
  try {
    await secondaryFirebaseProject.auth().signInAnonymously();
    return secondaryFirebaseProject
      .firestore()
      .collection("codeGroups")
      .where("active", "==", true)
      .onSnapshot(
        (snap) => {
          let allCombinations = [];
          if (!snap.empty) {
            allCombinations = snap.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
          }
          onSuccess(allCombinations);
        },
        (error) => {
          onError();
          console.error(error);
        },
      );
  } catch (error) {
    console.error(error);
  }
};

export const getCodesGroupById = async (id) => {
  try {
    const coverSheetSnapshot = await secondaryFirebaseProject
      .firestore()
      .collection("codeGroups")
      .doc(id)
      .get();
    return coverSheetSnapshot.data();
  } catch (error) {
    console.error(error);
  }
};

export const getCodeItemById = async (id) => {
  try {
    const coverSheetSnapshot = await secondaryFirebaseProject
      .firestore()
      .collection("codeItems")
      .doc(id)
      .get();
    return coverSheetSnapshot.data();
  } catch (error) {
    console.error(error);
  }
};

export const getAllCodeItems = async (onSuccess, onError) => {
  try {
    return secondaryFirebaseProject
      .firestore()
      .collection("codeItems")
      .where("active", "==", true)
      .onSnapshot(
        (snap) => {
          let allItems = [];
          if (!snap.empty) {
            allItems = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          }
          onSuccess(allItems);
        },
        (error) => {
          onError();
          console.error(error);
        },
      );
  } catch (error) {
    console.error(error);
  }
};

export const getRelatedCodes = (allCodeItems, codesCombination) => {
  const codesKeys = Object.keys(codesCombination).filter((el) =>
    el.includes("Code"),
  );
  const codes = codesKeys.map((el) => ({ [el]: codesCombination[el] }));
  return convertCodesToObjects(allCodeItems, codes);
};
