/* Copyright (C) EZ Fire, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Hamza Younes <hamza@ezfirecontrols.com>, July 12th, 2023
 */

import firebase from "firebase/app";
import "firebase/firestore";
import secondaryFirebaseProject from "./secondaryProjectDB";

export const createOrUpdateCoverSheet = async (coverSheet, options) => {
  const coverSheetToSet = {
    ...coverSheet,
    ...(!coverSheet.createdBy && {
      createdAt: firebase.firestore.Timestamp.now(),
      createdBy: firebase.auth().currentUser.uid,
    }),
    ...(options?.update && {
      modifiedAt: firebase.firestore.Timestamp.now(),
      modifiedBy: firebase.auth().currentUser.uid,
    }),
    type: "coverSheet",
  };

  try {
    secondaryFirebaseProject
      .firestore()
      .collection("coverSheets")
      .doc(coverSheet.id)
      .set(coverSheetToSet);
  } catch (error) {
    console.error(error);
  }
};

export const getCoverSheetById = async (coverSheetId) => {
  try {
    if (!coverSheetId) {
      return {};
    }
    const coverSheetSnapshot = await secondaryFirebaseProject
      .firestore()
      .collection("coverSheets")
      .doc(coverSheetId)
      .get();
    return coverSheetSnapshot.data();
  } catch (error) {
    console.error(error);
  }
};
