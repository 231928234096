import _ from "lodash";

import { newElementWith } from "../ExcalidrawAPI/element/mutateElement";
import { redrawTextBoundingBox } from "../ExcalidrawAPI/element";
import { getUpdatedTimestamp } from "../ExcalidrawAPI/utils";
import { preventTextWrapping } from "./commonFrameFunctions";
import {
  CONTENT_HEIGHT,
  HORIZONTAL_RULER_MARGIN_BOTTOM,
  MARGIN_UNIT,
  NOMENCLATURE_ELLIPSE_DIM,
  NOMENCLATURE_ELLIPSE_MARGIN_BOTTOM,
  HORIZONTAL_RULER_MARGIN_X,
} from "./constants";

import { convertToFeet, generateId } from "./common";
import { drawFrameWithPlayersSection } from "./drawFrame";
import { randomInteger } from "../ExcalidrawAPI/random";

const propsInCommon = {
  angle: 0,
  customData: { isImmutable: true },
  locked: true,
  roughness: 0,
  roundness: null,
  seed: randomInteger(),
  version: randomInteger(),
  versionNonce: randomInteger(),
  updated: getUpdatedTimestamp(),
};

const basicRectElement = {
  type: "rectangle",
  strokeColor: "transparent",
  fillStyle: "solid",
  ...propsInCommon,
};

const basicTextElement = {
  type: "text",
  baseline: 15,
  fontFamily: 2,
  strokeColor: "#000000",
  textAlign: "center",
  verticalAlign: "top",
  ...propsInCommon,
};

const createEllipseWithNomenclatureText = ({
  floorDrawing,
  pixelsPerFeet,
  groupId,
  ellipseDimension,
  startY,
}) => {
  const { nomenclature } = floorDrawing;
  const [textId, ellipseId] = [generateId(), generateId()];
  const ellipseProps = {
    x: 2 * HORIZONTAL_RULER_MARGIN_X + 1 * pixelsPerFeet,
    y: startY - ellipseDimension - NOMENCLATURE_ELLIPSE_MARGIN_BOTTOM,
    width: ellipseDimension,
    height: ellipseDimension,
  };
  const textElement = newElementWith(basicTextElement, {
    id: textId,
    containerId: ellipseId,
    baseline: 19,
    customData: { ...basicTextElement.customData, valueFor: "NOMENCLATURE:" },
    fontSize: 0.3125 * ellipseProps.width,
    height: 0.38 * ellipseProps.height,
    width: 0.77 * ellipseProps.width,
    originalText: nomenclature,
    text: nomenclature,
    textAlign: "center",
    verticalAlign: "middle",
  });
  const ellipseElement = {
    ...propsInCommon,
    id: ellipseId,
    type: "ellipse",
    boundElements: [
      {
        id: textId,
        type: "text",
      },
    ],
    groupIds: [groupId],
    height: ellipseProps.height,
    width: ellipseProps.width,
    x: ellipseProps.x,
    y: ellipseProps.y,
  };

  redrawTextBoundingBox(textElement, ellipseElement);

  return [ellipseElement, textElement];
};

const renderFloorName = ({ drawingProps, lineProps }) => {
  const { name, pixelsPerFeet, groupId } = drawingProps;
  const [floorNameTextId, floorNameRectId] = [generateId(), generateId()];
  const floorName = getFloorName(name);

  const floorNameText = {
    ...basicTextElement,
    customData: {
      type: "vertical-ruler",
      valueFor: "DRAWING TITLE:",
      isImmutable: true,
      solid: true,
    },
    id: floorNameTextId,
    containerId: floorNameRectId,
    text: floorName,
    originalText: floorName,
    fontSize: 1.1 * pixelsPerFeet,
    width: 12 * pixelsPerFeet,
    height: 1.2 * pixelsPerFeet,
    textAlign: "left",
    verticalAlign: "middle",
  };

  const floorNameRect = {
    ...basicRectElement,
    id: floorNameRectId,
    boundElements: [{ type: "text", id: floorNameTextId }],
    groupIds: [groupId],
    width: 12 * pixelsPerFeet,
    height: 1.2 * pixelsPerFeet,
    x: lineProps.x + 0.2 * pixelsPerFeet,
    y: lineProps.y - 1.5 * pixelsPerFeet,
  };

  redrawTextBoundingBox(floorNameText, floorNameRect);

  return [floorNameText, floorNameRect];
};

const renderOccupancyIdsText = ({
  drawingProps,
  lineProps,
  iconsAndCovers,
}) => {
  const { occupancyIds, groupId, pixelsPerFeet } = drawingProps;
  let textToRender = "";
  if (typeof occupancyIds === "object") {
    const drawingOccupancyGroups = [];
    for (const occupancyId of occupancyIds) {
      const occupancy = iconsAndCovers.find((el) => el.id === occupancyId);
      drawingOccupancyGroups.push(occupancy.occupancyGroup);
    }
    textToRender = Array.from(new Set(drawingOccupancyGroups)).join(" / ");
  } else if (typeof occupancyIds === "string") {
    textToRender = occupancyIds;
  }

  const [occupancyTextId, occupancyRectId] = [generateId(), generateId()];

  const occupancyRect = {
    ...basicRectElement,
    id: occupancyRectId,
    boundElements: [{ type: "text", id: occupancyTextId }],
    groupIds: [groupId],
    height: 1.2 * pixelsPerFeet,
    width: 22.3 * pixelsPerFeet,
    x: lineProps.x + 12.7 * pixelsPerFeet,
    y: lineProps.y - 1.5 * pixelsPerFeet,
  };

  const occupancyText = newElementWith(basicTextElement, {
    id: occupancyTextId,
    customData: {
      ...basicTextElement.customData,
      valueFor: "drawingOccupancies",
      solid: true,
    },
    containerId: occupancyRectId,
    groupIds: [groupId],
    text: `(OCCUPANCY = ${textToRender})`,
    originalText: `(OCCUPANCY = ${textToRender})`,
    fontSize: 1.1 * pixelsPerFeet,
    width: 22.3 * pixelsPerFeet,
    height: 1.2 * pixelsPerFeet,
    textAlign: "left",
    verticalAlign: "bottom",
  });

  const { textElement: newOccText, rectElement: newOccRect } =
    preventTextWrapping(occupancyText, occupancyRect);

  redrawTextBoundingBox(newOccText, newOccRect);

  return [occupancyText, occupancyRect];
};

const renderLowestLevelText = ({ drawingProps, lineProps }) => {
  const { groupId, pixelsPerFeet, isLowestLevel } = drawingProps;
  const [lowestLevelTextId, lowestLevelRectId] = [generateId(), generateId()];

  const lowestLevelText = {
    ...basicTextElement,
    customData: {
      ...basicTextElement.customData,
      type: "horizontal-ruler",
      valueFor: "isLowestLevel",
    },
    id: lowestLevelTextId,
    groupIds: [groupId],
    containerId: lowestLevelRectId,
    textAlign: "left",
    text: isLowestLevel ? "(LOWEST LEVEL OF FIRE DEPT. VEHICLE ACCESS)" : "",
    originalText: isLowestLevel
      ? "(LOWEST LEVEL OF FIRE DEPT. VEHICLE ACCESS)"
      : "",
    width: 22.3 * pixelsPerFeet,
    height: 1.6 * pixelsPerFeet,
    fontSize: 0.88 * pixelsPerFeet,
  };

  const lowestLevelRect = {
    ...basicRectElement,
    id: lowestLevelRectId,
    boundElements: [{ type: "text", id: lowestLevelTextId }],
    groupIds: [groupId],
    width: 22.3 * pixelsPerFeet,
    height: 1.6 * pixelsPerFeet,
    x: lineProps.x + 12.7 * pixelsPerFeet,
    y: lineProps.y,
  };
  const { textElement: adjustedText, rectElement: adjustedContainer } =
    preventTextWrapping(lowestLevelText, lowestLevelRect);

  redrawTextBoundingBox(adjustedText, adjustedContainer);

  return [adjustedText, adjustedContainer];
};

const createLineWithTexts = ({
  floorDrawing,
  pixelsPerFeet,
  groupId,
  iconsAndCovers,
  marginRight,
  marginBottom,
}) => {
  const { name, occupancyIds, isLowestLevel } = floorDrawing;
  const lineWidth = 35 * pixelsPerFeet;
  const lineProps = {
    width: lineWidth,
    x: 2 * HORIZONTAL_RULER_MARGIN_X + marginRight,
    y: marginBottom,
  };
  const [floorNameText, floorNameRect] = renderFloorName({
    drawingProps: { name, pixelsPerFeet, groupId },
    lineProps,
  });

  const [occupancyText, occupancyRect] = renderOccupancyIdsText({
    drawingProps: { groupId, occupancyIds, pixelsPerFeet },
    lineProps,
    iconsAndCovers,
  });

  const [lowestLevelText, lowestLevelRect] = renderLowestLevelText({
    drawingProps: { groupId, isLowestLevel, pixelsPerFeet },
    lineProps,
  });

  const lineElement = {
    ...propsInCommon,
    id: generateId(),
    groupIds: [groupId],
    type: "line",
    points: [
      [0, 0],
      [lineWidth, 0],
    ],
    width: lineProps.width,
    x: lineProps.x,
    y: lineProps.y,
  };

  return [
    lineElement,
    floorNameText,
    floorNameRect,
    occupancyText,
    occupancyRect,
    lowestLevelText,
    lowestLevelRect,
  ];
};

const createDrawingDataElementsOnRuler = ({
  iconsAndCovers,
  floorDrawing,
  pixelsPerFeet,
  groupId,
  startY,
}) => {
  const DATA_LINE_MARGIN_X = NOMENCLATURE_ELLIPSE_DIM + 1 * pixelsPerFeet;
  const DATA_LINE_MARGIN_Y =
    startY - NOMENCLATURE_ELLIPSE_DIM / 2 - NOMENCLATURE_ELLIPSE_MARGIN_BOTTOM;

  const propsToPass = {
    iconsAndCovers,
    floorDrawing,
    pixelsPerFeet,
    groupId,
    startY,
  };
  const nomenclatureElements = createEllipseWithNomenclatureText({
    ...propsToPass,
    ellipseDimension: NOMENCLATURE_ELLIPSE_DIM,
  });
  const rulerLineWithData = createLineWithTexts({
    ...propsToPass,
    marginRight: DATA_LINE_MARGIN_X,
    marginBottom: DATA_LINE_MARGIN_Y,
  });

  return [...nomenclatureElements, ...rulerLineWithData];
};

const getBarWidth = (iterationNumber, initialBarWidth) => {
  let width = 0;
  if (iterationNumber > 5) {
    //For the first five feet.
    width = initialBarWidth; // bar width = 10 feet
  } else if (iterationNumber === 5) {
    width = initialBarWidth / 2; // bar width = 5 feet
  } else {
    width = initialBarWidth / 10; // bar width = 1 feet
  }

  return width;
};

const getMeasureText = (i) => {
  let measureText = "";
  if (i < 6) {
    return i;
  }

  switch (i) {
    case 6:
      measureText = 10;
      break;
    case 7:
      measureText = 20;
      break;
    case 8:
      measureText = 30;
      break;
    default:
      measureText = 40;
      break;
  }
  return measureText;
};

const createBlackWhiteCells = ({
  iteration,
  barWidth,
  start,
  marginY,
  groupId,
  floorDrawing,
}) => {
  let width = 0;
  let barColor = "#000";
  const bars = [];
  if (iteration % 2 !== 0) {
    barColor = "#fff";
  }

  //Width of every element.
  width = getBarWidth(iteration, barWidth);

  //Create black/white unit
  for (let j = 0; j < 2; j++) {
    if (j === 1) {
      barColor = barColor === "#fff" ? "#000" : "#fff";
    }

    const bar = createSceneElement("rectangle", {
      backgroundColor: barColor,
      type: "horizontal-ruler",
      groupId,
      barWidth,
      floorDrawing,
      width,
      iterator: j,
      start,
      marginY,
    });

    bars.push(bar);
  }

  return bars;
};

const createMeasuredTextsElement = ({
  iteration,
  barWidth,
  groupId,
  start,
  floorDrawing,
}) => {
  const measureTexts = [];
  let measureText = 0;

  //Measure text of every element.
  measureText = getMeasureText(iteration);

  const text = createSceneElement("text", {
    type: "horizontal-ruler",
    barWidth,
    measureText,
    groupId,
    start,
    floorDrawing,
  });

  measureTexts.push(text);

  //At the last iteration -> Create the last measure text 40"
  if (iteration === 8) {
    const lastMeasureText = createSceneElement("text", {
      type: "horizontal-ruler",
      barWidth,
      measureText: "40",
      groupId,
      start,
      floorDrawing,
      isLastMeasureText: true,
    });
    measureTexts.push(lastMeasureText);
  }

  return measureTexts;
};

const createMeasuredBlackWhiteCells = ({
  barWidth,
  groupId,
  floorDrawing,
  marginY,
}) => {
  let start = 0;
  const bars = [];
  const measuredTexts = [];
  const propsToPass = {
    barWidth,
    groupId,
    floorDrawing,
  };
  for (let i = 0; i < 9; i++) {
    //Get the start of every element
    if (i > 0) {
      // The element that comes before the preceding element of the current element
      start += bars[2 * i - 2].width;
    }

    bars.push(
      ...createBlackWhiteCells({
        iteration: i,
        start,
        marginY,
        ...propsToPass,
      }),
    );

    measuredTexts.push(
      ...createMeasuredTextsElement({
        iteration: i,
        start,
        ...propsToPass,
      }),
    );
  }

  return [...bars, ...measuredTexts];
};

const drawHorizontalScaleRuler = (
  iconsAndCovers,
  currentDrawing,
  groupId,
  excalidrawAPI,
  updateScene,
) => {
  const { floorDrawing, pixelsPerFeet } =
    initializeRulerDrawing(currentDrawing);
  const horizontalRulerMarginY =
    CONTENT_HEIGHT - HORIZONTAL_RULER_MARGIN_BOTTOM - 1 * pixelsPerFeet;
  const measuredBlackWhiteCells = createMeasuredBlackWhiteCells({
    barWidth: 10 * pixelsPerFeet,
    floorDrawing,
    groupId,
    marginY: horizontalRulerMarginY,
  });

  const horizontalRulerDataElements = createDrawingDataElementsOnRuler({
    iconsAndCovers,
    floorDrawing,
    pixelsPerFeet,
    groupId,
    startY: horizontalRulerMarginY - 1 * pixelsPerFeet,
  });

  const currentSceneElements = excalidrawAPI?.getSceneElements();

  updateScene("elements", [
    ...currentSceneElements,
    ...measuredBlackWhiteCells,
    ...horizontalRulerDataElements,
  ]);
};

const drawVerticalScaleRuler = (
  currentDrawing,
  groupId,
  excalidrawAPI,
  updateScene,
) => {
  const { floorDrawing, pixelsPerFeet, bars, measureTexts } =
    initializeRulerDrawing(currentDrawing);
  const barHeight = pixelsPerFeet * 10;
  let start =
    CONTENT_HEIGHT - 2 * pixelsPerFeet - HORIZONTAL_RULER_MARGIN_BOTTOM;

  for (let i = 0; i < 9; i++) {
    let height = 0;
    let barColor = "#000";
    if (i % 2 !== 0) {
      barColor = "#fff";
    }

    //First 5 inches.
    if (i > 5) {
      height = barHeight;
    } else if (i === 5) {
      height = barHeight / 2;
    } else {
      height = barHeight / 10;
    }

    //Position of every element.
    if (i > 0) {
      start -= bars[2 * i - 2].height;
    }
    let measureText = 0;
    if (i < 6) {
      measureText = i;
    } else {
      measureText = getMeasureText(i);
    }
    const text = createSceneElement("text", {
      type: "vertical-ruler",
      barHeight,
      measureText,
      groupId,
      start,
      floorDrawing,
      margin: 0,
    });

    measureTexts.push(text);

    if (i === 8) {
      const lastMeasureText = createSceneElement("text", {
        type: "vertical-ruler",
        barHeight,
        groupId,
        start,
        floorDrawing,
        measureText: "40",
        height,
        margin: 0,
      });
      measureTexts.push(lastMeasureText);
    }
    for (let j = 0; j < 2; j++) {
      if (j === 1) {
        barColor = barColor === "#fff" ? "#000" : "#fff";
      }
      const bar = createSceneElement("rectangle", {
        backgroundColor: barColor,
        type: "vertical-ruler",
        groupId,
        barHeight,
        floorDrawing,
        height,
        iterator: j,
        start,
        margin: 0,
      });
      bars.push(bar);
    }
  }

  const currentSceneElements = excalidrawAPI?.getSceneElements();
  updateScene("elements", [...currentSceneElements, ...bars, ...measureTexts]);
};

function initializeRulerDrawing(currentDrawing) {
  const floorDrawing = currentDrawing.name ? currentDrawing : currentDrawing[0];
  return {
    floorDrawing,
    pixelsPerFeet: convertToFeet(
      floorDrawing.pixelsPerUnit,
      floorDrawing.correspondingUnit,
    ),
    bars: [],
    measureTexts: [],
  };
}

function createSceneElement(type, props) {
  if (type === "text") {
    const textElement = newElementWith(basicTextElement, {
      originalText: `${props.measureText}'`,
      text: `${props.measureText}'`,
      verticalAlign: "top",
      textAlign: "left",
    });
    if (props.type === "horizontal-ruler") {
      return {
        ...textElement,
        id: generateId(),
        fontSize: props.barWidth / 11,
        groupIds: [props.groupId],
        height: props.barWidth / 6,
        width: props.barWidth / 9,
        x:
          2 * HORIZONTAL_RULER_MARGIN_X +
          props.start +
          (props.isLastMeasureText
            ? (19 * props.barWidth) / 18
            : -props.barWidth / 18),
        y: CONTENT_HEIGHT - HORIZONTAL_RULER_MARGIN_BOTTOM,
      };
    }
    return {
      ...textElement,
      id: generateId(),
      fontSize: MARGIN_UNIT,
      groupIds: [props.groupId],
      height: MARGIN_UNIT,
      textAlign: "right",
      verticalAlign: "center",
      width: MARGIN_UNIT,
      x: 2 * HORIZONTAL_RULER_MARGIN_X - props.barHeight / 3,
      y: props.start - (props.height || 0),
    };
  } else if (type === "rectangle") {
    const rectangleElement = {
      ...basicRectElement,
      id: generateId(),
      backgroundColor: props.backgroundColor,
      groupIds: [props.groupId],
      strokeColor: "#000000",
      strokeWidth: 1,
    };
    if (props.type === "horizontal-ruler") {
      return {
        ...rectangleElement,
        height: props.barWidth / 11,
        width: props.width,
        x: 2 * HORIZONTAL_RULER_MARGIN_X + props.start,
        y: props.marginY - (props.barWidth * props.iterator) / 11,
      };
    } else if (props.type === "vertical-ruler") {
      return {
        ...rectangleElement,
        height: props.height,
        width: props.barHeight / 11,
        x:
          2 * HORIZONTAL_RULER_MARGIN_X -
          props.barHeight / 11 -
          (props.barHeight * props.iterator) / 11,
        y: props.start - props.height,
      };
    }
  }
}

export const drawScaleRuler = (
  users,
  iconsAndCovers,
  currentDrawing,
  currentProject,
  keyContacts,
  excalidrawAPI,
  updateScene,
  northHeading,
  drawingsPerProject,
) => {
  const groupId = generateId();
  drawHorizontalScaleRuler(
    iconsAndCovers,
    currentDrawing,
    groupId,
    excalidrawAPI,
    updateScene,
  );
  drawVerticalScaleRuler(currentDrawing, groupId, excalidrawAPI, updateScene);
  //TODO - Call this function in some general place.
  drawFrameWithPlayersSection(
    users,
    currentDrawing,
    currentProject,
    groupId,
    excalidrawAPI,
    updateScene,
    keyContacts,
    northHeading,
    drawingsPerProject,
  );
};

export const deleteScaleRuler = (excalidrawAPI, collabAPI, updateScene) => {
  const adjustedElements = excalidrawAPI?.getSceneElements().map((el) => {
    if (el.customData?.isImmutable && el.customData?.type === "ruler") {
      return newElementWith(el, { isDeleted: true });
    }
    return el;
  });
  collabAPI.syncElements(adjustedElements);
  updateScene("elements", adjustedElements);
};

export const toggleRulerAppearance = (excalidrawAPI, updateScene) => {
  let adjustedElements = _.cloneDeep(
    excalidrawAPI
      ?.getSceneElementsIncludingDeleted()
      .filter((el) => !el.customData?.isRemoved),
  );
  adjustedElements = adjustedElements.map((el) => {
    const deleteCondition = el.customData?.isImmutable;

    if (deleteCondition) {
      return newElementWith(el, { isDeleted: !el.isDeleted });
    }
    return el;
  });

  updateScene("elements", [...adjustedElements]);
};

export const getIfRulerShown = (excalidrawAPI) => {
  const sceneElements = excalidrawAPI.getSceneElements();
  for (let i = 0; i < sceneElements.length; i++) {
    //If there's one element has the type of "horizontal/vertical-ruler", then the ruler is shown
    if (
      sceneElements[i].customData?.isImmutable &&
      sceneElements[i].customData?.imageType !== "floor-drawing"
    ) {
      return true;
    }
  }
  return false;
};

const getFloorName = (drawingName) => {
  if (drawingName.length > 15) {
    return `${drawingName.toUpperCase().substring(0, 15)}...`;
  }
  return drawingName.toUpperCase();
};
