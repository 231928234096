import React from "react";

import "../../scss/Indicator.scss";

const Indicator = ({ number, style }) => {
  return number ? (
    <div style={style} className="custom-badge-container">
      {number}
    </div>
  ) : null;
};

export default Indicator;
