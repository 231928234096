import firebase from "firebase/app";
import "firebase/storage";
import { getDrawingThumbnail } from "../helpers/drawings";

export const uploadBarcodeToFireStorage = async (
  image,
  drawingId,
  projectNumber,
) => {
  const pathStorage = `projects/${projectNumber}/barcodes/${projectNumber}-${drawingId}-barcode`;
  const fileRef = firebase.storage().ref(pathStorage);
  let url = "";
  try {
    const res = await fileRef.put(image); // Here, we're uploading the image to be stored
    url = await res.ref.getDownloadURL();

    return url;
  } catch (error) {
    console.error(error);
  }
};

export const uploadDrawingToFireStorage = async (
  image,
  drawingId,
  projectNumber,
) => {
  const pathStorage = `projects/${projectNumber}/drawings/${projectNumber}-${drawingId}.jpeg`;
  const fileRef = firebase.storage().ref(pathStorage);
  try {
    //NOTE - You received the image as Data URL -> you MUST use putString
    const res = await fileRef.putString(image, "data_url"); // Here, we're uploading the image to be stored
    let drawingUrl = "";
    drawingUrl = await res.ref.getDownloadURL();
    const { thumbImageUrl, thumbFilePath } = await getDrawingThumbnail(
      pathStorage,
    );
    return { thumbImageUrl: thumbImageUrl[0], thumbFilePath, drawingUrl };
  } catch (error) {
    console.error(error);
  }
};

export const saveMapInFireStorage = async (imageUrl, projectId, options) => {
  try {
    const pathStorage = `projects/${projectId}/map/${projectId}-map.png`;
    const fileRef = firebase.storage().ref(pathStorage);
    const res = await fileRef.putString(imageUrl, "data_url"); // Here, we're uploading the image to be stored
    const downloadUrl = await res.ref.getDownloadURL();
    return downloadUrl;
  } catch (error) {
    console.error(`${options.errorMessage || ""} ${error}`);
  }
};

export const saveSceneToFireStorage = async (scene, roomKey) => {
  const uint8View = new Uint8Array(scene.ciphertext);
  scene.ciphertext = uint8View;
  const pathStorage = `scenes/${roomKey}.json`;
  const fileRef = firebase.storage().ref(pathStorage);
  try {
    fileRef.putString(JSON.stringify(scene));
  } catch (error) {
    console.error(error);
  }
};

export const loadSceneFromFireStorage = async (roomKey) => {
  const filePath = `scenes/${roomKey}.json`;
  const storageRef = firebase.storage().ref();
  try {
    const url = await storageRef.child(filePath).getDownloadURL();
    const res = await fetch(url);
    const data = await res.json();

    const [ciphertextAsUint8Array, ivAsUint8Array] = [[], []];
    for (const i in data.ciphertext) {
      ciphertextAsUint8Array.push(data.ciphertext[i]);
    }
    for (const i in data.iv) {
      ivAsUint8Array.push(data.iv[i]);
    }
    return {
      ...data,
      ciphertext: new Uint8Array(ciphertextAsUint8Array),
      iv: new Uint8Array(ivAsUint8Array),
    };
  } catch (error) {
    console.error(error);
  }
};

export const checkSceneExistence = async (roomKey) => {
  const filePath = `scenes/${roomKey}.json`;
  const storageRef = firebase.storage().ref();
  try {
    await storageRef.child(filePath).getDownloadURL();
    return true;
  } catch (error) {
    return false;
  }
};
