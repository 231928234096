export default {
  elements: [
    // {
    //   type: "rectangle",
    //   version: 141,
    //   versionNonce: 361174001,
    //   isDeleted: false,
    //   id: "oDVXy8D6rom3H1-LLH2-f",
    //   fillStyle: "hachure",
    //   strokeWidth: 1,
    //   strokeStyle: "solid",
    //   roughness: 1,
    //   opacity: 100,
    //   angle: 0,
    //   x: 100.50390625,
    //   y: 93.67578125,
    //   strokeColor: "#000000",
    //   backgroundColor: "transparent",
    //   width: 186.47265625,
    //   height: 141.9765625,
    //   seed: 1968410350,
    //   groupIds: [],
    // },
    // {
    //   id: "-xMIs_0jIFqvpx-R9UnaG",
    //   type: "ellipse",
    //   x: 300.5703125,
    //   y: 190.69140625,
    //   width: 198.21875,
    //   height: 129.51171875,
    //   angle: 0,
    //   strokeColor: "#000000",
    //   backgroundColor: "transparent",
    //   fillStyle: "hachure",
    //   strokeWidth: 1,
    //   strokeStyle: "solid",
    //   roughness: 1,
    //   opacity: 100,
    //   groupIds: [],
    //   seed: 957947807,
    //   version: 47,
    //   versionNonce: 1128618623,
    //   isDeleted: false,
    // },
    // {
    //   fileId: "tester",
    //   type: "image",
    //   x: 606.1042326312408,
    //   y: 153.57729779411773,
    //   width: 231.30325348751828,
    //   height: 231.64340533088227,
    //   angle: 0,
    //   strokeColor: "transparent",
    //   backgroundColor: "transparent",
    //   fillStyle: "hachure",
    //   strokeWidth: 1,
    //   strokeStyle: "solid",
    //   roughness: 1,
    //   opacity: 100,
    //   groupIds: [],
    //   strokeSharpness: "round",
    //   seed: 707269846,
    //   version: 143,
    //   versionNonce: 2028982666,
    //   isDeleted: false,
    //   boundElements: null,
    //   updated: 1644914782403,
    //   link: null,
    //   status: "pending",
    //   scale: [1, 1],
    // },
    {
      fileId: "tester",
      type: "image",
      x: 706.1042326312408,
      y: 253.57729779411773,
      width: 231.30325348751828,
      height: 231.64340533088227,
      angle: 0,
      strokeColor: "transparent",
      backgroundColor: "transparent",
      fillStyle: "hachure",
      strokeWidth: 1,
      strokeStyle: "solid",
      roughness: 1,
      opacity: 100,
      groupIds: [],
      strokeSharpness: "round",
      seed: 707269846,
      version: 143,
      versionNonce: 2028982666,
      isDeleted: false,
      boundElements: null,
      updated: 1644914782403,
      link: null,
      status: "pending",
      scale: [1, 1],
    },
    {
      fileId: "tester2",
      type: "image",
      x: 406.1042326312408,
      y: 753.57729779411773,
      width: 231.30325348751828,
      height: 231.64340533088227,
      angle: 0,
      strokeColor: "transparent",
      backgroundColor: "transparent",
      fillStyle: "hachure",
      strokeWidth: 1,
      strokeStyle: "solid",
      roughness: 1,
      opacity: 100,
      groupIds: [],
      strokeSharpness: "round",
      seed: 707269846,
      version: 143,
      versionNonce: 2028982666,
      isDeleted: false,
      boundElements: null,
      updated: 1644914782403,
      link: null,
      status: "pending",
      scale: [1, 1],
    },
  ],
  // appState: {
  //   viewBackgroundColor: "#fff",
  //   currentItemFontFamily: 1,
  //   gridModeEnabled: true,
  // },
  // scrollToContent: true,
  // libraryItems: [
  //   [
  //     // {
  //     //   fileId: "tester",
  //     //   type: "image",
  //     //   x: 606.1042326312408,
  //     //   y: 153.57729779411773,
  //     //   width: 231.30325348751828,
  //     //   height: 231.64340533088227,
  //     //   angle: 0,
  //     //   strokeColor: "transparent",
  //     //   backgroundColor: "transparent",
  //     //   fillStyle: "hachure",
  //     //   strokeWidth: 1,
  //     //   strokeStyle: "solid",
  //     //   roughness: 1,
  //     //   opacity: 100,
  //     //   groupIds: [],
  //     //   strokeSharpness: "round",
  //     //   seed: 707269846,
  //     //   version: 143,
  //     //   versionNonce: 2028982666,
  //     //   isDeleted: false,
  //     //   boundElements: null,
  //     //   updated: 1644914782403,
  //     //   link: null,
  //     //   status: "pending",
  //     //   scale: [1, 1],
  //     // },
  //   ],
  // ],
};
