import { useAtom } from "jotai";

import { excalidrawApiAtom } from "../store/variables";
import { getSceneById } from "../helpers/drawings";
import {
  getCollabServer,
  getSyncableElements,
} from "../ExcalidrawAPI/excalidraw-app/data";
import { decryptData } from "../ExcalidrawAPI/data/encryption";
import { WS_SCENE_EVENT_TYPES } from "../ExcalidrawAPI/excalidraw-app/app_constants";
import CustomPortal from "../ExcalidrawAPI/excalidraw-app/collab/CustomPortal";
import { saveToFirebase } from "../ExcalidrawAPI/excalidraw-app/data/firebase";
import { isSyncingAtom } from "../store/UI";

export const useSceneMethods = () => {
  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [, setIsSyncing] = useAtom(isSyncingAtom);

  //State

  //Functions
  const decryptElements = async (data, roomKey) => {
    const ciphertext = data.ciphertext.toUint8Array();
    const iv = data.iv.toUint8Array();

    const decrypted = await decryptData(iv, ciphertext, roomKey);
    const decodedData = new TextDecoder("utf-8").decode(
      new Uint8Array(decrypted),
    );
    return JSON.parse(decodedData);
  };

  const openSocketConnection = async ({ customPortal, roomId, roomKey }) => {
    try {
      const socketServerData = await getCollabServer();
      const { default: socketIOClient } = await import(
        /* webpackChunkName: "socketIoClient" */ "socket.io-client"
      );

      await customPortal.open(
        socketIOClient(socketServerData.url, {
          transports: socketServerData.polling
            ? ["websocket", "polling"]
            : ["websocket"],
        }),
        roomId,
        roomKey,
      );

      customPortal.socket.once("connect_error", () => {
        excalidrawAPI.setToast({
          message: `Error establishing the custom portal socket (connect_error)`,
          duration: 2000,
          closable: true,
          options: {
            position: "topRight",
            status: "error",
          },
        });
      });
    } catch (error) {
      console.error(error);
      excalidrawAPI.setToast({
        message: `Error establishing the custom portal socket`,
        duration: 2000,
        closable: true,
        options: {
          position: "topRight",
          status: "error",
        },
      });
    }
  };

  const saveCollabRoomToFirebase = async (syncableElements, portal) => {
    try {
      await saveToFirebase(
        portal,
        syncableElements,
        excalidrawAPI.getAppState(),
      );
    } catch (error) {
      console.error({
        // firestore doesn't return a specific error code when size exceeded
        errorMessage: /is longer than.*?bytes/.test(error.message)
          ? "Save failed because the file size has exceeded the limit."
          : "Save failed",
      });
      console.error(error);
    }
  };

  const extractRoomInfo = (link) => {
    const regex = /#room=([^,]+),([^]+)$/;
    if (!link) {
      return { roomId: "", roomKey: "" };
    }
    const match = link.match(regex);

    if (match) {
      const [, roomId, roomKey] = match;
      return { roomId, roomKey };
    }
    return null;
  };

  const getRemoteSceneElements = async (roomLink) => {
    const { roomId, roomKey } = extractRoomInfo(
      roomLink,
      "getRemoteSceneElements",
    );
    try {
      const storedScene = await getSceneById(roomId);
      const syncableElements = getSyncableElements(
        await decryptElements(storedScene, roomKey),
      );
      return syncableElements;
    } catch (error) {
      console.error("Error getting cover sheet elements");
    }
  };

  const getInitializingErrorMessage = (roomLink) => {
    const { roomId, roomKey } =
      extractRoomInfo(roomLink, "getInitializingErrorMessage") || {};
    if (!roomId || !roomKey) {
      return `Couldn't find the ${!roomId ? "roomId" : "roomKey"}`;
    }
    // else if (location.hash === roomLink) {
    //   return "You are already in this collaborative session.";
    // }
    return false;
  };

  const getCurrentElements = async (roomLink) => {
    if (!roomLink) {
      return [];
    }
    if (roomLink === location.hash) {
      //Update current scene => return current scene elements
      return excalidrawAPI.getSceneElements();
    }
    //Update remote scene => return remote scene elements
    return await getRemoteSceneElements(roomLink);
  };
  /**
   * @param {Array of objects} updates - the elements to be added/deleted from the remote scene.
   * @param {Object} roomData - An object contains roomId & roomKey
   */
  const updateRemoteScene = async ({ updates, roomLink, isUpdateAll }) => {
    if (!roomLink) {
      return;
    }
    setIsSyncing(true);
    const { roomId, roomKey } =
      extractRoomInfo(roomLink, "updateRemoteScene") || {};
    const initializingErrorMessage = getInitializingErrorMessage(roomLink);

    if (initializingErrorMessage) {
      console.error(initializingErrorMessage);
      setIsSyncing(false);
      return;
    }
    try {
      const storedScene = await getSceneById(roomId);
      const syncableElements = getSyncableElements(
        await decryptElements(storedScene, roomKey),
      );

      const newSyncableElementsElements = [
        ...(isUpdateAll ? [] : syncableElements),
        ...updates,
      ];

      // NOTE - Broadcast to all users
      // STEP 1 - Open new socket connection, and Connect to the remote room
      const customPortal = new CustomPortal();
      await openSocketConnection({ customPortal, roomId, roomKey });
      // STEP 2 - Broadcast the new elements to the room
      await customPortal.broadcastScene(
        WS_SCENE_EVENT_TYPES.UPDATE,
        newSyncableElementsElements,
        true,
      );
      // STEP 3 - Close the socket connection
      customPortal.socket.on("client-broadcast", () => {
        customPortal.close();
      });

      // TODO - Save to firebase
      await saveCollabRoomToFirebase(newSyncableElementsElements, customPortal);
      setIsSyncing(false);
    } catch (error) {
      setIsSyncing(false);
      console.error(error);
    }
  };

  //Return
  return { getRemoteSceneElements, getCurrentElements, updateRemoteScene };
};
