import { useAtom } from "jotai";
import { setToLocalStorage } from "../helpers/common";
import {
  currentDrawingAtom,
  currentProjectAtom,
  excalidrawApiAtom,
  northHeadingAtom,
} from "../store/variables";
import useReactiveUIVars from "./useReactiveUIVars";
import { useExcalidrawSetAppState } from "../ExcalidrawAPI/components/App";
import { calculateScrollCenter } from "../ExcalidrawAPI/scene";
import { newElementWith } from "../ExcalidrawAPI/element/mutateElement";

const usePlaceOnMapHandler = () => {
  //Atom
  const [currentDrawing] = useAtom(currentDrawingAtom);
  const [currentProject] = useAtom(currentProjectAtom);
  const [northHeading, setNorthHeading] = useAtom(northHeadingAtom);
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);

  //Custom Hooks
  const { isEditMode, setIsUploadPopupOpen, setIsPlacingOnMap } =
    useReactiveUIVars();

  //Excalidraw Hooks
  const setAppState = useExcalidrawSetAppState();

  //Functions
  const updateScene = (destination, update) => {
    const sceneData = {
      [destination]: update,
    };
    excalidrawAPI?.updateScene(sceneData);
  };

  const centerTheDrawing = () => {
    const elements = excalidrawAPI?.getSceneElements();
    const appState = excalidrawAPI?.getAppState();
    const canvas = document.querySelector(".excalidraw__canvas");

    setAppState({
      ...calculateScrollCenter(elements, appState, canvas),
    });
  };

  const lockDrawing = (locked) => {
    if (isEditMode) {
      return;
    }
    const currentDrawingElement = excalidrawAPI
      ?.getSceneElements()
      .find((el) => el.customData?.imageType === "floor-drawing");
    if (currentDrawingElement) {
      if (locked) {
        const { width, height, x, y } = currentDrawing ?? {};
        Object.assign(currentDrawingElement, {
          angle: 0,
          width,
          height,
          x,
          y,
          opacity: 100,
          locked,
        });
        excalidrawAPI?.zoomToFitAllDrawing();
      } else {
        Object.assign(currentDrawingElement, {
          locked,
          angle: northHeading ?? 0,
        });
      }
    }
    updateScene("elements", [currentDrawingElement]);
  };

  const placeOnMapHandler = () => {
    setAppState({ viewBackgroundColor: "#5b57d100" });
    const currentDrawingElement = excalidrawAPI
      ?.getSceneElements()
      .find((el) => el.customData?.imageType === "floor-drawing");
    if (currentDrawingElement) {
      const currentElProps = {
        opacity: 70,
        angle: northHeading ?? 0,
        locked: false,
      };
      excalidrawAPI?.updateScene({
        elements: excalidrawAPI?.getSceneElements().map((el) => {
          if (el.id === currentDrawingElement.id) {
            return newElementWith(el, currentElProps);
          }
          return newElementWith(el, { opacity: 1, locked: true });
        }),
        appState: { zoom: { value: currentDrawingElement ? 0.25 : 0 } },
      });
      lockDrawing(false);
      setTimeout(() => {
        centerTheDrawing();
      });
    }
    setIsUploadPopupOpen(false);
    setIsPlacingOnMap(true);
  };

  const closePlacingOnMap = ({ discard }) => {
    setIsPlacingOnMap(false);
    if (!discard && currentDrawing.type !== "coverSheet") {
      const currentDrawingElement = excalidrawAPI
        ?.getSceneElements()
        .find((el) => el.customData?.imageType === "floor-drawing");
      if (currentDrawingElement.angle !== 0) {
        setToLocalStorage(
          "appCustomData",
          { northHeading: currentDrawingElement.angle },
          { merge: true },
        );
        setNorthHeading(currentDrawingElement.angle);
      }
    }

    if (currentDrawing.type !== "coverSheet") {
      lockDrawing(true);
      const sceneElements = excalidrawAPI?.getSceneElements() || [];
      const currentDrawingElement = sceneElements.find(
        (el) => el.customData?.imageType === "floor-drawing",
      );
      const northEl = sceneElements.find(
        (el) => el.customData?.valueFor === "North Icon",
      );
      excalidrawAPI?.updateScene({
        elements: excalidrawAPI?.getSceneElements().map((el) => {
          if (el.id === currentDrawingElement.id) {
            return newElementWith(el, {
              angle: 0,
              opacity: 100,
              x: currentDrawing.x,
              y: currentDrawing.y,
              width: currentDrawing.width,
              height: currentDrawing.height,
              locked: true,
            });
          } else if (el.id === northEl.id) {
            return newElementWith(el, {
              opacity: 100,
              angle: !discard
                ? currentDrawingElement.angle
                : currentProject.northHeading,
            });
          }
          return newElementWith(el, {
            opacity: 100,
            locked: !!el.customData?.isImmutable,
          });
        }),
      });
    }
    setIsUploadPopupOpen(true);
    excalidrawAPI.zoomToFitAllDrawing();
  };

  return {
    lockDrawing,
    closePlacingOnMap,
    placeOnMapHandler,
  };
};

export default usePlaceOnMapHandler;
