import { useEffect } from "react";
import { t } from "../i18n";
import { Dialog, DialogProps } from "./Dialog";

import "./ConfirmDialog.scss";
import DialogActionButton from "./DialogActionButton";
import { useSetAtom } from "jotai";
import { isLibraryMenuOpenAtom } from "./LibraryMenuHeaderContent";
import { useExcalidrawSetAppState } from "./App";
import { useState } from "react";
import { KEYS } from "../keys";

interface Props extends Omit<DialogProps, "onCloseRequest"> {
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
  buttonsPlacement?: string;
  buttonsToShow?: "confirm" | "discard" | "both" | "none";
}
const ConfirmDialog = (props: Props) => {
  const {
    onConfirm,
    onCancel,
    children,
    confirmText = t("buttons.confirm"),
    cancelText = t("buttons.cancel"),
    className = "",
    buttonsPlacement = "end",
    buttonsToShow = "both",
    ...rest
  } = props;
  //Excalidraw Hooks
  const setAppState = useExcalidrawSetAppState();

  //Atoms
  const setIsLibraryMenuOpen = useSetAtom(isLibraryMenuOpenAtom);

  //State
  const [isConfirmed, setIsConfirmed] = useState(false);

  //Constants
  const confirmBtnClassName = `confirm-button_active ${
    isConfirmed ? "confirm-button_disabled" : ""
  }`;
  const cancelBtnClassName = `${isConfirmed ? "confirm-button_disabled" : ""}`;

  //Functions
  const onConfirmHandler = () => {
    setAppState({ openMenu: null });
    setIsLibraryMenuOpen(false);
    onConfirm();
    setIsConfirmed(true);
  };

  const onCancelHandler = () => {
    setAppState({ openMenu: null });
    setIsLibraryMenuOpen(false);
    onCancel();
  };

  //Effects
  useEffect(() => {
    const enterHandler = (e: KeyboardEvent) => {
      if (e.key === KEYS.ENTER) {
        onConfirmHandler();
      } else if (e.key === KEYS.ESCAPE) {
        onCancelHandler();
      }
    };
    addEventListener("keydown", enterHandler);

    return () => removeEventListener("keydown", enterHandler);
  });

  //Render
  return (
    <Dialog
      onCloseRequest={onCancel}
      small={true}
      {...rest}
      className={`confirm-dialog ${className}`}
    >
      {children}
      <div className={`confirm-dialog-buttons_${buttonsPlacement}`}>
        {["discard", "both"].includes(buttonsToShow) && (
          <DialogActionButton
            label={cancelText}
            onClick={onCancelHandler}
            className={cancelBtnClassName}
            spinnerSize="1.5rem"
          />
        )}
        {["confirm", "both"].includes(buttonsToShow) && (
          <DialogActionButton
            label={confirmText}
            onClick={onConfirmHandler}
            actionType="primary"
            className={confirmBtnClassName}
            isLoading={isConfirmed}
            spinnerSize="1.5rem"
          />
        )}
      </div>
    </Dialog>
  );
};
export default ConfirmDialog;
