import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";

import { Tooltip } from "@mui/material";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { currentDrawingAtom } from "../store/variables";

import "../scss/SlidersPullers.scss";

const SlidersPullers = ({
  setIsProjectSummaryOpen,
  setIsPagesManagerOpen,
  setIsAreasSliderOpen,
  setIsSettingsSliderOpen,
}) => {
  //State
  const [, setCurrentViewWidth] = useState(window.innerWidth);

  //Atom
  // const [, setIsUploadPopupOpen] = useAtom(isUploadPopupOpenAtom);
  const [currentDrawing] = useAtom(currentDrawingAtom);

  //Constants

  //Effects
  useEffect(() => {
    const handleResize = () => {
      setCurrentViewWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`sliders-pullers-container ${
        !location.hash ? "sliders-pullers-container_disabled" : null
      }`}
    >
      <Tooltip title="Tools" placement="top" arrow={true}>
        <button className="slider-pullers-puller sliders-pullers-main-puller">
          <ManageAccountsOutlinedIcon className="sliders-pullers-main-puller_start_icon" />
          <ManageAccountsIcon className="sliders-pullers-main-puller_end_icon" />
        </button>
      </Tooltip>
      <div className="sliders-pullers-content">
        <div className="sliders-pullers-divider"></div>
        <Tooltip title="Project Summary" placement="bottom" arrow={true}>
          <button
            className="slider-pullers-puller"
            onClick={() => setIsProjectSummaryOpen((prevStatus) => !prevStatus)}
          >
            <DescriptionOutlinedIcon />
          </button>
        </Tooltip>
        <Tooltip title="Pages Manager" placement="bottom" arrow={true}>
          <button
            id="slider-pullers-puller_pages"
            className="slider-pullers-puller"
            onClick={() => setIsPagesManagerOpen((prevStatus) => !prevStatus)}
          >
            <BookmarksOutlinedIcon />
          </button>
        </Tooltip>
        <Tooltip title="Areas Manager" placement="bottom" arrow={true}>
          <button
            className={`slider-pullers-puller ${
              currentDrawing?.type === "coverSheet"
                ? "slider-pullers-puller_disabled"
                : ""
            }`}
            onClick={() => {
              if (currentDrawing?.type === "coverSheet") {
                return;
              }
              setIsAreasSliderOpen((prevStatus) => !prevStatus);
            }}
          >
            <LayersOutlinedIcon />
          </button>
        </Tooltip>
        <Tooltip title="Settings Manager" placement="bottom" arrow={true}>
          <button
            className="slider-pullers-puller"
            onClick={() => setIsSettingsSliderOpen((prevStatus) => !prevStatus)}
          >
            <SettingsOutlinedIcon />
          </button>
        </Tooltip>
        {/* <Tooltip title="Upload Manager" placement="bottom" arrow={true}>
          <button
            className="slider-pullers-puller"
            onClick={() => setIsUploadPopupOpen((prevStatus) => !prevStatus)}
          >
            <BackupOutlinedIcon />
          </button>
        </Tooltip> */}
      </div>
    </div>
  );
};

export default SlidersPullers;
