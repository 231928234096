import React from "react";
import {
  useDevice,
  useExcalidrawAppState,
  useExcalidrawSetAppState,
} from "../App";
import DropdownMenu from "../dropdownMenu/DropdownMenu";

import * as DefaultItems from "./DefaultItems";

import { UserList } from "../UserList";
import { t } from "../../i18n";
import { HamburgerMenuIcon } from "../icons";
import { composeEventHandlers } from "../../utils";

const MainMenu = ({
  children,
  onSelect,
}: {
  children?: React.ReactNode;
  /**
   * Called when any menu item is selected (clicked on).
   */
  onSelect?: (event: Event) => void;
}) => {
  const device = useDevice();
  const appState = useExcalidrawAppState();
  const setAppState = useExcalidrawSetAppState();
  const infoPopup = document.getElementsByClassName(
    "project-drawing-info-container",
  )[0] as HTMLElement;
  const pagesPuller = document.getElementsByClassName(
    "order-drawer-puller_box",
  )[0] as HTMLElement;
  const infoPopupZIndexHandler = () => {
    infoPopup?.style.setProperty("z-index", `${appState.openMenu ? 4 : 3}`);
    pagesPuller?.style.setProperty("z-index", `${appState.openMenu ? 4 : 3}`);
  };
  const onClickOutside = device.isMobile
    ? undefined
    : () => {
        infoPopupZIndexHandler();
        setAppState({ openMenu: null });
      };

  return (
    <DropdownMenu open={appState.openMenu === "canvas"}>
      <DropdownMenu.Trigger
        onToggle={() => {
          infoPopupZIndexHandler();
          setAppState({
            openMenu: appState.openMenu === "canvas" ? null : "canvas",
          });
        }}
      >
        {HamburgerMenuIcon}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        onClickOutside={onClickOutside}
        onSelect={composeEventHandlers(onSelect, () => {
          setAppState({ openMenu: null });
          infoPopupZIndexHandler();
        })}
      >
        {children}
        {device.isMobile && appState.collaborators.size > 0 && (
          <fieldset className="UserList-Wrapper">
            <UserList mobile={true} collaborators={appState.collaborators} />
          </fieldset>
        )}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};

MainMenu.Trigger = DropdownMenu.Trigger;
MainMenu.Item = DropdownMenu.Item;
MainMenu.ItemLink = DropdownMenu.ItemLink;
MainMenu.ItemCustom = DropdownMenu.ItemCustom;
MainMenu.Group = DropdownMenu.Group;
MainMenu.Separator = DropdownMenu.Separator;
MainMenu.DefaultItems = DefaultItems;

export default MainMenu;

MainMenu.displayName = "Menu";
