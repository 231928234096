import React, { useEffect, useRef, useState } from "react";
import { Resizable } from "react-resizable";
import { atom, useAtom } from "jotai";

import {
  Badge,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SwipeableDrawer,
  Tooltip,
} from "@mui/material";
import LayersIcon from "@mui/icons-material/Layers";
import QueueIcon from "@mui/icons-material/Queue";
import KeyboardDoubleArrowUpOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowUpOutlined";

import CloseButton from "./CloseButton";
import AreasContainer from "./AreasContainer";
import FloatingSaveDiscard from "./FloatingSaveDiscard";
import DeleteButton from "./DeleteButton";
import CustomLoader from "./CustomLoader";

import {
  currentDrawingAtom,
  excalidrawApiAtom,
  isActivePolygonToolAtom,
  isSelectingPartAreaAtom,
} from "../store/variables";
import { useAreaMethods } from "../hooks/useAreaMethods";
import { useCommonFunctionsHook } from "../hooks/useCommonFunctionsHook";

import { newElementWith } from "../ExcalidrawAPI/element/mutateElement";
import ConfirmDialog from "../ExcalidrawAPI/components/ConfirmDialog";
import { CheckboxItem } from "../ExcalidrawAPI/components/CheckboxItem";

import { DELETED_PROPS, PULLER_MARGIN } from "../helpers/constants";
import { getFromLocalStorage, setToLocalStorage } from "../helpers/common";
import { deleteDevicesByBatch } from "../helpers/devices";
import { deleteAreasByBatch } from "../helpers/areas";

import "../scss/AreasSlider.scss";

export const isStartedSelectingAreaAtom = atom(false);
export const recentAddedAreaAtom = atom({});

const AreasSlider = ({ areas, isOpen, setIsOpen }) => {
  //Constants
  const defaultValues = getFromLocalStorage("appCustomData");
  const DEFAULT_VALUES = {
    NEW_AREAS: defaultValues.newAreas || [],
  };

  //Custom Hooks
  const { doAfterLoadingScene, triggerToast } = useCommonFunctionsHook();
  const {
    getAreaToBeUploaded,
    calculateArea,
    lockSpecificElement,
    convertToRealDim,
  } = useAreaMethods();

  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [currentDrawing] = useAtom(currentDrawingAtom);
  const [recentAddedArea, setRecentAddedArea] = useAtom(recentAddedAreaAtom);
  const [, setIsSelectingPartArea] = useAtom(isSelectingPartAreaAtom);
  const [isStartedSelectingArea, setIsStartedSelectingArea] = useAtom(
    isStartedSelectingAreaAtom,
  );
  const [, setIsActivePolygonTool] = useAtom(isActivePolygonToolAtom);

  //State
  const [currentViewWidth, setCurrentViewWidth] = useState(window.innerWidth);
  const [drawerWidth, setDrawerWidth] = useState(
    window.innerWidth <= 422 ? window.innerWidth - PULLER_MARGIN : 450,
  );
  const [newAreas, setNewAreas] = useState(DEFAULT_VALUES.NEW_AREAS);
  const [selectingTool, setSelectingTool] = useState("polygon");
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [isFloatingSaveDiscardShown, setIsFloatingSaveDiscardShown] =
    useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [customLoaderShown, setCustomLoaderShown] = useState(false);
  const [isClosedBehavior, setIsClosedBehavior] = useState(true);
  const [expandedArea, setExpandedArea] = useState(null);
  const [isScrollBtnVisible, setIsScrollBtnVisible] = useState(false);

  //Refs
  const areasSliderRef = useRef(null);
  const areasSliderContentRef = useRef(null);
  const scrollToTopBtnRef = useRef(null);

  //Constants
  const container =
    areasSliderRef !== null ? () => areasSliderRef.current : null;

  //Effects
  useEffect(() => {
    if (isOpen === false) {
      setTimeout(() => {
        setIsClosedBehavior(true);
      }, 400);
    } else {
      setIsClosedBehavior(false);
    }
  }, [isOpen]);

  useEffect(() => {
    doAfterLoadingScene(() => {
      const areasSliderDialog = document.querySelector(
        ".areas-slider-dialog .MuiDrawer-paper",
      );
      if (!areasSliderDialog) {
        return;
      }
      const toggleVisibility = () => {
        if (areasSliderDialog.scrollTop >= 200) {
          setIsScrollBtnVisible(true);
        } else {
          setIsScrollBtnVisible(false);
        }
      };

      areasSliderDialog.addEventListener("scroll", toggleVisibility);
      return () => {
        areasSliderDialog.removeEventListener("scroll", toggleVisibility);
      };
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setCurrentViewWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setToLocalStorage("appCustomData", { newAreas }, { merge: true });
  }, [newAreas]);

  //Functions
  const scrollToTop = () => {
    const areasSliderDialog = document.querySelector(
      ".areas-slider-dialog .MuiDrawer-paper",
    );
    areasSliderDialog.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const addAnimationToScrollBtn = (status) => {
    if (status) {
      scrollToTopBtnRef?.current.classList.add(
        "area-slider-scroll-btn_animated",
      );
    } else {
      scrollToTopBtnRef?.current.classList.remove(
        "area-slider-scroll-btn_animated",
      );
    }
  };

  const expandedAreaHandler = (panel) => {
    setExpandedArea(expandedArea === panel ? false : panel);
  };

  const getLastAreaEl = () => {
    const allSceneElements = excalidrawAPI.getSceneElements();
    return allSceneElements[allSceneElements.length - 1];
  };

  const deleteAreasAndTheirAssignedElementsFromScene = () => {
    const allSceneElements = excalidrawAPI.getSceneElements();
    const assignedDevices = allSceneElements.filter((d) =>
      selectedAreas.includes(d.customData?.areaId),
    );
    const devicesSceneIds = assignedDevices.map((d) => d.id);

    excalidrawAPI.updateScene({
      elements: allSceneElements.map((e) => {
        if (
          selectedAreas.includes(e.id) ||
          selectedAreas.includes(e.containerId) ||
          devicesSceneIds.includes(e.id)
        ) {
          return newElementWith(e, { ...DELETED_PROPS });
        }
        return e;
      }),
    });
  };

  const deleteSelectedAreasHandler = async () => {
    setCustomLoaderShown(true);
    const allSceneElements = excalidrawAPI.getSceneElements();
    const assignedDevices = allSceneElements.filter((el) =>
      selectedAreas.includes(el.customData?.areaId),
    );
    const devicesDocsIds = assignedDevices.map((d) => d.customData?.docId);
    const alreadyUploadedAreasAndSelected = selectedAreas.filter(
      (item) => !newAreas.map((a) => a.id).includes(item),
    );

    await deleteAreasByBatch(alreadyUploadedAreasAndSelected);
    await deleteDevicesByBatch(devicesDocsIds);

    setNewAreas((prevAreas) =>
      prevAreas.filter((a) => !selectedAreas.includes(a.id)),
    );
    setSelectedAreas([]);

    deleteAreasAndTheirAssignedElementsFromScene();
    setCustomLoaderShown(false);
  };

  const endPolygonTool = () => {
    setIsActivePolygonTool(false);
    document
      .querySelector(".ToolIcon__icon.regular-button")
      ?.classList.remove("regular-button");
  };

  const discardLastDrawnArea = () => {
    if (isStartedSelectingArea) {
      const allSceneElements = excalidrawAPI.getSceneElements();
      excalidrawAPI.updateScene({
        elements: allSceneElements.map((el) => {
          if (el.id === recentAddedArea.id) {
            return newElementWith(el, DELETED_PROPS);
          }
          return el;
        }),
        appState: { selectedElementIds: {} },
      });
    }
    endPolygonTool();
    setIsOpen(true);
    setIsSelectingPartArea(false);
    setIsStartedSelectingArea(false);
    setIsFloatingSaveDiscardShown(false);
    excalidrawAPI.setActiveTool({ type: "selection" });
  };

  const startSelectingPartArea = (isStart) => {
    //1. Show ✓ or ❌ buttons and just render the floor drawing.
    setIsFloatingSaveDiscardShown(isStart);

    //2. Close the confirm dialog of the selecting tool.
    setIsConfirmDialogOpen(false);

    //2. Activate "selecting part area" mode.
    setIsSelectingPartArea(isStart);

    //3. Close "Areas Manager".
    setIsOpen(!isStart);

    //4. Click ✓ or ❌ to save or discard the selected part area.
  };

  const updateAreaField = () => {
    const selectedAreaId =
      getFromLocalStorage("appCustomData")?.selectedPartArea;
    const newAreaEl = excalidrawAPI
      .getSceneElements()
      .find((el) => el.id === selectedAreaId);

    const areaProps = {
      type: newAreaEl.type,
      ...(newAreaEl.type === "line"
        ? { coordinates: newAreaEl.points }
        : { width: newAreaEl.width, height: newAreaEl.height }),
    };

    let scaleToUse = currentDrawing.pixelsPerUnit;
    if (currentDrawing.correspondingUnit === "inch") {
      scaleToUse *= 12;
    }
    setNewAreas((prevAreas) => {
      const updatedNewAreas = prevAreas.map((el) => {
        if (el.id === selectedAreaId) {
          return {
            ...el,
            width: { value: convertToRealDim(newAreaEl.width), unit: "ft" },
            height: { value: convertToRealDim(newAreaEl.height), unit: "ft" },
            area: calculateArea(areaProps),
          };
        }
        return el;
      });
      return updatedNewAreas;
    });
    lockSpecificElement(selectedAreaId);
    setIsOpen(true);
    setIsSelectingPartArea(false);
    setIsFloatingSaveDiscardShown(false);
    excalidrawAPI.updateScene({
      appState: { selectedElementIds: {} },
    });
  };

  //To add the new drawn area to the areas manager.
  const addAreaField = async (areaId) => {
    if (isFloatingSaveDiscardShown === "areaField") {
      updateAreaField();
      return;
    }
    //1. Get the recently added element
    const lastAreaEl = getLastAreaEl();
    const areaToBeUploaded = getAreaToBeUploaded(lastAreaEl);

    try {
      //2. Add the new area to the state
      setNewAreas((prevAreas) => {
        const updatedNewAreas = [...prevAreas, areaToBeUploaded];
        return updatedNewAreas;
      });
      expandedAreaHandler(areaToBeUploaded.id);
      setIsOpen(true);
      excalidrawAPI.setActiveTool({ type: "selection" });
      excalidrawAPI.updateScene({
        appState: { selectedElementIds: {} },
      });
      setRecentAddedArea(areaToBeUploaded);
      setIsSelectingPartArea(false);
      setIsStartedSelectingArea(false);
      setIsFloatingSaveDiscardShown(false);
    } catch (error) {
      discardLastDrawnArea();
      triggerToast("Couldn't add part area!", "error");
      excalidrawAPI.setActiveTool({ type: "selection" });
      setIsSelectingPartArea(false);
      setIsFloatingSaveDiscardShown(false);
      console.error(error);
    }
  };

  const confirmDialogConfirmHandler = () => {
    startSelectingPartArea(true);
    let activeToolType = null;
    if (selectingTool === "polygon") {
      const polygonBtn = document.querySelector('[title="Polygon — N or 1"]');
      polygonBtn.click();
      activeToolType = "line";
    } else {
      activeToolType = selectingTool;
    }
    excalidrawAPI?.setActiveTool({
      type: activeToolType,
    });
  };

  const confirmDialogCancelHandler = () => {
    startSelectingPartArea(false);
  };

  //Render
  return (
    <main ref={areasSliderRef} style={{ zIndex: isClosedBehavior ? 2 : 5 }}>
      <Resizable
        className="areas-slider-dialog"
        height={Infinity}
        width={drawerWidth}
        onResize={(_, { size }) => {
          const iconEl = document.querySelector(".areas-slider-icon");
          iconEl.classList.remove("areas-slider-puller_box_open");
          iconEl.classList.remove("areas-slider-puller_box_close");
          setDrawerWidth(size.width);
        }}
        minConstraints={[
          currentViewWidth <= 422 ? currentViewWidth - PULLER_MARGIN : 450,
          Infinity,
        ]}
        resizeHandles={["e"]}
      >
        <SwipeableDrawer
          container={container}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}
          swipeAreaWidth={0}
          transitionDuration={{ enter: 500, exit: 500 }}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "transparent",
            },
          }}
        >
          <section className="areas-slider-dialog_header">
            <Badge
              badgeContent={selectedAreas.length}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "tomato",
                  minWidth: "15px",
                  height: "17px",
                  width: "17px",
                  fontSize: "10px",
                  border: "1px solid #fff",
                  color: "#fff",
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <DeleteButton
                title={"Delete selected drawings"}
                disabled={selectedAreas.length === 0}
                onDelete={deleteSelectedAreasHandler}
              />
            </Badge>
            <h3>Areas Manager</h3>
            <CloseButton
              onClick={() => setIsOpen(false)}
              classes={["areas-slider-dialog_close_btn"]}
              style={{
                backgroundColor: "transparent",
                color: "#444",
                borderColor: "transparent",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: "#ebebeb",
                  borderColor: "#ebebeb",
                },
              }}
            />
          </section>
          <div className="areas-slider-dialog_divider"></div>

          <div className="selected-checkbox_container">
            <CheckboxItem
              title="Select All"
              checked={
                [...(areas || []), ...newAreas].length ===
                  selectedAreas.length && selectedAreas.length > 0
              }
              className={`order-drawer_select_checkbox${
                !areas?.length && !newAreas?.length ? " disabled_checkbox" : ""
              }`}
              onChange={(status) => {
                if (status) {
                  setSelectedAreas(
                    [...(areas || []), ...newAreas].map((el) => el.id),
                  );
                } else {
                  setSelectedAreas([]);
                }
              }}
            />
            <p
              className="selected-keyword"
              style={{ opacity: !selectedAreas.length ? 0.7 : 1 }}
            >
              Selected ({selectedAreas.length})
            </p>
          </div>
          <div
            className="areas-slider_content"
            ref={areasSliderContentRef}
            style={{ width: drawerWidth }}
          >
            <AreasContainer
              classes={["area-slider-area-container"]}
              areas={[...(areas || []), ...newAreas]}
              expandedArea={expandedArea}
              selectedAreas={selectedAreas}
              expandedAreaHandler={expandedAreaHandler}
              setSelectedAreas={setSelectedAreas}
              setNewAreas={setNewAreas}
              setSaveDiscardShown={setIsFloatingSaveDiscardShown}
              setIsParentOpen={setIsOpen}
              setCustomLoaderShown={setCustomLoaderShown}
            />

            <Tooltip title="Scroll to top" placement="top" arrow={true}>
              <button
                onMouseEnter={() => {
                  addAnimationToScrollBtn(true);
                }}
                onMouseLeave={() => {
                  addAnimationToScrollBtn(false);
                }}
                ref={scrollToTopBtnRef}
                onClick={scrollToTop}
                className={`area-slider-scroll-btn ${
                  isScrollBtnVisible ? "area-slider-scroll-btn_visible" : ""
                }`}
              >
                <KeyboardDoubleArrowUpOutlinedIcon />
              </button>
            </Tooltip>

            <div className="areas-slider-add-area-button_container">
              <Button
                id="areas-slider-add-area-button"
                className="areas-slider-add-area-button"
                variant="outlined"
                fullWidth={true}
                onClick={() => setIsConfirmDialogOpen(true)}
              >
                <p className="areas-slider-add-area-button_start">
                  Add new area
                </p>
                <p className="areas-slider-add-area-button_end">
                  <QueueIcon />
                </p>
              </Button>
            </div>
          </div>
          {customLoaderShown && (
            <div className="order-drawer_loader_container">
              <CustomLoader status="saving" />
            </div>
          )}
        </SwipeableDrawer>
      </Resizable>

      <div
        style={{
          transform: `translate(${isOpen ? `${drawerWidth}px` : "0px"}, -50%)`,
        }}
        className={`areas-slider-icon ${
          isOpen
            ? "areas-slider-puller_box_open"
            : "areas-slider-puller_box_close"
        }`}
      >
        <LayersIcon />
      </div>

      <FloatingSaveDiscard
        classes={["area-slider-floating-save-discard"]}
        onSave={addAreaField}
        onDiscard={discardLastDrawnArea}
        isShown={isFloatingSaveDiscardShown}
      />

      {isConfirmDialogOpen && (
        <ConfirmDialog
          title="Pick a tool"
          buttonsPlacement="center"
          onConfirm={confirmDialogConfirmHandler}
          onCancel={confirmDialogCancelHandler}
        >
          <p className="clear-canvas__content"></p>

          <FormControl
            id="area-slider-selecting-tools-dropdown"
            fullWidth={true}
          >
            <InputLabel id="area-slider-dropdown-label">Tool</InputLabel>
            <Select
              value={selectingTool}
              onChange={(e) => setSelectingTool(e.target.value)}
              fullWidth={true}
              label="Tool"
              sx={{ textTransform: "capitalize" }}
            >
              {["ellipse", "polygon", "rectangle"].map((t) => (
                <MenuItem
                  key={t}
                  value={t}
                  id="area-slider-selecting-tool"
                  className={`area-slider-selecting-tool_${t}`}
                >
                  {t}
                  <img
                    src={`https://outercloud-icons.s3.us-west-2.amazonaws.com/${t}.svg`}
                    alt={t}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ConfirmDialog>
      )}
    </main>
  );
};

export default AreasSlider;
