import { newElementWith } from "../ExcalidrawAPI/element/mutateElement";
import { redrawTextBoundingBox } from "../ExcalidrawAPI/element";
import { randomId, randomInteger } from "../ExcalidrawAPI/random";
import { getUpdatedTimestamp } from "../ExcalidrawAPI/utils";

import { preventTextWrapping } from "./commonFrameFunctions";

import {
  GRIDE_RULER_HEIGHT,
  GRIDE_RULER_WIDTH,
  GRID_RULER_SHORT_DIM,
  MARGIN_UNIT,
  PIXELS_PER_FEET,
} from "./constants";

const propsInCommon = {
  angle: 0,
  backgroundColor: "transparent",
  boundElements: [],
  customData: { isImmutable: true },
  fillStyle: "solid",
  isDeleted: false,
  link: null,
  locked: true,
  opacity: 100,
  roughness: 0,
  roundness: null,
  strokeColor: "#000000",
  strokeStyle: "solid",
  strokeWidth: 1,
  seed: randomInteger(),
  version: randomInteger(),
  versionNonce: randomInteger(),
  updated: getUpdatedTimestamp(),
};

const rectangleElement = {
  ...propsInCommon,
  id: randomId(),
  locked: true,
  type: "rectangle",
};

const lineElement = {
  ...propsInCommon,
  id: randomId(),
  lastCommittedPoint: null,
  roundness: { type: 2 },
  type: "line",
};

const textElement = {
  ...propsInCommon,
  baseline: 0,
  fontFamily: "Arial",
  id: randomId(),
  type: "text",
  textAlign: "center",
  verticalAlign: "middle",
};

export const drawGridRuler = ({ groupId }) => {
  const cellLongDimension = 10 * PIXELS_PER_FEET;
  const start = { x: 2 * MARGIN_UNIT, y: 2 * MARGIN_UNIT };
  const { dimension: gridCellHeight, count: numberOfCells } =
    getExactDimensionAndCountOfCells(GRIDE_RULER_HEIGHT, cellLongDimension);
  //NOTE - We subtracted the short dimension of the vertical ruler from the horizontal one to prevent overlapping
  const { dimension: gridCellWidth, count: numberOfHorizontalCells } =
    getExactDimensionAndCountOfCells(GRIDE_RULER_WIDTH, cellLongDimension);

  const verticalGrid = drawVerticalGridRuler({
    height: gridCellHeight,
    width: GRID_RULER_SHORT_DIM,
    start,
    numberOfCells,
    groupId,
  });
  const horizontalGrid = drawHorizontalGridRuler({
    height: GRID_RULER_SHORT_DIM,
    width: gridCellWidth,
    start: {
      x: start.x + GRID_RULER_SHORT_DIM,
      y: start.y,
    },
    numberOfCells: numberOfHorizontalCells,
    totalWidth: GRIDE_RULER_WIDTH,
    groupId,
  });
  const verticalLine = drawVerticalLine({
    height: GRIDE_RULER_HEIGHT,
    start: {
      x: start.x + GRIDE_RULER_WIDTH + GRID_RULER_SHORT_DIM,
      y: start.y,
    },
    groupId,
  });

  return [...verticalGrid, ...horizontalGrid, verticalLine];
};

const getExactDimensionAndCountOfCells = (totalDimension, initialDimension) => {
  if (totalDimension <= initialDimension) {
    return { dimension: totalDimension, count: 1 };
  }
  const count = Math.floor(totalDimension / initialDimension);
  const add = (totalDimension - count * initialDimension) / count;
  return { dimension: initialDimension + add, count };
};

//NOTE - Don't forget to add an id for every generated rectangle
const drawVerticalGridRuler = ({
  height,
  width,
  start,
  numberOfCells,
  groupId,
}) => {
  const verticalRects = [];
  for (let i = numberOfCells - 1; i >= 0; i--) {
    const [rectId, textId] = [randomId(), randomId()];
    const contentText = newElementWith(textElement, {
      id: textId,
      containerId: rectId,
      fontSize: i >= 10 ? 13 : 15,
      text: `${i}`,
      originalText: `${i}`,
      height,
    });

    const contentRect = newElementWith(rectangleElement, {
      id: rectId,
      boundElements: [{ type: "text", id: textId }],
      groupIds: [groupId],
      height,
      width,
      x: start.x,
      y: start.y + (verticalRects.length / 2) * height,
    });

    const {
      textElement: adjustedContentText,
      rectElement: adjustedContentRect,
    } = preventTextWrapping(contentText, contentRect, 0.1);

    redrawTextBoundingBox(adjustedContentText, adjustedContentRect);

    verticalRects.push(adjustedContentText, adjustedContentRect);
  }

  return verticalRects;
};

const drawHorizontalGridRuler = ({
  height,
  width,
  start,
  numberOfCells,
  totalWidth,
  groupId,
}) => {
  const capitalLetters = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode("A".charCodeAt(0) + i),
  );
  const horizontalRects = [];

  for (let i = 0; i < numberOfCells; i++) {
    const [rectId, textId] = [randomId(), randomId()];
    const contentText = newElementWith(textElement, {
      id: textId,
      containerId: rectId,
      fontSize: 15,
      text: `${capitalLetters[i]}`,
      originalText: `${capitalLetters[i]}`,
      y: start.y,
    });

    const contentRect = newElementWith(rectangleElement, {
      id: rectId,
      boundElements: [{ type: "text", id: textId }],
      groupIds: [groupId],
      height,
      width,
      x: start.x + (horizontalRects.length * width) / 2,
      y: start.y,
      strokeColor: "transparent",
    });

    const {
      textElement: adjustedContentText,
      rectElement: adjustedContentRect,
    } = preventTextWrapping(contentText, contentRect, 0.1);

    redrawTextBoundingBox(adjustedContentText, adjustedContentRect);
    horizontalRects.push(adjustedContentText, adjustedContentRect);
  }

  for (let i = 0; i < 2; i++) {
    horizontalRects.push(
      drawHorizontalLines({
        start: { x: start.x, y: start.y + i * height },
        width: totalWidth,
        groupId,
      }),
    );
  }

  for (let i = 0; i < numberOfCells - 1; i++) {
    horizontalRects.push(
      drawVerticalLine({
        start: { x: start.x + (1 + i) * width, y: start.y },
        height,
        groupId,
      }),
    );
  }
  return horizontalRects;
};

const drawVerticalLine = ({ start, height, groupId }) => {
  return newElementWith(lineElement, {
    id: randomId(),
    groupIds: [groupId],
    x: start.x,
    y: start.y,
    height,
    points: [
      [0, 0],
      [0, height],
    ],
    width: 0,
  });
};

const drawHorizontalLines = ({ start, width, groupId }) => {
  return newElementWith(lineElement, {
    id: randomId(),
    groupIds: [groupId],
    x: start.x,
    y: start.y,
    height: 0,
    points: [
      [0, 0],
      [width, 0],
    ],
    width: 0,
  });
};
