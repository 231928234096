import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MENU_PROPS } from "../helpers/constants";
import "../scss/TextFieldWithUnit.scss";

const TextFieldWithUnit = ({
  textValue,
  onChange,
  disabled,
  width,
  mainLabel,
  unitLabel,
  inputType = "text",
  inputSize = "medium",
  unitOptions,
  selectedUnit,
  onUnitChange,
}) => {
  //State
  const [currentUnit, setCurrentUnit] = useState(selectedUnit);

  //Render
  return (
    <div id="textfield-with-unit-main" style={{ width }}>
      <TextField
        id={`textfield-with-unit-textfield`}
        label={mainLabel}
        variant="outlined"
        value={textValue}
        fullWidth={true}
        type={inputType}
        size={inputSize}
        disabled={disabled}
        autoComplete="off"
        sx={{ border: "none" }}
        onChange={(e) => {
          onChange && onChange(e);
        }}
      />

      <FormControl
        id="textfield-with-unit-dropdown-form-control"
        fullWidth={true}
      >
        <InputLabel id="textfield-with-unit-dropdown-label">
          {unitLabel}
        </InputLabel>
        <Select
          labelId="textfield-with-unit-dropdown-label"
          id="textfield-with-unit-dropdown"
          value={currentUnit}
          label={unitLabel}
          name={mainLabel.toLowerCase()}
          MenuProps={MENU_PROPS}
          onChange={(e) => {
            setCurrentUnit(e.target.value);
            onUnitChange && onUnitChange(e);
          }}
        >
          {unitOptions.map((o) => (
            <MenuItem key={o} value={o}>
              {o}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TextFieldWithUnit;
