/* Copyright (C) EZ Fire, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Hamza Younes <hamza@ezfirecontrols.com>, Oct 4th, 2023
 */

import firebase from "firebase/app";
import "firebase/functions";

export const getAllAreas = async (onSuccess, onError) => {
  try {
    return firebase
      .firestore()
      .collection("areas")
      .where("active", "==", true)
      .onSnapshot(
        (snap) => {
          if (snap) {
            const allAreas = snap.docs.map((el) => el.data());
            onSuccess && onSuccess(allAreas);
          }
        },
        (error) => {
          onError && onError();
          console.error(error);
        },
      );
  } catch (error) {
    console.error(error);
  }
};

export const createOrUpdateArea = async (area, update = null, onSuccess) => {
  const db = firebase.firestore();

  if (update) {
    Object.assign(area, {
      modifiedAt: firebase.firestore.Timestamp.now(),
      modifiedBy: firebase.auth().currentUser.uid,
    });

    await db.collection("areas").doc(area.id).set(area, { merge: true });

    onSuccess && onSuccess();
    return area;
  }

  Object.assign(area, {
    active: true,
    id: area.id,
    createdAt: firebase.firestore.Timestamp.now(),
    createdBy: firebase.auth().currentUser.uid,
    isNew: false,
    points:
      area.points?.map((pointArray) => {
        const [x, y] = pointArray;
        return { x, y };
      }) || [],
  });

  await db.collection("areas").doc(area.id).set(area);
  onSuccess && onSuccess();
  return area;
};

export const deleteArea = async (area) => {
  await createOrUpdateArea({ ...area, active: false }, true);
};

export const deleteAreasByBatch = (selectedAreasIds) => {
  const db = firebase.firestore();

  const batch = db.batch();
  const collectionRef = db.collection("areas");
  selectedAreasIds.forEach((id) => {
    const documentRef = collectionRef.doc(id);
    batch.update(documentRef, {
      active: false,
      modifiedAt: firebase.firestore.Timestamp.now(),
      modifiedBy: firebase.auth().currentUser.uid,
    });
  });

  // Commit the batch write operation & Return a promise to be waited.
  return batch.commit();
};
