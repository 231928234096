import { useEffect } from "react";
import { useAtom } from "jotai";

import {
  isPlacingOnMapAtom,
  isReadyToDesignAtom,
  activeConfirmDialogAtom,
  isUploadPopupOpenAtom,
  isEditModeAtom,
} from "../store/UI";
import { setToLocalStorage } from "../helpers/common";

export default function useReactiveUIVars() {
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useAtom(
    isUploadPopupOpenAtom,
  );
  const [isPlacingOnMap, setIsPlacingOnMap] = useAtom(isPlacingOnMapAtom);
  const [isEditMode, setIsEditMode] = useAtom(isEditModeAtom);
  const [isReadyToDesign, setIsReadyToDesign] = useAtom(isReadyToDesignAtom);
  const [activeConfirmDialog, setActiveConfirmDialog] = useAtom(
    activeConfirmDialogAtom,
  );

  useEffect(() => {
    if (!location.hash) {
      setToLocalStorage(
        "appCustomData",
        { isUploadPopupOpen },
        { merge: true },
      );
    }
  }, [isUploadPopupOpen]);

  useEffect(() => {
    setToLocalStorage("appCustomData", { isPlacingOnMap }, { merge: true });
  }, [isPlacingOnMap]);

  useEffect(() => {
    setToLocalStorage("appCustomData", { isReadyToDesign }, { merge: true });
  }, [isReadyToDesign]);

  return {
    isUploadPopupOpen,
    activeConfirmDialog,
    isEditMode,
    setIsEditMode,
    isPlacingOnMap,
    setIsPlacingOnMap,
    isReadyToDesign,
    setIsReadyToDesign,
    setActiveConfirmDialog,
    setIsUploadPopupOpen,
  };
}
