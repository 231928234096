import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import { useAtom } from "jotai";
import domtoimage from "dom-to-image";

import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  Polygon,
} from "react-leaflet";
import L from "leaflet";

import _ from "lodash";
import {
  currentDrawingAtom,
  currentProjectAtom,
  excalidrawApiAtom,
} from "../store/variables";
import {
  activeConfirmDialogAtom,
  activeNorthHeadingIndicatorAngleAtom,
  isActiveNorthHeadingIndicatorAtom,
} from "../store/UI";

import { useExcalidrawSetAppState } from "../ExcalidrawAPI/components/App";
import Spinner from "../ExcalidrawAPI/components/Spinner";

import FloatMapOptions from "./FloatMapOptions";
import ScreenshotButton from "./ScreenshotButton";

import usePlaceOnMapHandler from "../hooks/usePlaceOnMapHandler";
import useReactiveUIVars from "../hooks/useReactiveUIVars";

import MapPrint from "../helpers/MapPrint";
import { updateProjectById } from "../helpers/projects";

import { saveMapInFireStorage } from "../services/firebaseStorage";

import north_img from "../assets/icon_north.svg";

import "leaflet/dist/leaflet.css";
import "../scss/Map.scss";

const Map = ({ location, title, polygonCoordinates }) => {
  //Custom Hooks
  const { closePlacingOnMap } = usePlaceOnMapHandler();
  const { isPlacingOnMap } = useReactiveUIVars();

  //Excalidraw setState
  const setExcalidrawState = useExcalidrawSetAppState();

  //Atom
  const [currentProject, setCurrentProject] = useAtom(currentProjectAtom);
  const [currentDrawing] = useAtom(currentDrawingAtom);
  const [, setActiveConfirmDialog] = useAtom(activeConfirmDialogAtom);
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [isActiveNorthIndicator, setIsActiveNorthIndicator] = useAtom(
    isActiveNorthHeadingIndicatorAtom,
  );
  const [activeNorthIndicatorAngle] = useAtom(
    activeNorthHeadingIndicatorAngleAtom,
  );

  //Ref
  const drawingImgRef = useRef(null);

  //State
  const [currentView, setCurrentView] = useState("drawing");
  const [isCapturing, setIsCapturing] = useState(false);
  const [isFlashing, setIsFlashing] = useState(false);
  const [isLoadingShot, setIsLoadingShot] = useState(false);
  const [screenshotLink, setScreenshotLink] = useState("");
  const [position, setPosition] = useState(location);

  //Constants
  const customIcon = new L.Icon({
    iconUrl: require("../assets/map-marker.png"),
    iconSize: [33, 33],
  });

  //Refs
  const mapContainerRef = useRef();
  const markerRef = useRef(null);

  //Callbacks
  const handleClick = useCallback(() => {
    setIsCapturing(true);
    setTimeout(() => {
      setIsCapturing(false);
    }, 2000);
  }, []);

  const toggleViewHandler = useCallback(
    (view) => {
      if (view === "map") {
        if (!position.lat || !position.lng) {
          setActiveConfirmDialog("invalidCoords");
        }
        setExcalidrawState({ selectedElementIds: {} });
      } else {
        drawingImgRef.current = excalidrawAPI
          ?.getSceneElements()
          .find((el) => el.customData?.imageType === "floor-drawing");
        const drawingIdAsExcaliImage = excalidrawAPI
          ?.getSceneElements()
          .find((el) => el.customData?.imageType === "floor-drawing")?.id;
        setExcalidrawState({
          selectedElementIds: { [drawingIdAsExcaliImage]: true },
        });
      }
    },
    [excalidrawAPI, setExcalidrawState, position, setActiveConfirmDialog],
  );

  const captureMapScreenshot = useCallback(
    async (options) => {
      let downloadUrl = "";
      if (!mapContainerRef.current) {
        return;
      }
      setIsCapturing(true);
      let switchedViews = false;
      if (currentView === "drawing") {
        setCurrentView("map");
        switchedViews = true;
      }
      toggleLeafletControlAppearance(true);
      try {
        const { offsetWidth, offsetHeight } =
          mapContainerRef.current._container;
        const imageUrl = await domtoimage.toPng(
          mapContainerRef.current._container,
          {
            width: offsetWidth,
            height: offsetHeight,
          },
        );
        addCaptureEffect();
        downloadUrl = await saveMapInFireStorage(
          imageUrl,
          currentProject.id,
          options,
        );
        switchedViews && setCurrentView("drawing");
        setScreenshotLink(downloadUrl);
      } catch (error) {
        console.error(`${options.errorMessage || ""} ${error}`);
      }
      setIsCapturing(false);
      setIsLoadingShot(false);
      toggleLeafletControlAppearance(false);
      return downloadUrl;
    },
    [currentProject, currentView],
  );

  const updateMapDataInDBHandler = useCallback(async () => {
    let linkToUpdate = screenshotLink || currentProject.mapImage;
    if (!linkToUpdate) {
      linkToUpdate = await captureMapScreenshot({
        errorMessage:
          "There was an error happened while taking and saving map screenshot",
      });
    }
    const isToUpdate =
      currentProject.mapImage !== linkToUpdate ||
      !_.isEqual(position, location);

    isToUpdate &&
      updateProjectById(
        currentProject.id,
        {
          mapImage: linkToUpdate,
          lat: position.lat,
          lng: position.lng,
        },
        {
          errorMessage: "Couldn't update the project with the map image link",
          onSuccess: () => {
            onUpdatingMapSuccessHandler(linkToUpdate, position);
          },
          onError: () => setMapToast("Failed to update project!"),
        },
      );
  }, [screenshotLink, currentProject, captureMapScreenshot, position]);

  const onUpdatingMapSuccessHandler = (linkToUpdate, position) => {
    setMapToast("Updated project successfully!");
    if (!location.hash) {
      setCurrentProject((prevProject) => ({
        ...prevProject,
        mapImage: linkToUpdate,
        lat: position.lat,
        lng: position.lng,
      }));
    }
  };

  const keysHandlerOnPlacingOnMap = useCallback(
    async ({ key }) => {
      if (key === "Escape") {
        isPlacingOnMap && closePlacingOnMap({ discard: true });
      } else if (key === "Enter" && isPlacingOnMap) {
        await updateMapDataInDBHandler();
        closePlacingOnMap({ discard: false });
      }
    },
    [isPlacingOnMap, closePlacingOnMap, updateMapDataInDBHandler],
  );

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    [],
  );

  //Effects
  useEffect(() => {
    if (currentDrawing?.type === "coverSheet") {
      setCurrentView("map");
    }
  }, [currentDrawing]);

  useEffect(() => {
    document.addEventListener("keyup", keysHandlerOnPlacingOnMap);
    return () =>
      document.removeEventListener("keyup", keysHandlerOnPlacingOnMap);
  }, [keysHandlerOnPlacingOnMap]);

  useEffect(() => {
    toggleViewHandler(currentView);
    setIsActiveNorthIndicator(currentView !== "map");
  }, [currentView, toggleViewHandler]);

  //General
  const downloadMapButton = document.querySelector(".A4Landscape");
  if (downloadMapButton) {
    downloadMapButton.addEventListener("click", handleClick);
  }

  //Functions
  const setMapToast = (message) => {
    excalidrawAPI.setToast({
      message,
      duration: 2000,
      closable: true,
      options: {
        position: "topRight",
        status: "success",
      },
    });
  };
  const toggleLeafletControlAppearance = (hidden = false) => {
    const leafletControl = document.querySelector(".leaflet-top");
    leafletControl.style.display = hidden ? "none" : "block";
  };

  const addCaptureEffect = () => {
    setIsFlashing(true);
    setTimeout(() => {
      setIsFlashing(false);
      setTimeout(() => {
        setIsLoadingShot(true);
      }, 500);
    }, 500);
  };

  return (
    <>
      <div
        className="map-component"
        style={{
          zIndex: currentView === "map" ? 2 : "inherit",
          opacity:
            currentView === "map"
              ? currentDrawing?.type === "coverSheet"
                ? 1
                : 0.8
              : 0.3,
        }}
        id="map"
      >
        <div
          className="map_flash_container"
          style={{ display: isFlashing ? "block" : "none" }}
        ></div>
        <div
          style={{ display: isLoadingShot ? "flex" : "none" }}
          className="map_loader_container"
        >
          <div className="map_loader_background"></div>
          <div className="map_loader_spinner">
            <Spinner size="2rem" />
          </div>
          <p className="map_loader_text">Saving Map Screenshot...</p>
        </div>
        <MapContainer
          ref={mapContainerRef}
          center={location}
          zoom={19}
          scrollWheelZoom={true}
          attributionControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom={19}
            maxNativeZoom={19}
          />
          <Polygon color={"red"} positions={polygonCoordinates}>
            <Tooltip>{title}</Tooltip>
          </Polygon>
          <Marker
            ref={markerRef}
            position={position}
            icon={customIcon}
            draggable={true}
            eventHandlers={eventHandlers}
          >
            <Tooltip>Relocate</Tooltip>
          </Marker>

          <MapPrint
            position="topleft"
            sizeModes={["Current", "A4Portrait", "A4Landscape"]}
            hideControlContainer={true}
            title="Export as PNG"
            exportOnly
          />
          <ScreenshotButton
            captureMapScreenshot={captureMapScreenshot}
            hidden={isCapturing}
          />
        </MapContainer>
      </div>
      <FloatMapOptions
        disabled={isLoadingShot}
        setCurrentView={setCurrentView}
        updateMapDataInDBHandler={updateMapDataInDBHandler}
      />
      {isActiveNorthIndicator ? (
        <div className="map-north-indicator">
          <img
            className="map-north-indicator_img"
            style={{
              transform: `rotate(${
                activeNorthIndicatorAngle ||
                (drawingImgRef.current?.angle * 180) / Math.PI
              }deg)`,
            }}
            src={north_img}
            alt="North icon"
          />
        </div>
      ) : null}
    </>
  );
};

export default Map;
