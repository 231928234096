import React, { useEffect, useRef, useState } from "react";
import { Resizable } from "react-resizable";
import { useAtom } from "jotai";

import { PULLER_MARGIN } from "../helpers/constants";
import { globalSettingsAtom } from "../store/variables";

import { Button, SwipeableDrawer, TextField } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

import SettingField from "./SettingField";
import CloseButton from "./CloseButton";

import "../scss/settingsSlider.scss";
import { useCommonFunctionsHook } from "../hooks/useCommonFunctionsHook";
import { setToLocalStorage } from "../helpers/common";

const SettingsSlider = ({ isOpened, setIsOpened }) => {
  //Custom Hooks
  const { triggerToast } = useCommonFunctionsHook();

  //Atom
  const [globalSettings, setGlobalSettings] = useAtom(globalSettingsAtom);

  //State
  const [settingsObject, setSettingsObject] = useState(globalSettings);
  const [currentViewWidth, setCurrentViewWidth] = useState(window.innerWidth);
  const [drawerWidth, setDrawerWidth] = useState(
    window.innerWidth <= 422 ? window.innerWidth - PULLER_MARGIN : 450,
  );
  const [isClosedBehavior, setIsClosedBehavior] = useState(true);
  const [areSettingsChanged, setAreSettingsChanged] = useState(false);

  //Refs
  const settingsSliderRef = useRef(null);
  const settingsSliderContentRef = useRef(null);

  //Constants
  const container =
    settingsSliderRef !== null ? () => settingsSliderRef.current : null;

  //Effects
  useEffect(() => {
    if (isOpened === false) {
      setTimeout(() => {
        setIsClosedBehavior(true);
      }, 400);
    } else {
      setIsClosedBehavior(false);
    }
  }, [isOpened]);

  useEffect(() => {
    const handleResize = () => {
      setCurrentViewWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    checkIfSettingsChanged();
  }, [settingsObject, globalSettings]);

  //Functions
  const checkIfSettingsChanged = () => {
    for (const key in globalSettings) {
      if (globalSettings[key] !== settingsObject[key]) {
        setAreSettingsChanged(true);
        return;
      }
    }
    setAreSettingsChanged(false);
  };

  const measureSystemHandler = (value) => {
    setSettingsObject((prevSettings) => ({
      ...prevSettings,
      measureSystem: value,
    }));
  };

  const numberFormatHandler = (value) => {
    setSettingsObject((prevSettings) => ({
      ...prevSettings,
      numberFormat: value,
    }));
  };

  const decimalPointsHandler = (e, value) => {
    const valueToSet = e?.target.value || value || "";
    if (valueToSet > 0) {
      setSettingsObject((prevSettings) => ({
        ...prevSettings,
        decimalPoints: Number(valueToSet),
      }));
    }
  };

  const applyBtnHandler = () => {
    setGlobalSettings(settingsObject);
    setToLocalStorage(
      "appCustomData",
      { globalSettings: settingsObject },
      { merge: true },
    );
    triggerToast("Changed setting successfully!", "success");
  };

  //Render
  return (
    <main ref={settingsSliderRef} style={{ zIndex: isClosedBehavior ? 2 : 5 }}>
      <Resizable
        className="settings-slider-dialog"
        height={Infinity}
        width={drawerWidth}
        onResize={(_, { size }) => {
          const iconEl = document.querySelector(".settings-slider-icon");
          iconEl.classList.remove("settings-slider-puller_box_open");
          iconEl.classList.remove("settings-slider-puller_box_close");
          setDrawerWidth(size.width);
        }}
        minConstraints={[
          currentViewWidth <= 422 ? currentViewWidth - PULLER_MARGIN : 450,
          Infinity,
        ]}
        resizeHandles={["e"]}
      >
        <SwipeableDrawer
          container={container}
          open={isOpened}
          onClose={() => setIsOpened(false)}
          onOpen={() => setIsOpened(true)}
          swipeAreaWidth={0}
          transitionDuration={{ enter: 500, exit: 500 }}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "transparent",
            },
            "& .MuiDrawer-paper": {
              width: `${drawerWidth}px`,
              overflowX: "hidden",
            },
          }}
        >
          <section className="settings-slider-dialog_header">
            <h3>
              Settings Manager{" "}
              <span>(version {process.env.REACT_APP_APP_VERSION})</span>
            </h3>
            <CloseButton
              onClick={() => setIsOpened(false)}
              classes={["settings-slider-dialog_close_btn"]}
              style={{
                backgroundColor: "transparent",
                color: "#444",
                borderColor: "transparent",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: "#ebebeb",
                  borderColor: "#ebebeb",
                },
              }}
            />
          </section>
          <div className="settings-slider-dialog_divider"></div>
          <div
            className="settings-slider_content"
            ref={settingsSliderContentRef}
            style={{ width: drawerWidth }}
          >
            <SettingField
              value={settingsObject.measureSystem}
              setValue={measureSystemHandler}
              label={"Unit"}
              options={["US Customary", "Metric"]}
            />

            <SettingField
              value={settingsObject.numberFormat}
              setValue={numberFormatHandler}
              label={"Numbers Format"}
              placeHolders={["eg. xx,xxx.xxxx", "eg. xxxxx.xxxx"]}
              options={["Comma", "None"]}
            />

            <SettingField label={"Decimal Points"}>
              <div className="settings-slider-decimal-points-text-field_container">
                <span>Round numbers to </span>
                <TextField
                  id="settings-slider-decimal-points-text-field"
                  value={settingsObject.decimalPoints}
                  type="number"
                  size="small"
                  sx={{ margin: "0 5px" }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      decimalPointsHandler(null, 2);
                    }
                  }}
                  onChange={decimalPointsHandler}
                />
                <span>decimal points</span>
              </div>
            </SettingField>
          </div>

          <section className="settings-slider-buttons-section">
            <Button
              variant="outlined"
              color="error"
              sx={{ marginRight: "16.5px" }}
              onClick={() => {
                console.log("Discarded");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              className="settings-slider-submit-btn"
              disabled={!areSettingsChanged}
              sx={{ marginRight: "16.5px" }}
              onClick={applyBtnHandler}
            >
              Apply
            </Button>
          </section>
        </SwipeableDrawer>
      </Resizable>

      <div
        style={{
          transform: `translate(${
            isOpened ? `${drawerWidth}px` : "0px"
          }, -50%)`,
        }}
        className={`settings-slider-icon ${
          isOpened
            ? "settings-slider-puller_box_open"
            : "settings-slider-puller_box_close"
        }`}
      >
        <SettingsIcon />
      </div>
    </main>
  );
};

export default SettingsSlider;
