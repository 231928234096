import _ from "lodash";
import { useAtom } from "jotai";

import { excalidrawApiAtom, iconsAndCoversAtom } from "../store/variables";
import { newElementWith } from "../ExcalidrawAPI/element/mutateElement";
import { redrawTextBoundingBox } from "../ExcalidrawAPI/element";
import { measureText } from "../ExcalidrawAPI/element/textElement";
import { getFontString, getUpdatedTimestamp } from "../ExcalidrawAPI/utils";
import {
  CONTENT_HEIGHT,
  HORIZONTAL_RULER_MARGIN_BOTTOM,
  MARGIN_UNIT,
  HORIZONTAL_RULER_MARGIN_X,
  DELETED_PROPS,
} from "../helpers/constants";
import { convertToFeet, generateId } from "../helpers/common";
import { randomInteger } from "../ExcalidrawAPI/random";
import { isSyncingAtom } from "../store/UI";

const propsInCommon = {
  angle: 0,
  customData: { isImmutable: true, type: "ruler" },
  locked: true,
  roughness: 0,
  roundness: null,
  seed: randomInteger(),
  version: randomInteger(),
  versionNonce: randomInteger(),
  updated: getUpdatedTimestamp(),
};

const basicRectElement = {
  type: "rectangle",
  strokeColor: "transparent",
  fillStyle: "solid",
  ...propsInCommon,
};

const basicTextElement = {
  type: "text",
  baseline: 15,
  fontFamily: 2,
  strokeColor: "#000000",
  textAlign: "center",
  verticalAlign: "top",
  ...propsInCommon,
};

export const useRulerDrawer = () => {
  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [iconsAndCovers] = useAtom(iconsAndCoversAtom);
  const [, setIsSyncing] = useAtom(isSyncingAtom);

  //State

  //Constants

  //Functions
  const updateScene = (updates) => {
    excalidrawAPI?.updateScene(updates);
  };

  const getPixelsPerFeet = (currentDrawing) => {
    const { pixelsPerUnit, correspondingUnit } = currentDrawing;
    return convertToFeet(pixelsPerUnit, correspondingUnit);
  };

  const createEllipseWithNomenclatureText = ({
    floorDrawing,
    groupId,
    startY,
    pixelsPerFeet,
    ellipseDimension,
  }) => {
    const HL_RULER_UNIT_WIDTH = 1 * pixelsPerFeet;
    const { nomenclature } = floorDrawing;
    const [textId, ellipseId] = [generateId(), generateId()];
    const ellipseProps = {
      x: 2 * HORIZONTAL_RULER_MARGIN_X + HL_RULER_UNIT_WIDTH,
      y: startY - ellipseDimension,
      width: ellipseDimension,
      height: ellipseDimension,
    };
    const textElement = newElementWith(basicTextElement, {
      id: textId,
      containerId: ellipseId,
      baseline: 1.75,
      customData: { ...basicTextElement.customData, valueFor: "NOMENCLATURE:" },
      fontSize: 16,
      originalText: nomenclature,
      text: nomenclature,
      textAlign: "center",
      verticalAlign: "middle",
    });
    const ellipseElement = {
      ...propsInCommon,
      id: ellipseId,
      type: "ellipse",
      boundElements: [{ id: textId, type: "text" }],
      groupIds: [groupId],
      height: ellipseProps.height,
      width: ellipseProps.width,
      x: ellipseProps.x,
      y: ellipseProps.y,
    };

    const { textElement: newNameText, rectElement: newNameRect } =
      preventTextWrapping(textElement, ellipseElement);

    redrawTextBoundingBox(newNameText, newNameRect);

    return [ellipseElement, textElement];
  };

  const renderFloorName = ({ drawingProps, lineProps }) => {
    const { name: floorName, groupId, pixelsPerFeet } = drawingProps;
    const [floorNameTextId, floorNameRectId] = [generateId(), generateId()];
    const FLOOR_NAME_PROPS = {
      WIDTH: 12 * pixelsPerFeet,
      HEIGHT: 1.2 * pixelsPerFeet,
      FONT_SIZE: 1.1 * pixelsPerFeet,
    };

    const floorNameText = {
      ...basicTextElement,
      customData: {
        ...basicTextElement.customData,
        valueFor: "DRAWING TITLE:",
        solid: true,
      },
      id: floorNameTextId,
      containerId: floorNameRectId,
      text: floorName.toUpperCase(),
      originalText: floorName.toUpperCase(),
      fontSize: FLOOR_NAME_PROPS.FONT_SIZE,
      textAlign: "left",
      verticalAlign: "middle",
    };

    const floorNameRect = {
      ...basicRectElement,
      id: floorNameRectId,
      boundElements: [{ type: "text", id: floorNameTextId }],
      groupIds: [groupId],
      width: FLOOR_NAME_PROPS.WIDTH,
      height: FLOOR_NAME_PROPS.HEIGHT,
      x: lineProps.x + 0.2 * pixelsPerFeet,
      y: lineProps.y - 1.5 * pixelsPerFeet,
    };

    const { textElement: newNameText, rectElement: newNameRect } =
      preventTextWrapping(floorNameText, floorNameRect);

    redrawTextBoundingBox(newNameText, newNameRect);

    return [floorNameText, floorNameRect];
  };

  const renderOccupancyIdsText = ({ drawingProps, lineProps }) => {
    const { occupancyIds, groupId, pixelsPerFeet } = drawingProps;
    let textToRender = "";
    if (typeof occupancyIds === "object") {
      const drawingOccupancyGroups = [];
      for (const occupancyId of occupancyIds) {
        const occupancy = iconsAndCovers.find((el) => el.id === occupancyId);
        drawingOccupancyGroups.push(occupancy.occupancyGroup);
      }
      textToRender = Array.from(new Set(drawingOccupancyGroups)).join(" / ");
    } else if (typeof occupancyIds === "string") {
      textToRender = occupancyIds;
    }

    const DRAWING_OCCUPANCY = {
      WIDTH: 22.3 * pixelsPerFeet,
      HEIGHT: 1.2 * pixelsPerFeet,
      FONT_SIZE: 1.1 * pixelsPerFeet,
    };

    const [occupancyTextId, occupancyRectId] = [generateId(), generateId()];
    const occupancyRect = {
      ...basicRectElement,
      id: occupancyRectId,
      boundElements: [{ type: "text", id: occupancyTextId }],
      groupIds: [groupId],
      width: DRAWING_OCCUPANCY.WIDTH,
      height: DRAWING_OCCUPANCY.HEIGHT,
      x: lineProps.x + 12.7 * pixelsPerFeet,
      y: lineProps.y - 1.5 * pixelsPerFeet,
    };

    const occupancyText = newElementWith(basicTextElement, {
      id: occupancyTextId,
      customData: {
        ...basicTextElement.customData,
        valueFor: "drawingOccupancies",
        solid: true,
      },
      containerId: occupancyRectId,
      groupIds: [groupId],
      text: `(OCCUPANCY = ${textToRender})`,
      originalText: `(OCCUPANCY = ${textToRender})`,
      fontSize: DRAWING_OCCUPANCY.FONT_SIZE,
      textAlign: "left",
      verticalAlign: "middle",
    });

    const { textElement: newOccText, rectElement: newOccRect } =
      preventTextWrapping(occupancyText, occupancyRect);

    redrawTextBoundingBox(newOccText, newOccRect);

    return [newOccText, newOccRect];
  };

  const renderLowestLevelText = ({ drawingProps, lineProps }) => {
    const { groupId, isLowestLevel, pixelsPerFeet } = drawingProps;
    const [lowestLevelTextId, lowestLevelRectId] = [generateId(), generateId()];

    const IS_LOWEST_LEVEL_PROPS = {
      WIDTH: 22.3 * pixelsPerFeet,
      HEIGHT: 1.6 * pixelsPerFeet,
      FONT_SIZE: 0.88 * pixelsPerFeet,
    };

    const lowestLevelText = {
      ...basicTextElement,
      customData: {
        ...basicTextElement.customData,
        valueFor: "isLowestLevel",
      },
      id: lowestLevelTextId,
      groupIds: [groupId],
      containerId: lowestLevelRectId,
      textAlign: "left",
      verticalAlign: "bottom",
      text: isLowestLevel ? "(LOWEST LEVEL OF FIRE DEPT. VEHICLE ACCESS)" : "",
      originalText: isLowestLevel
        ? "(LOWEST LEVEL OF FIRE DEPT. VEHICLE ACCESS)"
        : "",
      fontSize: IS_LOWEST_LEVEL_PROPS.FONT_SIZE,
    };

    const lowestLevelRect = {
      ...basicRectElement,
      id: lowestLevelRectId,
      boundElements: [{ type: "text", id: lowestLevelTextId }],
      groupIds: [groupId],
      width: IS_LOWEST_LEVEL_PROPS.WIDTH,
      height: IS_LOWEST_LEVEL_PROPS.HEIGHT,
      x: lineProps.x + 12.7 * pixelsPerFeet,
      y: lineProps.y,
    };
    const { textElement: adjustedText, rectElement: adjustedContainer } =
      preventTextWrapping(lowestLevelText, lowestLevelRect);

    redrawTextBoundingBox(adjustedText, adjustedContainer);

    return [adjustedText, adjustedContainer];
  };

  const createLineWithTexts = ({
    floorDrawing,
    groupId,
    marginRight,
    marginBottom,
  }) => {
    const { name, occupancyIds, isLowestLevel } = floorDrawing;
    const pixelsPerFeet = getPixelsPerFeet(floorDrawing);
    const lineWidth = 35 * pixelsPerFeet;
    const lineProps = {
      width: lineWidth,
      x: 2 * HORIZONTAL_RULER_MARGIN_X + marginRight,
      y: marginBottom,
    };
    const [floorNameText, floorNameRect] = renderFloorName({
      drawingProps: { name, pixelsPerFeet, groupId },
      lineProps,
    });
    const [occupancyText, occupancyRect] = renderOccupancyIdsText({
      drawingProps: { groupId, occupancyIds, pixelsPerFeet },
      lineProps,
    });

    const [lowestLevelText, lowestLevelRect] = renderLowestLevelText({
      drawingProps: { groupId, isLowestLevel, pixelsPerFeet },
      lineProps,
    });

    const lineElement = {
      ...propsInCommon,
      id: generateId(),
      groupIds: [groupId],
      type: "line",
      points: [
        [0, 0],
        [lineWidth, 0],
      ],
      width: lineProps.width,
      x: lineProps.x,
      y: lineProps.y,
    };

    return [
      lineElement,
      floorNameText,
      floorNameRect,
      occupancyText,
      occupancyRect,
      lowestLevelText,
      lowestLevelRect,
    ];
  };

  const createDrawingDataElementsOnRuler = ({
    floorDrawing,
    groupId,
    startY,
    pixelsPerFeet,
  }) => {
    const NOMENCLATURE_ELLIPSE_DIM = 4 * pixelsPerFeet;
    const DATA_LINE_MARGIN_X = NOMENCLATURE_ELLIPSE_DIM + pixelsPerFeet;
    const DATA_LINE_MARGIN_Y = startY - NOMENCLATURE_ELLIPSE_DIM / 2;
    const propsToPass = {
      floorDrawing,
      groupId,
      startY,
      pixelsPerFeet,
    };

    const nomenclatureElements = createEllipseWithNomenclatureText({
      ...propsToPass,
      ellipseDimension: NOMENCLATURE_ELLIPSE_DIM,
    });
    const rulerLineWithData = createLineWithTexts({
      ...propsToPass,
      marginRight: DATA_LINE_MARGIN_X,
      marginBottom: DATA_LINE_MARGIN_Y,
    });

    return [...nomenclatureElements, ...rulerLineWithData];
  };

  const getBarWidth = (iterationNumber, pixelsPerFeet) => {
    const HL_RULER_BAR_WIDTH = 10 * pixelsPerFeet;
    let width = 0;
    if (iterationNumber > 5) {
      //For the first five feet.
      width = HL_RULER_BAR_WIDTH; // bar width = 10 feet
    } else if (iterationNumber === 5) {
      width = HL_RULER_BAR_WIDTH / 2; // bar width = 5 feet
    } else {
      width = HL_RULER_BAR_WIDTH / 10; // bar width = 1 feet
    }

    return width;
  };

  const getMeasureText = (i) => {
    let measureText = "";
    if (i < 6) {
      return i;
    }

    switch (i) {
      case 6:
        measureText = 10;
        break;
      case 7:
        measureText = 20;
        break;
      case 8:
        measureText = 30;
        break;
      default:
        measureText = 40;
        break;
    }
    return measureText;
  };

  const createBlackWhiteCells = ({
    iteration,
    start,
    marginY,
    groupId,
    floorDrawing,
    pixelsPerFeet,
  }) => {
    let width = 0;
    let barColor = "#000";
    const bars = [];
    if (iteration % 2 !== 0) {
      barColor = "#fff";
    }

    //Width of every element.
    width = getBarWidth(iteration, pixelsPerFeet);

    //Create black/white unit
    for (let j = 0; j < 2; j++) {
      if (j === 1) {
        barColor = barColor === "#fff" ? "#000" : "#fff";
      }

      const bar = createSceneElement("rectangle", {
        backgroundColor: barColor,
        type: "horizontal-ruler",
        groupId,
        floorDrawing,
        width,
        iterator: j,
        start,
        marginY,
        pixelsPerFeet,
      });

      bars.push(bar);
    }

    return bars;
  };

  const createMeasuredTextsElement = ({
    iteration,
    groupId,
    start,
    floorDrawing,
    pixelsPerFeet,
  }) => {
    const measureTexts = [];
    let measureText = 0;

    //Measure text of every element.
    measureText = getMeasureText(iteration);

    const text = createSceneElement("text", {
      type: "horizontal-ruler",
      measureText,
      groupId,
      start,
      floorDrawing,
      pixelsPerFeet,
    });

    measureTexts.push(text);

    //At the last iteration -> Create the last measure text 40"
    if (iteration === 8) {
      const lastMeasureText = createSceneElement("text", {
        type: "horizontal-ruler",
        measureText: "40",
        groupId,
        start,
        floorDrawing,
        isLastMeasureText: true,
        pixelsPerFeet,
      });
      measureTexts.push(lastMeasureText);
    }

    return measureTexts;
  };

  const createMeasuredBlackWhiteCells = ({
    groupId,
    floorDrawing,
    marginY,
    pixelsPerFeet,
  }) => {
    let start = 0;
    const bars = [];
    const measuredTexts = [];
    const propsToPass = {
      groupId,
      floorDrawing,
      pixelsPerFeet,
    };
    for (let i = 0; i < 9; i++) {
      //Get the start of every element
      if (i > 0) {
        // The element that comes before the preceding element of the current element
        start += bars[2 * i - 2].width;
      }

      bars.push(
        ...createBlackWhiteCells({
          iteration: i,
          start,
          marginY,
          ...propsToPass,
        }),
      );

      measuredTexts.push(
        ...createMeasuredTextsElement({
          iteration: i,
          start,
          ...propsToPass,
        }),
      );
    }

    return [...bars, ...measuredTexts];
  };

  const drawHorizontalScaleRuler = ({ currentDrawing, groupId }) => {
    const { floorDrawing } = initializeRulerDrawing(currentDrawing);
    const pixelsPerFeet = getPixelsPerFeet(floorDrawing);
    const HL_RULER_BAR_WIDTH = 10 * pixelsPerFeet;
    const HL_RULER_UNIT_WIDTH = 1 * pixelsPerFeet;
    const HL_RULER_BAR_HEIGHT = HL_RULER_BAR_WIDTH / 11;
    const horizontalRulerMarginY =
      CONTENT_HEIGHT - HORIZONTAL_RULER_MARGIN_BOTTOM - HL_RULER_UNIT_WIDTH;

    const measuredBlackWhiteCells = createMeasuredBlackWhiteCells({
      floorDrawing,
      groupId,
      marginY: horizontalRulerMarginY,
      pixelsPerFeet,
    });

    const horizontalRulerDataElements = createDrawingDataElementsOnRuler({
      floorDrawing,
      groupId,
      startY: horizontalRulerMarginY - HL_RULER_BAR_HEIGHT - pixelsPerFeet,
      pixelsPerFeet,
    });

    const allHorizontalRulerElements = [
      ...measuredBlackWhiteCells,
      ...horizontalRulerDataElements,
    ];

    return allHorizontalRulerElements;
  };

  const drawVerticalScaleRuler = ({ currentDrawing, groupId }) => {
    const { floorDrawing, bars, measureTexts } =
      initializeRulerDrawing(currentDrawing);
    const pixelsPerFeet = getPixelsPerFeet(floorDrawing);
    const barHeight = pixelsPerFeet * 10;
    let start =
      CONTENT_HEIGHT - 2 * pixelsPerFeet - HORIZONTAL_RULER_MARGIN_BOTTOM + 1;

    for (let i = 0; i < 9; i++) {
      let height = 0;
      let barColor = "#000";
      if (i % 2 !== 0) {
        barColor = "#fff";
      }

      //First 5 inches.
      if (i > 5) {
        height = barHeight;
      } else if (i === 5) {
        height = barHeight / 2;
      } else {
        height = barHeight / 10;
      }

      //Position of every element.
      if (i > 0) {
        start -= bars[2 * i - 2].height;
      }
      let measureText = 0;
      if (i < 6) {
        measureText = i;
      } else {
        measureText = getMeasureText(i);
      }
      const text = createSceneElement("text", {
        type: "vertical-ruler",
        barHeight,
        measureText,
        groupId,
        start,
        floorDrawing,
        margin: 0,
        pixelsPerFeet,
      });

      measureTexts.push(text);

      if (i === 8) {
        const lastMeasureText = createSceneElement("text", {
          type: "vertical-ruler",
          barHeight,
          groupId,
          start,
          floorDrawing,
          measureText: "40",
          height,
          margin: 0,
          pixelsPerFeet,
        });
        measureTexts.push(lastMeasureText);
      }
      for (let j = 0; j < 2; j++) {
        if (j === 1) {
          barColor = barColor === "#fff" ? "#000" : "#fff";
        }
        const bar = createSceneElement("rectangle", {
          backgroundColor: barColor,
          type: "vertical-ruler",
          groupId,
          barHeight,
          floorDrawing,
          height,
          iterator: j,
          start,
          margin: 0,
          pixelsPerFeet,
        });
        bars.push(bar);
      }
    }

    const allVerticalRulerElements = [...bars, ...measureTexts];

    return allVerticalRulerElements;
  };

  function initializeRulerDrawing(currentDrawing) {
    const floorDrawing = currentDrawing.name
      ? currentDrawing
      : currentDrawing[0];
    return {
      floorDrawing,
      bars: [],
      measureTexts: [],
    };
  }

  function preventTextWrapping(textElement, containerElement) {
    const textFont = getFontString(textElement);
    const textElementWidth = measureText(
      textElement.originalText,
      textFont,
      containerElement.width,
    ).width;

    if (textElementWidth > 0.95 * containerElement.width) {
      let textElementSize = textElement.fontSize;
      let newTextWidth = measureText(
        textElement.originalText,
        textElementSize,
      ).width;
      let newTextFont = "";
      do {
        textElementSize -= 0.25;
        Object.assign(textElement, { fontSize: textElementSize });
        newTextFont = getFontString(textElement);
        newTextWidth = measureText(textElement.originalText, newTextFont).width;
      } while (newTextWidth > 0.95 * containerElement.width);
    }

    return { textElement, rectElement: containerElement };
  }

  function createSceneElement(type, props) {
    const HL_RULER_BAR_WIDTH = 10 * props.pixelsPerFeet;
    const HL_RULER_BAR_HEIGHT = HL_RULER_BAR_WIDTH / 11;

    if (type === "text") {
      const textElement = newElementWith(basicTextElement, {
        originalText: `${props.measureText}'`,
        text: `${props.measureText}'`,
        verticalAlign: "top",
        textAlign: "left",
      });
      if (props.type === "horizontal-ruler") {
        return {
          ...textElement,
          id: generateId(),
          fontSize: HL_RULER_BAR_WIDTH / 11,
          groupIds: [props.groupId],
          height: HL_RULER_BAR_WIDTH / 6,
          width: HL_RULER_BAR_WIDTH / 9,
          x:
            2 * HORIZONTAL_RULER_MARGIN_X +
            props.start +
            (props.isLastMeasureText
              ? -13 + (19 * HL_RULER_BAR_WIDTH) / 18
              : -HL_RULER_BAR_WIDTH / 18),
          y: CONTENT_HEIGHT - HORIZONTAL_RULER_MARGIN_BOTTOM,
        };
      }
      return {
        ...textElement,
        id: generateId(),
        fontSize: MARGIN_UNIT,
        groupIds: [props.groupId],
        height: MARGIN_UNIT,
        textAlign: "right",
        verticalAlign: "middle",
        width: HL_RULER_BAR_HEIGHT,
        x: 2 * HORIZONTAL_RULER_MARGIN_X - props.barHeight / 3,
        y: props.start - (props.height || 0),
      };
    } else if (type === "rectangle") {
      const rectangleElement = {
        ...basicRectElement,
        id: generateId(),
        backgroundColor: props.backgroundColor,
        groupIds: [props.groupId],
        strokeColor: "#000000",
        strokeWidth: 1,
      };
      if (props.type === "horizontal-ruler") {
        return {
          ...rectangleElement,
          height: HL_RULER_BAR_HEIGHT,
          width: props.width,
          x: 2 * HORIZONTAL_RULER_MARGIN_X + props.start,
          y: props.marginY - HL_RULER_BAR_HEIGHT * props.iterator,
        };
      } else if (props.type === "vertical-ruler") {
        return {
          ...rectangleElement,
          height: props.height,
          width: props.barHeight / 11,
          x:
            2 * HORIZONTAL_RULER_MARGIN_X -
            props.barHeight / 11 -
            (props.barHeight * props.iterator) / 11,
          y: props.start - props.height,
        };
      }
    }
  }

  const drawScaleRuler = ({ currentDrawing, groupId }) => {
    const horizontalRuler = drawHorizontalScaleRuler({
      currentDrawing,
      groupId,
    });
    const verticalRuler = drawVerticalScaleRuler({ currentDrawing, groupId });

    return [...horizontalRuler, ...verticalRuler];
  };

  const redrawScaleRuler = (drawing, groupId) => {
    const adjustedElements = excalidrawAPI.getSceneElements().map((el) => {
      if (el.customData?.type === "ruler") {
        return newElementWith(el, { ...DELETED_PROPS });
      }
      return el;
    });

    const newRulerElements = drawScaleRuler({
      currentDrawing: drawing,
      groupId,
    });

    updateScene({
      elements: [...adjustedElements, ...newRulerElements],
    });
    setIsSyncing(false);
  };

  const toggleRulerAppearance = (status = null) => {
    let adjustedElements = _.cloneDeep(
      excalidrawAPI
        ?.getSceneElementsIncludingDeleted()
        .filter((el) => !el.customData?.isRemoved),
    );
    adjustedElements = adjustedElements.map((el) => {
      const deleteCondition =
        el.customData?.isImmutable &&
        el.customData?.imageType !== "floor-drawing";

      if (deleteCondition) {
        return newElementWith(el, { isDeleted: status || !el.isDeleted });
      }
      return el;
    });

    updateScene({ elements: adjustedElements });
  };

  const getIfRulerShown = () => {
    const sceneElements = excalidrawAPI.getSceneElements();
    for (const el of sceneElements) {
      //If there's one element has the type of "horizontal/vertical-ruler", then the ruler is shown
      if (
        el.customData?.isImmutable &&
        el.customData?.imageType !== "floor-drawing"
      ) {
        return true;
      }
    }
    return false;
  };

  return {
    drawScaleRuler,
    redrawScaleRuler,
    toggleRulerAppearance,
    getIfRulerShown,
  };
};
