export const dialogToResponse = {
  "& .MuiDialog-paper": {
    width: {
      lg: "500px",
      md: "500px",
      sm: "500px",
      xs: "calc(100% - 32px)",
    },
    margin: "0px",
  },
  "& .MuiDialogContent-root": {
    scrollBehavior: "smooth",
    padding: {
      lg: "0 24px 3px 24px",
      xs: "0 15px 3px 15px",
    },
    overflowX: "hidden",
  },
  "& .MuiAccordion-root:before": {
    backgroundColor: "transparent",
  },
};

export const dialogToResponseForOpener = {
  "& .MuiDialog-paper": {
    margin: {
      lg: "32px",
      md: "32px",
      sm: "0px",
      xs: "0px",
    },
    width: {
      lg: "calc(100% - 64px)",
      xs: "95%",
    },
  },
  "& .MuiDialogContent-root": {
    padding: {
      lg: "20px 24px",
      xs: "20px 15px",
    },
  },
};

export const selectComponentToResponse = {
  "& .MuiSelect-icon": {
    right: {
      xs: "0px",
      sm: "7px",
    },
  },

  "& .MuiOutlinedInput-input": {
    fontSize: {
      lg: "1rem",
      md: "1rem",
      sm: "1rem",
      xs: "0.9rem",
    },

    padding: {
      sm: "16.5px 32px 16.5px 14px",
      xs: "16.5px 15px 16.5px 10px",
    },

    paddingRight: {
      sm: "15px",
      xs: "10px !important",
    },
  },

  "& .MuiInputLabel-root": {
    fontSize: {
      lg: "1rem",
      md: "1rem",
      sm: "1rem",
      xs: "0.9rem",
    },
    left: {
      sm: "0px",
      xs: "0px",
    },
    top: {
      sm: "0px",
      xs: "0px",
    },
  },
};

export const checkBoxLabelToResponse = {
  marginLeft: "0",
  "& .MuiFormControlLabel-root": {
    width: {
      lg: "370px",
      sm: "450px",
      xs: "auto",
    },
  },
  "& .MuiTypography-root": {
    fontSize: {
      lg: "1rem",
      md: "1rem",
      sm: "1rem",
      xs: "0.8rem",
    },
    position: {
      lg: "inherit",
      md: "relative",
      sm: "relative",
    },
    left: {
      lg: "0px",
      md: "0px",
      sm: "0px",
    },
    marginLeft: {
      sm: "0px",
      xs: "12px",
    },
  },
  "@media (max-width: 320px)": {
    "& .MuiTypography-root": {
      fontSize: "0.7rem !important",
      marginLeft: "8px",
    },
  },
};

export const checkBoxToResponse = {
  color: "#5b57d1",
  "&.Mui-checked": {
    color: "#5b57d1",
  },
  width: {
    lg: "1rem",
    sm: "0.9rem",
    xs: "0.9rem",
  },
  position: {
    lg: "relative",
    md: "relative",
    sm: "relative",
    xs: "relative",
  },
  left: {
    lg: "19px",
    md: "-12px",
    sm: "-12px",
    xs: "-22px",
  },
  "@media (max-width: 320px)": {
    left: "13px",
  },
};
