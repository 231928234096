import { CONVERSION_RATES, SQUARED_CONVERSION_RATES } from "./constants";

/* Copyright (C) EZ Fire, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Hamza Younes <hamza@ezfirecontrols.com>, Jan 20th, 2023
 */

export const formatOrder = (order, lastOrder) => {
  return `${String(order).padStart(3, "0")} OF ${String(lastOrder).padStart(
    3,
    "0",
  )}`;
};
export const getDecimalValues = (fraction) => {
  let result = 0;
  fraction
    ?.trim()
    .split(" ")
    .forEach((f) => {
      if (f.includes("/")) {
        const [f1, f2] = f.split("/");
        result += Number(f1) / Number(f2);
      } else {
        result += Number(f);
      }
    });
  return result;
};

const getRange = (start, end, length = end - start + 1) =>
  Array.from({ length }, (_, i) => start + i);

export const getLengthOfRed = (url) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const img = document.createElement("img");

    img.src = url;
    img.crossOrigin = "Anonymous";

    const redRange = getRange(240, 255);

    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");

      ctx.drawImage(img, 0, 0, img.width, img.height);
      const w = ctx.canvas.width;
      const h = ctx.canvas.height;
      const data = ctx.getImageData(0, 0, w, h);
      const buffer = data.data;
      let x;
      let y = 0;
      let p;
      let px;
      let result = [];
      const newResult = [];

      for (; y < h; y++) {
        result = [];
        p = y * 4 * w;

        for (x = 0; x < w; x++) {
          px = p + x * 4;

          if (
            redRange.includes(buffer[px]) &&
            buffer[px + 1] === 0 &&
            buffer[px + 2] === 0
          ) {
            result.push([x, y]);
          }
        }
        if (result.length) {
          newResult.push(result.length);
        }
      }
      resolve(Math.max(...newResult));
    };
  });
};

export const getFromLocalStorage = (key) => {
  return JSON.parse(window.localStorage.getItem(key));
};

export const setToLocalStorage = (key, value, options = null) => {
  if (options?.merge) {
    const previousValue = getFromLocalStorage(key) ?? {};
    const updatedValue = { ...previousValue, ...value };
    window.localStorage.setItem(key, JSON.stringify(updatedValue));
  } else {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const generateId = () => {
  let id = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";

  for (let i = 0; i < 21; i++) {
    id += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return id;
};

export const convertToFeet = (pixelsPerUnit, correspondingUnit) => {
  let convertFactor = 0;
  switch (correspondingUnit) {
    case "m":
      convertFactor = 1 / 3.28084;
      break;
    case "cm":
      convertFactor = 1 / 0.0328084;
      break;
    case "mm":
      convertFactor = 1 / 0.00328084;
      break;
    case "inch":
      convertFactor = 12;
      break;
    default:
      convertFactor = 1;
      break;
  }
  return pixelsPerUnit * convertFactor;
};
export const relateToFeet = (pixelsPerUnit, correspondingUnit) => {
  let convertFactor = 0;
  switch (correspondingUnit) {
    case "m":
      convertFactor = 3.28084;
      break;
    case "cm":
      convertFactor = 0.0328084;
      break;
    case "mm":
      convertFactor = 0.00328084;
      break;
    case "inch":
      convertFactor = 12;
      break;
    default:
      convertFactor = 1;
      break;
  }
  return pixelsPerUnit * convertFactor;
};

export const formatDrawingOrder = (order, lastOrder) => {
  if (!order || !lastOrder) {
    return "";
  }
  return `${order.toString().padStart(3, "0")} OF ${lastOrder
    .toString()
    .padStart(3, "0")}`;
};

export const getLargestOrder = (drawings) => {
  if (!drawings) {
    return;
  }
  return drawings.reduce((largestOrder, currentDrawing) => {
    return Math.max(largestOrder, currentDrawing.order);
  }, 2);
};

export const getUploadingIdOfDrawing = (drawing) => {
  return `${drawing?.projectId}-${drawing?.name
    .toLowerCase()
    .trim()
    .replace(/\s/g, "-")}`;
};

export const getDataFromTimestamp = (timestamp) => {
  const date = new Date(
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000,
  );

  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

export const checkIfObjectRepresentsDate = (dateObject) => {
  if (typeof dateObject === "object") {
    //it means it's a firebase timestamp object
    return dateObject.seconds || dateObject.nanoseconds;
  }
  return false;
};

export const clearDrawingOpenerFromLocalStorage = () => {
  setToLocalStorage(
    "appCustomData",
    {
      projectOfDrawingToOpen: "",
      drawingToOpen: "",
    },
    { merge: true },
  );
};

export const convertFromUnitToUnit = (value, currentUnit, nextUnit) => {
  const conversionRates = CONVERSION_RATES;

  if (currentUnit === nextUnit) {
    return value; // No conversion needed
  }

  if (!conversionRates.hasOwnProperty(currentUnit)) {
    throw new Error(`Conversion rate for ${currentUnit} not defined.`);
  }

  if (!conversionRates[currentUnit].hasOwnProperty(nextUnit)) {
    throw new Error(
      `Conversion rate from ${currentUnit} to ${nextUnit} not defined.`,
    );
  }

  const conversionRate = conversionRates[currentUnit][nextUnit];
  const convertedValue = value * conversionRate;

  return convertedValue;
};

/**
 * @param {Array} coordinates - coordinates as an array of arrays
 * @returns Number - area
 */
export const calculateAreaFromCoordinates = ({
  coordinates,
  pixelsPerUnit,
  unit,
  unitOfArea = "feet",
}) => {
  if (pixelsPerUnit <= 0) {
    throw new Error(
      "You should provide the drawing scale first (pixels per unit)",
    );
  }
  const numPoints = coordinates.length;
  let area = 0;

  for (let i = 0; i < numPoints; i++) {
    const [x1, y1] = coordinates[i];
    const [x2, y2] = coordinates[(i + 1) % numPoints];
    area += x1 * y2 - x2 * y1;
  }

  const areaInPixels = Math.abs(area / 2);
  const convertedScale = convertFromUnitToUnit(pixelsPerUnit, unitOfArea, unit);
  return areaInPixels / Math.pow(convertedScale, 2);
};

export const convertBetweenSquareUnits = (value, currentUnit, desiredUnit) => {
  if (currentUnit === desiredUnit) {
    return value; // No conversion needed
  }

  if (!SQUARED_CONVERSION_RATES.hasOwnProperty(currentUnit)) {
    throw new Error(`Conversion rate for ${currentUnit} not defined.`);
  }

  if (!SQUARED_CONVERSION_RATES[currentUnit].hasOwnProperty(desiredUnit)) {
    throw new Error(
      `Conversion rate from ${currentUnit} to ${desiredUnit} not defined.`,
    );
  }

  const conversionRate = SQUARED_CONVERSION_RATES[currentUnit][desiredUnit];
  const convertedValue = value * conversionRate;

  return roundAndLimitDecimals(convertedValue, 7);
};

export const roundAndLimitDecimals = (number, decimalPlaces) => {
  const roundedNumber =
    Math.round(number * 10 ** decimalPlaces) / 10 ** decimalPlaces;
  return roundedNumber;
};

export const getFloorsOfWorkAsString = (floorsOfWork) => {
  if (!floorsOfWork || floorsOfWork.every((el) => el.type === "coverSheet")) {
    return "NONE";
  }
  return (
    floorsOfWork
      ?.map((el) => el.nomenclature)
      .join(", ")
      .trim()
      .replace(/^,/, "")
      .trim() || ""
  );
};

export const getTotalWorkingArea = (drawings) => {
  return drawings
    .filter((el) => el.type !== "coverSheet")
    .reduce((accArea, currentDrawing) => {
      return accArea + Number(currentDrawing.floorArea);
    }, 0);
};

export const removeIdsFromString = (inputString) => {
  return inputString.replace(/Ids?$|Id$/, "");
};

export const getCodeNames = (allCodeItems, codeObj, key) => {
  if (!allCodeItems?.length) {
    return "";
  }
  if (typeof codeObj[key] === "string") {
    return (
      allCodeItems.find((el) => el.id.trim() === codeObj[key].trim())?.name ||
      ""
    );
  } else if (Array.isArray(codeObj[key])) {
    const names = [];
    for (const c of codeObj[key]) {
      names.push(
        allCodeItems.find((el) => el.id.trim() === c.trim())?.name || "",
      );
    }
    return names;
  }
  return "";
};

export const convertCodesToObjects = (allCodeItems, codes) => {
  codes = codes.map((codeObj) => {
    return Object.keys(codeObj).reduce((acc, key) => {
      const newKey = removeIdsFromString(key);
      const codeName =
        typeof codeObj[key] === "string"
          ? getCodeNames(allCodeItems, codeObj, key)
          : codeObj[key].map((c) => getCodeNames(allCodeItems, c, key));
      acc[newKey] = codeName;
      return acc;
    }, {});
  });

  return codes.reduce((accObject, currentObject) => {
    return { ...accObject, ...currentObject };
  }, {});
};

const orientation = (p, q, r) => {
  const val = (q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1]);
  if (val === 0) {
    return 0;
  }
  return val > 0 ? 1 : 2;
};

const onSegment = (p, q, r) => {
  return (
    q[0] <= Math.max(p[0], r[0]) &&
    q[0] >= Math.min(p[0], r[0]) &&
    q[1] <= Math.max(p[1], r[1]) &&
    q[1] >= Math.min(p[1], r[1])
  );
};

const doIntersect = (p1, q1, p2, q2) => {
  const o1 = orientation(p1, q1, p2);
  const o2 = orientation(p1, q1, q2);
  const o3 = orientation(p2, q2, p1);
  const o4 = orientation(p2, q2, q1);

  if (o1 !== o2 && o3 !== o4) {
    return true;
  }

  if (o1 === 0 && onSegment(p1, p2, q1)) {
    return true;
  }

  if (o2 === 0 && onSegment(p1, q2, q1)) {
    return true;
  }

  if (o3 === 0 && onSegment(p2, p1, q2)) {
    return true;
  }

  if (o4 === 0 && onSegment(p2, q1, q2)) {
    return true;
  }

  return false;
};

export const checkLineIntersections = (points) => {
  if (!points?.length) {
    return;
  }
  const n = points.length;
  if (points[0][0] === points[n - 1][0] && points[0][1] === points[n - 1][1]) {
    return false;
  }
  for (let i = 0; i < n - 1; i++) {
    for (let j = i + 2; j < n - 1; j++) {
      if (doIntersect(points[i], points[i + 1], points[j], points[j + 1])) {
        return true;
      }
    }
  }
  return false;
};

const errorPanelToOpen = (missedDataName) => {
  switch (missedDataName) {
    case "missedPropertyData":
      return "property-panel";
    case "missedProjectData":
      return "project-panel";
    case "missedDrawingData":
      return "floor-panel";
    case "missedCoverSheetData":
      return "cover-sheet-panel";
    default:
      return null;
  }
};

export const openPanelOnError = (missedData) => {
  const missedDataNames = [];
  for (const missingDataKey in missedData) {
    const missingDataArr = missedData[missingDataKey];
    missingDataArr.length && missedDataNames.push(missingDataKey);
  }
  if (missedDataNames.length !== 1) {
    return null;
  }

  return errorPanelToOpen(missedDataNames[0]);
};
