import React from "react";
import { randomInteger } from "../ExcalidrawAPI/random";

import "../scss/SettingField.scss";

const SettingField = ({
  value,
  setValue,
  label,
  options = [],
  placeHolders = [],
  optionClasses = [],
  children,
}) => {
  //State

  //Constants

  //Effects

  //Render
  return (
    <div className="setting-field-container-component">
      <label className="setting-field-label">{label}</label>
      <div className="setting-field-options-container">
        {options.map((el, index) => (
          <div
            key={randomInteger()}
            className={`setting-field-option ${optionClasses.join(" ")}`}
          >
            <label htmlFor={`setting-field-${el}`}>{el}</label>
            {placeHolders.length ? (
              <span className="setting-field-option_placeholder">
                ({placeHolders[index]})
              </span>
            ) : null}
            <input
              type="radio"
              id={`setting-field-${el}`}
              name={`setting-field-${el}`}
              value={el}
              checked={el === value}
              onChange={() => setValue(el)}
            />
          </div>
        ))}
        {children}
      </div>
    </div>
  );
};

export default SettingField;
