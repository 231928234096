export const pageSizes = [
  {
    id: "Letter/ANSI A",
    width: 8.5,
    height: 11,
  },
  {
    id: "Legal/ANSI B",
    width: 8.5,
    height: 14,
  },
  {
    id: "Ledger/ANSI B",
    width: 11,
    height: 17,
  },
  {
    id: "ANSI B",
    width: 17,
    height: 11,
  },
  {
    id: "Executive",
    width: 7.25,
    height: 10.55,
  },
  {
    id: "ANSI C",
    width: 22,
    height: 17,
  },
  {
    id: "ANSI D",
    width: 34,
    height: 22,
  },
  {
    id: "ANSI E",
    width: 44,
    height: 34,
  },
  {
    id: "A0",
    width: 33.11,
    height: 46.81,
  },
  {
    id: "A1",
    width: 23.39,
    height: 33.11,
  },
  {
    id: "A2",
    width: 16.54,
    height: 23.39,
  },
  {
    id: "A3",
    width: 11.69,
    height: 16.54,
  },
  {
    id: "A4",
    width: 8.27,
    height: 11.69,
  },
  {
    id: "A5",
    width: 5.83,
    height: 8.27,
  },
  {
    id: "A6",
    width: 4.13,
    height: 5.83,
  },
  {
    id: "A7",
    width: 2.91,
    height: 4.13,
  },
  {
    id: "A8",
    width: 2.05,
    height: 2.91,
  },
  {
    id: "B0",
    width: 39.37,
    height: 55.67,
  },
  {
    id: "B1",
    width: 27.83,
    height: 39.37,
  },
  {
    id: "B2",
    width: 19.69,
    height: 27.83,
  },
  {
    id: "B3",
    width: 13.9,
    height: 19.69,
  },
  {
    id: "B4",
    width: 9.84,
    height: 13.9,
  },
  {
    id: "B5",
    width: 6.93,
    height: 9.84,
  },
  {
    id: "B6",
    width: 4.92,
    height: 6.93,
  },
  {
    id: "B7",
    width: 3.46,
    height: 4.92,
  },
  {
    id: "B8",
    width: 2.44,
    height: 3.46,
  },
  {
    id: "B9",
    width: 1.73,
    height: 2.44,
  },
  {
    id: "B10",
    width: 1.22,
    height: 1.73,
  },
  {
    id: "C2",
    width: 25.51,
    height: 18.03,
  },
  {
    id: "C3",
    width: 18.03,
    height: 12.76,
  },
  {
    id: "C4",
    width: 12.76,
    height: 9.02,
  },
  {
    id: "C5",
    width: 9.02,
    height: 6.38,
  },
  {
    id: "C6",
    width: 6.38,
    height: 4.49,
  },
  {
    id: "D0",
    width: 42.91,
    height: 30.35,
  },
];

export const pageSizesWithRatioAndText = pageSizes.map((el) => ({
  ...el,
  ratio: el.height / el.width,
  sizeAsText: `${el.id} (${el.height}" X ${el.width}")`,
}));
