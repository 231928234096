import { atom } from "jotai";

import { getFromLocalStorage } from "../helpers/common";

export const orderDrawerAtom = atom(false);
export const isUploadPopupOpenAtom = atom<boolean | undefined>(
  getFromLocalStorage("appCustomData")?.isUploadPopupOpen || false,
);
export const isOpenFromSavedAtom = atom<boolean>(false);
export const isPlacingOnMapAtom = atom<boolean | undefined>(
  getFromLocalStorage("appCustomData")?.isPlacingOnMap || false,
);
export const isLoadingProjectAtom = atom<boolean | null | undefined>(false);
export const isReadyToDesignAtom = atom<boolean | null | undefined>(
  !!location.hash,
);
export const isEditModeAtom = atom(!!location.hash);
export const isDetectingPixelsPerUnitAtom = atom(
  getFromLocalStorage("appCustomData")?.isDetectingPixelsPerUnit || false,
);
export const isSelectingBoundariesAtom = atom(
  getFromLocalStorage("appCustomData")?.isSelectingBoundaries || false,
);
export const isLeavingSceneAtom = atom<boolean>(false);
export const isOpeningSceneAtom = atom<boolean>(false);
export const isLoadingSceneElementsAtom = atom<boolean>(false);
export const isLoadingCurrentProjectAtom = atom<boolean>(false);
export const isSyncingAtom = atom<boolean>(false);
export const expandedUploadPopupPanelAtom = atom<string | undefined | null>(
  !location.hash && !getFromLocalStorage("appCustomData")?.expandedPanel
    ? "project-panel"
    : getFromLocalStorage("appCustomData")?.expandedPanel,
);
export const activeConfirmDialogAtom = atom<string | null>(null);
export const activeLoaderAtom = atom<string | null>(null);
export const floatingSaveDiscardAtom = atom<string | null>(null);
export const isActiveNorthHeadingIndicatorAtom = atom<boolean>(false);
export const activeNorthHeadingIndicatorAngleAtom = atom<number | null>(null);
