import React from "react";

import { useAtom } from "jotai";

import { userPermissionsAtom } from "../store/auth";

import useReactiveUIVars from "../hooks/useReactiveUIVars";

import { collabAPIAtom } from "../ExcalidrawAPI/excalidraw-app/collab/Collab";
import MainMenu from "../ExcalidrawAPI/components/main-menu/MainMenu";
import { useExcalidrawElements } from "../ExcalidrawAPI/components/App";

import Switcher from "./Switcher";
import GridSizeSlider from "./GridSizeSlider";

import PowerSettingsNewSharpIcon from "@mui/icons-material/PowerSettingsNewSharp";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import { setToLocalStorage } from "../helpers/common";

import "../scss/CustomMainMenu.scss";

const CustomMainMenu = ({
  gridSizeValue,
  gridModeValue,
  updateScene,
  setGridModeValue,
  setGridSizeValue,
  signOutHandler,
  changeDrawingBtnHandler,
}) => {
  //Custom Hooks
  const { isReadyToDesign, setActiveConfirmDialog } = useReactiveUIVars();

  //Excalidraw Hooks

  //Atom
  const [collabAPI] = useAtom(collabAPIAtom);
  const [userPermissions] = useAtom(userPermissionsAtom);

  //State

  //Constants

  //Common

  //Effects

  //Functions
  const gridModeHandler = (e) => {
    setGridModeValue(e.target.checked);
    setToLocalStorage(
      "appCustomData",
      { gridMode: !gridModeValue },
      { merge: true },
    );
  };

  const currentElements = useExcalidrawElements();

  const clearCanvasBtnClassName = `${
    currentElements.length > 0 && !location.hash ? "" : "menu-item_disabled"
  }`;

  const homeBtnClassName = `${
    currentElements.length === 0 && !location.hash ? "menu-item_disabled" : ""
  }`;

  const exportImageBtnClassName = `${
    isReadyToDesign ? "" : "menu-item_disabled"
  }`;

  //Render
  return (
    <>
      <MainMenu.ItemCustom>
        <Switcher
          value={gridModeValue}
          valueHandler={gridModeHandler}
          labels={["Hide grid", "View grid"]}
          margin="0.3rem 0 0 0"
        />
      </MainMenu.ItemCustom>
      {gridModeValue && (
        <MainMenu.ItemCustom>
          <GridSizeSlider
            disabled={!gridModeValue}
            updateScene={updateScene}
            gridSizeValue={gridSizeValue}
            setGridSizeValue={setGridSizeValue}
            classes={["customized-grid-size-slider"]}
          />
        </MainMenu.ItemCustom>
      )}
      <MainMenu.Separator />

      {/* {userPermissions.includes("uploadDrawing") && ( */}
      <MainMenu.Item
        className="upload-new-drawing"
        icon={
          <img
            alt="home"
            src="https://outercloud-icons.s3.us-west-2.amazonaws.com/icon_upload.svg"
            style={{ width: "18px", height: "18px" }}
          />
        }
        onSelect={() =>
          changeDrawingBtnHandler({ clicked: "upload", collabAPI })
        }
      >
        <p>Upload</p>
      </MainMenu.Item>
      {/* )} */}

      {/* {userPermissions.includes("openDrawing") && ( */}
      <MainMenu.Item
        className="open-saved-drawing"
        icon={
          <img
            alt="home"
            src="https://outercloud-icons.s3.us-west-2.amazonaws.com/icon_open.svg"
            style={{ width: "16px", height: "16px" }}
          />
        }
        onSelect={() => changeDrawingBtnHandler({ clicked: "open", collabAPI })}
      >
        <p>Open</p>
      </MainMenu.Item>
      {/* )} */}

      <MainMenu.Item
        className={homeBtnClassName}
        icon={
          <img
            alt="home"
            src="https://outercloud-icons.s3.us-west-2.amazonaws.com/icon_home.svg"
            style={{ width: "16px", height: "16px" }}
          />
        }
        onSelect={() => {
          changeDrawingBtnHandler({ clicked: "welcome-screen", collabAPI });
        }}
      >
        <p>Home</p>
      </MainMenu.Item>
      <MainMenu.Item
        className={clearCanvasBtnClassName}
        icon={<HighlightOffRoundedIcon fontSize="large" />}
        onSelect={() => {
          setActiveConfirmDialog("clearCanvas");
        }}
      >
        <p>Clear canvas</p>
      </MainMenu.Item>
      <MainMenu.Separator />

      <MainMenu.DefaultItems.SaveAsImage className={exportImageBtnClassName} />
      {/* <MainMenu.Item
        className="print-floor-drawing"
        icon={<LocalPrintshopOutlinedIcon />}
        onSelect={handlePrint}
      >
        <p>Print</p>
      </MainMenu.Item> */}
      <MainMenu.DefaultItems.Help />
      <MainMenu.Separator />
      <MainMenu.Item
        className="sign-out_menu-option"
        icon={
          <PowerSettingsNewSharpIcon
            fontSize="small"
            sx={{ paddingLeft: "2px" }}
          />
        }
        onSelect={signOutHandler}
      >
        <p>Sign out</p>
      </MainMenu.Item>
    </>
  );
};

export default CustomMainMenu;
