import React from "react";

import { useAtom } from "jotai";
import { isActivePolygonToolAtom } from "../../store/variables";
import { setCursorForShape, updateActiveTool } from "../utils";
import HexagonOutlinedIcon from "@mui/icons-material/HexagonOutlined";
import HexagonRoundedIcon from "@mui/icons-material/HexagonRounded";

const PolygonButton = (props) => {
  //Atom
  const [isActivePolygonTool, setIsActivePolygonTool] = useAtom(
    isActivePolygonToolAtom,
  );

  //General
  const buttonClassNames = `polygon-tool-button ${
    isActivePolygonTool ? "polygon-tool-button_active" : ""
  }`;

  //Functions
  const setPolygonAsActiveTool = () => {
    const builtinLineButton = document.querySelector(
      `label[title="Line — L or 6"] .ToolIcon__icon`,
    );
    builtinLineButton.classList.add("regular-button");
    const nextActiveTool = updateActiveTool(props.appState, {
      type: "line",
    });
    !isActivePolygonTool && setIsActivePolygonTool(true);
    props.setAppState({
      activeTool: nextActiveTool,
      multiElement: null,
      selectedElementIds: {},
    });
    setCursorForShape(props.canvas, {
      ...props.appState,
      activeTool: nextActiveTool,
    });
  };

  //Render
  return (
    <button
      className={buttonClassNames}
      title="Polygon — N or 1"
      onClick={setPolygonAsActiveTool}
    >
      {isActivePolygonTool ? <HexagonRoundedIcon /> : <HexagonOutlinedIcon />}

      <span className="polygon-shortcut">1</span>
    </button>
  );
};

export default PolygonButton;
