import { useAtom } from "jotai";
import polylabel from "polylabel";
import {
  currentDrawingAtom,
  excalidrawApiAtom,
  isSelectingPartAreaAtom,
} from "../store/variables";
import { useCommonFunctionsHook } from "./useCommonFunctionsHook";
import { useElementMethods } from "./useElementMethods";
import {
  mutateElement,
  newElementWith,
} from "../ExcalidrawAPI/element/mutateElement";
import { measureText } from "../ExcalidrawAPI/element/textElement";
import { getFontString } from "../ExcalidrawAPI/utils";
import { redrawTextBoundingBox } from "../ExcalidrawAPI/element";
import { randomId } from "../ExcalidrawAPI/random";
import { calculateAreaFromCoordinates, convertToFeet } from "../helpers/common";
import { createDeviceInDB } from "../helpers/devices";
import { DELETED_PROPS } from "../helpers/constants";
import { getRectanglePoints } from "../helpers/exportedAreasMethods";

export const useAreaMethods = () => {
  //Custom Hooks
  const { doAfterLoadingScene, triggerToast } = useCommonFunctionsHook();
  const { basicEl } = useElementMethods();

  //Atom
  const [excalidrawAPI] = useAtom(excalidrawApiAtom);
  const [currentDrawing] = useAtom(currentDrawingAtom);
  const [, setIsSelectingPartArea] = useAtom(isSelectingPartAreaAtom);

  //State

  //Functions
  const lockAllPartAreaElements = (isToLock = true) => {
    doAfterLoadingScene(() => {
      excalidrawAPI?.updateScene({
        elements: [
          ...excalidrawAPI?.getSceneElements().map((el) => {
            if (el.customData?.isSelectedArea) {
              return mutateElement(el, { locked: isToLock });
            }
            return el;
          }),
        ],
      });
    });
  };

  const lockSpecificElement = (elementId, isToLock = true) => {
    doAfterLoadingScene(() => {
      excalidrawAPI?.updateScene({
        elements: [
          ...excalidrawAPI?.getSceneElements().map((el) => {
            if (el.id === elementId) {
              return mutateElement(el, { locked: isToLock });
            }
            return el;
          }),
        ],
      });
    });
  };

  const convertToRealDim = (dim) => {
    let scale = currentDrawing.pixelsPerUnit;
    if (currentDrawing.correspondingUnit === "inch") {
      scale *= 12;
    }
    return dim / scale;
  };

  const getAreaToBeUploaded = (areaEl) => {
    const areaProps = {
      type: areaEl.type,
      ...(areaEl.type === "line"
        ? { coordinates: areaEl.points }
        : { width: areaEl.width, height: areaEl.height }),
    };

    return {
      id: areaEl.id,
      isNew: true, //in case of a new area to be uploaded
      width: { value: convertToRealDim(areaEl.width), unit: "ft" },
      height: { value: convertToRealDim(areaEl.height), unit: "ft" },
      strokeColor: areaEl.strokeColor,
      backgroundColor: areaEl.backgroundColor,
      strokeWidth: areaEl.strokeWidth,
      type: areaEl.type,
      drawingId: currentDrawing.id,
      points: areaEl.points || [],
      area: calculateArea(areaProps),
      x: areaEl.x,
      y: areaEl.y,
    };
  };

  /**
   * A function to calculate the real area of shapes in square feet
   * @param {string} type - the type of shape
   * @param {number} width - the shape width
   * @param {number} height - the shape height
   * @param {array of objects} coordinates - [{x1, y1}, { x2, y2 }, ...]
   * @returns number - the area in square feet
   */
  const calculateArea = ({ type, width, height, coordinates }) => {
    const scaleToUse = convertToFeet(
      currentDrawing.pixelsPerUnit,
      currentDrawing.correspondingUnit,
    );
    if (type === "ellipse") {
      const a = width / 2;
      const b = height / 2;
      return (Math.PI * a * b) / Math.pow(scaleToUse, 2);
    } else if (type === "diamond") {
      return (width * height) / (2 * Math.pow(scaleToUse, 2));
    } else if (type === "rectangle") {
      return (width * height) / Math.pow(scaleToUse, 2);
    } else if (type === "line") {
      return calculateAreaFromCoordinates({
        coordinates,
        pixelsPerUnit: scaleToUse,
        unit: "feet",
        unitOfArea: "feet",
      });
    }
  };

  const getLastAreaEl = () => {
    const allSceneElements = excalidrawAPI.getSceneElements();
    return allSceneElements[allSceneElements.length - 1];
  };

  const discardLastDrawnArea = () => {
    const lastAreaEl = getLastAreaEl();
    const allSceneElements = excalidrawAPI.getSceneElements();
    excalidrawAPI.updateScene({
      elements: allSceneElements.map((el) => {
        if (el.id === lastAreaEl.id) {
          return newElementWith(el, DELETED_PROPS);
        }
        return el;
      }),
      appState: { selectedElementIds: {} },
    });
    setIsSelectingPartArea(false);
  };

  const addLabelToArea = (label, areaId) => {
    const labelTextElId = randomId();
    const containerEl = excalidrawAPI
      .getSceneElements()
      .find((el) => el.id === areaId);

    let labelLocation = getLabelLocationInPolygon(containerEl);
    const labelTextProps = measureText(
      label,
      getFontString({ fontSize: 22, fontFamily: 3 }),
    );
    labelLocation = {
      x: labelLocation.x - labelTextProps.width / 2,
      y: labelLocation.y - labelTextProps.height / 2,
    };
    const labelTextEl = newElementWith(
      { id: labelTextElId, type: "text", ...basicEl },
      {
        customData: { isImmutable: true, isSelectedArea: true, areaId },
        text: label,
        originalText: label,
        verticalAlign: "middle",
        textAlign: "center",
        fontFamily: 3,
        fontSize: 22,
        ...labelTextProps,
        ...(labelLocation ? labelLocation : {}),
      },
    );

    const updatedContainerEl = newElementWith(containerEl, {
      customData: { ...containerEl.customData, label },
    });

    labelLocation && mutateElement(labelTextEl, labelLocation);

    excalidrawAPI.updateScene({
      elements: [
        ...excalidrawAPI.getSceneElements().map((el) => {
          if (el.id === areaId) {
            return updatedContainerEl;
          }
          return el;
        }),
        labelTextEl,
      ],
    });
  };

  const updateLabelArea = (label, areaId) => {
    const containerEl = excalidrawAPI
      .getSceneElements()
      .find((el) => el.id === areaId);
    const labelTextElId = containerEl.boundElements[0]?.id;
    const updatedContainerEl = newElementWith(containerEl, {
      customData: { ...containerEl.customData, label },
    });
    const updatedTextEl = newElementWith(
      excalidrawAPI.getSceneElements().find((el) => el.id === labelTextElId),
      { text: label, originalText: label },
    );
    redrawTextBoundingBox(updatedTextEl, updatedContainerEl);
    excalidrawAPI.updateScene({
      elements: [
        ...excalidrawAPI.getSceneElements().map((el) => {
          if (el.id === labelTextElId) {
            return updatedTextEl;
          }
          return el;
        }),
      ],
    });
  };

  const addOrUpdateAreaLabel = (label, areaId, update) => {
    if (update) {
      updateLabelArea(label, areaId);
    } else {
      addLabelToArea(label, areaId);
    }
  };

  const deleteAreaElementsByIds = (areaElements) => {
    const allSceneElements = excalidrawAPI.getSceneElements();
    excalidrawAPI.updateScene({
      elements: allSceneElements.map((el) => {
        if (
          areaElements.includes(el.id) ||
          areaElements.includes(el.customData?.docId) ||
          (areaElements.includes(el.containerId) &&
            el.customData?.isSelectedArea &&
            el.type === "text")
        ) {
          return newElementWith(el, DELETED_PROPS);
        }
        return el;
      }),
      appState: { selectedElementIds: {} },
    });
    setIsSelectingPartArea(false);
  };

  const isPointInRectangle = (droppedObj, rect) => {
    const { x, y, width, height } = droppedObj;
    return (
      x >= rect.x &&
      x <= rect.x + rect.width &&
      x + width <= rect.x + rect.width &&
      y >= rect.y &&
      y <= rect.y + rect.height &&
      y + height <= rect.y + rect.height
    );
  };

  const isOnePointInRectangle = (point, rect) => {
    const { x, y } = point;
    return (
      x >= rect.x &&
      x <= rect.x + rect.width &&
      y >= rect.y &&
      y <= rect.y + rect.height
    );
  };

  const isPointInEllipse = (point, ellipse) => {
    const { x, y } = point;
    const a = ellipse.width / 2; // Semi-major axis
    const b = ellipse.height / 2; // Semi-minor axis
    const h = ellipse.x + a; // x-coordinate of the center
    const k = ellipse.y + b; // y-coordinate of the center

    const formula = (x - h) ** 2 / a ** 2 + (y - k) ** 2 / b ** 2;

    return formula <= 1;
  };

  const isPointInPolygon = (p, polygon) => {
    p = [p.x, p.y];
    const vertices = polygon.points.map((p) => [
      p[0] + polygon.x,
      p[1] + polygon.y,
    ]);

    let odd = false;
    for (let i = 0, j = vertices.length - 1; i < vertices.length; i++) {
      if (
        vertices[i][1] > p[1] !== vertices[j][1] > p[1] &&
        p[0] <
          ((vertices[j][0] - vertices[i][0]) * (p[1] - vertices[i][1])) /
            (vertices[j][1] - vertices[i][1]) +
            vertices[i][0]
      ) {
        odd = !odd;
      }
      j = i;
    }
    return odd;
  };

  const isWholeObjectInEllipse = (object, polygon) => {
    const { x, y, width, height } = object;
    const objectPoints = [
      { x, y },
      { x: x + width, y },
      { x, y: y + height },
      { x: x + width, y: y + height },
    ];
    let truthCount = 0; //If truthCount equals objectPoints.length => whole object located ellipse

    for (const p of objectPoints) {
      isPointInEllipse(p, polygon) && truthCount++;
    }

    return truthCount === objectPoints.length;
  };

  const isWholeObjectInPolygon = (object, polygon) => {
    const { x, y, width, height } = object;
    const objectPoints = [
      { x, y },
      { x: x + width, y },
      { x, y: y + height },
      { x: x + width, y: y + height },
    ];
    let truthCount = 0; //If truthCount equals objectPoints.length => whole object located ellipse

    for (const p of objectPoints) {
      isPointInPolygon(p, polygon) && truthCount++;
    }

    return truthCount === objectPoints.length;
  };

  const checkIfDroppedInArea = (droppedObj, container) => {
    if (container.type === "rectangle") {
      return isPointInRectangle(droppedObj, container);
    } else if (container.type === "ellipse") {
      return isWholeObjectInEllipse(droppedObj, container);
    } else if (container.type === "line") {
      return isWholeObjectInPolygon(droppedObj, container);
    }
  };

  const checkIfDblClickedOnArea = (point, area) => {
    if (area.type === "rectangle") {
      return isOnePointInRectangle(point, area);
    } else if (area.type === "ellipse") {
      return isPointInEllipse(point, area);
    } else if (area.type === "line") {
      return isPointInPolygon(point, area);
    }
  };

  const checkIfDroppedInAreaHandler = (deviceSceneEl) => {
    //Get the current areas.
    const allSceneElements = excalidrawAPI.getSceneElements();
    const currentAreas = allSceneElements.filter(
      (el) => el.customData?.isSelectedArea,
    );

    let updatedDeviceSceneEl = null;

    for (const a of currentAreas) {
      if (checkIfDroppedInArea(deviceSceneEl, a)) {
        updatedDeviceSceneEl = newElementWith(deviceSceneEl, {
          locked: true,
          customData: { ...deviceSceneEl.customData, areaId: a.id },
        });
        triggerToast(
          `Device assigned to ${a.customData.label || "UNNAMED"} area`,
          "success",
        );
        break;
      }
    }

    return updatedDeviceSceneEl;
  };

  const assignDroppedDeviceToArea = (element, updatedDeviceSceneEl) => {
    const allSceneElements = excalidrawAPI.getSceneElements();
    const foundAreas = !!allSceneElements.filter(
      (el) => el.customData?.isSelectedArea,
    ).length;
    const message = foundAreas
      ? "Device dropped outside all areas"
      : "No available areas to assign dropped device to!";
    excalidrawAPI.updateScene({
      elements: allSceneElements.map((el) => {
        if (el.customData?.docId === element.customData?.docId) {
          if (updatedDeviceSceneEl) {
            return updatedDeviceSceneEl;
          }
          triggerToast(message, "error");
          return newElementWith(el, { ...DELETED_PROPS });
        }
        return el;
      }),
      appState: !updatedDeviceSceneEl ? { selectedElementIds: {} } : {},
    });
  };

  const addAssignedDeviceToDB = async (element) => {
    const { drawingId, deviceId, docId, areaId } = element.customData ?? {};
    try {
      await createDeviceInDB({ docId, deviceId, drawingId, areaId });
      // addDesignerId();
    } catch (error) {
      console.error("Couldn't save the device in the DB: ", error);
      //Remove the dropped device in case of an error happened during saving.
      const excalidrawElements = excalidrawAPI?.getSceneElements();
      excalidrawAPI.updateScene({
        elements: excalidrawElements.map((el) => {
          if (el.customData?.docId !== docId) {
            return newElementWith(el, { ...DELETED_PROPS });
          }
          return el;
        }),
      });
    }
  };

  const getLabelLocationInPolygon = (polygon) => {
    let points = [];
    let absolutePoints = [];
    if (polygon.type === "line") {
      absolutePoints = polygon.points.map((p) => {
        return [p[0] + polygon.x, p[1] + polygon.y];
      });
    } else {
      points = getRectanglePoints(polygon);
      for (let i = 0; i < points.length; i += 2) {
        absolutePoints.push([points[i], points[i + 1]]);
      }
    }
    const centroid = polylabel([absolutePoints]);
    return { x: centroid[0], y: centroid[1] };
  };

  //Return
  return {
    lockAllPartAreaElements,
    lockSpecificElement,
    calculateArea,
    discardLastDrawnArea,
    deleteAreaElementsByIds,
    addOrUpdateAreaLabel,
    getAreaToBeUploaded,
    checkIfDroppedInArea,
    checkIfDroppedInAreaHandler,
    assignDroppedDeviceToArea,
    addAssignedDeviceToDB,
    convertToRealDim,
    checkIfDblClickedOnArea,
    getLabelLocationInPolygon,
  };
};
