import React, { useState, useEffect } from "react";

import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Slide,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";

import { dialogToResponseForOpener } from "../helpers/responsiveness.js";

import LoadingButton from "./LoadingButton.jsx";
import CloseButton from "./CloseButton.jsx";

import "../scss/savedDrawingOpener.scss";
import ConfirmDialog from "../ExcalidrawAPI/components/ConfirmDialog";
import { t } from "../ExcalidrawAPI/i18n";
import { INITIAL_COVER_SHEET_OBJ } from "../helpers/constants.js";
import { useSetAtom } from "jotai";
import { projectToPreloadAtom } from "../store/variables";

const SavedDrawingOpener = ({
  isOpened,
  setIsOpened,
  excalidrawAPI,
  projects,
  isLoadingProjects,
  isLoadingCurrentProject,
  onCloseHandler,
  allDrawings,
  leaveSceneHandler,
  openScene,
  uploadPage,
}) => {
  //Atom
  const setProjectToPreload = useSetAtom(projectToPreloadAtom);

  //Refs

  //Constants
  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //State
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedDrawing, setSelectedDrawing] = useState("");
  const [selectedDrawings, setSelectedDrawings] = useState([]);
  const [isLoadingDrawings, setIsLoadingDrawings] = useState(true);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  //Effects
  useEffect(() => {
    if (selectedProject && excalidrawAPI && !isLoadingProjects) {
      setSelectedProject(selectedProject);
    }
  }, [excalidrawAPI, selectedProject, isLoadingProjects]);

  useEffect(() => {
    const filteredDrawings =
      allDrawings?.filter((el) => el.projectId === selectedProject) || [];
    setSelectedDrawings(filteredDrawings);
    setIsLoadingDrawings(false);

    //NOTE - Check if the drawing to open is existed.
    if (
      filteredDrawings.length > 0 &&
      !filteredDrawings.find((el) => el.id === selectedDrawing)
    ) {
      if (selectedDrawing === "coverSheet") {
        setSelectedDrawing(
          filteredDrawings.find((el) => el.type === "coverSheet").id,
        );
      } else {
        setSelectedDrawing("");
      }
    }
  }, [allDrawings, selectedProject]);

  useEffect(() => {
    if (
      !selectedDrawings.some((c) => c.type === "coverSheet") &&
      selectedDrawings.length
    ) {
      setSelectedDrawings([INITIAL_COVER_SHEET_OBJ, ...selectedDrawings]);
    }
  }, [selectedDrawings]);

  //Functions
  const selectProjectHandler = (event) => {
    setSelectedDrawing("");
    setSelectedProject(event.target.value);
  };

  const confirmHandler = async () => {
    await leaveSceneHandler();
    const project = projects.find((el) => el.id === selectedProject);
    openScene(
      selectedDrawing,
      selectedDrawings.find((el) => el.id === selectedDrawing).type ===
        "coverSheet",
      project,
    );
    setIsConfirmDialogOpen(false);
    setIsOpened(false);
  };

  const discardHandler = () => {
    setIsConfirmDialogOpen(false);
    setIsBtnLoading(false);
  };

  const selectDrawingHandler = (event) => {
    setSelectedDrawing(event.target.value);
  };

  const closeBtnHandler = () => {
    setIsOpened(false);
    onCloseHandler();
    setSelectedProject("");
  };

  //Common

  //Render
  return (
    <>
      {!isConfirmDialogOpen && (
        <Dialog
          open={isOpened}
          TransitionComponent={Slide}
          onClose={onCloseHandler}
          aria-describedby="alert-dialog-slide-description"
          sx={dialogToResponseForOpener}
        >
          <DialogContent className="saved-drawing-dialog">
            <CloseButton
              onClick={() => {
                setIsOpened(false);
                onCloseHandler();
              }}
            />

            {isLoadingProjects ? (
              <Skeleton
                variant="rounded"
                height={56}
                width={552}
                sx={{ margin: "8px 0 16px 0" }}
              />
            ) : (
              <FormControl
                id="projects-dropdown-container_opener"
                sx={{ width: "100%" }}
              >
                <InputLabel id="projects-dropdown-label">Project</InputLabel>
                <Select
                  labelId="projects-dropdown-label"
                  id="projects-dropdown"
                  value={projects.length > 0 ? selectedProject : ""}
                  label="Project"
                  onChange={selectProjectHandler}
                  MenuProps={MenuProps}
                >
                  {projects.length === 0 && !isLoadingProjects && (
                    <MenuItem key="No-Projects" disabled={true}>
                      Oops, no projects found
                    </MenuItem>
                  )}

                  {projects
                    .sort((a, b) => -b.projectName.localeCompare(a.projectName))
                    .map((p) => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.projectName} {p.projectName ? "-" : ""} {p.id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

            {isLoadingProjects || isLoadingCurrentProject ? (
              <Skeleton variant="rounded" height={56} />
            ) : (
              <FormControl
                id="drawings-dropdown-container"
                sx={{ width: "100%" }}
                disabled={!selectedProject}
              >
                <InputLabel id="drawings-dropdown-label">Drawing</InputLabel>
                <Select
                  labelId="drawings-dropdown-label"
                  id="drawings-dropdown"
                  value={selectedDrawings.length > 0 ? selectedDrawing : ""}
                  label="Drawing"
                  onChange={selectDrawingHandler}
                  MenuProps={MenuProps}
                >
                  {selectedDrawings.length === 0 && !isLoadingDrawings && (
                    <div className="no_drawings_option_container">
                      <span>Oops, no drawings found</span>
                      <LoadingButton
                        onClick={() => {
                          setIsOpened(false);
                          setProjectToPreload(selectedProject);
                          uploadPage({ clicked: "upload" });
                        }}
                        label="Add new"
                        title="Add a new drawing"
                        classes={["upload-popup-submit-btn"]}
                      />
                    </div>
                  )}

                  {selectedDrawings.map((d) => (
                    <MenuItem
                      key={d.id}
                      value={d.id}
                      disabled={d.roomKey === location.hash}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        textTransform: "capitalize",
                      }}
                    >
                      {d.name}{" "}
                      <kbd>
                        <i>
                          {d.roomKey === location.hash && !isBtnLoading
                            ? "Opened"
                            : ""}
                        </i>
                      </kbd>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <section className="buttons-section">
              <Button
                variant="outlined"
                color="error"
                sx={{ marginRight: "20px" }}
                onClick={closeBtnHandler}
              >
                Cancel
              </Button>
              <LoadingButton
                // ref={submitBtn}
                disabled={
                  !selectedDrawing ||
                  !selectedProject ||
                  isLoadingCurrentProject
                }
                onClick={() => {
                  if (location.hash) {
                    setIsConfirmDialogOpen(true);
                  } else {
                    const project = projects.find(
                      (el) => el.id === selectedProject,
                    );
                    openScene(
                      selectedDrawing,
                      selectedDrawings.find((el) => el.id === selectedDrawing)
                        .type === "coverSheet",
                      project,
                    );
                  }
                }}
                label="Open"
                classes={["upload-popup-submit-btn"]}
                isBtnLoading={isBtnLoading}
                setIsBtnLoading={setIsBtnLoading}
              />
            </section>
          </DialogContent>
        </Dialog>
      )}
      {isConfirmDialogOpen && (
        <ConfirmDialog
          title={t("leaveSceneDialog.title")}
          onConfirm={confirmHandler}
          onCancel={discardHandler}
        >
          <p className="clear-canvas__content"> {t("alerts.leaveScene")}</p>
        </ConfirmDialog>
      )}
    </>
  );
};

export default SavedDrawingOpener;
